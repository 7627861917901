import React, { useState, useRef } from "react";
import SelectLoanTypeComponent from "../appication/selectLoanTypeComponent.jsx" 
import TitleComponent from "../title/titleComponent.jsx";
import LoanCalculationsForm from "./loanCalculations.jsx"
import ClientDetailsForm from "./ClientDetailsForm.jsx"
import FileUploads from "./FileUploads.jsx"
import ConfirmationForm from "./ConfirmationForm.jsx"
import { Toast } from 'primereact/toast';


const CreateNewPolicyPage = () => {


    const viewList = [
        <SelectLoanTypeComponent />,
        <ClientDetailsForm />,
        <LoanCalculationsForm />, 
        <FileUploads />, 
        <ConfirmationForm />, 

    ]
    const viewListTitles = [
        { "topHeading": "Select a loan product right for you", "middleHeading": "Apply for a new loan!!" },
        { "topHeading": "Personal Details", "middleHeading": "Confirm your personal details" },
        { "topHeading": "Fill in the Loan Details", "middleHeading": "Fill in the loan details & proceed with a loan right for you!" },
        { "topHeading": "Upload your documents", "middleHeading": "Upload your documents." },
        { "topHeading": "Confirm your policy details", "middleHeading": "Confirm whether your information is accurate" },
    ];
    const [activeView, setactiveView] = useState(0);
    const [userLoanApplicationData, setUserLoanApplicationData] = useState({});

    const toast = useRef(null);
    const moveToNextView = () => {
        setactiveView(activeView + 1);
        if (activeView < viewList.length) setactiveView(activeView + 1);
    }
    const moveToPreviousView = () => {
        if (activeView !== 0) setactiveView(activeView - 1);
    } 


    return (
        <>
            <div className="">
                <div className="container ">
                <Toast ref={toast} />
                    {React.cloneElement(<TitleComponent />, {
                        titles: viewListTitles[activeView],
                        moveToPreviousView: moveToPreviousView,
                        activeView: activeView,
                        userLoanApplicationData: userLoanApplicationData
                    })}
                    {React.cloneElement(viewList[activeView], {
                        moveToNextView: moveToNextView,
                        setUserLoanApplicationData: setUserLoanApplicationData,
                        userLoanApplicationData: userLoanApplicationData,
                        titles: viewListTitles[activeView],
                        toast: toast
                    })}
                </div>
            </div>

        </>
    );
};

export default CreateNewPolicyPage;