import axios from "axios";
import React, { useState, useRef } from "react";
import { useMountEffect } from 'primereact/hooks';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from "primereact/divider";
import { InputNumber } from 'primereact/inputnumber';
import { Skeleton } from 'primereact/skeleton';
import { Timeline } from 'primereact/timeline';
import { Toast } from 'primereact/toast';
import { useUpdateEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';

const LoanCalculations = ({ moveToNextView, moveToPreviousView, userLoanApplicationData, setUserLoanApplicationData }) => {


  const handleSubmit = (e) => {
    e.preventDefault();

  };


  useMountEffect(() => {
    console.log("userLoanApplicationData?.userData[0]?.entityId : " + userLoanApplicationData?.userData[0]?.entityId)
    console.log(userLoanApplicationData.userData)
    // fetchBanks(); 
  });

  const [loading, setloading] = useState(false);
  const moveToNext = (e) => {
    e.preventDefault();
    if (canContinue) {
      setUserLoanApplicationData({
        ...userLoanApplicationData, numberOfMonths: numberOfMonths, loanAmount: loanAmount, netSalary: netSalary
        , loanCalculationData: loanCalculationData,
        paymentSchedule: paymentSchedule
      })
      moveToNextView();
    }

  }

  const [loanAmount, setLoanAmount] = useState(0)
  const [loanCalculationData, setLoanCalculationData] = useState(null)
  const [paymentSchedule, setPaymentSchedule] = useState([])
  const [numberOfMonths, setNumberOfMonths] = useState(0)
  const [interestRate, setInterestRate] = useState(20)
  const [netSalary, setNetSalary] = useState(0)
  const [fortyPercentNetSalary, setFortyPercentNetSalary] = useState(0)
  const [canContinue, setCanContinue] = useState(false)

  const toast = useRef(null);
  useUpdateEffect(() => {
    setFortyPercentNetSalary(0.4 * netSalary);
    if (msgs.current) msgs.current.clear();
    setCanContinue(false);
    setLoanCalculationData({});
    setPaymentSchedule({})
  }, [netSalary, numberOfMonths, loanAmount]);

  const msgs = useRef(null);
  const showFormRef = useRef(null);

  const getCalculateScheduleData = async () => {
    setLoanCalculationData({});
    setPaymentSchedule({})
    var payload = {
      "operation": "clientCreation",
      "accessToken": "1234",
      "requestBody": {
        "dateFormat": "dd MMMM yyyy",
        "locale": "en_GB",
        "productId": userLoanApplicationData?.selectedProduct?.id,
        "clientId": userLoanApplicationData?.userData[0]?.entityId,
        "principal": loanAmount,
        "loanType": "individual",
        "loanTermFrequency": numberOfMonths,
        "loanTermFrequencyType": userLoanApplicationData?.selectedProduct?.repaymentFrequencyType?.id,
        "numberOfRepayments": numberOfMonths,
        "repaymentEvery": 1,
        "repaymentFrequencyType": userLoanApplicationData?.selectedProduct?.repaymentFrequencyType?.id,
        "interestRatePerPeriod": userLoanApplicationData?.selectedProduct?.interestRatePerPeriod,
        "amortizationType": userLoanApplicationData?.selectedProduct?.amortizationType?.id,
        "interestType": userLoanApplicationData?.selectedProduct?.interestType?.id,
        "interestCalculationPeriodType": userLoanApplicationData?.selectedProduct?.interestCalculationPeriodType?.id,
        "submittedOnDate": new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' '),
        "expectedDisbursementDate": new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' '),
        "transactionProcessingStrategyId": userLoanApplicationData?.selectedProduct?.transactionProcessingStrategyId
      }
    }
    setloading(true)
    try {


      await axios.post(process.env.REACT_APP_DOVESAPI + 'musoni/loans/calculateSchedule',
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + (localStorage.getItem('token'))
          }
        }).then((response) => {
          setloading(false)
          if (response.data.status === "SUCCESS" && response.data.responseBody != null) {
            calculateValues(response.data?.responseBody);
          } else {

            console.log(response.data.message)
          }
        });

    } catch (error) {
      setloading(false)
      console.error('Error fetching balance details:', error);
    }
  };

  const customizedContent = (item) => {

    return (
      <div>
        <small> {item.period ? "Payment number " + item.period : "Start"}  </small><br />
        <small> Due on Date: </small><h3><strong>{item?.dueDate[2] + "-" + item?.dueDate[1] + "-" + item?.dueDate[0]}</strong></h3>
        <ul>
          <li className='row '>
            <div className='col-md-12 text-left'>
              <p><strong>Amount due : {item.totalInstallmentAmountForPeriod ? "$" + item.totalInstallmentAmountForPeriod : "$0"}</strong></p>
            </div>
          </li>

        </ul>
      </div>
    );
  };

  const calculateValues = (loanCalculationData) => {

    console.log(loanCalculationData)


    if (msgs.current) msgs.current.clear();
    if ((0.4 * netSalary) >= loanCalculationData?.periods[1]?.totalDueForPeriod) {
      msgs.current.show({ severity: 'success', summary: 'Yes!', detail: 'You qualify to apply for this loan.\n Kindly read the loan repayment schedule before continuing.', sticky: true, closable: false },);
      msgs.current.show({ severity: 'info', summary: 'Please note:', detail: 'If you have other existing loans further approval checks might disqualify you. ', sticky: true, closable: false },);
      setCanContinue(true);
      setLoanCalculationData(loanCalculationData);
      setPaymentSchedule(loanCalculationData?.periods)
      showFormRef.current.collapse()
    } else {
      msgs.current.show({ severity: 'warn', summary: 'Unfortunately', detail: 'You do not qualify to apply for this loan because your details do not meet the requirements of this loan.', sticky: true, closable: false },);
      setCanContinue(false);
      showFormRef.current.expand()
    }
  }
  return (
    <div>
      <BlockUI blocked={loading}>
        <center>

          <div className="row  ">
            <Card className="m-2 col-xxl-8 col-xl-4 col-lg-4">
              <Messages ref={msgs} />
              {
                loading ?
                  <>
                    <div className="border-round border-1 surface-border p-4 surface-card">
                      <div className="flex mb-3">
                        <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem"></Skeleton>
                        </div>
                      </div>
                      <Skeleton width="100%" height="150px"></Skeleton>
                      <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                      </div>
                    </div></>
                  : <>

                    {
                      loanCalculationData && Object.keys(loanCalculationData).length !== 0 &&
                      <>
                        <div className="mt-4 mb-2">
                          <Divider align="center" type="dashed" >
                            <div className="inline-flex align-items-left">
                              <h3><i className="pi pi-file mr-2"></i>Loan Schedule Detail Below : </h3>
                            </div>
                          </Divider>
                          <div>
                            <Divider align="center" type="dashed" >
                              <div className="inline-flex align-items-left">
                                <h5><i className="pi pi-file mr-2"></i>Repayment Schedule : </h5>
                              </div>
                            </Divider>
                            <div>
                              {paymentSchedule && Object.keys(paymentSchedule).length !== 0 && <Timeline value={paymentSchedule} content={customizedContent} />}
                            </div>
                            <Divider align="center" type="dashed" >
                              <div className="inline-flex align-items-left">
                                <h5><i className="pi pi-file mr-2"></i>Repayment Details : </h5>
                              </div>
                            </Divider>
                            <ul>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Total Interest Amount</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {loanCalculationData?.currency?.displayLabel}{loanCalculationData?.totalInterestCharged}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Total Principal Disbursed</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {loanCalculationData?.currency?.displayLabel}{loanCalculationData?.totalPrincipalDisbursed}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Total Repayment Amount</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {loanCalculationData?.currency?.displayLabel}{loanCalculationData?.totalRepaymentExpected}</p>
                                </div>
                              </li>


                              {/* {product.description.map((element, index) => (
                                <li key={index}>{element}</li>
                            ))} */}
                            </ul>
                          </div>
                        </div>
                      </>
                    }
                    <Panel ref={showFormRef} toggleable>
                      <Divider align="center" type="dashed">
                        <div className="inline-flex align-items-left">
                          <h3><i className="pi pi-file mr-2"></i>Enter the loan details below : </h3>
                        </div>
                      </Divider>
                      <div className="inline-flex text-left">
                        <div className="row m-2">
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <label htmlFor="loanAmount" className="font-bold block mb-2"><strong>Loan Amount ({userLoanApplicationData?.selectedProduct?.currency?.displayLabel})</strong></label>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <InputNumber inputId="minmaxfraction" value={loanAmount} onValueChange={(e) => setLoanAmount(e.value)}
                              prefix={userLoanApplicationData?.selectedProduct?.currency?.displaySymbol + " "}
                              min={userLoanApplicationData?.selectedProduct?.minPrincipal} max={userLoanApplicationData?.selectedProduct?.maxPrincipal} />

                          </div>

                        </div>
                        <div className="row m-2">
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <label htmlFor="months" className="font-bold block mb-2"><strong>Period (Months)</strong></label>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <InputNumber inputId="months" value={numberOfMonths} onValueChange={(e) => setNumberOfMonths(e.value)}
                              min={userLoanApplicationData?.selectedProduct?.minNumberOfRepayments}
                              max={userLoanApplicationData?.selectedProduct?.maxNumberOfRepayments}
                              suffix={" " + userLoanApplicationData?.selectedProduct?.repaymentFrequencyType?.value} />
                          </div>


                        </div>
                        <div className="row m-2">
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <label htmlFor="netsalary" className="font-bold block mb-2"><strong>Net Salary ({userLoanApplicationData?.selectedProduct?.currency?.displayLabel})</strong></label>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <InputNumber inputId="netsalary" value={netSalary} onValueChange={(e) => setNetSalary(e.value)}
                              prefix={userLoanApplicationData?.selectedProduct?.currency?.displaySymbol + " "}
                              min={1} />
                          </div>
                        </div>
                        <div className="card flex justify-content-center">

                        </div>
                        {(fortyPercentNetSalary > 1) &&
                          <div className="row m-2">
                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                              <label htmlFor="netsalary" className="font-bold block mb-2"><strong>40 % of Net Salary </strong></label>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                              <p>{userLoanApplicationData?.selectedProduct?.currency?.displayLabel}{fortyPercentNetSalary}</p>
                            </div>
                          </div>}
                      </div>
                    </Panel>



                    <div className="row m-2">
                      <center >

                        <Button
                          className='justify-content-center text-center mt-4'
                          size='medium'
                          label={<strong className='blue-text'>{"Calculate Payment Schedule"}</strong>}
                          severity="info"
                          rounded
                          outlined
                          raised
                          onClick={() => getCalculateScheduleData()}
                        />

                        {canContinue && <div className="text-center mt-3">
                          <Button
                            onClick={moveToNext}
                            style={{ backgroundColor: '#084b9b', border: '1px solid #084b9b' }}
                            className="justify-content-center text-center  "
                            size="large"
                            label={<>Continue</>}
                            icon="pi pi-arrow-right"
                            severity="info"
                            rounded
                            raised


                          />
                        </div>}

                      </center>
                    </div>

                  </>
              } </Card>

            <Card className="m-2 col-xxl-3 col-xl-12 col-lg-12">
              <Divider align="center" type="dashed">
                <div className="inline-flex align-items-center">
                  <i className="pi pi-check-circle mr-2"></i>
                  <b>Selected Loan Product</b>
                </div>
              </Divider>
              <div>
                <ul>
                  <li className='row '>
                    <div className='col-md-6 text-left'>
                      <p><strong>Min Principal</strong></p>
                    </div>
                    <div className="text-right col-md-6">
                      <p> {userLoanApplicationData?.selectedProduct?.currency?.displaySymbol}{userLoanApplicationData?.selectedProduct?.minPrincipal}</p>
                    </div>
                  </li>
                  <li className='row '>
                    <div className='col-md-6 text-left'>
                      <p><strong>Max Principal</strong></p>
                    </div>
                    <div className="text-right col-md-6">
                      <p> {userLoanApplicationData?.selectedProduct?.currency?.displaySymbol}{userLoanApplicationData?.selectedProduct?.maxPrincipal}</p>
                    </div>
                  </li>
                  <li className='row '>
                    <div className='col-md-6 text-left'>
                      <p><strong>Min Number of Payments</strong></p>
                    </div>
                    <div className="text-right col-md-6">
                      <p>  {userLoanApplicationData?.selectedProduct?.minNumberOfRepayments} {userLoanApplicationData?.selectedProduct?.repaymentFrequencyType?.value}</p>
                    </div>
                  </li>
                  <li className='row '>
                    <div className='col-md-6 text-left'>
                      <p><strong>Max Number of Payments</strong></p>
                    </div>
                    <div className="text-right col-md-6">
                      <p>  {userLoanApplicationData?.selectedProduct?.maxNumberOfRepayments} {userLoanApplicationData?.selectedProduct?.repaymentFrequencyType?.value}</p>
                    </div>
                  </li>
                  <li className='row '>
                    <div className='col-md-6 text-left'>
                      <p><strong>Min Frequency</strong></p>
                    </div>
                    <div className="text-right col-md-6">
                      <p>  {userLoanApplicationData?.selectedProduct?.minNumberOfRepayments} {userLoanApplicationData?.selectedProduct?.repaymentFrequencyType?.value}</p>
                    </div>
                  </li>
                  <li className='row '>
                    <div className='col-md-6 text-left'>
                      <p><strong>Max Frequency</strong></p>
                    </div>
                    <div className="text-right col-md-6">
                      <p>  {userLoanApplicationData?.selectedProduct?.maxNumberOfRepayments} {userLoanApplicationData?.selectedProduct?.repaymentFrequencyType?.value}</p>
                    </div>
                  </li>
                  <li className='row '>
                    <div className='col-md-6 text-left'>
                      <p><strong>Amortization Type</strong></p>
                    </div>
                    <div className="text-right col-md-6">
                      <p>   {userLoanApplicationData?.selectedProduct?.amortizationType?.value}</p>
                    </div>
                  </li>
                  <li className='row '>
                    <div className='col-md-6 text-left'>
                      <p><strong>Annual Interest Rate</strong></p>
                    </div>
                    <div className="text-right col-md-6">
                      <p>   {userLoanApplicationData?.selectedProduct?.annualInterestRate}%</p>
                    </div>
                  </li>
                  {/* {product.description.map((element, index) => (
                                <li key={index}>{element}</li>
                            ))} */}
                </ul>
              </div>
            </Card>


          </div>


        </center>
      </BlockUI>
      <center>
      </center>
    </div>
  );
};

export default LoanCalculations;