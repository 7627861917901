import React, { useState, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from "axios";
import { Divider } from 'primereact/divider';
import AnimatedSuccessGIF from '../../../../../images/check-green.gif';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { useFormik } from 'formik';


const ConfirmationForm = ({ moveToNextView, moveToPreviousView, userPolicyInput, setuserPolicyInput, titles, toast }) => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [visible, setVisible] = useState(false);
  const history = useHistory()
  const [loading, setloading] = useState(false);
  const toastBC = useRef(null);
  const [canSubmit, setCanSubmit] = useState(false)

  const [visibleTC, setvisibleTC] = useState(false);

  const footerContent = (
    <div>
      <Button label="No" icon="pi pi-times" onClick={() => {
        formik.setFieldValue('item', false);
        setCanSubmit(false)
        setvisibleTC(false)
      }} className="p-button-text" />
      <Button label="Yes" icon="pi pi-check" onClick={() => {
        formik.setFieldValue('item', true);
        setCanSubmit(true)
        setvisibleTC(false)
      }
      } autoFocus />
    </div>
  );

  // ACCEPT TERMS AND CONDITIONS

  const formik = useFormik({
    initialValues: {
      item: null
    },
    validate: (data) => {
      let errors = {};

      if (!data.item || data.item === 'null') {
        errors.item = 'Value is required.';
      }

      return errors;
    },
    onSubmit: (data) => {
      data.item && setCanSubmit(true);
      formik.resetForm();
    }
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  // END OF TERMS AND CONDITIONS

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   onNextStep({ termsAgreed });
  // };

  // const handleTermsAgreementChange = () => {
  //   setTermsAgreed(true);
  // };


  const moveToNext = (e) => {
    toastBC.current.clear();
    // e.preventDefault();

    const payload = {
      "operation": "createPolicyApplicationStage3",
      "requestBody": {
        "planId": userPolicyInput.selectedPlanName,
        "planName": userPolicyInput.selectedPlanID,
        "productId": userPolicyInput.selectedProductId,
        "productName": userPolicyInput.selectedProductName,
        "applicationTotalStages": 3,
        "currentStages": 3,
        "applicationType": userPolicyInput.selectedPlanType,
        "mainMember": userPolicyInput.mainMember,
        "memberSpouse": userPolicyInput.memberSpouse,
        "memberChild": userPolicyInput.memberChild,
        "memberExtended": userPolicyInput.memberExtended,
        "memberBeneficiary": userPolicyInput.memberBeneficiary,
        "bankDetail": userPolicyInput.bankDetail
      }
    }
    console.log("-----\n\n\n\n\n");
    console.log(payload);
    setuserPolicyInput({ ...userPolicyInput });
    submitStage4toServer(payload);
  };

  const showToastMessage = (title, message, messageType) => {
    toast.current.show({ severity: messageType, summary: title, detail: message, life: 15000 });
  }

  const submitStage4toServer = async (payload) => {
    setloading(true);
    console.log(payload);
    await axios.post(process.env.REACT_APP_DOVESAPI + 'policy/apply/3', payload,{
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    }).then((response) => {

      if (response.data.status === "SUCCESS") {
        setloading(false);
        console.log(response.data.responseBody)
        setVisible(true)
        showToastMessage("Progress Saved", userPolicyInput.mainMemberName + " your progress has been saved", "success");

      }
      else {
        setloading(false);
        showToastMessage("Failed to save your progress", response.data.message, "warn");
      }
    }).catch((e) => {
      setloading(false);
      console.log(e)
      // showException()
    })


  }
  const clear = (submit) => {
    toastBC.current.clear();
    submit && moveToNext();
  };

  const confirm = () => {
    toastBC.current.show({
      severity: 'info',
      sticky: true,
      className: 'border-none',
      content: (
        <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
          <div className="text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <div className="font-bold text-xl my-3">Confirm your application details?</div>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={(e) => clear(true)}
              className='justify-content-center text-center m-2'
              size='large'
              label='Confirm'
              icon="pi pi-check-circle"
              severity="success"
              rounded
              raised
              style={{ color: "white" }} // Add this style to set the button text color
            />
            <Button
              onClick={(e) => { toastBC.current.clear(); }}
              className='justify-content-center text-center m-2'
              size='large'
              label='Cancel'
              icon="pi pi-times-circle"
              severity="warning"
              rounded
              raised
              style={{ color: "white" }} // Add this style to set the button text color
            />
          </div>
        </div>
      )
    });
  };

  return (
    <div>
      <BlockUI blocked={loading}>
        <center>
          <Card className="m-4 col-xl-9">
            {console.log(userPolicyInput)}
            <Accordion activeIndex={0}>
              {/* multiple  */}
              <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Plan & Policy Benefits </h4>}>
                <div className=' justify-content-start'>
                  <h4 className='mt-1 mb-2 --blue text-left'>
                    <strong>Selected Plan/Product</strong> : {userPolicyInput.selectedProduct && userPolicyInput.selectedProduct.ProductName1}
                  </h4>
                  <Divider type="dotted" />
                  <h4 className='mt-1 mb-2 --blue text-left'>
                    <strong>Product Benefits</strong> :
                  </h4>
                  <p className="m-0 text-left">{userPolicyInput.selectedProduct && userPolicyInput.selectedProduct.BenefitsText}</p>
                </div>
              </AccordionTab>
              <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Principal Member Details </h4>}>
                <DataTable value={[userPolicyInput.mainMember]} tableStyle={{ minWidth: '50rem' }}>
                  <Column field='ID_Number' header="ID Number"></Column>
                  <Column field='Title' header="Title"></Column>
                  <Column field='First_Name' header="First Name"></Column>
                  <Column field='Surname' header="Last Name"></Column>
                  <Column field='Date_Of_Birth' header="Date Of Birth"></Column>
                  <Column field='Gender' header="Gender"></Column>
                  <Column field='Marital_Status' header="Marital Status"></Column>
                  {/* <Column field='Address' header="Gender"></Column> */}
                  <Column field='Cell_Number' header="Cell Number"></Column>
                </DataTable>
              </AccordionTab>
              <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Beneficiary Details </h4>}>
                {userPolicyInput.memberSpouse != {} ? <><h4 className='mt-1 mb-2 --blue text-left'>
                  <strong>Spouse Beneficiary Details</strong> :
                </h4>
                  <Divider type="dotted" />
                  <DataTable value={[userPolicyInput.memberSpouse]} tableStyle={{ minWidth: '50rem' }}>
                    <Column field='ID_Num' header="ID Number"></Column>
                    <Column field='Name' header="First Name"></Column>
                    <Column field='Surname' header="Last Name"></Column>
                    <Column field='DateOfBirth' header="Date Of Birth"></Column>
                    <Column field='Gender' header="Gender"></Column>
                    <Column field='Telephone' header="Phone Number"></Column>
                  </DataTable></> : <><small>No Spouse Beneficiary Details</small><Divider type="dotted" /></>}

                {userPolicyInput.memberChild != [] ?
                  <><h4 className='mt-1 mb-2 --blue text-left'>  <strong>Children Beneficiary Details</strong>  : </h4>
                    {userPolicyInput.memberChild.map((beneficiary) => (
                      <><Divider type="dotted" />
                        <DataTable value={[beneficiary]} tableStyle={{ minWidth: '50rem' }}>
                          <Column field='IdNumber' header="ID Number"></Column>
                          <Column field='FirstName' header="First Name"></Column>
                          <Column field='LastName' header="Last Name"></Column>
                          <Column field='DateOfBirth' header="Date Of Birth"></Column>
                          <Column field='Gender' header="Gender"></Column>
                          <Column field='Telephone' header="Phone Number"></Column>
                        </DataTable></>

                    ))}
                  </>
                  : <><small>No Spouse Beneficiary Details</small><Divider type="dotted" /></>}

                {userPolicyInput.memberExtended != [] ?
                  <> <h4 className='mt-1 mb-2 --blue text-left'> <strong>Extended Family Beneficiary Details</strong>  : </h4>
                    {userPolicyInput.memberExtended.map((beneficiary) => (
                      <>
                        <Divider type="dotted" />
                        <DataTable value={[beneficiary]} tableStyle={{ minWidth: '50rem' }}>
                          <Column field='idNumber' header="ID Number"></Column>
                          <Column field='name' header="First Name"></Column>
                          <Column field='Surname' header="Last Name"></Column>
                          <Column field='dateOfBirth' header="Date Of Birth"></Column>
                          <Column field='gender' header="Gender"></Column>
                          <Column field='relationship' header="Relationship to Principal"></Column>
                        </DataTable>
                      </>
                    ))}

                  </>
                  : <><small>No Extended Family Beneficiary Details</small><Divider type="dotted" /></>}

                {userPolicyInput.memberBeneficiary != [] ?
                  <> <h4 className='mt-1 mb-2 --blue text-left'> <strong>Other Beneficiary Details</strong> : </h4>
                    {userPolicyInput.memberBeneficiary.map((beneficiary) => (
                      <>
                        <Divider type="dotted" />
                        <DataTable value={[beneficiary]} tableStyle={{ minWidth: '50rem' }}>
                          <Column field='IdNumber' header="ID Number"></Column>
                          <Column field='FirstName' header="First Name"></Column>
                          <Column field='LastName' header="Last Name"></Column>
                          <Column field='DateOfBirth' header="Date Of Birth"></Column>
                          <Column field='Gender' header="Gender"></Column>
                          <Column field='Telephone' header="Telephone"></Column>
                        </DataTable>
                      </>
                    ))}

                  </>
                  : <><small>No Other Beneficiary Details</small><Divider type="dotted" /></>}

              </AccordionTab>
              {userPolicyInput.bankDetail.length > 0 ? (
                <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Banking Details </h4>}>
                  {userPolicyInput.bankDetail.map((bankDetail) => (
                    <>
                      <DataTable value={[bankDetail]} tableStyle={{ minWidth: '50rem' }}>
                        <Column field='BankName' header="Bank Name"></Column>
                        <Column field='Account_Name' header="Account Name"></Column>
                        <Column field='Account_Number' header="Account Number"></Column>
                        <Column field='Type_of_Account' header="Type of Account"></Column>
                      </DataTable>
                    </>
                  ))}

                </AccordionTab>
              ) :
                (
                  <></>
                )}


            </Accordion>

            <div>
              <br></br>
              {console.log(userPolicyInput)}
              <TriStateCheckbox
                id="item"
                name="item"
                value={formik.values.item}
                onChange={(e) => {
                  formik.setFieldValue('item', e.value);
                  setCanSubmit(e.value)
                }}
                className={classNames({ 'p-invalid': formik.errors.item })}
              /> 
                <Button label="* I've read and accept the terms & conditions. Click here to read Terms and conditions" severity="info" text  onClick={() => setvisibleTC(true)}/>
          
            </div>
            {getFormErrorMessage('item')}
          </Card>
          <Dialog header="" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            <center>
              <div className=" text-center my-4">
                <img width="100px" src={AnimatedSuccessGIF} alt="" />
              </div>

              <h3>Policy Application Submitted Successfully</h3>
              <p>You have successfully submitted your policy application</p>
              <Button
                onClick={() => {
                  setVisible(false)
                  history.push("/")
                }}

                className='justify-content-center text-center m-2'
                size='large'
                label='Ok'
                icon="pi pi-check-circle"
                severity="success"
                rounded
                raised
                style={{ color: "white" }} // Add this style to set the button text color
              />

            </center>

          </Dialog>
          <Dialog header="Read Terms And Conditions" visible={visibleTC} style={{ width: '50vw' }} onHide={() => setvisibleTC(false)} footer={footerContent}>
              <div dangerouslySetInnerHTML={{ __html: userPolicyInput.selectedSubGroupPlan.termAndConditions }}></div>
          </Dialog>

          <div className="text-center">
            <Button onClick={confirm} disabled={canSubmit === true ? false : true} className='justify-content-center text-center mt-4' size='large' outlined label={<><strong >Submit Application </strong></>} icon="pi pi-arrow-right" severity="info" rounded raised />
          </div>
          <Toast ref={toastBC} position="center" />
        </center>
      </BlockUI>
      <center>
      </center>
    </div>
  );
};

export default ConfirmationForm;
