import BeneficiaryMembersForm from "./BeneficiaryMembersForm.jsx";
import axios from "axios";

class BeneficiaryMembersJS {

  
  generateNameDetails(fullName) {
    const names = fullName.split(" ");
    const firstName = names[0];
    const lastName = names[names.length - 1];

    let initials = "";
    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      initials += name.charAt(0).toUpperCase();
    }

    return {
      firstName,
      lastName,
      initials,
    };
  }

   validateAge (dateOfBirth)  {
    const currentDate = new Date();

    const birthDate = new Date(dateOfBirth);
    var age = currentDate.getFullYear() - birthDate.getFullYear();
    
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    
    return age;
  };


   generateQuote (mainMember, mainMemberProduct, beneficiaries){
    console.log(mainMember) 
    console.log(mainMemberProduct) 
    console.log(beneficiaries) 

    let arr = [{
      name: mainMember.First_Name+ ' '+ mainMember.Surname,
      relationship: 'Self',
      totalContribution: mainMemberProduct.Total
    }]

    beneficiaries.forEach((element)=>{
      const ben = {
        name: element.name,
        relationship: element.relationship,
        totalContribution: element.relationship === 'Relative'? element.selectedProduct.Total : 0 
      }

      arr.push(ben)
    })

    let totalContribution = 0;
    
    for (const item of arr) {
        totalContribution += item.totalContribution;
    }

    const totalObj = {
      "name": "Total",
      "relationship": "",
      "totalContribution": totalContribution
  };
  
  arr.push(totalObj);

    console.log(arr)
    return arr
  }

  formatDateToDDMMYYYY(inputDate) {
    const date = new Date(inputDate);
    
    if (isNaN(date)) {

    }
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

 validateFields (beneficiary){

    var x = false
    x = (
      beneficiary.name.trim() !== "" &&
      beneficiary.relationship !== "" &&
      beneficiary.gender !== "" &&
      beneficiary.dateOfBirth !== "" &&
      beneficiary.idNumber.trim() !== ""
    ); 

    return x;
  };

  async getSubGroupsByProductId(id){

    console.log(id)

    let productGroup = []
        await axios.get(process.env.REACT_APP_DOVESAPI+'policy/products/group',{
          headers: {
            Authorization: 'Bearer ' + (localStorage.getItem('token'))
          }
        }).then((response)=>{
            response.data.responseBody.forEach(element => {

                if(element && element){
                    productGroup.push(element)
                }
                
            });
        }).catch((e)=>{

        });

        // setGroupOptions(productGroup)
    await axios.get('')
  }

  // handleBeneficiaryChange = (e, index) => {

  //   let benMember = new BeneficiaryMembersForm()
  //   const { name, value } = e.target;
  //   const updatedBeneficiaries = [...benMember.beneficiaries];

  //   if (name === "name") {
  //     if (value.split(" ").length < 2) {
  //       benMember.setContextErrors([...benMember.contextErrors, {'index': index, errorMessage: 'Name must contain at least two words - Context' }])

  //     } else {
  //       benMember.setErrorMessage("");
  //       const nameDetails = this.generateNameDetails(value);
  //       updatedBeneficiaries[index].firstName = nameDetails.firstName;
  //       updatedBeneficiaries[index].lastName = nameDetails.lastName;
  //       updatedBeneficiaries[index].initials = nameDetails.initials;
  //     }
  //   }

  //   if(name === "dateOfBirth"){
  //     const benAge = this.validateAge(value);
  //     updatedBeneficiaries[index].beneficiaryAge = benAge
  //     if(benAge > 74 || benAge < 0){
  //       benMember.setContextErrors([...benMember.contextErrors, {'index': index, errorMessage: 'Invalid beneficiary age, accepted age limit is between 0 and 75. - Context' }])
  //       // setErrorMessage("Invalid beneficiary age, accepted age limit is between 0 and 75.");
  //       return 
  //     }
  //     else{
  //       benMember.setErrorMessage("")
  //     }
  //   }


  //   if (name === "relationship" && value === "Spouse") {

  //     benMember.setSpouseSelected(benMember.spouseSelected + 1)

  //   } 
    

  //   updatedBeneficiaries[index][name] = value;
  //   benMember.setBeneficiaries(updatedBeneficiaries);
  // };


}

export default BeneficiaryMembersJS;