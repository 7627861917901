import React, { useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';
import { Card } from 'primereact/card';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Tooltip } from 'primereact/tooltip';

export default function AdvertisementGallery() {
    const [imagesList, setImagesList] = useState([]);

    useEffect(() => {
        // Fetch advertisements from the server
        fetch(process.env.REACT_APP_DOVESAPI + 'advertisement/all', {
            headers: {
                Authorization: 'Bearer ' + (localStorage.getItem('token'))
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.responseBody)
                // Assuming the response data is an array of objects with image information
                setImagesList(data.responseBody);
            })
            .catch(error => {
                console.error('Error fetching advertisements:', error);
            });
    }, []);

    const itemTemplate = (item) => {
        return (
            <>
             <Tooltip target="#logo" mouseTrack mouseTrackLeft={10} />
                <div id="logo" className='m-2 flex justify-content-center' data-pr-tooltip={item.title+ ' \n Click to learn more'}>
                    <a href={item.callToActionURL} target="_blank" rel="noreferrer"  ><div className='m-2 flex justify-content-center'>
                        <img src={item.image} alt={item.alt} style={{ width: '100%', display: 'block' }} />
                        <h3 className='text-center mt-4 '>{item.title}</h3>
                        <ScrollPanel style={{ width: '100%', height: '80px' }}>
                        <p className='text-center mt-2 '><small><strong>{item.description}</strong></small></p>
                        </ScrollPanel>
                    </div>
                    </a>
                </div>
            </>
        );
    };

    return (
        <Card>
            <Galleria value={imagesList} style={{ maxWidth: '620px' }} showThumbnails={false} showIndicators item={itemTemplate} />
        </Card>
    );
}
