import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.png';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { useAuth } from '../../components/auth/useAuth';
import { Button } from 'primereact/button';
import { Custom } from '../../../css/Custom.css';
import { Card } from 'primereact/card';
import { useMountEffect } from 'primereact/hooks';
// import { Divider } from 'primereact/divider';

const Signup = () => {

  const { logout } = useAuth();
  useMountEffect(() => {
    logout();
  });


  const toast = useRef(null);


  return (
    <>
      <Toast ref={toast} />
        <div className="authincation1 section-padding1">
          <div className="container1 h-1001">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-xl-10 col-md-10">
                <div className="mini-logo text-center my-4">
                  <Link to="/">
                    <img width="150px" src={logo} alt="" />
                  </Link>
                  <div className=" text-center mt-0">
                    <h3 className=" m-4">Welcome to Doves Self Service Portal</h3>
                  </div>
                </div>

                  <div className="auth-form  ">
                    {/* <button> Existing Customer </button> */}
                    <div className="card-body">
                        <>
                        <div className="card-body">
                        <>
                          {/* <div className="flex flex-column md:flex-row m-2"> */}
                            <form name="myform" className="signin_validate row g-3">
                                <>
                                  <div className='row'>

                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                      <div className="wallet-widget card">
                                        <h5>Already have a profile?</h5>
                                        <h2><span className="text-success">Yes</span></h2>
                                        <p> <Link to={"/signin"} className="btn btn-outline-success">Login</Link></p>
                                      </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2">
                                      <Divider layout="vertical" className="hidden md:flex">
                                        <b>OR</b>
                                      </Divider>
                                    </div>

                                    <div className='col-lg-8 col-md-12 col-sm-12 row card' style={{minHeight:"27em"}}>
                                      <div className='row'>
                                        <div className=" text-center">
                                          <h4 className="mb-2  mt-4">CREATE NEW PROFILE
                                          </h4>
                                          <br />
                                          <br />
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 ">
                                          <Card>
                                          <center >
                                            {/* <h2><span className="text-primary">Yes,</span></h2> */}
                                            <br></br>
                                            <h5>I have<strong> an existing</strong> policy</h5>
                                            <br></br>
                                            <br></br>
                                            {/* <p onClick={toggleForms} className="btn btn-outline-primary">Create Profile</p> */}
                                            <p> <Link to={"/signup"} className="btn btn-outline-primary">Link Policy</Link></p>

                                          </center>
                                          </Card>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                        <Card>
                                          <center >
                                            {/* <h2><span className="text-success">No,</span></h2> */}
                                            <br></br>
                                            <h5>I <strong>do not</strong> have a policy</h5>
                                            <br></br>
                                            <br></br>
                                            <p> <Link to={"/create-new-account"} className="btn btn-outline-primary">Create New Profile</Link></p>
                                          </center>
                                          </Card>
                                        </div>
                                          
                                      </div>
                                    </div>
                                  </div>
                                </>
                            </form>
                          {/* </div> */}
                        </>
                    </div>

                  {/* <div className="card-body">
                        <form name="myform" className="signin_validate row g-3 ">
                          
                              <div className='col-12'>
                                <div className=" text-center mt-0">
                                </div>
                                <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-6 " >
                                    <Card title="Login" className="signup-widget card loginCard">
                                      <Divider></Divider>
                                      <h5>I have a Doves policy and I enrolled for self-service</h5>
                                      <br></br>
                                      <br></br>
                                      <Button onClick={(event)=>{
                                        event.preventDefault();
                                        window.location.href="signin"
                                      }} className="btn signUpButton">LOGIN</Button>
                                    </Card>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6">
                                  <Card title="Link Policy" className="signup-widget">
                                    <Divider></Divider> 
                                    <h5>I have a Doves policy, I want to enrol for self-service</h5>
                                    <br></br>
                                    <br></br>
                                    <Button onClick={(event)=>{
                                        event.preventDefault();
                                        window.location.href="signup"
                                      }} className="btn signUpButton">CONNECT</Button>
                                  </Card>

                                  </div>
                                  
                                </div>

                                <div className='row'>
                                  <center>
                                  <div className="col-lg-6 col-md-6 col-sm-6">
                                    <Card title="Create Profile" className="signup-widget card">
                                      <Divider></Divider>
                                      <h5>I do not have a Doves policy. </h5>
                                      <br></br>
                                      <br></br>
                                      <Button onClick={(event)=>{
                                        event.preventDefault();
                                        window.location.href="/create-new-account"
                                      }} className="btn signUpButton">CREATE PROFILE</Button>
                                    </Card>
                                  </div>
                                  </center>
                                </div>


                              </div>
                        </form>
                  </div> */}

                      {/* <div className="card-body">
                        <form name="myform" className="signin_validate row g-3 ">
                          
                              <div className='col-12'>
                                <div className=" text-center mt-0">
                                </div>
                                <div className='row'>
                                <div className="col-lg-5 col-md-5 col-sm-5 " >
                                    
                                <div style={{height:'200px'}} className="col-lg-12 col-md-12 col-sm-12 " >
                                   
                                </div>

                                  <div className="col-lg-12 col-md-12 col-sm-12 " >
                                    <Card title="Login" className="signup-widget card loginCard">
                                      <Divider></Divider>
                                      <h5>I have a Doves policy and I enrolled for self-service</h5>
                                      <br></br>
                                      <br></br>
                                      <Button onClick={(event)=>{
                                        event.preventDefault();
                                        window.location.href="signin"
                                      }} className="btn signUpButton">LOGIN</Button>
                                    </Card>
                                  </div>
                                  <div style={{height:'200px'}} className="col-lg-12 col-md-12 col-sm-12 " >
                                   
                                </div>
                                  </div>
                                  <div className="col-lg-1 col-md-1 col-sm-1 ">
                                  <Divider layout="vertical" className="hidden md:flex">
                                      <b>OR</b>
                                  </Divider>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6">
                                  <div className="col-lg-12 col-md-12 col-sm-12">
                                  <Card title="Link Policy" className="signup-widget">
                                    <Divider></Divider> 
                                    <h5>I have a Doves policy, I want to enrol for self-service</h5>
                                    <br></br>
                                    <br></br>
                                    <Button onClick={(event)=>{
                                        event.preventDefault();
                                        window.location.href="signup"
                                      }} className="btn signUpButton">CONNECT</Button>
                                  </Card>

                                  </div>
                                  <div className="col-lg-12 col-md-12 col-sm-12">
                                  <Card title="Create Profile" className="signup-widget card">
                                      <Divider></Divider>
                                      <h5>I do not have a Doves policy. </h5>
                                      <br></br>
                                      <br></br>
                                      <Button onClick={(event)=>{
                                        event.preventDefault();
                                        window.location.href="/create-new"
                                      }} className="btn signUpButton">Create Profile</Button>
                                    </Card>

                                  </div>
                                  </div>
                                  
                                </div>


                              </div>
                        </form>
                  </div> */}
                        </>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default Signup;
