// src/jsx/components/admin/user-management/CreateNewUser.jsx
// src/jsx/pages/new-user.jsx



import React from "react";
import Sidebar from '../../layout/navigation/sidebar/dynamic-sidebar';
import Header from '../../layout/headers/header'
import ViewEmployees from '../../components/tables/ViewEmployees'

const PriceDetails = () => {
    

    return (
        <>
        <Header />
        <Sidebar />
        <div className="content-body">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12">
                        <ViewEmployees />
                    </div>
                </div>
            </div>
        </div> 
        </>
    );
};

export default PriceDetails;
