import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { TieredMenu } from 'primereact/tieredmenu';
import CreateNewUser from '../admin/user-management/CreateNewUser'
import EditUser from '../admin/user-management/EditEmployees'
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useMountEffect } from 'primereact/hooks';

const EmployeesProfileReportTable = () => {
  const [employees, setemployees] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [activeUser, setActiveUser] = useState(null)

  const dt = useRef(null);
  const toast = useRef(null);
  const [reloadData, setReloadData] = useState(false);


  const handleLock = async (data) => {
    let userPayload = {
      "operation": "create-user-profile",
      "requestBody": data
  };

  const token = localStorage.getItem('token')

  const config = {
      headers: {
          "Authorization": `Bearer ${token}`
      }
  };

    const response = await axios.put(process.env.REACT_APP_DOVESAPI+'user/edit', userPayload, config).then((response)=>{
      if( response.data.status === "SUCCESS"){
        toast.current.show({ severity: 'success', summary: 'Updated', detail: data.isLocked ? 'You have successfully locked this profile' : 'You have successfully unlocked this profile', life: 3000 });
      }
      else{
        toast.current.show({ severity: 'warn', summary: 'Failed', detail: response.data.message, life: 3000 });
      }
    });
};

const handleActive = async ()=>{

  let userPayload = {
    "operation": "create-user-profile",
    "requestBody": data
  };

  const token = localStorage.getItem('token')

  const config = {
      headers: {
          "Authorization": `Bearer ${token}`
      }
  };

  const response = await axios.put(process.env.REACT_APP_DOVESAPI+'user/edit', userPayload, config).then((response)=>{
    if( response.data.status === "SUCCESS"){
      toast.current.show({ severity: 'success', summary: 'Updated', detail: data.isActive ? 'You successfully activated this profile' : 'You successfully disabled the profile', life: 3000 });
    }
    else{
      toast.current.show({ severity: 'warn', summary: 'Failed', detail: response.data.message, life: 3000 });
    }
  });


}

const rejectFunction = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
};


  const cols = [
    { field: 'firstName', header: 'First name' },
    { field: 'lastName', header: 'Last name' },
    { field: 'emailAddress', header: 'Email' },
    { field: 'phoneNumber', header: 'Phone number' },
    { field: 'actions', header: 'Actions' },
  ];

  useMountEffect(() => {
    fetchUsers();
  }, [reloadData]);


  function renderActions (rowData) {
    return (
      <React.Fragment>
        <Button
          text raised
          size="small"
          label='Edit'
          icon="pi pi-pencil"
          className="p-button-info"
          style={{ marginRight: '5px' }}
          onClick={() => {
            setReloadData(!reloadData);
            setActiveUser(rowData)
            setDialogVisible(!dialogVisible)

          }}
        />
        <Button
          text raised
          size="small"
          label={  rowData.isActive ? 'Disable': "Activate"}
          icon={ rowData.isActive? "pi pi-times": "pi pi-check"}
          className=" p-button-danger"
          onClick={(event) => {

            rowData.isActive = !rowData.isActive
              confirmPopup({
                target: event.currentTarget,
                message: rowData.isActive ? 'Are you sure you want to disable this?' : 'Are you sure you want to activate this?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => handleActive(rowData),
                reject: rejectFunction
            });
          }
          }
        />
        <Button
          text raised
          size="small"
          label={ rowData.isLocked ? 'Unlock': 'Lock'}
          icon={rowData.isLocked ? "pi pi-lock-open" : "pi pi-lock"}
          className={rowData.isLocked ? "p-button-success" : "p-button-warning"} // Conditionally set the class
          onClick={(event) => {

            rowData.isLocked = !rowData.isLocked
              confirmPopup({
                target: event.currentTarget,
                message: rowData.isLocked ? 'Are you sure you want to unlock this?' : 'Are you sure you want to lock this?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => handleLock(rowData),
                reject: rejectFunction
            });
          }
          }
        
       / >
        
      </React.Fragment>
    );
  }

  const [dialogVisible, setDialogVisible] = useState(false);

  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const data = []

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default();

        doc.autoTable(exportColumns, employees);
        doc.save('doves-self-service-employees-profiles-report.pdf');
      });
    });
  };


  const fetchUsers = async () => {
    try {
      await axios.get(process.env.REACT_APP_DOVESAPI+'users/employees', {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {
        // console.log(response);
        if (response.data.status === "SUCCESS") { 
          setemployees((response.data.responseBody));
        } else {
          console.log(response.data.status);
        }
      });

    } catch (error) {
      console.log(error)
    }
  };


  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(employees);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'doves-self-service-employees-profiles-report');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
  };


  const onGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
  };

  const globalFilterElement = (
    <input
      type="text"
      className="p-inputtext p-component"
      placeholder="Search"
      value={globalFilter}
      onChange={onGlobalFilter}
    />
  );

  const handleCreateUser = async () =>{
    setDialogVisible(false)

    await toast.current.show({ severity: 'success', summary: 'Success', detail: 'User registered successfully' });

  }


  const menu = useRef(null);
  const items = [
    {
      label: 'Download CSV',
      icon: 'pi pi-file-excel',
      command: () => { exportCSV(false) }
    },
    {
      label: 'Download PDF',
      icon: 'pi pi-file-pdf',
      command: () => { exportPdf(false) }
    }
  ];
  return (

    
    <div className="card home-chart"> 
    <Toast ref={toast} />


    { activeUser === null ? (
      <Dialog header="Register new user" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() => {
        setActiveUser(null)
        setDialogVisible(false)}}>
      <CreateNewUser onCloseDialog = { handleCreateUser} style={{ marginTop: "20px;"}} />
    </Dialog>
    ) : (
      <Dialog header="Edit user" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() =>{
        setActiveUser(null)
        setDialogVisible(false)}}>
          <EditUser employeeData = {activeUser}  onCloseDialog = { handleCreateUser} style={{ marginTop: "20px;"}} />
      </Dialog>
    )
  }
      

      
      
      <Tooltip target=".export-buttons>button" position="bottom" />
      <ConfirmPopup />

      <div className="card-header">
        <h2 className="card-title home-chart">Employees Profile Report</h2>
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {globalFilterElement}
          </span>
        </div>
        <div id="report-select">
          <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
          <Button label="Create new" raised size="small" icon="pi pi-user-plus" onClick={() => setDialogVisible(!dialogVisible)} />

        </div>

      </div>

      <div className="card-body">

        <DataTable
          paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
          ref={dt}
          value={employees}
          tableStyle={{ minWidth: '50rem' }}
          globalFilter={globalFilter}
          emptyMessage="No employees found"
        >
          {cols.map((col, index) => {
            if (col.field === 'actions') {
              return (
                <Column
                  key={index}
                  body={renderActions}
                  style={{ textAlign: 'center' }}
                />
              );
            }
            return (
              <Column key={index} field={col.field} header={col.header} />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};

export default EmployeesProfileReportTable;
