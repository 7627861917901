import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useMountEffect } from 'primereact/hooks';
import  axios  from 'axios';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

        
        

export default function LoanHistory() {
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);
  const [banksAvailable, setBanksList] = useState([]);
  const [loadingbanks, setLoadingBanks] = useState(false)
  const renderIds = (rowData) =>{
    console.log(JSON.stringify(rowData))
  }
  const renderActions = (rowData) => {
    return (
      <React.Fragment>
       
        <Button
          text raised
          size="small"
          label={  rowData.isActive ? 'Activate': "Deactivate"}
          icon={ rowData.isActive? "pi pi-check": "pi pi-times"}
          className=" p-button-danger"
          onClick={(event) => {

            rowData.isActive = !rowData.isActive
          }
          }
        />
        
        
      </React.Fragment>
    );
  };

  async function fetchAllBanks() {
    
    let activeProducts = []
    await axios.get(process.env.REACT_APP_DOVESAPI+'policy/banks', {
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    }).then((response) => {

        let products = response.data.responseBody

        products.forEach(product => {

            activeProducts.push(product)
        });

        
        
        console.log(response.data.responseBody)
    });
    setBanksList(activeProducts);
  }

  useMountEffect(() => {
    fetchAllBanks()
});

  const cols = [
    { field: 'index', header: '#' }, 
    { field: 'BankID', header: 'BANK ID' },
    { field: 'BankName1', header: 'Bank Name' },

  ];

  const handleAddProduct = async () =>{
    setVisible(false)
  }

  const getBanksFromEasipol = async () =>{
    setLoadingBanks(true)
    await axios.get(process.env.REACT_APP_DOVESAPI+'policy/banks/easipol',{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
  }).then((response) => {

        if(response.data.status === 'SUCCESS'){
          setLoadingBanks(false)
            toast.current.show({ severity: 'info', summary: 'Info', detail: 'You have pulled banks from easipol' });
            fetchAllBanks()
        }

    });
  }

  return (
    <div className="card ">
      <div className="card-header">
        <h2 className="card-title ">Active Banks List</h2>
        <Button onClick={() => getBanksFromEasipol()} icon="pi pi-sync" size='small' className='' style={{float: "right"}} label='Synchronise Easipol' text raised />
      </div>
    <Toast ref={toast} />
      <div className="card-body">
        {loadingbanks === false ? 
        <DataTable
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={banksAvailable.map((item, index) => ({ ...item, index: index + 1 }))}
          emptyMessage="No Banks found"
        >
          {cols.map((col, index) => {
            
            return <Column key={index} field={col.field} header={col.header} />;
          })}
        </DataTable>
        :
          <>
          <div className="w-full md:w-6 p-3">
                    <Skeleton className="mb-2"></Skeleton>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" className="mb-2"></Skeleton>
                    <Skeleton width="10rem" height="4rem"></Skeleton>
                </div>
          </>
        }
      </div>
    </div>
  );
}
