import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Card } from 'primereact/card';

const ViewMainMemberTable = ({ activeUserData }) => {
  const Users = activeUserData;

  const colsMainMember1 = [
    { field: 'Title', header: 'Title' },
    { field: 'First_Name', header: 'First name' },
    { field: 'Surname', header: 'Last name' },
    { field: 'Initials', header: 'Initials' },
    { field: 'ID_Number', header: 'ID number' },
    { field: 'Home_Tel', header: 'Phone' },
  ];

  const colsMainMember2 = [
    { field: 'Gender', header: 'Gender' },
    { field: 'Marital_Status', header: 'Marital Status' },
    { field: 'Date_Of_Birth', header: 'Date Of Birth' },
    { field: 'Region', header: 'Nationality' },
    { field: 'EmailAddress', header: 'Email' },
    { field: 'Address', header: 'Physical and Postal Address' }
  ];

  return (
    <div className="card home-chart">
      <Card>
        <center><h2 className="card-title home-chart">MAIN MEMBER DETAILS</h2></center>


        <div className="card-body">
          <DataTable
            value={[Users.mainMember]}
            tableStyle={{ minWidth: '50rem' }}
            emptyMessage="No member set"
          >
            {colsMainMember1.map((col, index) => (
              <Column key={index} field={col.field} header={col.header} />
            ))}
          </DataTable>

          <br />

          <DataTable
            value={[Users.mainMember]}
            tableStyle={{ minWidth: '50rem' }}
            emptyMessage="No Users found"
          >
            {colsMainMember2.map((col, index) => (
              <Column key={index} field={col.field} header={col.header} />
            ))}
          </DataTable>
        </div>
      </Card>
    </div>
  );
};

export default ViewMainMemberTable;
