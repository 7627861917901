import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { TieredMenu } from 'primereact/tieredmenu';
// import EditUser from '../admin/user-management/EditRoles.jsx'
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ViewMainMemberTable from "./components/ViewMainMemberTable.jsx";
import ViewSpouseMemberTable from "./components/ViewSpouseMemberTable.jsx";
import ViewMemberBeneficiaries from "./components/ViewMemberBeneficiaries.jsx"
import ViewChildBeneficiaries from "./components/ViewChildBeneficiaries.jsx"
import ViewExtendedBeneficiaryDetails from "./components/ViewExtendedBeneficiaryDetails.jsx"
import ViewBankingDetails from "./components/ViewBankingDetails.jsx"
import { useMountEffect } from 'primereact/hooks';

const ApprovePolicyTable = () => {
  const [Roles, setRoles] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [activeUser, setActiveUser] = useState(null)

  const dt = useRef(null);
  const toast = useRef(null);
  const [reloadData, setReloadData] = useState(false);

  const cols = [
    { field: 'mainMember.ID_Number', header: 'National ID' },
    // { field: 'mainMember.First_Name', header: 'First name' },
    { field: 'productName', header: 'Product Name' },
    { field: 'applicationStatus', header: 'Status' },
    { field: 'open-policy', header: 'View Full Policy' },
    { field: 'actions', header: 'Actions' },
  ];

  useMountEffect(() => {
    fetchUsers();
  });


  const renderActions = (rowData) => {
    return (
      <></>
      // <React.Fragment>
      //   <Button
      //     text
      //     raised
      //     size="small"
      //     icon="pi pi-check"
      //     className="p-button-secondary m-1"
      //     style={{ marginRight: '5px' }}
      //     onClick={() => {
      //       setReloadData(!reloadData);
      //       setActiveUser(rowData)
      //       setDialogVisible(!dialogVisible)

      //     }} > Approve</Button>
      //   <Button
      //     raised
      //     text
      //     size="small"
      //     icon="pi pi-trash"
      //     className=" p-button-danger m-1"
      //     onClick={() => {

      //     }}
      //   > Reject</Button>
      // </React.Fragment>
    );
  };
  const renderOpenPolicyAction = (rowData) => {
    return (
      <React.Fragment>
        <Button
          raised
          text
          size="small"
          icon="pi pi-check"
          className="p-button-info m-1"
          style={{ marginRight: '5px' }}
          onClick={() => {
            // setReloadData(!reloadData);
            setActiveUser(rowData)
            setPolicyDialogVisible(!dialogPolicyVisible)

          }} > View More</Button>
      </React.Fragment>
    );
  };

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogPolicyVisible, setPolicyDialogVisible] = useState(false);

  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const data = []

  useMountEffect(() => {
    fetchUsers();

  }, []);

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default();

        doc.autoTable(exportColumns, Roles);
        doc.save('doves-self-service-Roles-profiles-report.pdf');
      });
    });
  };


  const fetchUsers = async () => {
    try {
      await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/list/applications', {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {
        // console.log(response);
        if (response.data.status === "SUCCESS") {
          setRoles((response.data.responseBody));
        } else {
          console.log(response.data.status);
        }
      });

    } catch (error) {
      console.log(error)
    }
  };


  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(Roles);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'doves-self-service-Roles-profiles-report');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
  };


  const onGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
  };

  const globalFilterElement = (
    <input
      type="text"
      className="p-inputtext p-component"
      placeholder="Search"
      value={globalFilter}
      onChange={onGlobalFilter}
    />
  );

  const handleCreateUser = async () => {
    setDialogVisible(false)

    await toast.current.show({ severity: 'success', summary: 'Success', detail: 'User registered successfully' });

  }


  const menu = useRef(null);
  const items = [
    {
      label: 'Download CSV',
      icon: 'pi pi-file-excel',
      command: () => { exportCSV(false) }
    },
    {
      label: 'Download PDF',
      icon: 'pi pi-file-pdf',
      command: () => { exportPdf(false) }
    }
  ];
  return (
    <div className="card home-chart">

      {activeUser === null ? (
        <Dialog header="Register new user" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() => {
          setActiveUser(null)
          setDialogVisible(false)
        }}>

        </Dialog>
      ) : (
        <Dialog header="Edit users" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() => {
          setActiveUser(null)
          setDialogVisible(false)
        }}>
          {/* <EditUser employeeData={activeUser} onCloseDialog={handleCreateUser} style={{ marginTop: "20px;" }} /> */}
        </Dialog>

      )
      }
      <Dialog header="Full Policy Detail" visible={dialogPolicyVisible} maximizable style={{ width: '60vw' }} onHide={() => {
        setPolicyDialogVisible(false)
      }}>
        <ViewMainMemberTable activeUserData={activeUser} />
        <ViewSpouseMemberTable activeUserData={activeUser} />
        <ViewChildBeneficiaries activeUserData={activeUser} />
        <ViewExtendedBeneficiaryDetails activeUserData={activeUser} />
        <ViewMemberBeneficiaries activeUserData={activeUser} />
        <ViewBankingDetails activeUserData={activeUser} />
        <br></br>
        <hr></hr>
        {/* <Button
          text raised
          size="small"
          icon="pi pi-check"
          className="p-button-secondary m-1"
          style={{ marginRight: '5px' }}
          onClick={() => {
            // setReloadData(!reloadData);
            // setActiveUser(rowData)
            // setDialogVisible(!dialogVisible)

          }} > Approve</Button>
        <Button
          text
          raised
          size="small"
          icon="pi pi-trash"
          className=" p-button-danger m-1"
          onClick={() => {
            console.log('Reject');
          }}
        > Reject</Button> */}
        {/* <ViewMainMemberTable activeUserData={activeUser}/> */}
        {/* <EditUser employeeData={activeUser} onCloseDialog={handleCreateUser} style={{ marginTop: "20px;" }} /> */}
      </Dialog>



      <Tooltip target=".export-buttons>button" position="bottom" />
      <div className="card-header">
        <h2 className="card-title home-chart"> Policy Applications</h2>
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {globalFilterElement}
          </span>
        </div>
        <div id="report-select">
          <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
          {/* <Button label="Create new" raised size="small" icon="pi pi-user-plus" onClick={() => setDialogVisible(!dialogVisible)} /> */}

        </div>

      </div>

      <div className="card-body">

        <DataTable
          paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
          ref={dt}
          value={Roles}
          tableStyle={{ minWidth: '50rem' }}
          globalFilter={globalFilter}
          emptyMessage="No Policies found"
        >
          {cols.map((col, index) => {
            if (col.field === 'actions') {
              return (
                <Column
                  key={index}
                  body={renderActions}
                  style={{ textAlign: 'center' }}
                />
              );
            } else if (col.field === 'open-policy') {
              return (
                <Column
                  key={index}
                  body={renderOpenPolicyAction}
                  style={{ textAlign: 'center' }}
                />
              );
            }
            return (
              <Column key={index} field={col.field} header={col.header} />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};

export default ApprovePolicyTable;
