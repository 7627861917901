import React, { useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, } from 'react-bootstrap'

import logo from '../../../images/logo.png'
import thumb from '../../../images/profile/2.png'
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast'; 
import { useMountEffect } from 'primereact/hooks';

const Header = () => { 

    const [userType, setuserType] = useState([]);
    const [fullName, setfullName] = useState([]);
    const [firstName, setfirstName] = useState([]);
    const [initialsName, setinitialsName] = useState([]);
    const [lastName, setlastName] = useState([]);
    const [userId, setuserId] = useState([]);
    useMountEffect(() => {
        const fetchUserRole = async () => {
        try { 
            setuserType((localStorage.getItem('userType')));
            setinitialsName(localStorage.getItem('firstName').charAt(1)+ ""+(localStorage.getItem('lastName').charAt(1)));
            var fullName = localStorage.getItem('firstName').toString()+ " "+localStorage.getItem('lastName').toString();
            setfullName(fullName);
            setfirstName((localStorage.getItem('firstName')));
            setlastName((localStorage.getItem('lastName')));
            setuserId((localStorage.getItem('userId'))); 
        
        } catch (error) {
            console.error('Error fetching user userRole:', error);
        }
        };
        fetchUserRole();
    });



    const menuRight = useRef(null);
    const toast = useRef(null); 
    const items = [
        // {
        //     label:fullName ,
        // },
       
        {
            // label: 'Manage Profile',
            items: [
            
                // {
                //     label: 'Edit Profile',
                //     icon: 'pi pi-user',
                //     command: () => {
                //         toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                //     },
                   
                // },
                // {
                //     label: 'Change Password',
                //     icon: 'pi pi-lock',
                //     command: () => {
                //         toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                //     }
                // }
            ]
        },
        { separator: true},
        {
            label: <strong>Logout</strong>,
            icon: 'pi pi-sign-out',
            url: '/signin'
        }
    ];
    return (
        <>
        <Toast ref={toast}></Toast>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="header-content">
                                <div className="header-left">
                                    <div className="brand-logo">
                                        <Link to={"/"}>
                                            <img src={logo} height={180} alt="" /> 
                                        </Link>
                                    </div>
                                    {/* <div className="search">
                                        <form action="#">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search Here" />
                                                <span className="input-group-text"><i className="icofont-search"></i></span>
                                            </div>
                                        </form>
                                    </div> */}
                                </div>

                                <div className="header-right">
                                 

                                    <div className="flex-auto"> 
                                    <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                                    <Avatar onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup label= {initialsName}  size="large"  shape="circle" className="p-overlay-badge" style={{ backgroundColor: '#084b9b', color: '#ffffff' }}>
                                        {/* <Badge value="0" /> */}
                                    </Avatar>
                                </div>
                                  
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Header;