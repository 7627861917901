import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { useMountEffect } from 'primereact/hooks';
import { Skeleton } from 'primereact/skeleton';

function PolicyHistoryTable({ balanceFormatted }) {

  const [loading, setLoading] = useState(true);
  const [historyData, setHistoryData] = useState([]);
  let userProfile = {};

  useMountEffect(() => {
    userProfile = (JSON.parse(localStorage.getItem('userProfile')));
    fetch(process.env.REACT_APP_DOVESAPI + 'policy/history/' + balanceFormatted.Policy_Number,{
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        if (data.status === 'SUCCESS') {
          setHistoryData(data.responseBody);
        } else {
          console.error('Failed to fetch policy history:', data.message);
        }
      })
      .catch(error => {
        setLoading(false)
        console.error('Error fetching policy history:', error);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div className="border-round border-1 surface-border p-4 surface-card">
          <div className="flex mb-3">
            <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
            <div>
              <Skeleton width="10rem" className="mb-2"></Skeleton>
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height=".5rem"></Skeleton>
            </div>
          </div>
          <Skeleton width="100%" height="150px"></Skeleton>
          <div className="flex justify-content-between mt-3">
            <Skeleton width="4rem" height="2rem"></Skeleton>
            <Skeleton width="4rem" height="2rem"></Skeleton>
          </div>
        </div>) : <>
        {historyData && historyData.length !== 0 ?
          <Card className='mb-5 mt-8' >
            <h3 className='pl-4 pt-3'><strong>Your Payment Statement</strong></h3>
            <Divider type='dotted' />
            <DataTable value={historyData} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
              <Column field="receiptNumber" header="Receipt Number"></Column>
              <Column field="receiptDate" header="Receipt Date"></Column>
              <Column field="receiptAmount" header="Receipt Amount"></Column>
              <Column field="receiptAmount" header="Payment Method"></Column>
              <Column field="paymentMethod" header="Payment Method"></Column>
              <Column field="monthsPaidInAdvance" header="Months Paid In Advance"></Column>
              <Column field="monthsFor" header="Months For"></Column>
              <Column field="amountDue" header="Amount Due"></Column>
              {/* <Column field="remarks" header="Remarks"></Column> */}
            </DataTable>

          </Card> : <></>}
      </>}
    </>
  );
}

export default PolicyHistoryTable;
