import axios from "axios";
import React, { useState } from "react";
import { Button } from 'primereact/button'; 
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui'; 
import { useMountEffect } from 'primereact/hooks';
import { Dropdown } from 'primereact/dropdown';

const BankingDetailsForm = ({ moveToNextView, moveToPreviousView, userPolicyInput, setuserPolicyInput, titles, toast }) => {
  const [bankName, setBankName] = useState("");
  const [bankId, setBankId] = useState(0);
  const [branchCode, setBranchCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branches, setBranchOptions] = useState([])
  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const accountTypes = [
    {name: "Savings", value: "savings"},
    {name: "Current", value: 'current'},
    {name: "Fixed Deposit", value: 'fixed deposit'}, 
    {name: "Joint", value: 'joint'}, 
    {name: "Business", value: 'business'}]; // Add more as needed
  const [loading, setloading] = useState(false);



  const moveToNext = (e) => {
    e.preventDefault();

    // Validation logic here
    // if ( bankName.trim() === "" || accountNumber.trim() === "" || branchName.trim() === "" || accountType.trim() === ""
    // ) {
    //   return;
    // }

    const bankDetail = [{
      "BankName": bankName.BankName1,
      "BankNameId": bankId.BankID,
      "BranchName": branchName,
      "Account_Name": accountName,
      "Account_Number": accountNumber,
      "Type_of_Account": accountType.toLowerCase(),
      "Branch_Code": branchCode,
      "MemberID": 0,
      "StartDate": "2023-08-03T16:28:51.868Z",
      "cardExpiryDate": "2023-08-03T16:28:51.868Z",
      "tokenExpiryDate": "2023-08-03T16:28:51.868Z"
    }];
    const payload = {
      "operation": "createPolicyApplicationStage3",
      "requestBody": {
        "planId": userPolicyInput.selectedPlanName,
        "planName": userPolicyInput.selectedPlanID,
        "productId": userPolicyInput.selectedProductId,
        "productName": userPolicyInput.selectedProductName,
        "applicationTotalStages": 3,
        "currentStages": 2,
        "applicationType": userPolicyInput.selectedPlanType,
        "mainMember": userPolicyInput.mainMember,
        "memberSpouse": userPolicyInput.memberSpouse,
        "memberChild": userPolicyInput.memberChild,
        "memberExtended": userPolicyInput.memberExtended,
        "memberBeneficiary": userPolicyInput.memberBeneficiary,
        "bankDetail":bankDetail
      }
    }
    setuserPolicyInput({ ...userPolicyInput, bankDetail: bankDetail });
    submitStage3toServer(payload);
  };

  const fetchFromUser = ()=>{
    const userProfileData = localStorage.getItem("userProfile");

    if (userProfileData) {
        try {
            const decodedData = JSON.parse(userProfileData);
            setAccountName(decodedData.firstName+" "+decodedData.lastName)

        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    } else {
        console.log("userProfileData not found in localStorage.");
    }

  }

  function populateForExisting() {

    console.log(userPolicyInput.bankDetail)
    if(userPolicyInput.bankDetail && userPolicyInput.bankDetail.length > 0){
      setBankName(userPolicyInput.bankDetail[0].BankNameId)
      setBankId(userPolicyInput.bankDetail[0].BankNameId)
      setAccountNumber(userPolicyInput.bankDetail[0].Account_Number)
      setBranchName(userPolicyInput.bankDetail[0].Branch_Code)
      setAccountName(userPolicyInput.bankDetail[0].Account_Name)
      setAccountType(userPolicyInput.bankDetail[0].Type_of_Account)
  
      // nationalityOptions
    }
  }

  useMountEffect(() => {
    console.log(userPolicyInput)
    fetchFromUser()
    fetchBanks();
    populateForExisting()

});

  async function fetchBanks() {
    setloading(true);
    await axios.get(process.env.REACT_APP_DOVESAPI+'policy/banks',{
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    }).then((response) => {
      setloading(false);
      setBranchOptions(response.data.responseBody)
    })
  }


  const showToastMessage = (title, message, messageType) => {
    toast.current.show({ severity: messageType, summary: title, detail: message, life: 15000 });
  }

  const submitStage3toServer = async (payload) => {
    setloading(true);
    console.log(payload);
    await axios.post(process.env.REACT_APP_DOVESAPI+'policy/apply/2', payload,{
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    }).then((response) => {

      if (response.data.status === "SUCCESS") {
        setloading(false);
        console.log(response.data.responseBody)
        showToastMessage("Progress Saved", userPolicyInput.mainMemberName + " your progress has been saved", "success");
        moveToNextView();
      }
      else {
        setloading(false);
        showToastMessage("Failed to save your progress", response.data.message, "warn");
      }
    }).catch((e) => {
      setloading(false);
      console.log(e)
      // showException()
    })


  }

  return (
    <div>
      <BlockUI blocked={loading}>
        <center>
          <Card className="m-4 col-xl-9">
            <form name="principalMemberForm" onSubmit={moveToNext}>
              <div className="row g-4">
                {/* <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <label className="form-label">Select your bank</label>
                  <select
                    className="form-select"
                    id="bankName"
                    name="bankName"
                    required
                    value={bankName}
                    onChange={(e) => { 
                      setBankName(e.target.value)
                      setBankId(e.target.value)
                    }}
                  >
                    <option value="">Select your bank</option>
                    {branches.map((bank) => (
                      <option key={bank.BankID} value={bank.BankID}>
                        {bank.BankName1}
                      </option>
                    ))}
                  </select>
                </div> */}
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <label className="form-label">Select your bank</label>
                        <div className="card flex justify-content-center">
                            <Dropdown name="bankName" id="bankName" value={bankName}  onChange={(e) => { 
                              console.log(e.value)
                              setBankName(e.value);
                              setBankId(e.value) }} options={branches} optionLabel="BankName1" 
                                placeholder="Select your Bank" className="w-full md:w-14rem" />
                        </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <label className="form-label">Enter bank branch name</label>
                  <input
                          type="text"
                          className="form-control"
                          id="branchName"
                          placeholder="Enter branch name"
                          name="branchName"
                          required
                          value={branchName}
                          onChange={(e) => setBranchName(e.target.value)}
                        />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <label className="form-label">Select an account type</label>
                        <div className="card flex justify-content-center">
                            <Dropdown value={accountType}  onChange={(e) => setAccountType(e.value)} options={accountTypes} optionLabel="name" 
                                placeholder="Select Account Type" className="w-full md:w-14rem" />
                        </div>
                </div>
                
                <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <label className="form-label">Enter account name</label>
                  <input
                          type="text"
                          className="form-control"
                          id="accountName"
                          placeholder="Enter account name"
                          name="accountName"
                          required
                          disabled
                          value={accountName}
                          onChange={(e) => setAccountName(e.target.value)}
                        />
                </div>

                <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <label className="form-label">Enter account number</label>
                  <input
                          type="number"
                          className="form-control"
                          id="accountNumber"
                          placeholder="Enter account number"
                          name="accountNumber"
                          required
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                        />
                </div>
                <div className="text-center">
                        <Button onClick={moveToNext} className='justify-content-center text-center mt-4' size='large' outlined label={<><strong >Continue </strong></>} icon="pi pi-arrow-right" severity="info" rounded raised />
                      </div>
              </div>
            </form>
          </Card>
        </center>
      </BlockUI>
      <center> 
      </center>
    </div>
  );
};

export default BankingDetailsForm;