import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../../../images/logo.png';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useAuth } from '../../components/auth/useAuth';
import { Skeleton } from 'primereact/skeleton';
import { useMountEffect } from 'primereact/hooks';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const history = useHistory();
  const auth = useAuth();
  const toast = useRef(null);

  const otpOptions = ['EMAIL', 'PHONE'];
  const [otpOption, setOtpOption] = useState(otpOptions[0]);
  const { logout,login } = useAuth();
  const [loading, setLoading] = useState(false);


  const buttonStyle = {
    width: '300px', 
    height: '30px',
};

  useMountEffect(() => {
    logout();
  });
  const handleSubmit = (e) => {

    e.preventDefault();


    if (!validateInput()) {
      return;
    }

    setEmailError('');

    setLoading(true);
    axios
      .post(process.env.REACT_APP_DOVESAPI + 'authenticate', {
        username: email,
        password: password
      })
      .then((response) => {
        console.log(response);
        setLoading(false)
        if (response.data.status === "SUCCESS") {
          localStorage.setItem('token', response.data.accessToken);
          localStorage.setItem('isAuthenticated', true);
          localStorage.setItem('userRole', (JSON.stringify(response.data.responseBody.userRole)));
          localStorage.setItem('userType', (JSON.stringify(response.data.responseBody.userType)));
          localStorage.setItem('firstName', (JSON.stringify(response.data.responseBody.firstName)));
          localStorage.setItem('emailAddress', (JSON.stringify(response.data.responseBody.emailAddress)));
          localStorage.setItem('lastName', (JSON.stringify(response.data.responseBody.lastName)));
          localStorage.setItem('userId', (JSON.stringify(response.data.responseBody.userId)));
          localStorage.setItem('idnumber', (JSON.stringify(response.data.responseBody.idnumber)));
          localStorage.setItem('userProfile', (JSON.stringify(response.data.responseBody)));
          console.log(response.data.responseBody);
          const user = { 'token': response.data.accessToken, 'data': response.data.responseBody }; // Define the user details
          auth.login(user); // Update the user details
          login(user);
          history.push('/');
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Login Failed',
            detail: response.data.message,
            life: 4000
          })

          setLoading(false)
        }

      })
      .catch((error) => {
        console.error(error);

        if (error.response && error.response.status === 401) {
          // Handle the token expiration error here
          toast.current.show({
            severity: 'error',
            summary: 'Session Expired',
            detail: 'Your session has expired. Please log in again.',
            life: 4000
          });

          setLoading(false)
  
        }
        else {
          // Handle other errors here
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'An unexpected error occurred. Please try again later.',
            life: 4000
          });

          setLoading(false)
        }
      });
  };

  const validateInput = () => {

    var result = '';
    if(otpOption === "EMAIL"){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      result = email.match(emailRegex)
      setEmailError('Please enter a valid email address or a 10-digit phone number.');
    }
    else{
      const phoneRegex = /^\d{5,}$/;
      result = email.match(phoneRegex)
      setEmailError('Please enter a valid phone number');
    }
    
    return result ;
  };


  return (
    <>
      <Toast ref={toast} />
      <div className="authincation ">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-4">
                <Link to={"/landing"}><img height="150px" src={logo} alt="" /></Link>
                <h4 className="card-title mt-3">Sign in to Doves Self Service Portal</h4>
              </div>
              {loading ? (
                <div className="border-round border-1 surface-border p-4 surface-card">
                  <div className="flex mb-3">
                    <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                    <div>
                      <Skeleton width="10rem" className="mb-2"></Skeleton>
                      <Skeleton width="5rem" className="mb-2"></Skeleton>
                      <Skeleton height=".5rem"></Skeleton>
                    </div>
                  </div>
                  <Skeleton width="100%" height="150px"></Skeleton>
                  <div className="flex justify-content-between mt-3">
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                  </div>
                </div>) : <div className="auth-form card">
                <div className="card-body">
                  <form name="myform" className="signin_validate row g-3" action="otp-2" onSubmit={handleSubmit}>
                    <div className="col-12">
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Email or Phone"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailError && <p className="error-message">{emailError}</p>} */}

                        <div className="col-12">
                         <center> <h5>Select Login Option </h5></center>
                          <div className="flex justify-content-center">
                            <center>
                              <SelectButton size="small" style={buttonStyle} value={otpOption} onChange={(e) =>{
                                if(e.value != null){
                                  setOtpOption(e.value)}
                                }
                                
                              }  options={otpOptions} />
                              </center>
                          </div>
                          <br></br>

                          { otpOption === "EMAIL" ? (
                            <>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={"Enter your email address here"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            // value={inputValue}
                            // onChange={(e) => handleEmailOtpOtion(e.target.value)}
                            required
                          />

                          </>
                          ):(
                            <>
                              {/* <input
                                type="text"
                                className="form-control"
                                placeholder={"Enter your phone number here"}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                required
                              /> */}

                              <PhoneInput
                              inputStyle={{
                                width: "100%", 
                                height: "43px"
                              }}
                                country={'zw'}
                                international={false}
                                // value={inputValue}
                                // onChange={(e)=>handlePhoneOtpOtion(e)}
                                value={email}
                                onChange={(e) => setEmail(e)}
                                // placeholder='263 717 787 787'
                              />
                            </>
                          )}

                          
                          
                          {emailError && <p className="text-danger">{emailError}</p>}
                        </div>
                    </div>
                    <div className="col-12">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-6">
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Remember me</label>
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      <Link to={"/reset"}>Forgot Password?</Link>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-primary btn-block">Sign in</button>
                    </div>
                  </form>
                  <p className="mt-3 mb-0">Don't have an account? <Link className="text-primary" to={"/landing"}>Sign up</Link></p>
                </div>
              </div>}
              <div className="privacy-link">
                {/* <Link to={"/signup"}>Have an issue with 2-factor authentication?</Link> */}
                {/* <br /> */}
                {/* <Link to={"/signup"}>Privacy Policy</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
