import React, { useState,useRef } from "react";
import { Button } from "primereact/button";
import axios from 'axios';
import { useMountEffect } from 'primereact/hooks';
import { Tag } from 'primereact/tag';
import { Checkbox } from 'primereact/checkbox';
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";
import { Toast } from 'primereact/toast';

const GroupActivation = ({ termAndConditions, groupImageBannerUrl, rowData }) => {
    const [productOptions, setProductOptions] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [productToBeEdited, setProductToBeEdited] = useState({})


    useMountEffect(() => {
        fetchProductsByGroupID()
    })

    function handleCheckBoxChanges(event) {
        setSelectedProducts(prevSelectedProducts => {
            if (event.checked) {
                return [...prevSelectedProducts, event.value];
            } else {
                return prevSelectedProducts.filter(product => product !== event.value);
            }
        });
    }
    const toast = useRef(null);

    function removeElementFromSelected(elementToRemove) {
        const index = selectedProducts.indexOf(elementToRemove);

        if (index !== -1) {
            selectedProducts.splice(index, 1);
        }

        setSelectedProducts(selectedProducts)
    }

    async function updateProducts() {
        // const payload = {easipolSubGroupId ,
        //     productId: productToBeEdited.ProductID,
        //     productExtension: selectedProducts
        // }


        // const payload ={
        //     "accessToken": "",
        //     "operation": "SaveActivePlansProducts",
        //     "requestBody": {
        //         "easipolSubGroupId": rowData.easipolSubGroupId,
        //         "activeStatus": true,
        //         "termAndConditions":"These are the terms and conditions for that product",
        //         "groupImageBannerUrl":"https://cdn-jkoif.nitrocdn.com/HfHWFpdftSlEKzpmZKWTczjXSKDdMgyx/assets/images/optimized/rev-9c9bb5e/doveswebsite.xplug.tech/wp-content/uploads/2023/07/female-gym-shoes-walking-in-sand.jpg",
        //         "productsList": [ 
        //             {
        //                 "productId": productToBeEdited.ProductID,
        //                  "productExtensions": selectedProducts
        //             }    
        //         ] 
        //     }
        // }

        console.log("rowData")
        console.log(rowData)
        console.log("rowData")

        // console.log(productToBeEdited.ProductID)
        const payload = {
            "accessToken": "",
            "operation": "SaveActivePlansProducts",
            "requestBody": {
                "easipolSubGroupId": rowData.easipolSubGroupId,
                "category": rowData.category,
                "activeStatus": rowData.activeStatus,
                "termAndConditions": rowData.termAndConditions,
                "groupImageBannerUrl": rowData.groupImageBannerUrl,
                "productsList": [
                    {
                        "productID": productToBeEdited.ProductID,
                        "productExtensions": selectedProducts.map((element) => {
                            return element.ProductID
                        })
                    }
                ]
            }
        };

        try {

            await axios.post(process.env.REACT_APP_DOVESAPI + "policy/products/active", payload,{
                headers: {
                  Authorization: 'Bearer ' + (localStorage.getItem('token'))
                }
              }).then((response) => {
                if (response.data.status === "SUCCESS") { 
                    toast.current.show({ severity: 'success', sticky: true, summary: 'SUCCESS', detail: response.data.message });
                    // window.location.reload();
                }
                else {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Failed', detail: response.data.message });
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    async function fetchProductsByGroupID() {
        try {
            await axios.get(process.env.REACT_APP_DOVESAPI + "policy/products/group/easipol/" + rowData.easipolSubGroupId,{
                headers: {
                  Authorization: 'Bearer ' + (localStorage.getItem('token'))
                }
              }).then((response) => {
                if (response.data.status === "SUCCESS") {

                    const responseMap = new Map();
                    response.data.responseBody.forEach(product => {
                        responseMap.set(product.ProductID, product);
                    });

                    console.log(rowData.planProducts)
                    const updatedArray = rowData.planProducts.map(product => {
                        const responseProduct = responseMap.get(product.ProductID);
                        if (responseProduct) {
                            return responseProduct;
                        }
                        return product;
                    });

                    // setSelectedProducts(updatedArray);
                    setProductOptions(response.data.responseBody)
                    setIsLoading(true)
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <>  <Toast ref={toast} />
            <div className="">
                <div className="container">
                    <div className="row">
                        <center>
                            <h4>{rowData.easipolPolicySubGroup.Description} </h4>
                            {console.log(rowData)}

                            <br></br>
                            <div className="">
                                <div className="">
                                    <div>
                                        <div>
                                            <Dropdown value={productToBeEdited} onChange={(e) => setProductToBeEdited(e.value)} options={rowData.planProducts} optionLabel="ProductName1"
                                                placeholder="Select a product to update" className="w-full md:w-14rem" />
                                            <div key='d'>
                                                <hr />
                                                <b>{productToBeEdited.ProductName1}</b>
                                                <div>{productToBeEdited.BenefitsText}</div>
                                                <div>
                                                    Age From: {productToBeEdited.AgeFrom} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Age To: {productToBeEdited.AgeTo}
                                                    <br></br><br></br>

                                                    <h5><b>Product Extensions</b></h5>

                                                    <div className=' col-md-12 row flex'>
                                                        { console.log(productOptions)}
                                                        {isLoading ? (
                                                            productOptions.map((productOption, index1) => (
                                                                index1 !== null &&
                                                                <div key={'c' + index1} style={{ margin: "3px", width: "auto" }}>
                                                                    <Tag style={{ backgroundColor: "#f2f2f2", color: "#455964" }}>

                                                                        <Checkbox
                                                                            onChange={handleCheckBoxChanges}
                                                                            inputId={`cb${index1}`}
                                                                            value={productOption}
                                                                            checked={selectedProducts.includes(productOption)}
                                                                        // checked={selectedProducts.includes(productOption)}
                                                                        ></Checkbox>


                                                                        {index1 + 1 + ". " + productOption.ProductName1}
                                                                    </Tag>

                                                                </div>

                                                            ))) : (
                                                            <>
                                                                <center>
                                                                    <div className="border-round border-1 surface-border p-4 surface-card">
                                                                        <div className="flex mb-3">
                                                                            <div>
                                                                                <Skeleton width="10rem" className="mb-2"></Skeleton>
                                                                                <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                                                <Skeleton height=".5rem"></Skeleton>
                                                                            </div>
                                                                        </div>
                                                                        <Skeleton width="100%" height="150px"></Skeleton>
                                                                        <div className="flex justify-content-between mt-3">
                                                                            <Skeleton width="4rem" height="2rem"></Skeleton>
                                                                            <Skeleton width="4rem" height="2rem"></Skeleton>
                                                                        </div>
                                                                    </div>
                                                                </center>
                                                            </>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{ position: "sticky", bottom: "10px", textAlign: "right" }}>
                                            <Button severity="primary" onClick={updateProducts} size='small' icon="pi pi-upload" label='Save Changes' raised></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GroupActivation;
