import { Link } from 'react-router-dom';
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from "primereact/inputswitch";
import { useMountEffect } from 'primereact/hooks';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { Editor } from "primereact/editor";
import { Skeleton } from 'primereact/skeleton';
import { InputTextarea } from "primereact/inputtextarea";

const GroupActivation = ({ onCloseDialog, rowData }) => {

    const [loading, setLoading] = useState(false);

    return (
        <>
            {/* <Toast ref={toast} /> */}
            <div className="">
                <div className="container">
                    <div className="row">
                        <center>
                            <img src={rowData.groupImageBannerUrl} alt="No Group Artwork" className="pb-2 w-2rem shadow-2 border-round" style={{ width: '20%' }} />

                        </center>
                        {/* <h4 className='m-2'>{rowData.easipolPolicySubGroup.Description}</h4> */}
                        {console.log(rowData)}
                        <ul className='mt-1'>
                            <li className='row'>
                                <div className='col-md-6'>
                                    <h4><strong>Group Name :</strong></h4>
                                </div>
                                <div className="text-right col-md-6">
                                    <h4>{rowData.easipolPolicySubGroup.Description}</h4>
                                </div>
                            </li>
                            <li className='row'>
                                <div className='col-md-6'>
                                    <h4><strong>Category Name :</strong></h4>
                                </div>
                                <div className="text-right col-md-6">
                                    <h4>{rowData.category}</h4>
                                </div>
                            </li>
                            <li className='row'>
                                <div className='col-md-6'>
                                    <h4><strong>Products Count :</strong></h4>
                                </div>
                                <div className="text-right col-md-6">
                                    <h4>{rowData.planProducts?.length}</h4>
                                </div>
                            </li>
                            <li className='row'>
                                <div className='col-md-6'>
                                    <h4><strong>Terms & Conditions (Unformatted Text) :</strong></h4>
                                </div>
                                <div className="text-right col-md-6">
                                    <InputTextarea disabled rows={3} cols={30} value={rowData.termAndConditions} />
                                </div>
                            </li>
                        </ul>
                        <br></br>
                        <div className="">
                            <div className="">
                                <div>
                                    {rowData.planProducts.map((element, index) => (
                                        <div key={index}>
                                            <hr />
                                            <b>{element.ProductName1}</b>
                                            <br></br>
                                            <div><strong>Product Benefits: </strong> {element.BenefitsText}</div>
                                            <div>
                                                <br></br>
                                                <strong>Age From:</strong> {element.AgeFrom} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br></br><strong>Age To:</strong> {element.AgeTo} <br></br>
                                                <strong>Can have Children:</strong>  {(element.CanHaveChildren === "1") ? "True" : "False"} <br></br>
                                                <strong>Can have Spouse Beneficiary:</strong>  {element.CanHaveSpouse === "1" ? "True" : "False"}<br></br>
                                                <strong>Can have Extended Beneficiary:</strong>  {element.Extended === "1" ? "True" : "False"} <br></br>
                                                <strong>Default Waiting Period:</strong>  {element.DefaultWaiting} <br></br>
                                                <strong>Currency Type:</strong>  {element.CurrencyType} <br></br>
                                                <strong>Total Price:</strong>  {element.Total} <br></br>
                                                <strong>Product ID:</strong>  {element.ProductID} <br></br>
                                                <strong>Franchise ID:</strong>  {element.FranchiseID} <br></br>
                                                {/* <Button style={{float:"right"}} size='small' icon="pi pi-plus" label='Product Extension' raised  text></Button> */}
                                            </div>
                                            <div style={{ float: "right" }}>
                                                {/* <Button label='Product Extension' raise  text></Button> */}
                                            </div>
                                            {/* <br></br> */}

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default GroupActivation;
