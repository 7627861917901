import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../../../images/logo.png';
import { SelectButton } from 'primereact/selectbutton';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';

const Reset = () => {
  const [inputValue, setInputValue] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [resetStep, setResetStep] = useState(1);
  const [userId, setUserId] = useState('');
  const [securityToken, setSecurityToken] = useState('');
  const otpOptions = ['EMAIL', 'PHONE'];
  const [otpOption, setOtpOption] = useState(otpOptions[0]);
  const toast = useRef(null);


  const footer = (
    <React.Fragment>
        <Divider />
        <p className="mt-2">Suggestions</p>
        <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
            <li>At least one lowercase</li>
            <li>At least one uppercase</li>
            <li>At least one numeric</li>
            <li>Minimum 8 characters</li>
        </ul>
    </React.Fragment>
);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (resetStep === 1) {
      if (!validateInput()) {
        return;
      }
      setError('');

      try {
        const response = await axios.post(process.env.REACT_APP_DOVESAPI + 'user/requestPasswordResetLink', {
          accessToken: '',
          operation: 'request-password-reset-link',
          requestBody: {
            username: inputValue,
          },
        }).then((response) => {
          if (response.data.status === "SUCCESS") {
            showToast('info', "OTP Sent", "Check your " + otpOption.toLowerCase() + ", we sent you an OTP", 5000)
            
            // const { securityToken } = response.data.responseBody; // Assuming the API response contains userId and securityToken

            setUserId(response.data.responseBody.userId); 
            setResetStep(2);

          }
          else {
            showToast('warn', "Error", response.data.message, 4000)
          }
        });

      } catch (error) {
        console.log(error);
        setError('Failed to request password reset link. Please try again later.');
      }
    } else {
      // if (!validateOtp()) {
      //   setError('Please enter a valid OTP.');
      //   return;
      // }
      if (!validatePassword()) {
        setError('Please enter a valid password and confirm password.');
        return;
      }
      setError('');

      try { 
        await axios.post(process.env.REACT_APP_DOVESAPI + `user/resetNewPassword/${userId}/${otpValue}`, {
          accessToken: '',
          operation: 'set-new-password',
          requestBody: {
            username: inputValue,
            password: password,
          },
        }).then((response) => {
          if (response.data.status === "SUCCESS") {
            showToast('success', "Success", "Password reset successful", 3000)
            window.location.href="/signin"
          }
          else {
            showToast('warn', "Error", response.data.message, 4000)

          }
        });


      } catch (error) {
        // setError('Failed to reset password. Please try again later.');
        showToast('error', "Error", "Error occured, advise the customer service", 5000)

      }
    }
  };

  const showToast = (severity, summary, message, period) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: message,
      life: period
    });
  }
  const handleResendOTP = async () => {
    try {
      await axios.post(process.env.REACT_APP_DOVESAPI + 'user/requestPasswordResetLink', {
        accessToken: '',
        operation: 'resend-otp',
        requestBody: {
          username: inputValue,
        },
      }).then((response) => {
        if (response.data.status === "SUCCESS") {
          showToast('info', "OTP Sent", "Check your " + otpOption.toLowerCase() + ", we sent you an OTP", 5000)
          const { userId } = response.data; // Assuming the API response contains userId and securityToken
          setUserId(userId); 
          setResetStep(2);
        }
        else {
          showToast('warn', "Error", response.data.message, 4000)
        }
      });
    } catch (error) {
      showToast('error', "Error", "Failed to resend OTP. Please try again later.", 5000)

      // setError('Failed to resend OTP. Please try again later.');
    }
  };
  const validateInput = () => {

    if (otpOption === "EMAIL") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isEmailValid = inputValue.match(emailRegex);
      !isEmailValid ? setError('Please enter a valid email address.') : setError('')
      return isEmailValid
    }
    else {
      const phoneRegex = /^\d{6,}$/;  // Matches if there are 6 or more digits consecutively
      const isPhoneValid = phoneRegex.test(inputValue);
      !isPhoneValid ? setError('Please enter a valid phone number.') : setError('')
      return isPhoneValid;
    }


  };

  const handlePhoneOtpOtion = (value) => {
    setInputValue(value)
  };

  const handleEmailOtpOtion = (value) => {
    setInputValue(value)
  };

  const validateOtp = () => {
    const otpRegex = /^\d{6}$/;
    const isOtpValid = otpValue.match(otpRegex);
    return isOtpValid;
  };

  const validatePassword = () => {
    return password !== '' && password === confirmPassword;
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-4 col-md-5">
              <div className="mini-logo text-center my-3">
                <Link to={"/landing"}>
                  <img height="150px" src={logo} alt="" />
                </Link>
                <h4 className="card-title mt-3">Reset Password</h4>
              </div>
              <div className="auth-form card">
                <div className="card-body">
                  <form className="row g-3">
                    {resetStep === 1 ? (
                      <>
                        <div className="col-12">
                          <h5>How you want to receive your reset OTP? </h5>
                          <div className="card flex justify-content-center">
                            <center>
                              <SelectButton size="small" value={otpOption} onChange={(e) => {
                                if (e.value != null) {
                                  setOtpOption(e.value)
                                }
                              }

                              } options={otpOptions} />
                            </center>
                          </div>

                          {otpOption === "EMAIL" ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={"Enter your email address here"}
                                value={inputValue}
                                onChange={(e) => handleEmailOtpOtion(e.target.value)}
                                required
                              />

                            </>
                          ) : (
                            <>
                              {/* <input
                                type="text"
                                className="form-control"
                                placeholder={"Enter your phone number here"}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                required
                              /> */}

                              <PhoneInput
                                country={'zw'}
                                international={false}
                                value={inputValue}
                                onChange={(e) => handlePhoneOtpOtion(e)}
                                placeholder='263 717 787 787'
                              />
                            </>
                          )}



                          {error && <p className="text-danger">{error}</p>}
                        </div>
                        <div className="text-center mt-4">
                          <button onClick={handleFormSubmit} type="submit" className="btn btn-primary btn-block">
                            Submit
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12">
                          <label className="form-label">Enter the OTP</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={otpValue}
                            onChange={(e) => setOtpValue(e.target.value)}
                            required
                          />
                          {error && <p className="text-danger">{error}</p>}
                        </div>
                        <div className="col-12">
                          {/* <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          /> */}

                          <Password
                            placeholder="Password"
                            inputClassName='form-control'
                            name="password"
                            value={password}
                            toggleMask={true}
                            mediumLabel
                            id="f3" inputStyle={{ width: "100%" }} style={{ width: "100%" }}
                            header={''}
                            footer={footer}
                            mediumRegex
                            feedback
                            onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="col-12">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                          {error && <p className="text-danger">{error}</p>}
                        </div>
                        <div className="text-center mt-4">
                          <button onClick={handleFormSubmit} type="submit" className="btn btn-primary btn-block">
                            Submit
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                  {resetStep === 1 ? (
                    <>
                    </>
                  ) : (
                    <>
                      <div className="new-account mt-3">
                        <p>
                          Don't get code?{' '}
                          <Link className="text-primary" to="#" onClick={handleResendOTP}>
                            Resend
                          </Link>
                        </p>
                      </div>

                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
