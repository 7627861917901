import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../../images/logo.png';
import NotFound from '../../images/not-found.gif';
import { Button } from 'primereact/button';


const Reset = () => {
 

  return (
    <>
      {/* <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-6 col-md-7"> */}
              
              <div className="auth-form ">
                <div className="card-body">
                  <div className="mini-logo text-center my-3">
                  <Link to={"/landing"}>
                    <img height="450px" src={NotFound} alt="Imageot found" />
                  </Link>
                  <h4 className="card-title mt-3">SORRY, PAGE NOT FOUND</h4>
                  <br></br><br></br>
                  {/* <Button text raised>
                      Back
                  </Button> */}
                  <Button label="HOME" size='small' onClick={()=>{
                    window.location.href="/signin"
                  }} icon="pi pi-home" severity="primary" text raised />

                </div>
                </div>
              </div>
            {/* </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Reset;
