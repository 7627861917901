import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import axios from "axios";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useMountEffect } from 'primereact/hooks';
import PrimeIcons from 'primeicons/primeicons.css';





const PrincipalMemberForm = ({ moveToNextView, moveToPreviousView, userPolicyInput, setuserPolicyInput, titles, toast }) => {
  const [firstName, setFirstName] = useState("");
  const [otherNames, setOtherNames] = useState("");
  const [surname, setSurname] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [dob, setDob] = useState("");
  const [title, setTitle] = useState("");
  const [gender, setGender] = useState("");
  const [mStatus, setMstatus] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [altPhoneNumber, setAltPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [region, setRegion] = useState("Mutare");
  const [nationality, setNationality] = useState('');
  const [cumulativeResponse, setCumulativeResponse] = useState(null)
  const [formErrors, setFormErrors] = useState({});


  const [otherNameError, setOtherNameError] = useState(true);
  

  const [loading, setloading] = useState(false);
  const genderOptions = [
    {
      name: 'MALE',
      value: 'MALE'
    },
    {
      name: 'FEMALE',
      value: 'FEMALE'
    },
  ]

  const nationalityOptions = [
    {
      name: 'Zimbabwean',
      value: 'ZIMBABWEAN'
    },
    {
      name: 'South African',
      value: 'SOUTH AFRICAN'
    },
    {
      name: 'Namibian',
      value: 'NAMIBIAN'
    },
    {
      name: 'Botswanan',
      value: 'BOTSWANAN'
    },
    {
      name: 'Zambian',
      value: 'ZAMBIAN'
    },
    {
      name: 'Mozambican',
      value: 'MOZAMBICAN'
    },
    {
      name: 'Swazi',
      value: 'SWAZI'
    },
    {
      name: 'Lesotho',
      value: 'LESOTHO'
    },
    {
      name: 'Malawian',
      value: 'MALAWIAN'
    },
  ];  


  const fetchFromUser = ()=>{
    const userProfileData = localStorage.getItem("userProfile");

    if (userProfileData) {
        try {
            const decodedData = JSON.parse(userProfileData);
            setFirstName(decodedData.firstName)
            setSurname(decodedData.lastName)
            setEmail(decodedData.emailAddress)
            setPhoneNumber(decodedData.phoneNumber)
            setIdNumber(decodedData.idnumber)

        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    } else {
        console.log("userProfileData not found in localStorage.");
    }

  }

  useMountEffect(() => {
    fetchFromUser()
    populateForExisting()
});

function convertDOBToCAT(dobString) {
  // Parse the DOB string into a Date object
  const dobDate = new Date(dobString);

  // Create a CAT (Central Africa Time) Date object
  const catDate = new Date(dobDate);
  catDate.setUTCHours(catDate.getUTCHours() + 2);

  return catDate;
}


function populateForExisting() {
  if(userPolicyInput.mainMember != null){
    setFirstName(userPolicyInput.mainMember.First_Name)
    setAddress(userPolicyInput.mainMember.Address)
    setNationality(userPolicyInput.mainMember.Region)
    setSurname(userPolicyInput.mainMember.Surname)
    setDob(convertDOBToCAT(userPolicyInput.mainMember.Date_Of_Birth))
    setGender(userPolicyInput.mainMember.Gender)
    setTitle(userPolicyInput.mainMember.Title)
    setMstatus(userPolicyInput.mainMember.Marital_Status)
    setAltPhoneNumber(userPolicyInput.mainMember.Home_Tel)
    console.log(userPolicyInput.mainMember.Date_Of_Birth)

    // nationalityOptions
  }
}
  const titleOptions = [
    {
      name: 'Mr',
      value: 'MR'
    },
    {
      name: 'Mrs',
      value: 'MRS'
    },
    {
      name: 'Miss',
      value: 'MISS'
    },
    {
      name: 'Ms',
      value: 'MS'
    },
    {
      name: 'Dr',
      value: 'DR'
    },
    {
      name: 'Prof',
      value: 'PROF'
    },
    {
      name: 'Rev',
      value: 'REV'
    }
  ];

  


  const maritalStatusOptions = [
    {
      name: 'SINGLE',
      value: 'SINGLE'
    },
    {
      name: 'MARRIED',
      value: 'MARRIED'
    },
    {
      name: 'DIVORCED',
      value: 'DIVORCED'
    },
    {
      name: 'WIDOWED',
      value: 'WIDOWED'
    },
    {
      name: 'SEPARATED',
      value: 'SEPARATED'
    }
  ];


  function handleSubmit(e) {
    e.preventDefault();
    
    const errors = formValidation();

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      moveToNext()
      
    } else {
      setFormErrors(errors);
    }
  }

  const moveToNext = () => {
    

    let mainMember ={
      firstName: firstName,
      surname: surname,
      idNumber: idNumber,
      dob: formatDateToDDMMYYYY(dob),
      title: title,
      ID_Number: idNumber,
      ProductId: userPolicyInput.selectedProductId,
      First_Name: firstName,
      Initials: generateInitials(firstName, surname),
      Title: title,
      Surname: surname,
      Date_Of_Birth: formatDateToDDMMYYYY(dob),
      Region: nationality,
      Gender: gender,
      Marital_Status: mStatus,
      Address: address,
      PostalAddress: address,
      Home_Tel: phoneNumber,
      Work_Tel: phoneNumber,
      Cell_Number: phoneNumber,
      EmailAddress: email,
      MMAge: calculateAge(dob),
      SourceOfWealth: "Employment",
      SourceOfFunds: "Employment",
      ExtendedPlanID: userPolicyInput.selectedProductId,
      ExtendedPlanCover: 0,
      Nationality: nationality

    }

    console.log(mainMember);

    const payload = {
      "operation": "createPolicyApplicationStage1",
      "requestBody": {
        "planId": userPolicyInput.selectedPlanName,
        "planName": userPolicyInput.selectedPlanID,
        "productId": userPolicyInput.selectedProductId,
        "groupId": userPolicyInput.selectedSubGroupPlan.easipolPolicySubGroup.SubGroupID,
        "productName": userPolicyInput.selectedProductName,
        "applicationTotalStages": 3,
        "currentStages": 1,
        "applicationType": userPolicyInput.selectedPlanType,
        "mainMember": mainMember
      }
    }
   
    setuserPolicyInput({ ...userPolicyInput, mainMember: mainMember, mainMemberName: mainMember.firstName });
    submitStage1toServer(payload);

  }

  const formValidation = ()=>{
    const errors = {}
    if (!firstName.trim()) {
      errors.firstName = 'First name is required';
    }
  
    // if (!otherNames.trim()) {
    //   errors.otherNames = 'Other names are required';
    // }
  
    if (!surname.trim()) {
      errors.surname = 'Surname is required';
    }
  
    if (!idNumber.trim()) {
      errors.idNumber = 'ID number is required';
    }

    if (!title.trim()) {
      errors.title = 'Select your title';
    }

    if (!gender.trim()) {
      errors.gender = 'Select your gender';
    }

    if (!mStatus.trim()) {
      errors.mStatus = 'Select your marital status';
    }

    if (!dob) {
      errors.dob = 'Select your date of birth';
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    }

    if (!address.trim()) {
      errors.address = 'Address is required';
    }

    if (!nationality.trim()) {
      errors.nationality = 'Select your nationality';
    }

    return errors
  }
  function validateDateOfBirth(value) {
    
    const applicantAge = calculateAge(value)
    
    if( applicantAge < Number(userPolicyInput.selectedProduct.AgeFrom) ||  applicantAge > Number(userPolicyInput.selectedProduct.AgeTo) ){

      let errors = formValidation();

      errors.dob = "Date out of range: Your age should between "+ userPolicyInput.selectedProduct.AgeFrom +" and "+ userPolicyInput.selectedProduct.AgeTo +"."

      setFormErrors(errors);

    }
    else{
      setDob(value)
    }

    
  }
  function formatDateToDDMMYYYY(inputDate) {
    const date = new Date(inputDate);
    
    if (isNaN(date)) {
      return "Invalid Date";
    }
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  

  const showToastMessage = (title, message, messageType) => {
    toast.current.show({ severity: messageType, summary: title, detail: message, life: 15000 });
  }

  function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  function generateInitials(firstName, surname) {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const surnameInitial = surname.charAt(0).toUpperCase();
    return firstInitial + surnameInitial;
  }

  const submitStage1toServer = async (payload) => {
    setloading(true);
    console.log(payload);
    await axios.post(process.env.REACT_APP_DOVESAPI+'policy/apply/1', payload,{
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        setloading(false);
        console.log(response.data.responseBody)
        setCumulativeResponse(response.data.responseBody)
        showToastMessage("Progress Saved", firstName + " your progress has been saved", "success");
        moveToNextView();
      }
      else {
        setloading(false);
        showToastMessage("Failed to save your progress", response.data.message, "warn");
      }
    }).catch((e) => {
      setloading(false);
      console.log(e)
    })


  }



  return (
    <div>
      
      <BlockUI blocked={loading}>
        <center>

          {/* {} */}
          <Card className="m-4 col-xl-9">
            <form name="principalMemberForm" onSubmit={handleSubmit}>
              <div className="row g-4">
                <br></br>
                <h3>BASIC INFORMATION</h3>
                <div className="col-xxl-4 col-xl-4 col-lg-4">
                  
                  <span className="p-float-label">
                    <Dropdown  inputId="title" placeholder="Your title" value={title} options={titleOptions} onChange={(e) => setTitle(e.value)} optionLabel="name" style={{ width:"100%"}} ></Dropdown>
                    <label htmlFor="title">Select Title</label>
                  </span>
                  {formErrors.title && <div className="error-message"> { formErrors.title }</div>}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 flex justify-content-center">
                  <span className="p-float-label">
                 
                  <Dropdown placeholder="Your gender"  inputId="gender" value={gender} options={genderOptions} onChange={(e) => setGender(e.value)} optionLabel="name" style={{ width:"100%"}} ></Dropdown>

                  <label style={{marginLeft:"20px"}} htmlFor="gender">Select Gender</label>
                  </span>
                  {formErrors.gender && <div className="error-message"> { formErrors.gender }</div>}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4">
                 
                  <span className="p-float-label">
                    <Dropdown  inputId="mStatus" placeholder="Your marital Status" value={mStatus} options={maritalStatusOptions} onChange={(e) => setMstatus(e.value)} optionLabel="name" style={{ width:"100%"}} ></Dropdown>
                    <label htmlFor="mStatus">Select Marital Status</label>
                  </span>
                  {formErrors.mStatus && <div className="error-message"> { formErrors.mStatus }</div>}

                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 flex justify-content-center">
                <span className="p-float-label">
                  <InputText
                    className="form-control"
                    id="firstName"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <label htmlFor="firstName"> First Name </label>
                  </span>
                  {formErrors.firstName && <div className="error-message"> {formErrors.firstName}</div>}
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <span className="p-float-label">
                  <InputText
                    className="form-control"
                    id="otherNames"
                    placeholder="Enter your other name"
                    value={otherNames}
                    onChange={(e) => setOtherNames(e.target.value)}
                  />
                  <label htmlFor="otherNames">Other names </label>
                  </span>
                  {formErrors.otherNames && <div className="error-message"> {formErrors.otherNames}</div>}

                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3">
                  
                  <span className="p-float-label">
                  <InputText
                    className="form-control"
                    id="surname"
                    placeholder="Enter your surname"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                  <label htmlFor="surname">Surname </label>
                  </span>
                  {formErrors.surname && <div className="error-message"> { formErrors.surname }</div>}
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <span className="p-float-label">
                  <InputText
                  required
                    className="form-control"
                    id="idNumber"
                    placeholder="Enter your national ID"
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                  />
                  <label htmlFor="idNumber"> National ID Number </label>
                  </span>
                  {formErrors.idNumber && <div className="error-message"> { formErrors.idNumber }</div>}
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 flex justify-content-center">
                  <span className="p-float-label">
                  {/* <Calendar
                      showIcon
                      className="create-role-input"
                      inputId="birth_date"
                      placeholder="Select your Date of birth"
                      value={dob}
                      onChange={(e) => {
                        if (e.value !== null) {
                          validateDateOfBirth(e.value);
                        }
                      }}
                    /> */}

<Calendar
  showIcon
  className="create-role-input"
  inputId="birth_date"
  placeholder="Select your Date of birth"
  value={dob}
  onChange={(e) => {
    if (e.value !== null) {
      validateDateOfBirth(e.value);
    }
  }}
  prevIcon="pi pi-chevron-left" // PrimeIcons class for navigating to the previous month
  nextIcon="pi pi-chevron-right" // PrimeIcons class for navigating to the next month
/>



                    <label htmlFor="birth_date">Birth Date</label>
                  </span>
                  {formErrors.dob && <div className="error-message"> { formErrors.dob }</div>}
                </div>
                <br></br>
                <h3>CONTACT DETAILS</h3>
                <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <span className="p-float-label">
                    <InputText
                      id="phoneNumber"
                      placeholder="Phone number"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      disabled
                      className="form-control"
                    />
                    <label htmlFor="phoneNumber">Phone Number</label>
                  </span>
                  {formErrors.phoneNumber && <div className="error-message"> { formErrors.phoneNumber }</div>}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <span className="p-float-label">
                    <InputText
                      id="altPhoneNumber"
                      placeholder="Phone number"
                      name="altPhoneNumber"
                      value={altPhoneNumber}
                      onChange={(e) => setAltPhoneNumber(e.target.value)}
                      // required
                      className="form-control"
                    />
                    <label htmlFor="altPhoneNumber">Alternative Phone Number</label>
                  </span>
                  {formErrors.altPhoneNumber && <div className="error-message"> { formErrors.altPhoneNumber }</div>}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4">
                  <span className="p-float-label">
                    <InputText
                      id="email"
                      placeholder="Email address"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      // required
                      className="form-control"
                    />
                    <label htmlFor="email">Email Address</label>
                  </span>
                  {formErrors.email && <div className="error-message"> { formErrors.email }</div>}
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <span className="p-float-label">
                    <InputText
                      id="address"
                      placeholder="Enter your address"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="form-control"
                    />
                    <label htmlFor="address">Physical Address</label>
                  </span>
                  {formErrors.address && <div className="error-message"> { formErrors.address }</div>}
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">

                  <span className="p-float-label">
                    <Dropdown inputId="nationality" value={nationality} options={nationalityOptions} onChange={(e) => setNationality(e.value)} optionLabel="name" style={{ width:"100%"}} ></Dropdown>
                    <label htmlFor="nationaLity">Select Nationality</label>
                  </span>
                  {formErrors.nationality && <div className="error-message"> { formErrors.nationality }</div>}
                </div>
                <div className="text-center">
                  <Button className='justify-content-center text-center mt-4' size='large' outlined label={<><strong >Continue </strong></>} icon="pi pi-arrow-right" severity="info" rounded raised />
                </div>
              </div>
            </form>
          </Card>
        </center>
      </BlockUI>
    </div>
  );
};

export default PrincipalMemberForm;
