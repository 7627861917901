import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Header from '../../layout/headers/header'
import CustomerNavMenu from "../../layout/navigation/topbar/CustomerNavMenu"
import DynamicDashboard from "../../layout/dashboard/dynamic-dashboard"
import DynamicNavigation from "../../layout/navigation/dynamic-navigation"
import TrackLoanApplication from "../../components/loans/track_loans_application_progress"
import MakePayment from '../../components/makePayment/makePayment';
import PolicyPaymentHistory from '../../components/paymentsHistory/PolicyPaymentHistoryTable_'
import BalanceWidgetMin from "../../components/paymentsHistory/getPolicyBalanceMin"
import PolicyBalance from '../../components/paymentsHistory/getPolicyDisplayMin'

const Payments = () => {
    return (
        <>
            {/* <Header /> */}
            <DynamicNavigation />
            <div className="content-body">
                <div className="container">
                    <div className="row"> 
                        <div className="row">
                        <PolicyBalance />
                        </div> 
                    </div>
                </div>
            </div>
        </>
    );
};

export default Payments;