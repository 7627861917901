import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMountEffect } from 'primereact/hooks';
import { Card } from 'primereact/card';
import axios from 'axios';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import { Link, useHistory } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import localBGImage from "../../../images/patterns/applyPolicy.png"
import MakePayment from '../makePayment/makePayment';
import PolicyBalance from '../../components/paymentsHistory/getPolicyDisplay'

function BalanceWidgetMin() {
  const [balanceDetails, setBalanceDetails] = useState(null);
  const [policyDisplay, setpolicyDisplay] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory()
  let userProfile = {};


  function parseTrackApplicationResponse(apiResponse) {

    // console.log(apiResponse);
    if (apiResponse && apiResponse.responseBody && apiResponse.responseBody.policyApplicationRequestId) {
      // console.log("---- Yes");
      return <PendingApplicationPolicyDisplay applicationDetail={apiResponse.responseBody} />;
    } else if (apiResponse && apiResponse.responseBody && apiResponse.responseBody.MainMember) {
      // console.log("---- NO");
      return <EasipolPolicyDisplay balanceDetails={apiResponse.responseBody} />;
    } else {
      // console.log("---- MAYbe");
      return <NewApplicationPolicyDisplay applicationDetail={apiResponse.responseBody} />;
    }
  }

  function PendingApplicationPolicyDisplay({ applicationDetail }) {

    console.log(applicationDetail.applicationStatus);
    switch (applicationDetail.applicationStatus) {
      case 'waiting-approval':
        return (
          <>
            <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Your Policy is being reviewed</h3>
                <p><strong>We will be in touch in a moment</strong><br />We embrace operational excellence at all times, gained by our years of extensive experience</p>

              </div>
            </Card>
          </>
        );
      case 'incomplete-application':
        return <>
          <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
            <div className='m-2 flex justify-content-center'>
              <h3 className='text-left '>You have not completed your policy application</h3>
              <p><strong>You can </strong>continue from where you left from and finish you application</p>
              <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Continue Application</strong></>} severity="warn" rounded outlined raised
                onClick={() => { history.push("/manage-policy") }}
              />
            </div>
          </Card>
        </>;
      case 'rejected-policy-application':
        return (
          <>
            <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Your Policy might have concerns that need you to address them.</h3>
                <p><strong>For the ones you love</strong><br />We embrace operational excellence at all times, gained by our years of extensive experience</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Contact Support</strong></>} severity="warn" rounded outlined raised
                  onClick={() => { history.push("/manage-policy") }}
                />
              </div>
            </Card>
          </>
        );;
      default:
        return (
          <>
            <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Your Policy application is being processed</h3>
                <p><strong>For the ones you love</strong><br />We embrace operational excellence at all times, gained by our years of extensive experience</p>
                {/* <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Contact Support</strong></>} severity="warn" rounded outlined raised
                onClick={() => { history.push("/manage-policy") }}
              /> */}
              </div>
            </Card>
          </>
        );
    }
  };
  function EasipolPolicyDisplay({ balanceDetails }) {
    // console.log(balanceDetails);
    // Process the second response data here
    return (
      <>
        <div className="col-xxl-6 col-xl-6 col-lg-12">
          <MakePayment balanceFormatted={balanceDetails.BalanceFormatted} policyNumberCurrentUser={balanceDetails.MainMember.Policy_Number} phoneNumberCurrentUser={balanceDetails.MainMember.Cell_Number} />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-12">
          <Card className='' >
            <h2 className='text-left mt-1 mb-2'><strong>Your Policy Details</strong></h2>
            <Divider type='dotted' />
            <ul className='mt-1'>
              <li className='row'>
                <h4>Principal Member : {balanceDetails.MainMember.Title} {balanceDetails.MainMember.First_Name} {balanceDetails.MainMember.Surname}</h4>

              </li>
              <li className='row'>
                <h4>Current Balance : ${balanceDetails.BalanceFormatted}</h4>
              </li>
              <li className='row'>
                <h4>Usual Premium : ${balanceDetails.MainMember.UsualPremium}</h4>
              </li>
              <li className='row'>
                <h4>Policy Number : {balanceDetails.MainMember.Policy_Number}</h4>
              </li>
            </ul>
          </Card>
        </div>
      </>
    );
  }
  function NewApplicationPolicyDisplay(response) {
    return (
      <>
        <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
          <div className='m-2 flex justify-content-center'>
            <h3 className='text-left '>Get an instant Doves Life Policy</h3>
            <p><strong>For the ones you love</strong><br />We embrace operational excellence at all times, gained by our years of extensive experience</p>
            <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
              onClick={() => { history.push("/manage-policy") }}
            />
          </div>
        </Card>
      </>
    );
  }
  useMountEffect(() => {
    const getPolicyDetails = async () => {

      try {
        userProfile = (JSON.parse(localStorage.getItem('userProfile')));
        await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/balance/' + userProfile.idnumber, {
          headers: {
            Authorization: 'Bearer ' + (localStorage.getItem('token'))
          }
        }).then((response) => {
          setLoading(false);
          if (response.data.status === "SUCCESS") { 
            // console.log(response);

            if (response.data.responseBody === null) {
            } else {
              setBalanceDetails(response.data.responseBody);
            }

          } else {
            // console.log(response.data.status)
          }
          // console.log(userProfile.idnumber);
          // console.log(response.data);
          setpolicyDisplay(parseTrackApplicationResponse(response.data))
        });

      } catch (error) {
        setLoading(false);
        console.error('Error fetching balance details:', error);
      }
    };

    getPolicyDetails();
  }, []);

  return (
    <div className="row">
      {loading ? (
        <div className="border-round border-1 surface-border p-4 surface-card">
          <div className="flex mb-3">
            <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
            <div>
              <Skeleton width="10rem" className="mb-2"></Skeleton>
              <Skeleton width="5rem" className="mb-2"></Skeleton>
              <Skeleton height=".5rem"></Skeleton>
            </div>
          </div>
          <Skeleton width="100%" height="150px"></Skeleton>
          <div className="flex justify-content-between mt-3">
            <Skeleton width="4rem" height="2rem"></Skeleton>
            <Skeleton width="4rem" height="2rem"></Skeleton>
          </div>
        </div>) :
        <div className="row">
          {policyDisplay}
        </div>}
    </div>
  );
}

export default BalanceWidgetMin;
