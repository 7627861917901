
import React, { useState, useEffect } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { ProgressBar } from 'primereact/progressbar';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useMountEffect } from 'primereact/hooks';
import axios from 'axios';
import { Skeleton } from 'primereact/skeleton';
import paymentImage from "../../../images/patterns/loans-bg.png"
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function TrackLoanApplication() {

    const [loanDetails, setLoanDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    let userProfile = {};

    useMountEffect(() => {
        getPolicyDetails();
    }, []);

    const getPolicyDetails = async () => {
        setLoading(true)
        try {
            userProfile = (JSON.parse(localStorage.getItem('userProfile')));

            await axios.get(process.env.REACT_APP_DOVESAPI + 'musoni/loans/' + userProfile.idnumber,
                {
                    headers: {
                        Authorization: 'Bearer ' + (localStorage.getItem('token'))
                    }
                }).then((response) => {
                    setLoading(false)
                    if (response.data.status === "SUCCESS" && response.data.responseBody != null) {
                        console.log("\n\n\n INFORMATION RETURNED")
                        console.log(response.data?.responseBody)
                        setLoanDetails(response.data?.responseBody?.loans);
                    } else {

                        console.log(response.data.status)
                    }
                });

        } catch (error) {
            setLoading(false)
            console.error('Error fetching balance details:', error);
        }
    };

    const formatTitle = (inputString) => {
        if (typeof inputString !== 'string') {
            console.error('Input is not a string');
            return '';
        }

        // Split the input string by capital letters
        const words = inputString.split(/(?=[A-Z])/);

        // Capitalize the first letter of each word and join with spaces
        const formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return formattedString;
    };

    return (
        <div className="col-xxl-8 col-xl-8 col-lg-12">
            <div className=" col-lg-24">
                <div className="">
                    {loading ? (
                        <div className="border-round border-1 surface-border p-4 surface-card">
                            <div className="flex mb-3">
                                <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                                <div>
                                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                    <Skeleton height=".5rem"></Skeleton>
                                </div>
                            </div>
                            <Skeleton width="100%" height="150px"></Skeleton>
                            <div className="flex justify-content-between mt-3">
                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                <Skeleton width="4rem" height="2rem"></Skeleton>
                            </div>
                        </div>) :
                        <>

                            {(loanDetails && loanDetails !== null && loanDetails !== "null" && loanDetails.length !== 0)
                                ? (<> <div className="col-xxl-12 col-xl-12 col-lg-12">
                                    <><Card className='mb-5 mt-3 ' header={(<img src={paymentImage} style={{ width: '100%' }} />)}>
                                        <div className="invite-content">
                                            {(loanDetails && loanDetails !== null && loanDetails !== "null" && loanDetails.length !== 0) &&
                                                <>
                                                    {Object.keys(loanDetails).map((key, count) => (
                                                        console.log(loanDetails[key]),
                                                        <>
                                                            <div className='flex text-center m-4'>
                                                                <h3>Your {formatTitle(key)}</h3>
                                                            </div>

                                                            <div className="card">
                                                                <Accordion activeIndex={0}>
                                                                    {Object.values(loanDetails[key]).map((record, indexNo) => (

                                                                        <AccordionTab active header={"Loan " + (indexNo + 1)} key={count}>

                                                                            <li className='row'>
                                                                                <div className='col-md-6'>
                                                                                    <p><strong>Loan Product Name</strong></p>
                                                                                </div>
                                                                                <div className="text-right col-md-6">
                                                                                    <p>{record.productName}</p>
                                                                                </div>
                                                                            </li>
                                                                            <li className='row'>
                                                                                <div className='col-md-6'>
                                                                                    <p><strong>Loan Status</strong></p>
                                                                                </div>
                                                                                <div className="text-right col-md-6">
                                                                                    <p><u style={{ textDecoration: "underline #084b84 dashed" }}>{record.status.value}</u></p>
                                                                                </div>
                                                                            </li>
                                                                            <li className='row'>
                                                                                <div className='col-md-6'>
                                                                                    <p><strong>Currency</strong></p>
                                                                                </div>
                                                                                <div className="text-right col-md-6">
                                                                                    <p>{
                                                                                        record.currency.displayLabel
                                                                                    }</p>
                                                                                </div>
                                                                            </li>
                                                                            {
                                                                                record.originalLoan && <>
                                                                                    <li className='row'>
                                                                                        <div className='col-md-6'>
                                                                                            <p><strong>Original Loan Amount</strong></p>
                                                                                        </div>
                                                                                        <div className="text-right col-md-6">
                                                                                            <p>{record.currency.displaySymbol + record.originalLoan}</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </>
                                                                            }
                                                                            {
                                                                                record.loanBalance && <>
                                                                                    <li className='row'>
                                                                                        <div className='col-md-6'>
                                                                                            <p><strong>Loan Balance</strong></p>
                                                                                        </div>
                                                                                        <div className="text-right col-md-6">
                                                                                            <p><u style={{ textDecoration: "underline #084b84 dashed" }}>{record.currency.displaySymbol + record.loanBalance}</u></p>
                                                                                        </div>
                                                                                    </li>
                                                                                </>
                                                                            }
                                                                            {
                                                                                record.amountPaid && <>
                                                                                    <li className='row'>
                                                                                        <div className='col-md-6'>
                                                                                            <p><strong>Amount Paid</strong></p>
                                                                                        </div>
                                                                                        <div className="text-right col-md-6">
                                                                                            <p><u style={{ textDecoration: "underline #084b84 dashed" }}>{record.currency.displaySymbol + record.amountPaid}</u></p>
                                                                                        </div>
                                                                                    </li>
                                                                                </>
                                                                            }
                                                                            <li className='row'>
                                                                                <div className='col-md-6'>
                                                                                    <p><strong>Loan Type</strong></p>
                                                                                </div>
                                                                                <div className="text-right col-md-6">
                                                                                    <p>{
                                                                                        record.loanType.value
                                                                                    }</p>
                                                                                </div>
                                                                            </li>
                                                                            
                                                                            {
                                                                                record.loanCycle && <>
                                                                                    <li className='row'>
                                                                                        <div className='col-md-6'>
                                                                                            <p><strong>Loan Tenure</strong></p>
                                                                                        </div>
                                                                                        <div className="text-right col-md-6">
                                                                                            <p><u style={{ textDecoration: "underline #084b84 dashed" }}>{record.loanCycle +" month(s)"}</u></p>
                                                                                        </div>
                                                                                    </li>
                                                                                </>
                                                                            }


                                                                            <li className='row'>
                                                                                <div className='col-md-6'>
                                                                                    <p><strong>Submitted On</strong></p>
                                                                                </div>
                                                                                <div className="text-right col-md-6">
                                                                                    <p>{
                                                                                        record.timeline.submittedOnDate[2] + "/" +
                                                                                        record.timeline.submittedOnDate[1] + "/" +
                                                                                        record.timeline.submittedOnDate[0]
                                                                                    }</p>
                                                                                </div>
                                                                            </li>
                                                                            <li className='row'>
                                                                                <div className='col-md-6'>
                                                                                    <p><strong>Expected Maturity Date</strong></p>
                                                                                </div>
                                                                                <div className="text-right col-md-6">
                                                                                    <p>{
                                                                                        record?.timeline?.expectedMaturityDate[2] + "/" +
                                                                                        record?.timeline?.expectedMaturityDate[1] + "/" +
                                                                                        record?.timeline?.expectedMaturityDate[0]
                                                                                    }</p>
                                                                                </div>
                                                                            </li>


                                                                        </AccordionTab>


                                                                    ))}
                                                                </Accordion>
                                                            </div>
                                                        </>
                                                    ))}
                                                </>
                                            }
                                        </div>
                                    </Card>

                                    </>

                                </div></>)
                                : <></>
                            }

                        </>
                    }
                </div>
            </div>
        </div>
    )
}
