import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { TieredMenu } from 'primereact/tieredmenu';
import AddNewRole from '../admin/roles-management/AddNewRole';
import ViewRole from '../admin/user-management/ViewRole.jsx'
import EditRole from '../admin/user-management/EditRoles.jsx'
import { Dialog } from 'primereact/dialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useMountEffect } from 'primereact/hooks';


const RolesProfileReportTable = () => {
  const [Roles, setRoles] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [activeRole, setActiveRole] = useState(null)
  const dt = useRef(null);
  const toast = useRef(null);
  const [reloadData, setReloadData] = useState(false);
  const [viewRole, setViewRole] = useState(false)


  const cols = [
    { field: 'id', header: '#' },
    { field: 'name', header: 'Name' },
    // { field: 'permissions.length', header: 'Permissions' },
    { field: 'actions', header: 'Actions' },
  ];

  useMountEffect(() => {
    fetchRoles();
  }, [reloadData]);


  const renderActions = (rowData) => {
    return (
      <React.Fragment>
        <Button
            raised
            text
            label='View'
          size="small" 
          icon="pi pi-eye"
          className="p-button-info m-1"
          style={{ marginRight: '5px' }}
          onClick={() => {
            setReloadData(!reloadData);
            setActiveRole(rowData);
            setDialogVisible(!dialogVisible);
            setViewRole(prevViewRole => !prevViewRole); // Use functional form
          }}
        />
        <Button
            raised
            text
          size="small" 
          label="Edit"
          icon="pi pi-pencil"
          className="p-button-warn m-1"
          style={{ marginRight: '5px' }}
          onClick={() => {
            setReloadData(!reloadData);
            setActiveRole(rowData)
            setDialogVisible(!dialogVisible)

          }}
        /> 



        {/* <Button
          text
          raised
          size="small"
          label="Delete"
          icon="pi pi-times"
          className=" p-button-danger p-button-outlined m-1"
          style={{ marginRight: '5px' }}
          onClick={(event) => {

              confirmPopup({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => { console.log('Yes')},
                reject: () => { console.log('No')},
            });
          }
          }
        /> */}
      </React.Fragment>
    );
  };

  const [dialogVisible, setDialogVisible] = useState(false);

  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const data = []


  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default();

        doc.autoTable(exportColumns, Roles);
        doc.save('doves-self-service-Roles-profiles-report.pdf');
      });
    });
  };


  const fetchRoles = async () => {
    try {
      await axios.get(process.env.REACT_APP_DOVESAPI+'system/roles', {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {
        // console.log(response);
        if (response.data.status === "SUCCESS") {
          setRoles((response.data.responseBody));
        } else {
          console.log(response.data.status);
        }
      });

    } catch (error) {
      console.log(error)
    }
  };


  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(Roles);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'doves-self-service-Roles-profiles-report');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
  };


  const onGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
  };

  const globalFilterElement = (
    <input
      type="text"
      className="p-inputtext p-component"
      placeholder="Search"
      value={globalFilter}
      onChange={onGlobalFilter}
    />
  );

  const handleCreateRole = async () => {
    setDialogVisible(false)

    await toast.current.show({ severity: 'success', summary: 'Success', detail: 'User registered successfully' });

  }


  const menu = useRef(null);
  const items = [
    {
      label: 'Download CSV',
      icon: 'pi pi-file-excel',
      command: () => { exportCSV(false) }
    },
    {
      label: 'Download PDF',
      icon: 'pi pi-file-pdf',
      command: () => { exportPdf(false) }
    }
  ];

  const submitNewRole = async ()=>{
    await axios.get(process.env.REACT_APP_DOVESAPI+'role/add',{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then((response)=>{ 

    }).catch((e)=>{

    })
}
  return (


    <div className="card home-chart">
      <ConfirmPopup />

      {activeRole === null ? (
        <Dialog header="Register new role user" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() => {
          setActiveRole(null)
          setDialogVisible(false)
        }}>
          <AddNewRole onCloseDialog={handleCreateRole} style={{ marginTop: "20px;" }} />
        </Dialog>
      ) : (
        <>
        {
          viewRole ? 
          <Dialog header="View role" visible={dialogVisible} maximizable style={{ width: '50vw' }} onHide={() => {
            setActiveRole(null)
            setDialogVisible(false)
            setViewRole(prevViewRole => false)
          }}>
            <ViewRole roleData={activeRole} onCloseDialog={handleCreateRole} style={{ marginTop: "20px;" }} />
          </Dialog>
          : 
          <Dialog header="Edit role" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() => {
            setActiveRole(null)
            setDialogVisible(false)
          }}>
            <EditRole roleData={activeRole} onCloseDialog={handleCreateRole} style={{ marginTop: "20px;" }} />
          </Dialog>
        }
        </>
      )
      }




      <Tooltip target=".export-buttons>button" position="bottom" />
      <div className="card-header">
        <h2 className="card-title home-chart"> User roles</h2>
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {globalFilterElement}
          </span>
        </div>
        <div id="report-select">
          <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
          <Button label="Create New Role" raised size="small" icon="pi pi-user-plus" onClick={() => setDialogVisible(!dialogVisible)} />

        </div>

      </div>

      <div className="card-body">

        <DataTable
          paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
          ref={dt}
          value={Roles}
          tableStyle={{ minWidth: '50rem' }}
          globalFilter={globalFilter}
          emptyMessage="No Roles found"
        >
          {cols.map((col, index) => {
            if (col.field === 'actions') {
              return (
                <Column
                  key={index}
                  body={renderActions}
                  style={{ textAlign: 'center' }}
                />
              );
            }
            return (
              <Column key={index} field={col.field} header={col.header} />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};

export default RolesProfileReportTable;
