import { Link } from 'react-router-dom';
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from "primereact/inputswitch";
import { useMountEffect } from 'primereact/hooks';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { Editor } from "primereact/editor";
import { Skeleton } from 'primereact/skeleton';


const GroupActivation = ({ onCloseDialog }) => {
    const [groupName, setGroupName] = useState(null);
    const [groupStatus, setGroupStatus] = useState(true);
    const [termsAndConditions, setTermsAndConditions] = useState('');
    const [groupImage, setGroupImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [groupOptions, setGroupOptions] = useState([]);
    const [groupProducts, setGroupProducts] = useState([]);
    const [errors, setErrors] = useState({
        groupName: '',
        groupStatus: '',
    });

    const [categoryName, setCategoryName] = useState('')

    const categories = [
        "Legal aid",
        "Group life assurance",
        "Cash plan",
        "Funeral policy",
    ]
    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };
    const header = renderHeader();
    const [selectedGroupProducts, setSelectedGroupProducts] = useState(null);
    const toast = useRef(null);


    useMountEffect(() => {
        fetchLocalGroups()
    });


    const fetchLocalGroups = async () => {
        let productGroup = []
        await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/products/group',{
            headers: {
              Authorization: 'Bearer ' + (localStorage.getItem('token'))
            }
          }).then((response) => {
            response.data.responseBody.forEach(element => {

                if (typeof element != 'undefined') {
                    productGroup.push(element)
                }

            });
        }).catch((e) => {

        });

        setGroupOptions(productGroup)

    }

    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Form Submitted', detail: 'The form is successfully submitted.' });
    };




    const handleSubmit = (e) => {
        e.preventDefault();

        postProduct()
    };


    const postProduct = async () => {

        let readyProductList = []
        selectedGroupProducts.forEach((element) => {
            readyProductList.push({ productID: element.ProductID })
        })

        let prodObj = []
        readyProductList.forEach((elementProduct) => {

            console.log(elementProduct)
            prodObj.push({
                "productID": elementProduct.productID,
                "productExtensions": []
            })

        })
        let productPayload = {
            "accessToken": "",
            "operation": "SaveActivePlansProducts",
            "requestBody": {

                "easipolSubGroupId": groupName.SubGroupID,
                "activeStatus": groupStatus,
                "category": categoryName,
                "productsList": prodObj,
                "groupImageBannerUrl": groupImage,
                "termAndConditions": termsAndConditions
            }
        };

        const token = localStorage.getItem('token')

        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        try {
            const response = await axios.post(process.env.REACT_APP_DOVESAPI + 'policy/products/active', productPayload, config);
            console.log(response.data);
            if (response.data.status === 'SUCCESS') {
                await toast.current.show({ severity: 'success', sticky: true, summary: 'SUCCESS', detail: response.data.message });
                window.location.reload();
            } else {

                toast.current.show({ severity: 'error', sticky: true, summary: 'Failed', detail: response.data.message });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validateProduct = (e) => {
        let formIsValid = true;
        const newErrors = { ...errors };

        if (!groupName.trim()) {
            newErrors.groupName = 'Group Name is required';
            formIsValid = false;
        } else {
            newErrors.groupName = '';
        }

        if (typeof groupStatus !== 'boolean') {
            newErrors.groupStatus = 'Group Status must be true or false';
            formIsValid = false;
        } else {
            newErrors.groupStatus = '';
        }

        if (formIsValid) {
            setErrors({});
            return true;
        } else {
            setErrors(newErrors);
            return false;
        }
    };

    async function fetchProductsPerGroup(id) {
        setLoading(true)
        await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/products/group/easipol/' + id,{
            headers: {
              Authorization: 'Bearer ' + (localStorage.getItem('token'))
            }
          })
            .then((response) => {
                setLoading(false)
                setGroupProducts(response.data.responseBody)
            })
            .catch()
    }

    const handleGroupChange = (val) => {
        setGroupName(val.value)
        setGroupProducts([])
        setSelectedGroupProducts(null)
        console.log(val.value.SubGroupID)
        fetchProductsPerGroup(val.value.SubGroupID)
    }

    const handleCategoryChange = (val) => {
        setCategoryName(val.value)
        console.log(val.value.SubGroupID)
    }

    const handleSyncEasipol = async () => {
        setLoading(true)
        await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/products/easipol',{
            headers: {
              Authorization: 'Bearer ' + (localStorage.getItem('token'))
            }
          }).then(async (response) => {
            if (response.data.status === "SUCCESS") {
                setLoading(false)
                await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/products/group/easipol',{
                    headers: {
                      Authorization: 'Bearer ' + (localStorage.getItem('token'))
                    }
                  }).then((response) => {
                    if (response.data.status === "SUCCESS") {
                        toast.current.show({ severity: 'success', summary: 'SUCCESS', detail: 'Products synchronization completed' });
                    }
                })

            }
        })
    }
    return (
        <>
            <Toast ref={toast} />
            <div className="">
                <div className="container">
                    <div className="row">
                        <center>
                            <Button onClick={handleSyncEasipol} icon="pi pi-sync" size='small' className='' style={{ float: "right" }} label='Easipol' text raised />
                            <br></br>
                            <br></br>
                            <div className="">
                                <div className="">
                                    <div>

                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <Dropdown
                                                    filter
                                                    className='register-user-input'
                                                    id="groupName"
                                                    value={groupName}
                                                    options={groupOptions}
                                                    optionLabel="Description"
                                                    onChange={(e) => handleGroupChange(e)}
                                                    placeholder="Select a role"
                                                />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }} htmlFor="groupName">Subgroup</label>
                                            </span>
                                            <div className=" ">

                                                <small style={{ color: "blue" }}>
                                                </small>

                                            </div>
                                        </div>

                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputText
                                                    className='register-user-input'
                                                    id="groupImamge"
                                                    value={groupImage}
                                                    onChange={(e) => {
                                                        console.log(e)
                                                        setGroupImage(e.target.value)
                                                    }}
                                                    placeholder="Paste image url"
                                                />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }} htmlFor="groupName">Group image</label>
                                            </span>
                                        </div>

                                        {loading === false ? <>
                                            <div className="card-body flex justify-content-center">
                                                <span className="p-float-label">
                                                    <MultiSelect value={selectedGroupProducts} onChange={(e) => setSelectedGroupProducts(e.value)} options={groupProducts} optionLabel="ProductName1" className="register-user-input" />
                                                    <label style={{ paddingLeft: "5px", paddingRight: "5px" }} htmlFor="ms-groupProducts">Select Products to activate</label>
                                                </span>
                                            </div>


                                            <div className="card-body flex justify-content-center">
                                                <span className="p-float-label">
                                                    <Dropdown
                                                        filter
                                                        className='register-user-input'
                                                        id="categoryName"
                                                        value={categoryName}
                                                        options={categories}
                                                        onChange={(e) => handleCategoryChange(e)}
                                                        placeholder="Select a category"
                                                    />
                                                    <label style={{ paddingLeft: "5px", paddingRight: "5px" }} htmlFor="groupName">Product Category</label>
                                                </span>
                                            </div>

                                            <div className="card-body flex justify-content-center" style={{ width: '25vw' }}>
                                                <span className="p-float-label">
                                                    <Editor placeholder='Group terms and conditions' value={termsAndConditions} headerTemplate={header} onTextChange={(e) => setTermsAndConditions(e.htmlValue)} style={{ minHeight: '120px' }} />
                                                </span>
                                            </div>

                                            <div className="card-body flex justify-content-center register-user-input"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "5px",
                                                    padding: "10px",
                                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <div style={{ marginRight: "10px" }}>
                                                    <InputSwitch
                                                        id="value"
                                                        name="value"
                                                        checked={groupStatus}
                                                        onChange={(e) => {
                                                            var message = ''
                                                            if (groupStatus === false) {
                                                                message = 'Product will be created active'
                                                            } else {
                                                                message = 'Product will be created disabled'
                                                            }
                                                            toast.current.show({ severity: 'info', summary: 'Group Status', detail: message });
                                                            setGroupStatus(e.value)
                                                        }}
                                                        className="p-float-label"
                                                    />
                                                </div>

                                                {
                                                    groupStatus === false ? (
                                                        <>
                                                            <div>
                                                                Activate group upon creation?
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div>
                                                            Create and set it disabled?
                                                        </div>
                                                    )
                                                }


                                            </div>


                                            <br></br>
                                            <hr></hr>
                                            <div className="card-body flex justify-content-center">
                                                <Button type="submit" label="Save" onClick={handleSubmit} className="p-button-primary p-mt-3" raised size="small" />
                                            </div>
                                        </> :
                                            <>
                                                <center> Please wait...</center>
                                                <div className="w-full md:w-6 p-3">
                                                    <Skeleton className="mb-2"></Skeleton>
                                                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                    <Skeleton height="2rem" className="mb-2"></Skeleton>
                                                    <Skeleton width="10rem" height="4rem"></Skeleton>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GroupActivation;
