import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Card } from 'primereact/card';

const ViewMemberBeneficiaries = ({ activeUserData }) => {
  const Users = activeUserData;

  const colsMainMember1 = [
    { field: 'Name', header: 'First name' },
    { field: 'Surname', header: 'Last name' },
    { field: 'Gender', header: 'Gender' },
    { field: 'ID_num', header: 'ID number' },
    { field: 'Date_of_birth', header: 'Date Of Birth' },
    { field: 'Age', header: 'Age' },
    { field: 'PolicyMaturityDate', header: 'Maturity' }
  ];

  return (
    <div className="card home-chart">
        <Card>
        <center><h2 className="card-title home-chart">CHILD BENEFICIARY DETAILS</h2></center>


        <div className="card-body">
            <DataTable
            value={Users.memberChild}
            tableStyle={{ minWidth: '50rem' }}
            emptyMessage="No child beneficiaries found"
            >
            {colsMainMember1.map((col, index) => (
                <Column key={index} field={col.field} header={col.header} />
            ))}
            </DataTable>

            <br />
        </div>
        </Card>
    </div>
  );
};

export default ViewMemberBeneficiaries;
