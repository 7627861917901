import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Header from '../../layout/headers/header';
import CustomerNavMenu from "../../layout/navigation/topbar/CustomerNavMenu";
import DynamicDashboard from "../../layout/dashboard/dynamic-dashboard";
import DynamicNavigation from "../../layout/navigation/dynamic-navigation";
import TrackLoanApplication from "../../components/loans/track_loans_application_progress";
import MakePayment from '../../components/makePayment/makePayment';
import ProductsTable from '../../components/tables/ProductList.jsx';
import BanksTable from '../../components/tables/BanksTables.jsx';

const Loans = () => {
    const [loansAvailable, setLoansAvailable] = useState(false);
    
    return (
        <>
            <Header />
            <DynamicNavigation />
            <div className="content-body">
                <div className="container">
                    <BanksTable />
                    {/* <ProductsTable /> */}
                </div>
            </div>
        </>
    );
};

export default Loans;
