import React, { useState,useRef } from 'react'
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast'; 
import { Avatar } from 'primereact/avatar';
import { useMountEffect } from 'primereact/hooks';

const UserProfile = () => {

    const [userType, setuserType] = useState([]);
    const [fullName, setfullName] = useState([]);
    const [firstName, setfirstName] = useState([]);
    const [initialsName, setinitialsName] = useState([]);
    const [lastName, setlastName] = useState([]);
    const [userId, setuserId] = useState([]);

    useMountEffect(() => {
        const fetchUserRole = async () => {
            try {
                setuserType((localStorage.getItem('userType')));
                setinitialsName(localStorage.getItem('firstName').charAt(1) + "" + (localStorage.getItem('lastName').charAt(1)));
                var fullName = localStorage.getItem('firstName').toString() + " " + localStorage.getItem('lastName').toString();
                setfullName(fullName.replaceAll('"', ''));
                setfirstName((localStorage.getItem('firstName')));
                setlastName((localStorage.getItem('lastName')));
                setuserId((localStorage.getItem('userId')));

            } catch (error) {
                console.error('Error fetching user userRole:', error);
            }
        };
        fetchUserRole();
    }, []);

    const menuRight = useRef(null);
    const toast = useRef(null);

    const items = [
        // {
        //     label:fullName ,
        // },

        {
            label: 'Manage Profile',
            items: [

                {
                    label: 'Edit Profile',
                    icon: 'pi pi-user',
                    command: () => {
                        toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                    },

                },
                {
                    label: 'Change Password',
                    icon: 'pi pi-lock',
                    command: () => {
                        toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                    }
                }
            ]
        },
        { separator: true },
        {
            label: <strong>Logout</strong>,
            icon: 'pi pi-sign-out',
            url: '/signin'
        }
    ];

    return (
        <>
            <Toast ref={toast}></Toast>

            <div>
                <div>
                    <Menu model={items} popup ref={menuRight} id="popup_menu_left" popupAlignment="left" />
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', padding: '1.2rem'}}>
                    <Avatar onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup label={initialsName} size="large" shape="circle" className="p-overlay-badge" style={{ backgroundColor: '#084b9b', color: '#ffffff' }} />
                    <div className="flex flex-column align p-2">
                            <span className="coin-name" style={{fontWeight: '600'}}>{fullName}</span>
                        </div>

                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default UserProfile