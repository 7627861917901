import React, { useState } from 'react';
import { Galleria } from 'primereact/galleria';
import { Card } from 'primereact/card';
import localBGImage from "../../../images/patterns/beneficiaries-bg.png"
import welcomeBanner from "../../../images/banners/welcome.png"
import welcomeBanner2 from "../../../images/products/1.jpg"
import { useMountEffect } from 'primereact/hooks';

export default function ClickEventDemo() {
    const [images, setImages] = useState(null);
    
    const imagesList=[
        {
            itemImageSrc: welcomeBanner,
            thumbnailImageSrc: welcomeBanner, 
        },
        // {
        //     itemImageSrc: welcomeBanner2,
        //     thumbnailImageSrc: welcomeBanner2,
            
        // }
    ]
    useMountEffect(() => {
        setImages(imagesList);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const itemTemplate = (item) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '80%', display: 'block' }} />;
    };

    return (
        
            <Galleria value={images} style={{ maxWidth: '620px' }} showThumbnails={false} showIndicators item={itemTemplate} />
         
    )
}
  