import React, { useState } from 'react'; 
import CustomerDashboard from "./customer-dashboard";
import EmployeeDashboard from "./employee-dashboard";
import CustomerNavMenu from "../navigation/topbar/CustomerNavMenu"
import Sidebar from "../navigation/sidebar/dynamic-sidebar"
import { useMountEffect } from 'primereact/hooks';

const DynamicDashboard = () => { 
  const [userType, setuserType] = useState([]);
  useMountEffect(() => {
    const fetchUserRole = async () => {
      try { 
        setuserType(JSON.parse(localStorage.getItem('userType'))); 
      } catch (error) {
        console.error('Error fetching user userRole:', error);
      }
    };
    fetchUserRole();
  }, []);

  return (
    <>
      {userType === "CUSTOMER" ? (<CustomerDashboard />) : (<EmployeeDashboard />)}
    </>);
};


export default DynamicDashboard;

