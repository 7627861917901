import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Header from '../../layout/headers/header'
import CustomerNavMenu from "../../layout/navigation/topbar/CustomerNavMenu"
import DynamicDashboard from "../../layout/dashboard/dynamic-dashboard"
import DynamicNavigation from "../../layout/navigation/dynamic-navigation"
import ProductCarousel from "../../components/products_services/products_carousel_min";
import TrackPolicyApplication from "../../components/policy/track_policy_application_progress"
import MakePayment from '../../components/makePayment/makePayment';
import CreateNewPolicyPage from "../../pages/create_policy/createNewPolicyPage"
import { Card } from 'primereact/card';
import axios from 'axios';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import localBGImage from "../../../images/patterns/applyPolicy.png"
import legalBGImage from "../../../images/patterns/applylegalPolicy.png"
import legalBGImage2 from "../../../images/patterns/applyGrouplegalPolicy.png"
import CashPlanBGImage from "../../../images/patterns/applycashplanPolicy.png"
import LoanBGImage from "../../../images/patterns/loans-bg-2.png"
import { Link, useHistory } from 'react-router-dom';
import DisplayPolicyDetailComponent from "../../pages/manage_policy/displayPolicy/displayPolicyDetailComponent"
import { ScrollPanel } from 'primereact/scrollpanel';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import { useMountEffect } from 'primereact/hooks';
import WelcomeBanner from "../../components/news/Welcome_carousel"
import localBGImageBeneficiaries from "../../../images/patterns/beneficiaries-bg.png"
import { BlockUI } from 'primereact/blockui';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import News from "../../components/news/news_carousel"
import paymentImage from "../../../images/patterns/payments-bg.png"

const BalanceWidget = () => {

  const [policyDisplay, setpolicyDisplay] = useState(null);
  const [selectedPolicyOBJ, setSelectedPolicyOBJ] = useState(null);
  const [selectedDisplayPolicyDetails, setselectedDisplayPolicyDetails] = useState(null);
  const [selectedDisplayPolicyComponent, setselectedDisplayPolicyComponent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingFullPolicy, setloadingFullPolicy] = useState(false);
  const history = useHistory()
  // const [policyList, setPolicyList] = useState([]);
  const [policyValue, setpolicyValue] = useState(null);
  const [policyDisplayKey, setpolicyDisplayKey] = useState(1);

  let userProfile = {};


  function parseTrackApplicationResponse(apiResponse, policyDisplayKey) {
    setLoading(false)
    console.log(apiResponse);
    if (apiResponse && apiResponse.responseBody && !(Array.isArray(apiResponse.responseBody)) && apiResponse.responseBody.policyApplicationRequestId) {
      console.log("---- Yes");
      return <>
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-12">
            <News />
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-12">
            <PendingApplicationPolicyDisplay applicationDetail={apiResponse.responseBody} />
          </div>
        </div>
      </>;

    } else if (apiResponse && apiResponse.responseBody && (Array.isArray(apiResponse.responseBody)) && apiResponse.responseBody.length > 0) {
      console.log("---- NO");
      return <EasipolPolicyDisplay key={policyDisplayKey} policyList={apiResponse.responseBody} policyDisplayKey={policyDisplayKey} apiResponse={apiResponse} />;
    } else {
      console.log("---- MAYbe");

      return <NewLifeApplicationPolicyDisplay applicationDetail={apiResponse.responseBody} />;
    }
  }

  useEffect(() => {
    // This effect will run whenever selectedPolicyOBJ changes
    console.log("policyDisplayKey updated:", policyDisplayKey);
  }, [policyDisplayKey]);

  function updateSelectedPolicy(policyValue, policyList, apiResponse) {
    let policyDisplayKey = policyList.findIndex(item => item.MainMember.Policy_Number === policyValue.MainMember.Policy_Number);
    console.log(policyDisplayKey)
    console.log(policyList[policyDisplayKey])
    setpolicyDisplayKey(policyList.findIndex(item => item.MainMember.Policy_Number === policyValue.MainMember.Policy_Number));
    setpolicyDisplay(parseTrackApplicationResponse(apiResponse, policyDisplayKey));

    setpolicyValue(policyList[policyDisplayKey])
    // setpolicyDisplay(parseTrackApplicationResponse(response.data))
  }

  function EasipolPolicyDisplay({ policyList, policyDisplayKey, apiResponse }) {
    console.log(policyList);
    return (<>

      {policyList ?
        <> <div className="row mb-5">
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <News />
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <Card className='' header={(<img src={localBGImage} alt="" style={{ width: '100%' }} />)}>
              <h2 className='text-left mt-1 mb-2'><strong>Your Policy Details</strong></h2>
              <Divider type='dotted' />

              <div className="card flex justify-content-center">
                <Dropdown required
                  value={policyList[policyDisplayKey]}
                  onChange={(e) => updateSelectedPolicy(e.value, policyList, apiResponse)}
                  options={policyList} optionLabel="MainMember.Policy_Number"
                  className="w-full md:w-14rem" />
              </div>
              <Divider type='dotted' />
              {policyList[policyDisplayKey].MainMember.Status === 0 ? (<Message severity="warn" text="Your policy status is INACTIVE, you need to make a subscription to enable it" style={{ width: '100%' }} />) : policyList[policyDisplayKey].MainMember.Status === 1 ? null : policyList[policyDisplayKey].MainMember.Status === 2 ? <Message severity="warn" text="Your policy has lapsed , you need to make a subscription to enable it" style={{ width: '100%' }} /> : <Message severity="warn" text="Your policy status is ARCHIVED, please call our customer service for guidance" style={{ width: '100%' }} />}
              <Divider type='dotted' />
              {loadingFullPolicy ? (
                <div className="border-round border-1 surface-border p-4 surface-card">
                  <div className="flex mb-3">
                    <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                    <div>
                      <Skeleton width="10rem" className="mb-2"></Skeleton>
                      <Skeleton width="5rem" className="mb-2"></Skeleton>
                      <Skeleton height=".5rem"></Skeleton>
                    </div>
                  </div>
                </div>) :
                <div className="col-xxl-12 col-xl-12 col-lg-12">
                  {/* THE SELECTED POLICY DETAILS TO BE DISPLAYED BELOW */}
                  {policyList ?
                    <><ul className='mt-1'>
                      <li className='row'>
                        <div className='col-md-6'>
                          <h4>Principal Member</h4>
                        </div>
                        <div className="text-right col-md-6">
                          <h5>{policyList[policyDisplayKey].MainMember.Title} {policyList[policyDisplayKey].MainMember.First_Name} {policyList[policyDisplayKey].MainMember.Surname}</h5>
                        </div>
                      </li>
                      <br></br>
                      <li className='row'>
                        <div className='col-md-6'>
                          <h4>Monthly Premium</h4>
                        </div>
                        <div className="text-right col-md-6">
                          <h5>${policyList[policyDisplayKey].MainMember.UsualPremium}</h5>
                        </div>
                      </li>
                      <br></br>
                      <li className='row'>
                        <div className='col-md-6'>
                          <h4>Policy Number</h4>
                        </div>
                        <div className="text-right col-md-6">
                          <h5>{policyList[policyDisplayKey].MainMember.Policy_Number}</h5>
                          {/* <span>Your policy Number.</span> */}
                        </div>
                      </li>
                      <br></br>
                      <li className='row'>
                        <div className='col-md-6'>
                          <h4>Policy Status</h4>
                        </div>
                        {/* <div className="text-right col-md-6">
                                                <h5>{policyList[policyDisplayKey].MainMember.Status === 0 ? "INACTIVE" : policyList[policyDisplayKey].MainMember.Status === 1 ? "ACTIVE" : policyList[policyDisplayKey].MainMember.Status === 2 ? "LAPSED" : "ARCHIVED"}</h5>
                                                <span>Your policy Number.</span>
                                            </div> */}
                        <div className="text-right col-md-6">
                          <Button
                            raised
                            size='small'
                            label={policyList[policyDisplayKey].MainMember.Status === 0 ? "INACTIVE" : policyList[policyDisplayKey].MainMember.Status === 1 ? "ACTIVE" : policyList[policyDisplayKey].MainMember.Status === 2 ? "LAPSED" : "ARCHIVED"}
                            severity={policyList[policyDisplayKey].MainMember.Status === 0 ? "warning" : policyList[policyDisplayKey].MainMember.Status === 1 ? "success" : policyList[policyDisplayKey].MainMember.Status === 2 ? "warning" : "danger"} />
                        </div>
                      </li>
                      <br></br>
                      <li className='row'>
                        <div className='col-md-6'>
                          <h4>Policy Product</h4>
                        </div>
                        <div className="text-right col-md-6">
                          <h5>{policyList[policyDisplayKey].MainMember.Product_Name}</h5>
                        </div>
                      </li>
                      <br></br>
                      <li className='row'>
                        <div className='col-md-6'>
                          <h4>Address</h4>
                        </div>
                        <div className="text-right col-md-6">
                          <h5>{policyList[policyDisplayKey].MainMember.Address}</h5>
                          <span>{policyList[policyDisplayKey].MainMember.Region}</span>
                        </div>
                      </li>
                      <br></br>
                    </ul>
                    </> :

                    <><h5>Select Policy to display</h5></>
                  }
                  {/* <EasipolPolicyDisplayComponent selectedPolicy={selectedPolicyOBJ} /> */}
                </div>

              }
            </Card>
            <div>
            </div>
          </div>

          {/* <div className="col-xxl-6 col-xl-6 col-lg-12">
            <MakePayment balanceFormatted={policyList[policyDisplayKey].BalanceFormatted} policyNumberCurrentUser={policyList[policyDisplayKey].MainMember.Policy_Number} phoneNumberCurrentUser={policyList[policyDisplayKey].MainMember.Cell_Number} />
          </div> */}



        </div></> : <h5>Select policy to display</h5>}
    </>
    );
  }

  const deleteApplication = async (ID_Number) => {
    try {


      await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/track/' + ID_Number + "/delete", {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {

        if (response.data.status === "SUCCESS") {
          console.log(response);
          window.location.reload();
        } else {
          console.log(response.data.status)
        }
        // console.log(userProfile.idnumber);
        // // console.log(response.data);
        // setLoading(false)
        // setpolicyDisplay(parseTrackApplicationResponse(response.data, 0))
      });

    } catch (error) {
      // setLoading(false)
      console.error('Error clearing balance details:', error);
    }
  };

  const getPolicyDetails = async () => {
    setLoading(true)
    try {
      userProfile = (JSON.parse(localStorage.getItem('userProfile')));

      await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/track/' + userProfile.idnumber, {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {

        if (response.data.status === "SUCCESS") {
          // console.log(response);

          if (response.data.responseBody === null) {
          } else {
            // setBalanceDetails(response.data.responseBody);
          }

        } else {
          // console.log(response.data.status)
        }
        // console.log(userProfile.idnumber);
        // console.log(response.data);
        setLoading(false)
        setpolicyDisplay(parseTrackApplicationResponse(response.data, 0))
      });

    } catch (error) {
      setLoading(false)
      console.error('Error fetching balance details:', error);
    }
  };

  useMountEffect(() => {
    getPolicyDetails();
  }, []);

  function PendingApplicationPolicyDisplay({ applicationDetail }) {

    console.log(applicationDetail.applicationStatus);
    switch (applicationDetail.applicationStatus) {
      case 'waiting-approval':
        return (
          <>
            <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Your Policy is being reviewed</h3>
                <p><strong>We will be in touch in a moment</strong><br />We embrace operational excellence at all times, gained by our years of extensive experience</p>

              </div>
            </Card>
          </>
        );
      case 'incomplete-application':
        return <>
          <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
            <div className='m-2 flex justify-content-center'>
              <h3 className='text-left '>You have not completed your policy application</h3>
              <p><strong>You can </strong>continue from where you left from and finish you application</p>
              <p className='text-danger'><i>Kindly finish this application or delete it to access more functionality</i></p>
              <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Continue Application</strong></>} severity="warn" rounded outlined raised
                onClick={() => { history.push("/manage-policy") }}
              />
              <Button className='justify-content-center text-center mt-4' size='large' label='Delete Application' severity="danger" rounded raised
                onClick={() => { deleteApplication(applicationDetail.mainMember.ID_Number) }}
              />
            </div>
          </Card>
        </>;
      case 'rejected-policy-application':
        return (
          <>
            <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Your Policy might have concerns that need you to address them.</h3>
                <p><strong>For the ones you love</strong><br />We embrace operational excellence at all times, gained by our years of extensive experience</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Contact Support</strong></>} severity="warn" rounded outlined raised
                  onClick={() => { history.push("/manage-policy") }}
                />
              </div>
            </Card>
          </>
        );;
      default:
        return (
          <>
            <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Your Policy application is being processed</h3>
                <p><strong>For the ones you love</strong><br />We embrace operational excellence at all times, gained by our years of extensive experience</p>
                {/* <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Contact Support</strong></>} severity="warn" rounded outlined raised
                onClick={() => { history.push("/manage-policy") }}
              /> */}
              </div>
            </Card>
          </>
        );
    }
  };
  function NewApplicationPolicyDisplay(response) {
    return (
      <>
        <center className='mt-4 mb-5'>
          <h3 className='m-7'> Get more from Doves. <br />Our wide range of insurance products below:</h3>
          <Divider type='dashed' />
        </center>

        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <Card className=' p-0' header={(<img src={legalBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Apply for Legal Aid </h3>
                <p><strong>Get yourself cover</strong><br />Create your legal aid policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/apply-policy?category=Legal aid") }}
                />
              </div>
            </Card>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <Card className=' p-0' header={(<img src={CashPlanBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Apply for Cash Plan Policy now!</h3>
                <p><strong>Get cash assistance</strong><br />Apply for your cash plan policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/apply-policy?category=Cash plan") }}
                />
              </div>
            </Card>
          </div></div>
        <div className="row mt-5">
          <div className="col-xxl-6 col-xl-6 col-lg-12 ">
            <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Apply for Life Assurance Policy now!</h3>
                <p><strong>For the ones you love</strong><br />Create your life assurance policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/apply-policy?category=Funeral policy") }}
                />
              </div>
            </Card>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-12 ">
            <Card className=' p-0' header={(<img src={legalBGImage2} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Get Group Life Assurance Policy now!</h3>
                <p><strong>For the ones you love</strong><br />Create your life assurance policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/apply-policy?category=Group life Assurance") }}
                />
              </div>
            </Card>
          </div>
        </div>

        <div className="row mt-5">
          <center className='mt-4 mb-5'>
            <h3 className='m-7'> Financial Services. <br />Access our financial services below:</h3>
            <Divider type='dashed' />
          </center>

          <div className="col-xxl-6 col-xl-6 col-lg-6 ">
            <Card className=' p-0' header={(<img src={LoanBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Get a loan now!</h3>
                <p><strong>We offer the following services: short term loans </strong> foreign exchange services; investments; financial intelligence; financial planning.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/loans") }}
                />
              </div>
            </Card>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 ">
            <Card className='mb-5 ' header={(<img src={paymentImage} style={{ width: '100%' }} />)}>
              <div className="invite-content">
                <h3>Make Payments</h3>
                <p>Make payments to your policy and stay update</p>
                <p>All Payments in USD</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Make Payments</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/payments") }}
                />
              </div>
            </Card>
          </div>

        </div>

      </>
    );
  }

  function NewApplicationPolicyDisplay_(response) {
    return (
      <>
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <Card className=' p-0' header={(<img src={legalBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Apply for Legal Aid and Cash Plan Policy now!</h3>
                <p><strong>Get yourself cover</strong><br />Create your legal aid or cash plan policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/apply-policy?category=Legal aid") }}
                />
              </div>
            </Card>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <Card className=' p-0' header={(<img src={CashPlanBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Apply for Cash Plan Policy now!</h3>
                <p><strong>Get cash assistance</strong><br />Apply for your cash plan policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/apply-policy?category=Cash plan") }}
                />
              </div>
            </Card>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-12">
            <Card className=' p-0' header={(<img src={CashPlanBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Apply for Cash Plan Policy now!</h3>
                <p><strong>Get cash assistance</strong><br />Apply for your cash plan policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/apply-policy?category=Cash plan") }}
                />
              </div>
            </Card>
          </div>


        </div>

      </>
    );
  }

  function NewLifeApplicationPolicyDisplay(response) {
    return (
      <>
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12">
            <Card className=' p-0' header={(<img src={legalBGImage2} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Get life assurance now!</h3>
                <p><strong>For the ones you love</strong><br />Create your life assurance policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Apply Now</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/apply-policy?category=Funeral policy") }}
                />
              </div>
            </Card>
          </div>

        </div>

      </>
    );
  }

  function NewOtherApplicationPolicyDisplay(response) {
    return (
      <>
        <div className="row">
          <div className="col-xxl-8 col-xl-8 col-lg-12">
            <Card className=' p-0' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
              <div className='m-2 flex justify-content-center'>
                <h3 className='text-left '>Create Policy now!</h3>
                <p><strong>For the ones you love</strong><br />Create new policy today and embrace a peace of mind.</p>
                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>Create Policy</strong></>} severity="info" rounded outlined raised
                  onClick={() => { history.push("/manage-policy") }}
                />
              </div>
            </Card>
          </div>
        </div>

      </>
    );
  }

  return (
    <>
      <div className="row">
        {loading ? (
          <div className="border-round border-1 surface-border p-4 surface-card">
            <div className="flex mb-3">
              <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
              <div>
                <Skeleton width="10rem" className="mb-2"></Skeleton>
                <Skeleton width="5rem" className="mb-2"></Skeleton>
                <Skeleton height=".5rem"></Skeleton>
              </div>
            </div>
            <Skeleton width="100%" height="150px"></Skeleton>
            <div className="flex justify-content-between mt-3">
              <Skeleton width="4rem" height="2rem"></Skeleton>
              <Skeleton width="4rem" height="2rem"></Skeleton>
            </div>
          </div>) :
          <>
            <div className="col-xxl-12 col-xl-12 col-lg-12">
              <center className='mt-4'>
                <h4 className='m-7'> Quick Access Options</h4>
                <Divider type='dashed' />
              </center>
              {policyDisplay}
            </div>
            <div className="mt-5 col-xxl-12 col-xl-12 col-lg-12">
              <NewApplicationPolicyDisplay />
            </div>
          </>
        }
      </div>
    </>
  );
};

export default BalanceWidget;