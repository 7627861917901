import React, { useState } from 'react';  
import CustomerNavMenu from "./topbar/CustomerNavMenu"
import Sidebar from "./sidebar/dynamic-sidebar"
import { useMountEffect } from 'primereact/hooks';

const DynamicNavigation = ({activeItem}) => { 
  const [userType, setuserType] = useState([]);
  useMountEffect(() => {
    const fetchUserRole = async () => {
      try { 
        setuserType(JSON.parse(localStorage.getItem('userType')));
        // console.log("userType --- " + userType)
      } catch (error) {
        console.error('Error fetching user userRole:', error);
      }
    };
    fetchUserRole();
  });

  return (
    <>
      {userType === "CUSTOMER" ? (<><CustomerNavMenu activeItem={activeItem} /></>) : (<><Sidebar /></>)}
    </>);
};


export default DynamicNavigation;

