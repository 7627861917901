import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { useMountEffect } from 'primereact/hooks';

const Calculator = ({ moveToNextView, moveToPreviousView, userPolicyInput, setuserPolicyInput }) => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedBenefit, setSelectedBenefit] = useState('');
    const [rowClick, setRowClick] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    
    useMountEffect(() => {
        setProducts(userPolicyInput.selectedSubGroupPlan.planProducts);
        setSelectedProduct(0)
    });


    const renderBenefitsText = (rowData) => {
        return (
            <div>
                {rowData.BenefitsText} {/* Use the correct key for benefits */}
            </div>
        );
    };

    const onRowToggle = (event) => {
        setExpandedRows(event.data); // Update the expandedRows state with the selected row data
    };
    const handleSelect = (e) => {

        if (e.value != null) {
            setSelectedProduct(e.value);
            setSelectedBenefit(e.value.BenefitsText ? e.value.BenefitsText : "") 
            setuserPolicyInput({ ...userPolicyInput, selectedProduct: e.value, selectedProductId: e.value.ProductID, selectedProductName: e.value.ProductName1 }); 
        }
        
    };

    return (
        <div className="card">
            <DataTable
                value={products}
                selectionMode={rowClick ? null : 'radiobutton'}
                selection={selectedProduct}
                onSelectionChange={(e) => handleSelect(e)}
                dataKey="ProductName1"
                rowExpansionTemplate={renderBenefitsText}
                expandedRows={expandedRows}
                onRowToggle={onRowToggle}>
                <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                <Column field="ProductName1" ></Column>
            </DataTable>

            <div className='mt-4 text-left'>
                {selectedBenefit ? (
                    <>
                        <h4>Additional Benefits for selected extra benefit</h4>
                        <p>{selectedBenefit}</p>
                    </>
                ) : null}
            </div>

        </div>
    );
};

export default Calculator;