import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import axios from "axios";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useMountEffect } from 'primereact/hooks';
import PrimeIcons from 'primeicons/primeicons.css';
import { Skeleton } from 'primereact/skeleton';





const ClientDetailsForm = ({ moveToNextView, moveToPreviousView, userLoanApplicationData, setUserLoanApplicationData, toast }) => {
  const [firstName, setFirstName] = useState("");
  const [otherNames, setOtherNames] = useState("");
  const [surname, setSurname] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [dob, setDob] = useState("");
  const [title, setTitle] = useState("");
  const [gender, setGender] = useState("");
  const [mStatus, setMstatus] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [altPhoneNumber, setAltPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [region, setRegion] = useState("Mutare");
  const [nationality, setNationality] = useState('');
  const [cumulativeResponse, setCumulativeResponse] = useState(null)
  const [formErrors, setFormErrors] = useState({});


  const [otherNameError, setOtherNameError] = useState(true);


  const [loading, setloading] = useState(false);
  const genderOptions = [
    {
      name: 'MALE',
      value: 'MALE'
    },
    {
      name: 'FEMALE',
      value: 'FEMALE'
    },
  ]

  const nationalityOptions = [
    {
      name: 'Zimbabwean',
      value: 'ZIMBABWEAN'
    },
    {
      name: 'South African',
      value: 'SOUTH AFRICAN'
    },
    {
      name: 'Namibian',
      value: 'NAMIBIAN'
    },
    {
      name: 'Botswanan',
      value: 'BOTSWANAN'
    },
    {
      name: 'Zambian',
      value: 'ZAMBIAN'
    },
    {
      name: 'Mozambican',
      value: 'MOZAMBICAN'
    },
    {
      name: 'Swazi',
      value: 'SWAZI'
    },
    {
      name: 'Lesotho',
      value: 'LESOTHO'
    },
    {
      name: 'Malawian',
      value: 'MALAWIAN'
    },
  ];


  const fetchFromUser = () => {
    const userProfileData = localStorage.getItem("userProfile");

    if (userProfileData) {
      try {
        const decodedData = JSON.parse(userProfileData);
        setFirstName(decodedData.firstName)
        setSurname(decodedData.lastName)
        setEmail(decodedData.emailAddress)
        setPhoneNumber(decodedData.phoneNumber)
        setIdNumber(decodedData.idnumber)

      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("userProfileData not found in localStorage.");
    }

  }

  useMountEffect(() => {
    fetchFromUser()
  });

  function convertDOBToCAT(dobString) {
    // Parse the DOB string into a Date object
    const dobDate = new Date(dobString);

    // Create a CAT (Central Africa Time) Date object
    const catDate = new Date(dobDate);
    catDate.setUTCHours(catDate.getUTCHours() + 2);

    return catDate;
  }


  const titleOptions = [
    {
      name: 'Mr',
      value: 'MR'
    },
    {
      name: 'Mrs',
      value: 'MRS'
    },
    {
      name: 'Miss',
      value: 'MISS'
    },
    {
      name: 'Ms',
      value: 'MS'
    },
    {
      name: 'Dr',
      value: 'DR'
    },
    {
      name: 'Prof',
      value: 'PROF'
    },
    {
      name: 'Rev',
      value: 'REV'
    }
  ];




  const maritalStatusOptions = [
    {
      name: 'SINGLE',
      value: 'SINGLE'
    },
    {
      name: 'MARRIED',
      value: 'MARRIED'
    },
    {
      name: 'DIVORCED',
      value: 'DIVORCED'
    },
    {
      name: 'WIDOWED',
      value: 'WIDOWED'
    },
    {
      name: 'SEPARATED',
      value: 'SEPARATED'
    }
  ];


  function handleSubmit(e) {
    e.preventDefault();

    const errors = formValidation();

    if (Object.keys(errors).length === 0) {
      // alert("BIGGER BETTER THINGS")
      setFormErrors({});
      submitStage1toServer()

    } else {
      console.log(errors)
      // alert("SMALLLL")
      setFormErrors(errors);
    }

  }


  const formValidation = () => {
    const errors = {}
    if (!firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    // if (!otherNames.trim()) {
    //   errors.otherNames = 'Other names are required';
    // }

    if (!surname.trim()) {
      errors.surname = 'Surname is required';
    }

    if (!idNumber.trim()) {
      errors.idNumber = 'ID number is required';
    }

    // if (!title.trim()) {
    //   errors.title = 'Select your title';
    // }

    // if (!gender.trim()) {
    //   errors.gender = 'Select your gender';
    // }

    // if (!mStatus.trim()) {
    //   errors.mStatus = 'Select your marital status';
    // }

    if (!dob) {
      errors.dob = 'Select your date of birth';
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    }

    if (!address.trim()) {
      errors.address = 'Address is required';
    }

    // if (!nationality.trim()) {
    //   errors.nationality = 'Select your nationality';
    // }

    return errors
  }
  function validateDateOfBirth(value) {

    const applicantAge = calculateAge(value)
    if (applicantAge < Number(18)) {
      let errors = formValidation();

      errors.dob = "Date out of range: Your should be at least 18 years old."

      setFormErrors(errors);
    } else {
      setDob(value)
    }



  }
  function formatDateToDDMMYYYY(inputDate) {
    const date = new Date(inputDate);

    if (isNaN(date)) {
      return "Invalid Date";
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }


  const showToastMessage = (title, message, messageType) => {
    toast.current.show({ severity: messageType, summary: title, detail: message, life: 15000 });
  }

  function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  function generateInitials(firstName, surname) {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const surnameInitial = surname.charAt(0).toUpperCase();
    return firstInitial + surnameInitial;
  }

  const submitStage1toServer = async (payload) => {

    setloading(true);

    var payload = {
      "operation": "clientCreation",
      "accessToken": "1234",
      "requestBody": {
        "officeId": 1,
        "firstname": firstName,
        "externalId": idNumber,
        "middlename": otherNames,
        "lastname": surname,
        "locale": "en",
        "dateFormat": "dd MMMM yyyy",
        "activationDate": new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' '),
        "active": true,
        "dateOfBirth": dob.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' '),
        "mobileNo": formatPhoneNumber(phoneNumber ),
        "emailAddress": "email"
      }
    }
    console.log(payload);
    await axios.post(process.env.REACT_APP_DOVESAPI + 'musoni/clients/add', payload,
      {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }
    ).then((response) => {
      if (response.data.status === "SUCCESS") {
        setloading(false);
        console.log(response.data.responseBody)
        setCumulativeResponse(response.data.responseBody)
        setUserLoanApplicationData({ ...userLoanApplicationData, userData: response.data.responseBody })
        showToastMessage("Personal Details Saved", firstName + " your personal details have been saved.", "success");
        moveToNextView();
      }
      else {
        setloading(false);
        showToastMessage("Failed to verify your personal details. Kindly try again", response.data.message, "warn");
      }
    }).catch((e) => {
      setloading(false);
      console.log(e)
    })


  }
  const formatPhoneNumber = (phoneNumber) => {
    // Remove non-digit characters from the phone number
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Check if the cleaned phone number is valid
    if (/^\d{10,14}$/.test(cleanedPhoneNumber)) {
      // Format the phone number
      const formattedPhoneNumber = `+${cleanedPhoneNumber.slice(0, 3)} ${cleanedPhoneNumber.slice(3, 5)} ${cleanedPhoneNumber.slice(5)}`;
      return formattedPhoneNumber;
    } else {
      // If the phone number is not valid, return the original input
      return phoneNumber;
    }
  };


  return (
    <div>

      <BlockUI blocked={loading}>
        <center>

          {/* {} */}
          {loading
            ? <><div className="card">
              <div className="border-round border-1 surface-border p-4 surface-card">
                <div className="flex mb-3">
                  <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                  <div>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                    <Skeleton height=".5rem"></Skeleton>
                  </div>
                </div>
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="flex justify-content-between mt-3">
                  <Skeleton width="4rem" height="2rem"></Skeleton>
                  <Skeleton width="4rem" height="2rem"></Skeleton>
                </div>
              </div>
            </div></>
            : <>
              <Card className="m-4 col-xl-9">
                <form name="principalMemberForm" onSubmit={handleSubmit}>
                  <div className="row g-4 m-4">
                    <br></br>
                    <h3>  Confirm Your Basic Information</h3>
                    {/* <div className="col-xxl-4 col-xl-4 col-lg-4">

                  <span className="p-float-label">
                    <Dropdown inputId="title" placeholder="Your title" value={title} options={titleOptions} onChange={(e) => setTitle(e.value)} optionLabel="name" style={{ width: "100%" }} ></Dropdown>
                    <label htmlFor="title">Select Title</label>
                  </span>
                  {formErrors.title && <div className="error-message"> {formErrors.title}</div>}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 flex justify-content-center">
                  <span className="p-float-label">

                    <Dropdown placeholder="Your gender" inputId="gender" value={gender} options={genderOptions} onChange={(e) => setGender(e.value)} optionLabel="name" style={{ width: "100%" }} ></Dropdown>

                    <label style={{ marginLeft: "20px" }} htmlFor="gender">Select Gender</label>
                  </span>
                  {formErrors.gender && <div className="error-message"> {formErrors.gender}</div>}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4">

                  <span className="p-float-label">
                    <Dropdown inputId="mStatus" placeholder="Your marital Status" value={mStatus} options={maritalStatusOptions} onChange={(e) => setMstatus(e.value)} optionLabel="name" style={{ width: "100%" }} ></Dropdown>
                    <label htmlFor="mStatus">Select Marital Status</label>
                  </span>
                  {formErrors.mStatus && <div className="error-message"> {formErrors.mStatus}</div>}

                </div> */}

                    <div className="col-xxl-6 col-xl-12 col-lg-12 flex justify-content-center">
                      <span className="p-float-label">
                        <InputText
                          className="form-control"
                          id="firstName"
                          placeholder="Enter your first name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          disabled
                        />
                        <label htmlFor="firstName"> First Name </label>
                      </span>
                      {formErrors.firstName && <div className="error-message"> {formErrors.firstName}</div>}
                    </div>
                    {/* <div className="col-xxl-4 col-xl-12 col-lg-12">
                      <span className="p-float-label">
                        <InputText
                          className="form-control"
                          id="otherNames"
                          placeholder="Enter your other name"
                          value={otherNames}
                          onChange={(e) => setOtherNames(e.target.value)}
                        />
                        <label htmlFor="otherNames">Other names </label>
                      </span>
                      {formErrors.otherNames && <div className="error-message"> {formErrors.otherNames}</div>}

                    </div> */}
                    <div className="col-xxl-6 col-xl-12 col-lg-12">

                      <span className="p-float-label">
                        <InputText
                          className="form-control"
                          id="surname"
                          placeholder="Enter your surname"
                          value={surname}
                          disabled
                          onChange={(e) => setSurname(e.target.value)}
                        />
                        <label htmlFor="surname">Surname </label>
                      </span>
                      {formErrors.surname && <div className="error-message"> {formErrors.surname}</div>}
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                      <span className="p-float-label">
                        <InputText
                          required
                          className="form-control"
                          id="idNumber"
                          placeholder="Enter your national ID"
                          value={idNumber}
                          disabled
                          onChange={(e) => setIdNumber(e.target.value)}
                        />
                        <label htmlFor="idNumber"> National ID Number </label>
                      </span>
                      {formErrors.idNumber && <div className="error-message"> {formErrors.idNumber}</div>}
                    </div>
                    <div className="col-xxl-6 col-xl-12 col-lg-12">
                      <span className="p-float-label">
                        <InputText
                          id="phoneNumber"
                          placeholder="Phone number"
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          disabled
                          className="form-control"
                        />
                        <label htmlFor="phoneNumber">Phone Number</label>
                      </span>
                      {formErrors.phoneNumber && <div className="error-message"> {formErrors.phoneNumber}</div>}
                    </div>
                    
                    <br></br>
                    <h3 className="m-3 mt-5">Complete your details</h3>
                    <div className="col-xxl-4 col-xl-12 col-lg-12 flex justify-content-center">
                      <span className="p-float-label">

                        <Calendar
                          showIcon
                          className="create-role-input"
                          inputId="birth_date"
                          placeholder="Select your Date of birth"
                          value={dob}

                          onChange={(e) => {
                            if (e.value !== null) {
                              validateDateOfBirth(e.value);
                            }
                          }}
                          prevIcon="pi pi-chevron-left" // PrimeIcons class for navigating to the previous month
                          nextIcon="pi pi-chevron-right" // PrimeIcons class for navigating to the next month
                        />



                        <label htmlFor="birth_date">Birth Date</label>
                      </span>
                      {formErrors.dob && <div className="error-message"> {formErrors.dob}</div>}
                    </div>
                    <div className="col-xxl-4 col-xl-12 col-lg-12">
                      <span className="p-float-label">
                        <InputText
                          id="email"
                          placeholder="Email address"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          // required
                          className="form-control"
                        />
                        <label htmlFor="email">Email Address</label>
                      </span>
                      {formErrors.email && <div className="error-message"> {formErrors.email}</div>}
                    </div>
                    <div className="col-xxl-4 col-xl-12 col-lg-12">
                      <span className="p-float-label">
                        <InputText
                          id="address"
                          placeholder="Enter your address"
                          name="address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          className="form-control"
                        />
                        <label htmlFor="address">Physical Address</label>
                      </span>
                      {formErrors.address && <div className="error-message"> {formErrors.address}</div>}
                    </div>
                    <div className="text-center">
                      <Button className='justify-content-center text-center mt-4' size='large' outlined label={<><strong >Continue </strong></>} icon="pi pi-arrow-right" severity="info" rounded raised />
                    </div>
                  </div>
                </form>
              </Card>
            </>}
        </center>
      </BlockUI>
    </div>
  );
};

export default ClientDetailsForm;
