import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import logo from '../../../images/logo.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import AnimatedSuccessGIF from '../../../images/check-green.gif';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';




const Signup = () => {
  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    nationalId: '',
    password: '',
    confirmPassword: '',
    otp:''
  });

  const [formErrors, setFormErrors] = useState({});
  const [formStep, setFormStep] = useState(1);
  const history = useHistory();
  const toast = useRef(null);
  const [userId, setUserId] = useState('')
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('')

  const validateFormStep1 = () => {
    const errors = {};
  
    if (!formFields.firstName.trim()) {
      errors.firstName = 'First name is required';
    }
  
    if (!formFields.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }
  
    // if (!formFields.email.trim()) {
    //   errors.email = 'Email is required';
    // } else if (!/\S+@\S+\.\S+/.test(formFields.email)) {
    //   errors.email = 'Invalid email address';
    // }
  
    if (!formFields.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    }

    if (formFields.nationalId.trim() === '') {
      errors.nationalId = 'National ID number is required';
    } 
    
    // else if (!/^\d{2}-.{5,}$/.test(formFields.nationalId)) {
    //   errors.nationalId = 'Enter a valid National ID Number (00-1234567A00)';
    // }
  
    return errors;
  };
  
  

  const validateFormStep2 = () => {
    const errors = {};
  
    if (!formFields.password.trim()) {
      errors.password = 'Password is required';
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(formFields.password)) {
      errors.password =
        'Password must meet the following criteria:\n\n'+
        '- At least 8 characters long\n'+
        '- Contain at least one letter (A-Z or a-z)\n'+
        '- Contain at least one number (0-9)\n'+
        '- Contain at least one special character (@, $, !, %, *, #, ?, or &)\n'+
        '\nFor example, valid passwords include:\n'+
        '- MyP@ssw0rd\n'+
        '- Secur!ty2023\n'+
        '- 1Am$tr0ng#P@ss';
    }
    
  
    if (formFields.password !== formFields.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
  
    if (!formFields.otp.trim()) {
      errors.otp = 'OTP is required';
    } else if (formFields.otp.length !== 6) {
      errors.otp = 'OTP must be 6 characters';
    }
    
  
    return errors;
  };
  

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [name]: value,
    }));
  };

  const handleSubmitData = () => {
    const errors = validateFormStep1();

    if (Object.keys(errors).length === 0) {
  
      setFormErrors({});
      registerUser()
      
    } else {
      setFormErrors(errors);
    }
  };

  const registerUser = async () => {
    const requestData = {
      accessToken: '',
      operation: 'create-user-profile',
      requestBody: {
        firstName: formFields.firstName.toUpperCase(),
        lastName: formFields.lastName.toUpperCase(),
        emailAddress: formFields.email,
        phoneNumber: formFields.phoneNumber,
        IDNumber: formFields.nationalId.replace(/[-\s]/g, ''),
        isActive: true,
        locked: false,
        enabled: true,
        userType:"CUSTOMER", 
        userRole: 1,
      },
    };

    await fetch(process.env.REACT_APP_DOVESAPI+'user/create/customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then( async (data) => {
        if (data.status === 'SUCCESS') {
          
          setFormStep(2);
          setUserId(data.responseBody.userId)

          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Check your email and phone, we sent you an OTP',
            life: 4000
          }
          );
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: data.message,
            life: 4000
          });
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, exception occured, contact admin',
          life: 4000
        });
      });
  };

  const setNewUserPassword = async () => {

    const payload = {
      "accessToken": "",
      "operation": "set-new-password",
      "requestBody": { 
          "password":  formFields.password
      }
  }

    await axios.post(process.env.REACT_APP_DOVESAPI+'user/setNewPassword/'+userId+'/'+ formFields.otp, payload,{
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    }).then((data) => {

      if (data.data.status === "SUCCESS") {

        setRegistrationSuccess(true)
        setVisible(true)
      }
      else {
         toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: data.data.message,
          life: 4000
        });
      }
    })
      .catch((error) => {

        
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 4000
        });

      });
  }

  const handlePhoneOtpOption = (value) => {
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      ["phoneNumber"]: value,
    }));
  }
  const handleSetPassword = async () => {
    
      const errors = validateFormStep2();

      if (Object.keys(errors).length === 0) {
        setNewUserPassword();
      } else {
        setFormErrors(errors);
      }
  };

        // const header = <h6>Pick a password</h6>;
        const footer = (
            <React.Fragment>
                <Divider />
                <p className="mt-2">Suggestions</p>
                <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                </ul>
            </React.Fragment>
        );

  return (
    <>
      <Toast ref={toast} />

      {
        !registrationSuccess ? (
        <>
        
        <div className="authincation ">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-4">
                <Link to="/landing">
                  <img height="150px" width="150px" src={logo} alt="" />
                </Link>
                <h4 className="card-title mt-3">Create your account</h4>
              </div>
              <div className="auth-form card">
                <div className="card-body">
                  <form name="myform" className="signin_validate row g-3">
                    {formStep === 1 ? (
                      <>
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            value={formFields.firstName}
                            onChange={handleFieldChange}
                          />
                          {formErrors.firstName && <p className="text-danger">{formErrors.firstName}</p>}
                        </div>
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Surname"
                            name="lastName"
                            value={formFields.lastName}
                            onChange={handleFieldChange}
                          />
                          {formErrors.lastName && <p className="text-danger">{formErrors.lastName}</p>}
                        </div>
                        <div className="col-12">
                          {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={formFields.phoneNumber}
                            onChange={handleFieldChange}
                          /> */}

                            <PhoneInput
                                country={'zw'}
                                international={false}
                                // inputStyle={{color:'green', width: '100%'}}
                                inputStyle={{ width: '100%'}}
                                containerStyle={{margin:'0px'}}
                                buttonStyle={{}}
                                // dropdownStyle={{height:'500px'}}
                                value={formFields.phoneNumber}
                                onChange={(e)=>handlePhoneOtpOption(e)}
                                placeholder='263 717 787 787'
                              />
                          {formErrors.phoneNumber && <p className="text-danger">{formErrors.phoneNumber}</p>}
                        </div>
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="National ID Number"
                            name="nationalId"
                            value={formFields.nationalId}
                            onChange={handleFieldChange}
                          />
                          {formErrors.nationalId && <p className="text-danger">{formErrors.nationalId}</p>}
                        </div>


                        {/* <div className='col-12'>
                        </div> */}
                        <div className="col-12">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                            value={formFields.email}
                            onChange={handleFieldChange}
                          />
                          {formErrors.email && <p className="text-danger">{formErrors.email}</p>}
                        </div>

                      </>
                    ) : (
                      <>
                      <div className="col-12">
                        <input
                          type="otp"
                          className="form-control"
                          placeholder="OTP"
                          name="otp"
                          value={formFields.otp}
                          onChange={handleFieldChange}
                        />
                        {formErrors.otp && <p className="text-danger">{formErrors.otp}</p>}
                      </div>
                        <div className="col-12">
                          {/* <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={formFields.password}
                            onChange={handleFieldChange}
                          /> */}
                          <Password
                            placeholder="Password"
                            inputClassName = 'form-control'
                            name="password" 
                            value={formFields.password} 
                            toggleMask={true} 
                            mediumLabel 
                            id="f3" inputStyle={{width: "100%"}} style={{width: "100%"}}
                            header={''} 
                            footer={footer}
                            mediumRegex 
                            feedback 
                            onChange={handleFieldChange}  />

                          {formErrors.password && <p className="text-danger">{formErrors.password}</p>}
                        </div>
                        <div className="col-12">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={formFields.confirmPassword}
                            onChange={handleFieldChange}
                          />
                          {formErrors.confirmPassword && (
                            <p className="text-danger">{formErrors.confirmPassword}</p>
                          )}
                        </div>
                      </>
                    )}
                    <div className="col-12">
                      {formStep === 1 ? (
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={handleSubmitData}
                        >
                          Next
                        </button>
                      ) : (
                        <button type="button" className="btn btn-primary w-100" onClick={handleSetPassword}>
                          Sign Up
                        </button>
                      )}
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Already have an account?{' '}
                      <Link className="text-primary" to="/signin">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      ) :(
          <div className="row justify-content-center h-100 align-items-center">

              <Dialog header="" visible={visible} style={{ width: '20vw' }} onHide={() => {
                setVisible(false)
                history.push("/signin")
              }
                }>

              <center>
                <div className=" text-center my-4">
                  <img width="100px" src={AnimatedSuccessGIF} alt="" />
                </div>

                <h3>Profile Created Successfully</h3>
                <p>You can now proceed to login</p>
                <Button
                  onClick={() => {
                    setVisible(false)
                    history.push("/signin")
                  }}
                  className='justify-content-center text-center m-2'
                  size='large'
                  label='Ok'
                  icon="pi pi-check-circle"
                  severity="success"
                  rounded
                  raised
                  style={{ color: "white" }} // Add this style to set the button text color
                /> 

            </center>
            
            </Dialog>
          </div>
      
      )}
    </>
  );
};

export default Signup;