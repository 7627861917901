import { Link } from 'react-router-dom';
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from "primereact/inputswitch";
import { classNames } from 'primereact/utils';
import { useMountEffect } from "primereact/hooks";
import { InputTextarea } from "primereact/inputtextarea";

const AddNewAd = ({ onCloseDialog,activeAds }) => {
    const [id, setId] = useState(activeAds.id);
    const [title, setTitle] = useState(activeAds.title);
    const [imageUrl, setImageUrl] = useState(activeAds.image);
    const [callToActionURL, setCallToActionURL] = useState(activeAds.callToActionURL); 
    const [description, setDescription] = useState(activeAds.description);
    const [errors, setErrors] = useState({
        title: '',
        imageUrl: '',
        callToActionURL: '',
        phoneNumber: '',
        description: '',
        userStatus: '',
    });
    const [options, setOptions] = useState([]);
 
    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Form Submitted', detail: 'The form is successfully submitted.' });
    };

    const toast = useRef(null);

    const validateEmail = (callToActionURL) => {
        // return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(callToActionURL);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = validateUser(e)

        if (validation === true) {
            createNewAds()
        }
    };

    const getFormErrorMessage = (name) => {

    };

    const createNewAds = async () => {
        let userPayload = {
            "operation": "add-new-ads",
            "requestBody": {
                "id": id,
                "title": title,
                "image": imageUrl,
                "callToActionURL": callToActionURL, 
                "description": description
            }
        };

        const token = localStorage.getItem('token')

        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        try {
            const response = await axios.post(process.env.REACT_APP_DOVESAPI + 'advertisement/save', userPayload, config);
            console.log(response.data);
            if (response.data.status === 'FAILED') {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Failed', detail: response.data.message });
            } else {
                onCloseDialog({ success: true }); 
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validateUser = (e) => {
        let formIsValid = true;
        const newErrors = { ...errors };

   
        if (!title.trim()) {
            newErrors.title = 'Title is required';
            formIsValid = false;
        } else {
            newErrors.title = '';
        }

   
        if (!imageUrl.trim()) {
            newErrors.imageUrl = 'Image Url is required';
            formIsValid = false;
        } else {
            newErrors.imageUrl = '';
        }

 
        if (!callToActionURL.trim()) {
            newErrors.callToActionURL = 'callToActionURL is required';
            formIsValid = false;
        } else {
            newErrors.callToActionURL = '';
        }

    
        // If form is valid, submit the data
        if (formIsValid) {
            setErrors({});
            return true;
        } else {
            setErrors(newErrors);
            return false;
        }
    };
 

    return (
        <>
            <Toast ref={toast} />
            <div className="">
                <div className="container">
                    <div className="row">
                        <center>
                            <div className="">
                                <div className="">
                                    <form onSubmit={handleSubmit}>
                                        <div style={{ marginTop: "10px" }} className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputText
                                                    id="title"
                                                    className='register-user-input'
                                                    style={{}}
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                                <label
                                                    htmlFor="title"
                                                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                                                >
                                                    Title
                                                </label>
                                            </span>
                                            {errors.title && <small className="p-error">{errors.title}</small>}
                                        </div>

                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputText id="imageUrl" className='register-user-input' value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }} htmlFor="imageUrl"> Image Url </label>
                                            </span>
                                            {errors.imageUrl && <small className="p-error">{errors.imageUrl}</small>}
                                        </div>
                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputText id="callToActionURL" className='register-user-input' value={callToActionURL} onChange={(e) => setCallToActionURL(e.target.value)} />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }} htmlFor="callToActionURL"> callToActionURL </label>
                                            </span>
                                            {errors.callToActionURL && <small className="p-error">{errors.callToActionURL}</small>}
                                        </div>
                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputTextarea id="description" className='register-user-input' value={description} onChange={(e) => setDescription(e.target.value)} />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }} htmlFor="description">Description </label>
                                            </span>
                                            {errors.description && <small className="p-error">{errors.description}</small>}
                                        </div> 
                                        <br></br>
                                        <hr></hr>
                                        <div className="card-body flex justify-content-center">
                                            <Button type="submit" label="Edit Advertisement" className="p-button-primary p-mt-3" raised size="small" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddNewAd;
