import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dashboard from './pages/index';
import EditUsers from './pages/users/new-user';
import ViewUser from './pages/view-user';
import UserRoles from "./components/admin/roles-management/ViewRoles";
import Beneficiaries from "./components/admin/roles-management/ViewBeneficiaries.jsx";
import BankingDetails from "./components/admin/roles-management/ViewBankingDetails.jsx";
import OnboardNew from './pages/create_policy/components/onboarding/PolicyApplicationForm';
import Signin from './pages/signin/signin';
import Signup from './pages/signin/signup';
import CreateNew from './pages/signin/create-new';
import LoanApplication from "./pages/loans/NewLoan.jsx";
import VerifyEmail from './pages/users/verify-email';
import { AuthProvider } from './components/auth/useAuth';
import { RequireAuth } from './components/auth/RequireAuth';
import Custom404 from './pages/404';
import ApprovePolicyPage from "./pages/manage_policy/approvepolicy/ApprovePolicyPage";
import ApproveLoansPage from "./pages/loans/approval/ApproveLoansPage";
import Reset from './pages/signin/reset';
import ResetFrom from './pages/signin/reset-password.jsx';
import Landing from './pages/signin/landing'; 
import ManageProducts from './pages/manage_products/ManageProducts.jsx'
import ManageBanks from './pages/manage_products/ManageBanks.jsx'
import ManageAds from './pages/manage_ads/manage_ads'
import PaymentReport from './pages/reports/payments/PaymentsReport'






/*
  REPORTS IMPORTS
*/

import CustomersProfileReport from './pages/reports/customers/CustomersProfileReport';
import EmployeesProfileReport from './pages/reports/employees/EmployeesProfileReport';
import AllUsersProfileReport from './pages/reports/all-users/AllUsersProfileReport';


/*
    CUSTOMER FUNCTIONALITY IMPORT
*/

import ManagePolicyPage from "./pages/manage_policy/manage_policy"
import ManageLoans from "./pages/loans/loans"
import Payments from './pages/payments/payments';
import createNewPolicyPage from "./pages/create_policy/createNewPolicyPage"
import ManagePolicy from "./pages/manage_policy/manage_policy"
import ApplyPolicy from "./pages/manage_policy/apply_policy"
import TermsAndConditions from "./pages/t&C/t&C"

const Router = () => {
    return (
        <AuthProvider >
            <BrowserRouter >
                {/* <BrowserRouter> */}
                <div id="main-wrapper">
                    <Switch>
                        <Route path='/signin' component={Signin} />
                        <Route path='/signup' component={Signup} />
                        <Route path='/landing' component={Landing} />
                        <Route path='/create-new-account' component={CreateNew} />

                        <Route path='/' exact >
                            <RequireAuth><Dashboard /></RequireAuth>
                        </Route>
                        <Route path="/edit-users">
                            <RequireAuth><EditUsers /></RequireAuth>
                        </Route>
                        <Route path="/edit-user-roles"  >
                            <RequireAuth><UserRoles /></RequireAuth>
                        </Route>
                        <Route path="/beneficiaries"  >
                            <RequireAuth><Beneficiaries /></RequireAuth>
                        </Route>
                        <Route path="/banking-details">
                            <RequireAuth><BankingDetails/></RequireAuth>
                        </Route>
                        {
                            /*
                                CUSTOMER FUNCTIONALITY ROUTES
                            */
                        }
                        <Route path='/manage-policy'>
                            <RequireAuth><ManagePolicy /></RequireAuth>
                        </Route>
                        <Route path='/apply-policy'>
                            <RequireAuth><ApplyPolicy /></RequireAuth>
                        </Route>
                        <Route path='/loans/new' >
                            <RequireAuth><LoanApplication /></RequireAuth>
                        </Route>

                        <Route path='/loans' >
                            <RequireAuth><ManageLoans /></RequireAuth>
                        </Route>
                        <Route path='/payments'>
                            <RequireAuth><Payments /></RequireAuth>
                        </Route>
                        <Route path='/profile' >
                            <RequireAuth><ManagePolicyPage /></RequireAuth>
                        </Route>

                        {/* PRODUCT MANAGEMENT */} 

                        <Route path='/list/products' >
                            <RequireAuth><ManageProducts /></RequireAuth>
                        </Route>

                        {/* PRODUCT MANAGEMENT */} 

                        <Route path='/list/banks' >
                            <RequireAuth><ManageBanks /></RequireAuth>
                        </Route>

                        {/* ADS MANAGEMENT */} 

                        <Route path='/manage/ads' >
                            <RequireAuth><ManageAds /></RequireAuth>
                        </Route>

                        {/* 
                            EMPLOYEE FUNCTIONALITY ROUTES
                        */}

                        <Route path="/policy/approval" >
                            <RequireAuth><ApprovePolicyPage /></RequireAuth>
                        </Route>
                        <Route path="/loans/approval">
                            <RequireAuth><ApproveLoansPage /></RequireAuth>
                        </Route>


                        {/*                         
                            REPORT ROUTES
                        */}

                        <Route path="/customers-profile-report" >
                            <RequireAuth><CustomersProfileReport /></RequireAuth>
                        </Route>
                        <Route path="/payments-report" >
                            <RequireAuth><PaymentReport /></RequireAuth>
                        </Route>
                        <Route path="/employee-profile-report" >
                            <RequireAuth><EmployeesProfileReport /></RequireAuth>
                        </Route>
                        <Route path="/users-profile-report" >
                            <RequireAuth><AllUsersProfileReport /></RequireAuth>
                        </Route>
                        <Route path="/new-policy" >
                            <RequireAuth><createNewPolicyPage /></RequireAuth>
                        </Route>
                        <Route path="/termsandconditions" >
                            <RequireAuth><TermsAndConditions /></RequireAuth>
                        </Route>

                        <Route path='/reset' component={Reset} />
                        <Route path='/set-password/:userId/:otp' component={ResetFrom} />


                        {/* IF ROUTE IS NOT FOUND */}

                        <Route path='*' component={Custom404} />

                    </Switch>
                </div>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default Router;