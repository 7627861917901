import axios from "axios";
import React, { useState, useRef } from "react";
import { useMountEffect } from 'primereact/hooks';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from "primereact/divider";
import { InputNumber } from 'primereact/inputnumber';
import { Skeleton } from 'primereact/skeleton';
import { Timeline } from 'primereact/timeline';
import { Toast } from 'primereact/toast';
import { useUpdateEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';

import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';

const FileUploads = ({ moveToNextView, moveToPreviousView, userLoanApplicationData, setUserLoanApplicationData }) => {


  const handleSubmit = (e) => {
    e.preventDefault();

  };


  useMountEffect(() => {
    console.log("userLoanApplicationData?.userData[0]?.entityId : " + userLoanApplicationData?.userData[0]?.entityId)
    console.log(userLoanApplicationData.userData)
    // fetchBanks(); 
  });

  const [loading, setloading] = useState(false);
  const moveToNext = (e) => {
    e.preventDefault();

    // setUserLoanApplicationData({ ...userLoanApplicationData })
    moveToNextView();


  }

  const [canContinue, setCanContinue] = useState(false)

  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 4 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <center>
          <i className="pi pi-image mt-3 p-1" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
          <span style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }} className="my-5 text-info"><br />
            Drag and Drop A file Here<br /><i>Only Images are allowed</i>
          </span>
        </center>
      </div>
    );
  };

  const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: false, className: 'custom-choose-btn p-button-rounded p-button-outlined', label: "Select File to Upload" };
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: false, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined', label: "Upload selected file" };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: false, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined', label: "Clear selection" };

  const msgs = useRef(null);
  const showFormRef = useRef(null);

  const uploadFiles = async (fileData, file) => {
    var payload = {
      "operation": "clientCreation",
      "accessToken": "1234",
      "requestBody": {
        "clientId": userLoanApplicationData?.userData[0]?.entityId,
        "file": fileData
      }
    }
    setloading(true)
    try {
      await axios.post(process.env.REACT_APP_DOVESAPI + 'musoni/clients/uploadFiles',
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + (localStorage.getItem('token'))
          }
        }).then((response) => {
          setloading(false)
          if (response.data.status === "SUCCESS" && response.data.responseBody != null) {
            setUploadedFiles([...uploadedFiles, file.name])
            console.log("Finished Uploading Files");
            console.log(response.data.responseBody)
            showToastMessage("Progress Saved", file.name + "  has been uploaded.", "success");
            onTemplateClear();
          } else {
            console.log(response.data.message)
          }
        });

    } catch (error) {
      setloading(false)
      console.error('Error fetching balance details:', error);
    }
  };

  const customBase64Uploader = async (event) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

    reader.readAsDataURL(blob);
    console.log(file)
    reader.onloadend = function () {
      const base64data = reader.result;
      uploadFiles(base64data, file);
    };


  };

  const showToastMessage = (title, message, messageType) => {
    toast.current.show({ severity: messageType, summary: title, detail: message, life: 15000 });
  }


  return (
    <div>
      <BlockUI blocked={loading}>
        <center>
          <Toast ref={toast}></Toast>
          <div className="row  ">
            <Card className="m-2 col-xxl-12 col-xl-12 col-lg-12">
              <Messages ref={msgs} />
              {
                loading ?
                  <>
                    <div className="border-round border-1 surface-border p-4 surface-card">
                      <div className="flex mb-3">
                        <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem"></Skeleton>
                        </div>
                      </div>
                      <Skeleton width="100%" height="150px"></Skeleton>
                      <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                      </div>
                    </div></>
                  : <>

                    <Divider align="center" type="dashed">
                      <div className="inline-flex align-items-left">
                        <h3><i className="pi pi-upload mr-2"></i>Upload your valid documents : </h3>
                      </div>
                    </Divider>
                    <h4 className='mt-1 mb-2 --blue'>Select one photo and click upload before selecting another file</h4>
                    <h4 className='mt-1 mb-2 --blue'>Upload your national ID or passport and payslip images.</h4>
                    <p className="text-danger">You need to upload at least two files before proceeding! Max file size (4MB)</p>
                    <br />

                    <div className="inline-flex text-left">
                      <div>


                        <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                        <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                        <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                        <FileUpload ref={fileUploadRef} accept="image/*"
                          customUpload uploadHandler={customBase64Uploader}
                          maxFileSize={4000000}
                          onUpload={onTemplateUpload}
                          onSelect={onTemplateSelect}
                          onError={onTemplateClear}
                          onClear={onTemplateClear}
                          headerTemplate={headerTemplate}
                          itemTemplate={itemTemplate}
                          emptyTemplate={emptyTemplate}
                          chooseOptions={chooseOptions}
                          uploadOptions={uploadOptions}
                          cancelOptions={cancelOptions}
                        />
                      </div>
                    </div>

                    <Divider align="center" type="dotted" />
                    <div className="inline-flex text-left">
                      {uploadedFiles && uploadedFiles.length !== 0 && <>
                        <h4 className='mt-1 mb-2 --blue'>Uploaded Files</h4>
                  
                          {uploadedFiles.map((element, index) => (
                             <p className='mt-1 mb-2 --blue' key={index} > <i className="pi pi-fw pi-images"></i> <span>{element} </span> </p>
                          ))}
                       

                      </>}
                    </div>
                    <br />

                    {uploadedFiles && uploadedFiles.length > 1 && <>
                      <div className="row m-2">
                        <center >

                          <div className="text-center mt-3">
                            <Button
                              onClick={moveToNext}
                              style={{ backgroundColor: '#084b9b', border: '1px solid #084b9b' }}
                              className="justify-content-center text-center  "
                              size="large"
                              label={<>Continue</>}
                              icon="pi pi-arrow-right"
                              severity="info"
                              rounded
                              raised


                            />
                          </div>

                        </center>
                      </div>
                    </>}
                  </>
              } </Card>



          </div>


        </center>
      </BlockUI>
      <center>
      </center>
    </div>
  );
};

export default FileUploads;