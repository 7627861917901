import React  from 'react'; 
import Header from '../../layout/headers/header' 
import DynamicNavigation from "../../layout/navigation/dynamic-navigation" 
import { Card } from 'primereact/card'; 
import { Divider } from 'primereact/divider'; 
import localBGImage from "../../../images/patterns/t&c-bg.png"  
const termsAndConditions = () => {

    return (
        <>
            <Header />
            <DynamicNavigation />
            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <Card className='mb-5' header={(<img src={localBGImage} style={{ width: '100%' }} />)}>
                        <h2 className='text-left mt-1 mb-2'><strong>General Terms and Conditions</strong></h2> 
                        {/* <ScrollPanel style={{ width: '100%', height: '80%' }}> */}
                            <p>
                                
                                Term to maturity of 20 years.
                                <br />Cover for the immediate Family is immediate once the first deduction has been paid through Company Stop order but all dependants shall have a waiting period of 6 months. The waiting period for dependants is only waived for accidental deaths excluding murder.
                                <br />24 months waiting period on suicidal deaths.
                                <br />Dependants will be any other persons other than the spouse and biological children and will include parents, in laws or anyone else appointed by the principal member. Each dependant shall pay a dependant's premium as indicated on the enrolment form.
                                <br />Premiums are due at the start of every month.
                                <br />Dependants added after policy inception shall pay premiums for the full 20 years.
                                <br />The policy will automatically lapse if 3 premiums are missed and can only be reinstated after all arrears have been cleared.
                                <br />A grace period of 30 days shall be allowed for non-payment after the premium becomes due.
                                <br />On reinstatement after lapse, the waiting periods will reapply.
                                <br />Premiums are reviewed in line with the cost of service provision.
                                <br />It is the duty of the policyholder to ensure premiums are paid and up to date to make these obligations enforceable at law.
                                <br />The policyholder should ensure that the names entered on this proposal form are correct; any variations will make the claim invalid.
                                <br />Children should be registered within 6 months of births; Claims on Children not registered within this period will be declined.
                                <br />Claims for Children under the age of 6 months will be given basic cover i.e. no Bus and no Grocery Pay-out.
                               
                                <br /><br /><strong>Age Limits</strong> <Divider/>
                                Principal member can only be between the ages of 18 and 64 at policy inception
                                <br />Children are covered up until the age of 18 or 23 for those in full time tertiary education
                                <br />Maximum age limit for dependants is 75 years at policy inception.
                                <br /><br /><strong>Constrains</strong><Divider/> 
                                Clients must not without admin intervention edit beneficiary details once the policy is running
                                <br />Clients must not add children above 6 months without admin intervention.
                                <br />Clients must not pay for more than 2 months in ZWL.



                            </p>
                        {/* </ScrollPanel> */}
                    </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default termsAndConditions;