import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import logo from '../../../images/logo.png';
import { useHistory } from 'react-router-dom';
import AnimatedSuccessGIF from '../../../images/check-green.gif';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';


import axios from 'axios';
import { useMountEffect } from 'primereact/hooks';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';

const Reset = () => {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { userId, otp: urlOtp } = useParams();
  const [otp, setOtp] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const history = useHistory();

  useMountEffect(() => {
    if (urlOtp) {
      setOtp(urlOtp);
    }
  }, [urlOtp]);

  const footer = (
    <React.Fragment>
        <Divider />
        <p className="mt-2">Suggestions</p>
        <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
            <li>At least one lowercase</li>
            <li>At least one uppercase</li>
            <li>At least one numeric</li>
            <li>Minimum 8 characters</li>
        </ul>
    </React.Fragment>
);
  const handleFormSubmit = async (e) => {

    e.preventDefault();

    if (!validatePassword()) {
      setError('Please enter a valid password and confirm password.');
      return;
    }

    
    const otpToUse = otp;
    await axios.post(process.env.REACT_APP_DOVESAPI + `user/resetNewPassword/${userId}/${otpToUse}`, {
      accessToken: '',
      operation: 'set-new-password',
      requestBody: {
        password: password,
      },
      
    }).then((response) => {
      if (response.data.status === 'SUCCESS') {
        setRegistrationSuccess(true)
        setVisible(true)
      }
      else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response.data.message,
          life: 4000
        });
      }
    }).catch((e) => {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Sorry, exception occured, contact admin',
        life: 4000
      });
    })
  };




  const validatePassword = () => {
    return password !== '' && password === confirmPassword;
  };

  return (
    <>
      <Toast ref={toast} />

      {
        !registrationSuccess ? (
          <>
            <div className="authincation1 section-padding1">
              <div className="container1 h-1001">
                <div className="row justify-content-center h-100 align-items-center">
                  <div className="col-xl-4 col-md-5">
                    <div className="mini-logo text-center my-3">
                      <Link> 
                        <img height="150px" src={logo} alt="Logo" />
                      </Link>
                      <h4 className="card-title mt-3">Set Password</h4>
                    </div>
                    <div className="auth-form card">
                      <div className="card-body">
                        <form className="row g-3">
                          <>
                            <div className="col-12">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)} // Update OTP state
                                maxLength={6}
                                required
                              />
                              {error && <p className="text-danger">{error}</p>}
                            </div>

                            <div className="col-12">
                              {/* <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          /> */}

                              <Password
                                placeholder="Password"
                                inputClassName='form-control'
                                name="password"
                                value={password}
                                toggleMask={true}
                                mediumLabel
                                id="f3" inputStyle={{ width: "100%" }} style={{ width: "100%" }}
                                header={''}
                                footer={footer}
                                mediumRegex
                                feedback
                                onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="col-12">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                              />
                              {error && <p className="text-danger">{error}</p>}
                            </div>
                            <div className="text-center mt-4">
                              <button onClick={handleFormSubmit} type="submit" className="btn btn-primary btn-block">
                                Submit
                              </button>
                            </div>
                          </>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        ) : (
          <div className="row justify-content-center h-100 align-items-center">

            <Dialog header="" visible={visible} style={{ width: '20vw' }} onHide={() => setVisible(false)}>

              <center>
                <div className=" text-center my-4">
                  <img width="100px" src={AnimatedSuccessGIF} alt="" />
                </div>

                <h3>Profile Created Successfully</h3>
                <p>You can now proceed to login</p>
                <Button
                  onClick={() => {
                    setVisible(false)
                    history.push("/signin")
                  }}
                  className='justify-content-center text-center m-2'
                  size='large'
                  label='Ok'
                  icon="pi pi-check-circle"
                  severity="success"
                  rounded
                  raised
                  style={{ color: "white" }}
                />

              </center>

            </Dialog>
          </div>

        )}
    </>
  );
};

export default Reset;
