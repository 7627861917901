import React, { useState, useRef } from "react";
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Divider } from 'primereact/divider';
import { useMountEffect } from 'primereact/hooks';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const CreateNewUser = ({roleData}) => {
    const toast = useRef(null);
    const [userRole, setUserRole] = useState(roleData)



    useMountEffect(() => {
        const fetchData = async () => {
            await fetchSetRole();
        };
    
        fetchData();
    });
    


    async function  fetchSetRole() {
        
    }
      
      

    return (
        <>
            <Toast ref={toast} />
            <div className="">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <Card >
                                {/* {JSON.stringify(userRole)} */}
                                {/* <center><b>USER ROLE</b></center>
                                <Divider></Divider>
                                <br></br> */}
                                Role Name : {userRole.name}
                                <hr></hr>
                                Permissions: (<small>Everything acception to user with this role</small>)
                                <DataTable value={userRole.permissions} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="id" header="ID"></Column>
                                    <Column field="action" header="Action"></Column>
                                    <Column field="name" header="Name"></Column>
                                    <Column field="description" header="Decription"></Column>
                                </DataTable>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateNewUser;
