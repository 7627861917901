
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import { Link, useHistory } from 'react-router-dom';
import image1 from '../../../../images/loans.jpg'
import { Card } from 'primereact/card';
import { useMountEffect } from 'primereact/hooks';
import axios from 'axios';
import { Skeleton } from 'primereact/skeleton';


const LoanTypeCarousel = ({ moveToNextView, moveToPreviousView, userLoanApplicationData, setUserLoanApplicationData }) => {
    const [products, setProducts] = useState([]);
    const history = useHistory()
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const moveNext = (product) => {
        setUserLoanApplicationData({ ...userLoanApplicationData, selectedProduct: product })
        moveToNextView();
    };


    const productsList = [
        {
            "id": 1,
            "code": "SSB",
            "name": "CIVIL SERVANTS (SSB)",
            "description": [
                "Financial solutions for civil servants.",
                "Salary-based loans covering urgent expenses.",
                "Includes school fees, medical bills, assets, and home expansion."
            ],
            "image": image1,
            "price": "product.Total",
            "category": "product.FranchiseID"
        },
        // {
        //     "id": 2,
        //     "code": "SMALL BUSINESS (SME)",
        //     "name": "SME",
        //     "description": [
        //         "Exclusive loans for SMEs with free financial literacy training.",
        //         "Support provided across diverse economy sectors.",
        //         "Empowering small businesses with financial solutions."
        //     ],
        //     "image": image1,
        //     "price": "product.Total",
        //     "category": "product.FranchiseID"
        // },
        {
            "id": 3,
            "code": "SECURITY BASED LENDING",
            "name": "SECURITY BASED LENDING",
            "description": [
                "Comprehensive funeral service package including casket and mortuary.",
                "Additional offerings like hearse, grave site setup, and more.",
                "Options for optional extras such as bus, holiday arrangements, and grocery services."
            ],
            "image": image1,
            "price": "product.Total",
            "category": "product.FranchiseID"
        },
        {
            "id": 4,
            "code": "KANZATU",
            "name": "KANZATU",
            "description": [
                "Credit for policy and non-policy holders.",
                "Partnership with Doves Funeral Services and DLA.",
                "Group Loan option for funeral expenses with flexible repayments over time."
            ],
            "image": image1,
            "price": "product.Total",
            "category": "product.FranchiseID"
        }
    ]


    useMountEffect(() => {
        setProducts(productsList);
    }, []);

    const handleLearnMore = (id) => {

    }

    const [loanDetails, setLoanDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    let userProfile = {};

    useMountEffect(() => {
        getLoans();
    }, []);

    const getLoans = async () => {
        setLoading(true)
        try {
            userProfile = (JSON.parse(localStorage.getItem('userProfile')));

            await axios.get(process.env.REACT_APP_DOVESAPI + 'musoni/products',// + userProfile.idnumber, 
                {
                    headers: {
                        Authorization: 'Bearer ' + (localStorage.getItem('token'))
                    }
                }).then((response) => {
                    setLoading(false)
                    if (response.data.status === "SUCCESS" && response.data.responseBody != null) { 
                        setLoanDetails(response.data.responseBody);
                    } else {

                        console.log(response.data.status)
                    }
                });

        } catch (error) {
            setLoading(false)
            console.error('Error fetching balance details:', error);
        }
    };

    const ProductTemplate = ({ product }) => {
 
        return (
            <> {loading ? (
                <div className="border-round border-1 surface-border p-4 surface-card">
                    <div className="flex mb-3">
                        <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                        <div>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton height=".5rem"></Skeleton>
                        </div>
                    </div>
                    <Skeleton width="100%" height="150px"></Skeleton>
                    <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                    </div>
                </div>) :
                <Card style={{ height: "36em" }} className="border-1 surface-border border-round m-2 text-center py-1 px-1">
                    <h4>{product.name}</h4>

                    <div className="mb-3">
                        {/* <img src={product.image} alt={product.name} className="w-1 shadow-2 rounded" style={{ width: '100%', height: 'auto' }} /> */}
                    </div>
                    <div>
                        <ul>
                            <li className='row text-left'>
                                <div className='col-md-6'>
                                    <p><strong>Currency</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p> {product?.currency?.name}</p>
                                </div>
                            </li>
                            <li className='row '>
                                <div className='col-md-6 text-left'>
                                    <p><strong>Min Principal</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p> {product?.currency?.displaySymbol}{product?.minPrincipal}</p>
                                </div>
                            </li>
                            <li className='row '>
                                <div className='col-md-6 text-left'>
                                    <p><strong>Max Principal</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p> {product?.currency?.displaySymbol}{product?.maxPrincipal}</p>
                                </div>
                            </li>
                            <li className='row '>
                                <div className='col-md-6 text-left'>
                                    <p><strong>Min No of Payments</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p>  {product?.minNumberOfRepayments} {product?.repaymentFrequencyType?.value}</p>
                                </div>
                            </li>
                            <li className='row '>
                                <div className='col-md-6 text-left'>
                                    <p><strong>Max No of Payments</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p>  {product?.maxNumberOfRepayments} {product?.repaymentFrequencyType?.value}</p>
                                </div>
                            </li>
                            <li className='row '>
                                <div className='col-md-6 text-left'>
                                    <p><strong>Min Frequency</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p>  {product?.minNumberOfRepayments} {product?.repaymentFrequencyType?.value}</p>
                                </div>
                            </li>
                            <li className='row '>
                                <div className='col-md-6 text-left'>
                                    <p><strong>Max Frequency</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p>  {product?.maxNumberOfRepayments} {product?.repaymentFrequencyType?.value}</p>
                                </div>
                            </li>
                            <li className='row '>
                                <div className='col-md-6 text-left'>
                                    <p><strong>Amortization Type</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p>   {product?.amortizationType?.value}</p>
                                </div>
                            </li>
                            <li className='row '>
                                <div className='col-md-6 text-left'>
                                    <p><strong>Annual Interest Rate</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                    <p>   {product?.annualInterestRate}%</p>
                                </div>
                            </li>
                            {/* {product.description.map((element, index) => (
                                <li key={index}>{element}</li>
                            ))} */}
                        </ul>
                    </div>

                    <div className="button-container" style={{ marginBottom: "0px" }}>
                        <Button
                            className='justify-content-center text-center mt-4'
                            size='large'
                            label={<strong className='blue-text'>{"Apply Now"}</strong>}
                            severity="info"
                            rounded
                            outlined
                            raised
                            onClick={() => moveNext(product)}
                        />
                    </div>
                </Card>}
            </>
        );
    };

    return (
        <>{loading ? (
            <div className="border-round border-1 surface-border p-4 surface-card">
                <div className="flex mb-3">
                    <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                    <div>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                        <Skeleton height=".5rem"></Skeleton>
                    </div>
                </div>
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="flex justify-content-between mt-3">
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                </div>
            </div>) : <div>
            <br></br>
            <center><h2><small>SELECT THE LOAN TYPE </small></h2></center>

            <div className="row">
                {loanDetails && loanDetails.map((element, index) => (
                    <div className="col-xxl-4 col-xl-4 col-lg-4" key={index}>   <ProductTemplate product={element} /></div>
                ))}

            </div>
            {/* <Carousel value={products} numScroll={1} numVisible={3} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} /> */}

        </div>}</>
    )
}

export default LoanTypeCarousel;