import { Link } from 'react-router-dom';
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from "primereact/inputswitch";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useMountEffect } from 'primereact/hooks';


const EditUser = ({ onCloseDialog, employeeData }) => {
//   console.log("Employee Data:", employeeData);

    const [firstName, setFirstName] = useState(employeeData.firstName);
    const [lastName, setLastName] = useState(employeeData.lastName);
    // const [email, setEmail] = useState(employeeData.emailAddress);
    const [idNumber, setIdNumber] = useState(employeeData.idnumber);
    const [email, setEmail] = useState(employeeData.emailAddress);
    const [phoneNumber, setPhoneNumber] = useState(employeeData.phoneNumber);
    const [userStatus, setUserStatus] = useState(employeeData.isActive);
    const [userRole, setUserRole] = useState(employeeData.userRole.id);
    const [allSystemRoles, setAllSystemRoles] = useState(null)
    const [selectedRole, setSelectedRole] = useState(null)

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        idNumber: ''
    });
    



    useMountEffect(() => {
        // console.log(JSON.stringify(employeeData))
        fetchRoles();
      }, []);

      const [options, setOptions] = useState([]);

      const fetchRoles = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_DOVESAPI+'system/roles',{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        });
          setAllSystemRoles(response.data.responseBody);
      
          const newOptions = response.data.responseBody.map(element => ({
            label: element.name,
            value: element.id,
            description: element.description
          }));
      
          setOptions(newOptions);
        } catch (error) {
        //   console.error(error);
        }
      };

    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Form Submitted', detail: 'The form is successfully submitted.' });
    };

    const handleUserRoleChange = (val)=>{

        setUserRole(val)
        options.forEach(element => {
            //console.log(element)

            if(element.value === val){
                setSelectedRole(element)
            }
        });
    }
   
    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Cancel', detail: 'You have cancelled', life: 3000 });
    }

    const confirmUpdate = (e) => {

        e.preventDefault();
        const validation = validateUser(e)

        if (validation === true) {
            confirmDialog({
                message: "Are you sure you want to update this?",
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: registerUser,
                reject
            });
        }
        else{
            console.log("Not ")
        }
    };

    const toast = useRef(null);


    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    // const handleSubmit = (e) => {
    //     console.log(validation)

    //     if (validation === true) {
    //         registerUser()
    //     }
    // };

    const getFormErrorMessage = (name) => {

    };

    const registerUser = async () => {
        let userPayload = {
            "operation": "create-user-profile",
            "requestBody": {
                "userId": employeeData.userId,
                "firstName": firstName,
                "lastName": lastName,
                "emailAddress": email,
                "phoneNumber": phoneNumber,
                "idnumber": idNumber,
                "isActive": true,
                "locked": false,
                "enabled": true,
                "userType": "EMPLOYEE",
                "userRole": userRole
            }
        };

        const token = localStorage.getItem('token')

        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        try {
            const response = await axios.put(process.env.REACT_APP_DOVESAPI+'user/edit', userPayload, config);
            // console.log(response.data);
            if (response.data.status === 'FAILED') {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Failed', detail: response.data.message });
            } else {
                onCloseDialog({ success: true });
            }
        } catch (error) {
            // console.error(error);
        }
    };

    const validateUser = (e) => {
        let formIsValid = true;
        const newErrors = { ...errors };

        // Validate First Name
        if (!firstName.trim()) {
            newErrors.firstName = 'First name is required';
            formIsValid = false;
        } else {
            newErrors.firstName = '';
        }

        // Validate Last Name
        if (!lastName.trim()) {
            newErrors.lastName = 'Last name is required';
            formIsValid = false;
        } else {
            newErrors.lastName = '';
        }

        // Validate Email
        if (!email.trim()) {
            newErrors.email = 'Email is required';
            formIsValid = false;
        } else if (!validateEmail(email)) {
            newErrors.email = 'Invalid email format';
            formIsValid = false;
        } else {
            newErrors.email = '';
        }

        // Validate Phone Number
        if (!phoneNumber.trim()) {
            newErrors.phoneNumber = 'Phone number is required';
            formIsValid = false;
        } else if (!/^\d{5,}$/.test(phoneNumber)) {
            newErrors.phoneNumber = 'Phone number should be more than 5 digits';
            formIsValid = false;
        } else {
            newErrors.phoneNumber = '';
        }

        if (formIsValid) {
            setErrors({});
            return true;
        } else {
            setErrors(newErrors);
            return false;
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="">

                <div className="container">
                    <div className="row">
                        <center>
                            <div className="">
                                <div className="">
                                    <form>
                                    <div style={{ marginTop: "10px" }} className="card-body flex justify-content-center">
                                        <span className="p-float-label">
                                            <InputText
                                            id="firstName"
                                            className='register-user-input'
                                            style={{}}
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            />
                                            <label
                                            htmlFor="firstName"
                                            style={{ paddingLeft: "5px", paddingRight: "5px" }} 
                                            >
                                            First Name
                                            </label>
                                        </span>
                                        {errors.firstName && <small className="p-error">{errors.firstName}</small>}
                                        </div>

                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputText id="lastName" className='register-user-input' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }}  htmlFor="lastName"> Last Name </label>
                                            </span>
                                            {errors.lastName && <small className="p-error">{errors.lastName}</small>}
                                        </div>

                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputText id="idNumber" className='register-user-input' value={idNumber} onChange={(e) => setIdNumber(e.target.value)} />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }}  htmlFor="idNumber"> National ID Number </label>
                                            </span>
                                            {errors.idNumber && <small className="p-error">{errors.idNumber}</small>}
                                        </div>
                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputText id="email" className='register-user-input' value={email} onChange={(e) => setEmail(e.target.value)} />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }}  htmlFor="email"> Email </label>
                                            </span>
                                            {errors.email && <small className="p-error">{errors.email}</small>}
                                        </div>
                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <InputText id="phoneNumber" className='register-user-input' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }}  htmlFor="phoneNumber"> Phone </label>
                                            </span>
                                            {errors.phoneNumber && <small className="p-error">{errors.phoneNumber}</small>}
                                        </div>
                                        <div className="card-body flex justify-content-center">
                                            <span className="p-float-label">
                                                <Dropdown 
                                                className='register-user-input'
                                                id="userRole"
                                                value={userRole}
                                                options={options}
                                                onChange={(e) => handleUserRoleChange(e.value)}
                                                placeholder="Select a role"
                                                />
                                                <label style={{ paddingLeft: "5px", paddingRight: "5px" }}  htmlFor="userRole">User Role</label>
                                            </span>
                                            <div className=" "> 
                                            
                                                <small style={{color: "blue"}}>
                                                {selectedRole && selectedRole.description && <small>{selectedRole.description}</small>}
                                                </small> 
                                                
                                            </div>

                                        </div>
                                        {/* <div
                                            className="register-user-input"
                                            style={{
                                                display: "flex",
                                                alignItems: "center", // Align items vertically (centered vertically)
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                padding: "10px",
                                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                                            }}
                                            >
                                            <div style={{ marginRight: "10px" }}>

                                                <InputSwitch
                                                id="value"
                                                name="value"
                                                checked={userStatus}
                                                onChange={(e) =>{

                                                    var message = ''
                                                    if(userStatus === false){
                                                        message = 'User set active'
                                                    }
                                                    else{
                                                        message = 'User diabled'
                                                    }
                                                    toast.current.show({ severity: 'info', summary: 'user status', detail: message });
                                                    setUserStatus(e.value)
                                                } } 
                                                className="p-float-label"
                                                />
                                            </div>

                                            {
                                                userStatus === false ? (
                                                    <>
                                                        <div>
                                                            Activate user?
                                                        </div>
                                                    </>
                                                ):(
                                                    <div>
                                                        Disabled user?
                                                    </div>
                                                )
                                            }
                                            
                                            
                                        </div> */}
                                        <br></br>
                                        <hr></hr>
                                        <div className="card-body flex justify-content-center">
                                            <Button type="submit" onClick={confirmUpdate} label="Update" className="p-button-primary p-mt-3" raised size="small" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditUser;
