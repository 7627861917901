import React, { useState } from 'react';
import Header from '../../layout/headers/header';
import DynamicNavigation from "../../layout/navigation/dynamic-navigation";
import LoanForm from '../../components/loans/loanDetails/LoanForm.jsx';

const Loans = () => {
    const [loansAvailable, setLoansAvailable] = useState(false);
    
    return (
        <>
            <Header />
            <DynamicNavigation />
            <div className="content-body">
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-24">
                            <LoanForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loans;
