import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { TieredMenu } from 'primereact/tieredmenu';
// import { MenuItem } from 'primereact/menuitem';

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useMountEffect } from 'primereact/hooks';

const PaymentsReportTable = () => {
  const [Users, setUsers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const dt = useRef(null);

  const cols = [
    { field: 'id', header: 'ID' },
    { field: 'policyNumber', header: 'Policy Number' },
    { field: 'productName', header: 'Product Name' },
    { field: 'payerName', header: 'Payer Name' },
    { field: 'billingAddress', header: 'Billing Address' },
    { field: 'mobileNumber', header: 'Phone Number' },
    { field: 'emailAddress', header: 'Email' },
    { field: 'amount', header: 'Amount' },
    { field: 'paymentStatus', header: 'Payment Status' },
    { field: 'paymentReference', header: 'Payment Reference' }, 
    { field: 'notes', header: 'Payment Notess' }, 
    { field: 'easipolPaymentResponse', header: 'Easipol Payment Status' },
    { field: 'pollUrl', header: 'Paynow Verification Link' },
    { field: 'timeStamp', header: 'Timestamp' }
  ];

  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const data = []

  useMountEffect(() => {
    fetchUsers();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default();

        doc.autoTable(exportColumns, Users);
        doc.save('doves-self-service-payments-report.pdf');
      });
    });
  };


  const fetchUsers = async () => {
    try {
      await axios.get(process.env.REACT_APP_DOVESAPI+'payment/report', {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {
        console.log(response);
        if (response.data.status === "SUCCESS") { 
          setUsers((response.data.responseBody));
        } else {
          console.log(response.data.status);
        }
      });

    } catch (error) {
      console.log(error)
    }
  };


  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(Users);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'doves-self-service-payments-report');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
  };


  const onGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
  };

  const globalFilterElement = (
    <input
      type="text"
      className="p-inputtext p-component"
      placeholder="Search"
      value={globalFilter}
      onChange={onGlobalFilter}
    />
  );

  const menu = useRef(null);
  const items  = [
    {
      label: 'Download CSV',
      icon: 'pi pi-file-excel',
      command: () => { exportCSV(false) }
    },
    // {
    //   label: 'Download PDF',
    //   icon: 'pi pi-file-pdf',
    //   command: () => { exportPdf(false) }
    // }
  ];
  return (
    <div className="card home-chart">
      <Tooltip target=".export-buttons>button" position="bottom" />
      <div className="card-header">
        <h2 className="card-title home-chart">Payments Report</h2>
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {globalFilterElement}
          </span>
        </div>
        <div id="report-select">
          <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
          <Button label="Download Report" icon="pi pi-download" onClick={(e) => menu.current.toggle(e)} />
        </div>

      </div>

      <div className="card-body">

        <DataTable
          paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
          ref={dt}
          value={Users}
          // header={header}
          tableStyle={{ minWidth: '50rem' }}
          globalFilter={globalFilter}
          emptyMessage="No payments found"
        >
          {cols.map((col, index) => (
            <Column key={index} field={col.field} header={col.header} />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default PaymentsReportTable;
