import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";



class DonutChart extends Component {

    
    constructor(props) {
        super(props);
        
        var chartValues = [];
        var chartLabels = [];
        var chartColors = [];
        props.getApplicationsByStatus.map((data, index) => {
            chartValues.push(data.count)
            chartLabels.push(data.status)
            chartColors.push(Math.random() < 0.5 ? getRandomShadeOfBlue() : getRandomShadeOfGrey())//['#084b9b', '#96989a', '#5b5b5b']
        })
 

        this.state = {

            series: chartValues,
            options: {
                chart: {
                    height: 220,
                    type: 'donut',
                },
                dataLabels: {
                    enabled: false
                },
                labels:chartLabels,
                fill: {
                    colors: chartColors
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            show: false
                        }
                    }
                }],
                legend: {
                    show: false,
                    position: 'right',
                    offsetY: 0,
                    height: 150,
                }
            },


        };
    }




    render() {
        return (


            <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height={220} />


        );
    }
}

function getRandomShadeOfBlue() {
    // Generate a random shade of blue
    const blueShades = ['#084b9b', '#357ABD', '#1F77B4', '#8FB1CC', '#2B6EA6'];
    return blueShades[Math.floor(Math.random() * blueShades.length)];
}

function getRandomShadeOfGrey() {
    // Generate a random shade of grey
    const greyShades = ['#96989a', '#A2A4A5', '#7D7E80', '#C2C4C6', '#B0B2B5'];
    return greyShades[Math.floor(Math.random() * greyShades.length)];
}
export default DonutChart;