import React, { useState, useEffect } from 'react'; 
import Header from '../../layout/headers/header' 
import DynamicNavigation from "../../layout/navigation/dynamic-navigation" 
import CreateNewPolicyPage from "../create_policy/createNewPolicyPage" 
const ApplyPolicy = () => {

    return (
        <>
            <Header />
            <DynamicNavigation />
            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12">
                            <CreateNewPolicyPage  className="mt-2" existingApplicationProgress={{applicationDetail:null}} isLifePolicy={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default ApplyPolicy;