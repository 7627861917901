import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Signup from '../../pages/signin/signup';
import { useAuth } from './useAuth';

export const RequireAuth = ({ children, signup }) => {

  const auth = useAuth(); 
  const { isAuthenticated, logout,login } = useAuth();
  // let isAuthenticated = localStorage.getItem('isAuthenticated');
  // console.log('USER AUTH STATUS : ' + isAuthenticated);
  // console.log('USER AUTH STATUS : not good -- ' + isAuthenticated);
  // if (isAuthenticated) {
  //   return <Route render={() => children} />;
  // } else {
  //   return <Redirect to='/signup' />;
  // }


  if (!isAuthenticated) {
    // console.log('USER AUTH STATUS : not good -- ' + isAuthenticated);
    return <Redirect to='/landing' />;
    // return <Navigate to="/signup" replace />;
  }

  return children;

};
