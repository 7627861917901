import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Card } from 'primereact/card';

const viewMainMemberTable = ({ activeUserData }) => {
  const Users = activeUserData;

  const bankDetails = [
    { field: 'BankNameId', header: 'Bank Id' },
    { field: 'Account_Name', header: 'Account Name' },
    { field: 'Account_Number', header: 'Account Number' },
    { field: 'Type_of_Account', header: 'Type Of Account' },
    { field: 'Debit_Day_FirstTimeOnly', header: 'Debit Date' },
    { field: 'Branch_Code', header: 'Branch Code' },
  ];

  return (
    <div className="card home-chart">
      <Card>
        <center><h2 className="card-title home-chart">BANKING DETAILS</h2></center>


        <div className="card-body">
          <DataTable
            value={[Users.bankAccount]}
            tableStyle={{ minWidth: '50rem' }}
            emptyMessage="No banking details found"
          >
            {bankDetails.map((col, index) => (
              <Column key={index} field={col.field} header={col.header} />
            ))}
          </DataTable>

          <br />
        </div>
      </Card>
    </div>
  );
};

export default viewMainMemberTable;
