import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.png';
import AnimatedSuccessGIF from '../../../images/check-green.gif';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useMountEffect } from 'primereact/hooks';
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import { useAuth } from '../../components/auth/useAuth';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';

const Signup = () => {
  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [maskedPhone, setMaskedPhone] = useState("")
  const [maskedPhoneList, setmaskedPhoneList] = useState([])
  const [existingFormFields, setExistingFormFields] = useState({
    idNumber: '',
    phoneNumber: '',
    otp: '',
    password: '',
    confirmPassword: '',
    email: ''
  })
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const toast = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [formStep, setFormStep] = useState(1);
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [userId, setUserId] = useState('')
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useMountEffect(() => {
    toggleForms()
  });

  const validateFormStep1 = () => {
    const errors = {};

    if (!formFields.firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    if (!formFields.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }

    // if (!formFields.email.trim()) {
    //   errors.email = 'Email is required';
    // } else if (!/\S+@\S+\.\S+/.test(formFields.email)) {
    //   errors.email = 'Invalid email address';
    // }

    if (!formFields.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    }


    return errors;
  };


  const validateExisiting1 = () => {
    const errors = {};

    // if (existingCustomer && !existingFormFields.idNumber.trim()) {
    //   errors.idNumber = 'ID number is required';
    // }

    if (typeof existingFormFields.idNumber !== 'undefined' && (existingFormFields.idNumber.trim() === '')) {
      errors.idNumber = 'National ID number is required';
    }
    // else if (!/^\d{2}-\d{5,}.*$/.test(existingFormFields.idNumber)) {


    //   errors.idNumber = 'Enter a valid National ID Number (00-1234567A00)';
    // }


    setFormErrors(errors)
    return errors;
  };


  const validateExisiting2 = () => {

    const errors = {}

    if (existingFormFields.phoneNumber.trim().length !== 12) {
      console.log(existingFormFields.phoneNumber.trim().length)
      errors.phoneNumber = 'Phone number must meet the format (+263 717 787 787)';
    }
    if (selectedPolicy == null) {
      console.log(selectedPolicy)
      errors.selectedPolicy = 'Select a verification phone number.';
    }

    return errors;

  }


  function toggleForms() {

    if (existingCustomer === false) {
      setExistingCustomer(true)
    }
    else {
      setExistingCustomer(false)

    }
  }

  const validateFormStep2 = () => {
    const errors = {};

    if (!formFields.password.trim()) {
      errors.password = 'Password is required';
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(formFields.password)) {
      errors.password =
        'Password must meet the following criteria:\n\n' +
        '- At least 8 characters long\n' +
        '- Contain at least one letter (A-Z or a-z)\n' +
        '- Contain at least one number (0-9)\n' +
        '- Contain at least one special character (@, $, !, %, *, #, ?, or &)\n' +
        'For example, valid passwords include:\n' +
        '- MyP@ssw0rd\n' +
        '- Secur!ty2023\n' +
        '- 1Am$tr0ng#P@ss';
    }

    if (formFields.password !== formFields.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [name]: value,
    }));
  };

  const handleExistingFieldChange = (e) => {
    const { name, value } = e.target;
    setExistingFormFields((prevFormFields) => ({
      ...prevFormFields,
      [name]: value,
    }));

    console.log(existingFormFields)
  };

  const handleSetPassword = () => {
    const errors = validateFormStep1();

    if (Object.keys(errors).length === 0) {
      setFormStep(2);
      setFormErrors({});
    } else {
      setFormErrors(errors);
    }

    if (formStep === 2) {
      handleSubmit();
    }
  };

  const registerUser = () => {
    setLoading(true);
    const requestData = {
      accessToken: '',
      operation: 'create-user-profile',
      requestBody: {
        firstName: formFields.firstName.toUpperCase(),
        lastName: formFields.lastName.toUpperCase(),
        emailAddress: formFields.email,
        phoneNumber: formFields.phoneNumber,
        isActive: true,
        locked: false,
        enabled: true,
        userType: 'EMPLOYEE',
        userRole: 1,
        credentials: {
          userName: formFields.email,
          password: formFields.password,
        },
      },
    };

    fetch(process.env.REACT_APP_DOVESAPI + 'user/create/customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === 'SUCCESS') {
          // console.log(JSON.stringify(data))
          setUserId(data.responseBody.userId)
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: data.message,
            life: 4000
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, exception occured, contact admin',
          life: 4000
        });
      });

  };


  const fetchRecordById = async (idNumber) => {
    setLoading(true);


    await axios.get(process.env.REACT_APP_DOVESAPI + 'user/requestPhoneNumberVerification/' + idNumber.replace(/[-\s]/g, ''), {

      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => {
        setLoading(false);
        if (data.data.status === 'SUCCESS') {
          data.data.responseBody.forEach(policy => {
            maskedPhoneList.push(
              { name: policy.maskedPhoneNUmber, code: policy.maskedPolicyNumber })
          });

          // setmaskedPhoneList(data.data.responseBody);
          // setMaskedPhone(data.data.responseBody.maskedPhoneNUmber);
          setFormStep(2);

        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: data.data.message,
            life: 4000
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, exception occured, contact admin',
          life: 4000
        });
      });

  }

  const confirmPhone = async () => {
    setLoading(true);
    console.log(existingFormFields)
    const requestBody = {
      "accessToken": "",
      "operation": "verifyPhoneNumber",
      "requestBody": {
        "phoneNumber": existingFormFields.phoneNumber,
        "emailAddress": existingFormFields.email,
        "policyNumber":selectedPolicy.code
      }
    };
    await axios.post(process.env.REACT_APP_DOVESAPI + 'user/verifyPhoneNumber', requestBody).then((data) => {
      setLoading(false);
      if (
        data.data.status === 'SUCCESS'
      ) {
        setFormStep(3)
        setUserId(data.data.responseBody.userId)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Check your email and phone, we sent you an OTP',
          life: 4000
        });
      }

      else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: data.data.message,
          life: 4000
        });
      }

    })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
      });
  }


  const handlePhoneNumber = (value) => {

    setExistingFormFields({
      ...existingFormFields,
      phoneNumber: value,
    });

  };

  const setNewUserPassword = async () => {
    setLoading(true);
    const payload = {
      "accessToken": "",
      "operation": "set-new-password",
      "requestBody": {
        "password": existingFormFields.password
      }
    }

    await axios.post(process.env.REACT_APP_DOVESAPI + 'user/setNewPassword/' + userId + '/' + existingFormFields.otp, payload).then((data) => {
      setLoading(false);
      if (data.data.status === "SUCCESS") {

        setRegistrationSuccess(true)
        setVisible(true)
      }
      else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: data.data.message,
          life: 4000
        });
      }
    })
      .catch((error) => {
        setLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, exception occured, contact admin',
          life: 4000
        });
      });
  }
  const handleSubmit = () => {

    let errors = {}

    if (existingCustomer === true) {
      errors = validateExisiting1();

    }
    else {
      errors = validateFormStep1();

    }
    if (formStep === 1) {


      if (Object.keys(errors).length === 0) {
        fetchRecordById(existingFormFields.idNumber);
        setFormErrors({});
      } else {
        setFormErrors(errors);
      }


    } else if (formStep === 2) {

      if (existingCustomer === true) {
        errors = validateExisiting2();
      }
      else {
        errors = validateFormStep2();
      }

      if (Object.keys(errors).length === 0 && existingCustomer === false) {
        registerUser();

      }
      else if (Object.keys(errors).length === 0 && formStep === 2 && existingCustomer === true) {
        confirmPhone();
      }
      else {

        console.log(errors)
        setFormErrors(errors);
      }
    }

    else if (formStep === 3) {
      setNewUserPassword()
      setFormStep(4)
    }
  };

  const [selectedPolicy, setselectedPolicy] = useState(null);
  

  return (
    <>
      <Toast ref={toast} />

      {
        !registrationSuccess ? (
          <>

            <div className="authincation  ">
              <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                  <div className="col-xl-5 col-md-6">
                    <div className="mini-logo text-center my-4">
                      <Link to="/landing">
                        <img width="150px" src={logo} alt="" />
                      </Link>
                    </div>

                    {formStep < 4 ? (
                      <div className="auth-form card">
                        {/* <button> Existing Customer </button> */}
                        <div className="card-body">
                          {loading ? (
                            <div className="border-round border-1 surface-border p-4 surface-card">
                              <div className="flex mb-3">
                                <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                                <div>
                                  <Skeleton width="10rem" className="mb-2"></Skeleton>
                                  <Skeleton width="5rem" className="mb-2"></Skeleton>
                                  <Skeleton height=".5rem"></Skeleton>
                                </div>
                              </div>
                              <Skeleton width="100%" height="150px"></Skeleton>
                              <div className="flex justify-content-between mt-3">
                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                <Skeleton width="4rem" height="2rem"></Skeleton>
                              </div>
                            </div>) : <>
                            {existingCustomer === false ? (
                              <>
                                <form name="myform" className="signin_validate row g-3">
                                  {formStep === 1 ? (
                                    <>
                                      <div className='col-12'>
                                        <div className=" text-center mt-0">
                                          <h2 className="card-title mb-4">Create a new profile<br />
                                            <small><i>Are you an existing doves customer.</i></small>
                                          </h2>
                                        </div>
                                        <div className='row'>
                                          <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="wallet-widget card">
                                              <h5>I already have a Doves policy</h5>
                                              <h2><span className="text-primary">Yes</span></h2>
                                              <p> <p onClick={toggleForms} className="btn btn-outline-primary">Create Profile</p></p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="wallet-widget card">
                                              <h5>I do not have a Doves policy</h5>
                                              <h2><span className="text-success">NO</span></h2>
                                              <p> <Link to={"/create-new-account"} className="btn btn-outline-success">Create New Profile</Link></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="col-12">
                                        <input
                                          type="password"
                                          className="form-control"
                                          placeholder="Password"
                                          name="password"
                                          value={formFields.password}
                                          onChange={handleFieldChange}
                                        />
                                        {formErrors.password && <p className="text-danger">{formErrors.password}</p>}
                                      </div>
                                      <div className="col-12">
                                        <input
                                          type="password"
                                          className="form-control"
                                          placeholder="Confirm Password"
                                          name="confirmPassword"
                                          value={formFields.confirmPassword}
                                          onChange={handleFieldChange}
                                        />
                                        {formErrors.confirmPassword && (
                                          <p className="text-danger">{formErrors.confirmPassword}</p>
                                        )}
                                      </div>
                                    </>
                                  )}
                                  <div className="col-12">
                                    {formStep === 1 ? (
                                      <div></div>
                                    ) : (
                                      <button type="button" className="btn btn-primary w-100" onClick={handleSubmit}>
                                        Sign Up
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </>
                            ) : (
                              <form name="myform1" className="signin_validate row g-3">


                                {formStep === 1 ? (
                                  <>
                                    <div className="col-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your ID Number"
                                        name="idNumber"
                                        value={existingFormFields.idNumber}
                                        onChange={handleExistingFieldChange}
                                      />
                                      {formErrors.idNumber && <p className="text-danger">{formErrors.idNumber}</p>}
                                    </div>
                                  </>
                                ) : formStep === 2 ? (
                                  <>
                                    <div className="col-12">
                                      <center>
                                        <h2> Verify your policy details</h2> 
                                        <p> Select one of your phone numbers below and enter to verify </p>
                                      </center>
                                      <div className="card flex justify-content-center">
                                        <Dropdown required value={selectedPolicy} onChange={(e) =>  setselectedPolicy(e.value)} options={maskedPhoneList} optionLabel="name"
                                          placeholder="Select verification phone number" className="w-full md:w-14rem" />
                                      </div>
                                      {formErrors.selectedPolicy && <p className="text-danger">{formErrors.selectedPolicy}</p>}

                                      {/* <input
                                type="text"
                                className="form-control"
                                placeholder={maskedPhone}
                                name="phoneNumber"
                                value={existingFormFields.phoneNumber}
                                onChange={handleExistingFieldChange}
                              /> */}

                                      <PhoneInput  
                                        country={'zw'}
                                        international={false}
                                        value={existingFormFields.phoneNumber}
                                        onChange={(e) => handlePhoneNumber(e)}
                                        placeholder='263 717 787 787'
                                      />
                                      {formErrors.phoneNumber && <p className="text-danger">{formErrors.phoneNumber}</p>}
                                    </div>
                                    <div className="col-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Set Email"
                                        name="email"
                                        value={existingFormFields.email}
                                        onChange={handleExistingFieldChange}
                                      />
                                      {formErrors.otp && <p className="text-danger">{formErrors.otp}</p>}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="OTP"
                                        name="otp"
                                        value={existingFormFields.otp}
                                        onChange={handleExistingFieldChange}
                                      />
                                      {formErrors.otp && <p className="text-danger">{formErrors.otp}</p>}
                                    </div>
                                    {/* <div className="col-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Set Email"
                                        name="email"
                                        value={existingFormFields.email}
                                        onChange={handleExistingFieldChange}
                                      />
                                      {formErrors.otp && <p className="text-danger">{formErrors.otp}</p>}
                                    </div> */}
                                    <div className="col-12">
                                      <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        name="password"
                                        value={existingFormFields.password}
                                        onChange={handleExistingFieldChange}
                                      />
                                      {formErrors.password && <p className="text-danger">{formErrors.password}</p>}
                                    </div>
                                    <div className="col-12">
                                      <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        name="confirmPassword"
                                        value={existingFormFields.confirmPassword}
                                        onChange={handleExistingFieldChange}
                                      />
                                      {formErrors.confirmPassword && (
                                        <p className="text-danger">{formErrors.confirmPassword}</p>
                                      )}
                                    </div>
                                  </>

                                )}


                                <button type="button" className="btn btn-primary w-100" onClick={handleSubmit}>
                                  Next
                                </button>
                              </form>
                            )}
                            <div className="new-account mt-3">

                              {existingCustomer === false ? (


                                <p>
                                  {/* Already an existing doves customer?{' '}
                          <Link onClick={toggleForms} className="text-primary" >
                            Create Profile
                          </Link> */}
                                </p>

                              ) : (
                                <p>
                                  Do not have a policy ?{' '}
                                  <Link to="/create-new-account" className="text-primary" >
                                    Sign up
                                  </Link>
                                </p>
                              )}
                              <p>
                                Already have an account?{' '}
                                <Link className="text-primary" to="/signin">
                                  Sign In
                                </Link>
                              </p>
                            </div></>}
                        </div>
                      </div>

                    ) : (
                      <div className="auth-form card">
                        <div className="card-body">

                          <center>
                            <div className=" text-center my-4">
                              <img width="100px" src={AnimatedSuccessGIF} alt="" />
                            </div>

                            <h3>Profile Created Successfully</h3>
                            <p>You can now proceed to login</p>
                            <Button
                              onClick={() => {
                                setVisible(false)
                                history.push("/")
                              }}
                              className='justify-content-center text-center m-2'
                              size='large'
                              label='Ok'
                              icon="pi pi-check-circle"
                              severity="success"
                              rounded
                              raised
                              style={{ color: "white" }} // Add this style to set the button text color
                            />

                          </center>

                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (

          <div className="row justify-content-center h-100 align-items-center">

            <Dialog header="" visible={visible} style={{ width: '20vw' }} onHide={() => {
              setVisible(false)
              history.push("/signin")
            }}>

              <center>
                <div className=" text-center my-4">
                  <img width="100px" src={AnimatedSuccessGIF} alt="" />
                </div>

                <h3>Profile Created Successfully</h3>
                <p>You can now proceed to login</p>
                <Button
                  onClick={() => {
                    setVisible(false)
                    history.push("/signin")
                  }}
                  className='justify-content-center text-center m-2'
                  size='large'
                  label='Ok'
                  icon="pi pi-check-circle"
                  severity="success"
                  rounded
                  raised
                  style={{ color: "white" }} // Add this style to set the button text color
                />

              </center>

            </Dialog>
          </div>

        )}
    </>
  );
};

export default Signup;