import React, { useState } from "react";
import { PrimeIcons } from 'primereact/api';
// src/jsx/pages/onboarding/PlanInformation.jsx 
import PremiumCalculator from "../../../../components/policy/calculator/PremiumCalculator";
import ExtraBenefits from "./ExtraBenefitSelection.jsx";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

const SelectProductBenefitForm = ({ moveToNextView, moveToPreviousView, userPolicyInput, setuserPolicyInput }) => {

  const handleMoveNext = () => {
    moveToNextView()
  };



  return (
    <div>
      <center>
        <div className="card1">
          <div style={{ marginLeft: 'auto' }} className="col-xl-12">
            <div className="row">
              <div className="col-xl-9">
                <Card >
                  {
                    userPolicyInput.selectedSubGroupPlan &&
                    <>
                      <div className="card-header">
                        <h3 className="">{userPolicyInput.selectedPlanName}</h3>

                        <img height={"60em"} src={userPolicyInput.selectedSubGroupPlan.groupImageBannerUrl} ></img>
                      </div>
                      <div className="card-body text-left" style={{ backgroundSize: "cover", minHeight: "30em" }} >
                        <p><small>{userPolicyInput.selectedSubGroupPlan.Description}</small></p>
                        {/* <h4>Basic Benefit of this policy</h4>
                    <ul>
                      <li icon="pi pi-check">Age limit 65 years for policyholder and 75 years for dependent</li>
                      <li>No medicals required to join the policy</li>
                      <li>Covers children on full-time tertiary education up to 23 years</li>
                      <li>Suits individuals from all walks of life.</li>
                    </ul>  */}
                        <h4>Select product below</h4>
                        <br />

                        <ExtraBenefits moveToNextView={moveToNextView} setuserPolicyInput={setuserPolicyInput} userPolicyInput={userPolicyInput} />
                      </div>
                      <div className="text-center">
                        <Button onClick={handleMoveNext} className='justify-content-center text-center mt-4' size='large' outlined label={<><strong >Continue </strong></>} icon="pi pi-arrow-right" severity="info" rounded raised />
                      </div>
                    </>}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default SelectProductBenefitForm;
