import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { TieredMenu } from 'primereact/tieredmenu';
import CreateNewUser from '../admin/user-management/CreateNewUser'
import EditUser from '../admin/user-management/EditEmployees'
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useMountEffect } from 'primereact/hooks';

const EmployeesProfileReportTable = () => {
  const [employees, setemployees] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [activeUser, setActiveUser] = useState(null)

  const dt = useRef(null);
  const toast = useRef(null);
  const [reloadData, setReloadData] = useState(false);


  

  const cols = [
    { field: 'BenID', header: 'ID' },
    { field: 'Member_ID', header: 'Member ID' },
    { field: 'BenName', header: 'First Name' },
    { field: 'BenSurname', header: 'Surname' },
    { field: 'BenDOB', header: 'Date Of Birth' },
    { field: 'BenRelation', header: 'Relationship' },
    { field: 'BenTelephone', header: 'Phone' },
    { field: 'actions', header: 'Actions' },
  ];



  
  // const cols = [
  //   { field: 'firstName', header: 'First name' },
  //   { field: 'lastName', header: 'Last name' },
  //   { field: 'emailAddress', header: 'Email' },
  //   { field: 'phoneNumber', header: 'Phone number' },
  //   { field: 'actions', header: 'Actions' },
  // ];

  useMountEffect(() => {
    fetchUsers();
  }, [reloadData]);


  const renderActions = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text raised
          size="small"
          icon="pi pi-pencil"
          className="p-button-warning"
          style={{ marginRight: '5px' }}
          onClick={() => {
            setReloadData(!reloadData);
            setActiveUser(rowData)
            setDialogVisible(!dialogVisible)

          }}
        />
        <Button
          text raised
          size="small"
          icon="pi pi-times"
          className=" p-button-danger"
          onClick={() => {
            console.log('Disabled');
          }}
        />
      </React.Fragment>
    );
  };

  const [dialogVisible, setDialogVisible] = useState(false);

  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const data = []

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default();

        doc.autoTable(exportColumns, employees);
        doc.save('doves-self-service-employees-profiles-report.pdf');
      });
    });
  };


  const fetchUsers = async () => {
    try {
      await axios.get(process.env.REACT_APP_DOVESAPI+'users/employees', {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {
        // console.log(response);
        if (response.data.status === "SUCCESS") { 
          // setemployees((response.data.responseBody));
          setemployees([{
            "userId": 2,
            "firstName": "John",
            "lastName": "Doe",
            "emailAddress": "0777757168",
            "phoneNumber": "courageb@xplug.co.zw",
            "isActive": true,
            "locked": false,
            "enabled": true,
            "userType": "EMPLOYEE",
            "userRole": {
                "id": 1,
                "name": "SUPER_ADMIN",
                "description": "User can access all the system functions",
                "creationTimestamp": null,
                "lastUpdateTimestamp": null,
                "permissions": [
                    {
                        "id": 1,
                        "name": "Edit Users",
                        "description": "Edit Users",
                        "icons": "icofont-groups",
                        "action": "/edit-users",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483809",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483821"
                    },
                    {
                        "id": 2,
                        "name": "Edit Roles",
                        "description": "Edit Users Roles",
                        "icons": "icofont-manage-user",
                        "action": "/edit-user-roles",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483828",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483831"
                    },
                    {
                        "id": 3,
                        "name": "Customer Profiles",
                        "description": "Customer Profiles",
                        "icons": "icofont-chart-bar-graph",
                        "action": "/customers-profile-report",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483845",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483847"
                    },
                    {
                        "id": 4,
                        "name": "Employee Profiles",
                        "description": "Employee Profiles",
                        "icons": "icofont-chart-bar-graph",
                        "action": "/employee-profile-report",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.48385",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483852"
                    },
                    {
                        "id": 5,
                        "name": "All Profiles",
                        "description": "All Profiles",
                        "icons": "icofont-chart-bar-graph",
                        "action": "/users-profile-report",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483855",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483857"
                    },
                    {
                        "id": 6,
                        "name": "Audit Report",
                        "description": "Audit",
                        "icons": "icofont-chart-bar-graph",
                        "action": "/audit",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483861",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483863"
                    },
                    {
                        "id": 7,
                        "name": "View Conditions",
                        "description": "View Business Conditions",
                        "icons": "icofont-cogs",
                        "action": "/business-conditions",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483874",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483876"
                    },
                    {
                        "id": 8,
                        "name": "Edit Conditions",
                        "description": "Edit Business Conditions",
                        "icons": "icofont-cogs",
                        "action": "/business-conditions",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483879",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483881"
                    },
                    {
                        "id": 9,
                        "name": "Track Application",
                        "description": "Track your pending application",
                        "icons": "pi pi-compass",
                        "action": "/track-policy-application",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483893",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483895"
                    },
                    {
                        "id": 10,
                        "name": "View Policy",
                        "description": "View your policy",
                        "icons": "icofont-ebook",
                        "action": "/business-conditions",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483901",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483903"
                    },
                    {
                        "id": 11,
                        "name": "Edit Beneficiaries",
                        "description": "Edit Business Conditions",
                        "icons": "icofont-ebook",
                        "action": "/beneficiaries",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483906",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483908"
                    },
                    {
                        "id": 12,
                        "name": "Edit Banking Detail",
                        "description": "Edit Business Conditions",
                        "icons": "icofont-ebook",
                        "action": "/banking-details",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483911",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483913"
                    },
                    {
                        "id": 13,
                        "name": "Apply Loan",
                        "description": "Apply Loan",
                        "icons": "icofont-cash-on-delivery",
                        "action": "/loans",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483922",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483924"
                    },
                    {
                        "id": 14,
                        "name": "Track Application",
                        "description": "Track Application",
                        "icons": "icofont-cash-on-delivery",
                        "action": "/loans",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483926",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483927"
                    },
                    {
                        "id": 15,
                        "name": "View loans ",
                        "description": "View Loans",
                        "icons": "icofont-cash-on-delivery",
                        "action": "/loans",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483932",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483933"
                    },
                    {
                        "id": 16,
                        "name": "Make Payments",
                        "description": "Make Payments",
                        "icons": "icofont-cash-on-delivery",
                        "action": "/loans",
                        "endpoints": [
                            {}
                        ],
                        "creationTimestamp": "2023-07-24T09:23:09.483936",
                        "lastUpdateTimestamp": "2023-07-24T09:23:09.483938"
                    }
                ],
                "permissionsGroups": [
                    {
                        "groupId": null,
                        "groupName": "User Management",
                        "groupDescription": "User Management",
                        "groupIcons": "icofont-ui-user-group",
                        "creationTimestamp": "2023-07-24T10:41:11.913648",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.913651",
                        "systemFunctions": [
                            {
                                "id": 1,
                                "name": "Edit Users",
                                "description": "Edit Users",
                                "icons": "icofont-groups",
                                "action": "/edit-users",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483809",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483821"
                            },
                            {
                                "id": 2,
                                "name": "Edit Roles",
                                "description": "Edit Users Roles",
                                "icons": "icofont-manage-user",
                                "action": "/edit-user-roles",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483828",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483831"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "User Management",
                        "groupDescription": "User Management",
                        "groupIcons": "icofont-ui-user-group",
                        "creationTimestamp": "2023-07-24T10:41:11.914127",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.914128",
                        "systemFunctions": [
                            {
                                "id": 1,
                                "name": "Edit Users",
                                "description": "Edit Users",
                                "icons": "icofont-groups",
                                "action": "/edit-users",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483809",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483821"
                            },
                            {
                                "id": 2,
                                "name": "Edit Roles",
                                "description": "Edit Users Roles",
                                "icons": "icofont-manage-user",
                                "action": "/edit-user-roles",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483828",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483831"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Reports",
                        "groupDescription": "View Reports",
                        "groupIcons": "icofont-chart-bar-graph",
                        "creationTimestamp": "2023-07-24T10:41:11.914689",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.91469",
                        "systemFunctions": [
                            {
                                "id": 3,
                                "name": "Customer Profiles",
                                "description": "Customer Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/customers-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483845",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483847"
                            },
                            {
                                "id": 4,
                                "name": "Employee Profiles",
                                "description": "Employee Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/employee-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.48385",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483852"
                            },
                            {
                                "id": 5,
                                "name": "All Profiles",
                                "description": "All Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/users-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483855",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483857"
                            },
                            {
                                "id": 6,
                                "name": "Audit Report",
                                "description": "Audit",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/audit",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483861",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483863"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Reports",
                        "groupDescription": "View Reports",
                        "groupIcons": "icofont-chart-bar-graph",
                        "creationTimestamp": "2023-07-24T10:41:11.915174",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.915174",
                        "systemFunctions": [
                            {
                                "id": 3,
                                "name": "Customer Profiles",
                                "description": "Customer Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/customers-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483845",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483847"
                            },
                            {
                                "id": 4,
                                "name": "Employee Profiles",
                                "description": "Employee Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/employee-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.48385",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483852"
                            },
                            {
                                "id": 5,
                                "name": "All Profiles",
                                "description": "All Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/users-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483855",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483857"
                            },
                            {
                                "id": 6,
                                "name": "Audit Report",
                                "description": "Audit",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/audit",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483861",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483863"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Reports",
                        "groupDescription": "View Reports",
                        "groupIcons": "icofont-chart-bar-graph",
                        "creationTimestamp": "2023-07-24T10:41:11.915602",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.915602",
                        "systemFunctions": [
                            {
                                "id": 3,
                                "name": "Customer Profiles",
                                "description": "Customer Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/customers-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483845",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483847"
                            },
                            {
                                "id": 4,
                                "name": "Employee Profiles",
                                "description": "Employee Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/employee-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.48385",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483852"
                            },
                            {
                                "id": 5,
                                "name": "All Profiles",
                                "description": "All Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/users-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483855",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483857"
                            },
                            {
                                "id": 6,
                                "name": "Audit Report",
                                "description": "Audit",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/audit",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483861",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483863"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Reports",
                        "groupDescription": "View Reports",
                        "groupIcons": "icofont-chart-bar-graph",
                        "creationTimestamp": "2023-07-24T10:41:11.916082",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.916083",
                        "systemFunctions": [
                            {
                                "id": 3,
                                "name": "Customer Profiles",
                                "description": "Customer Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/customers-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483845",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483847"
                            },
                            {
                                "id": 4,
                                "name": "Employee Profiles",
                                "description": "Employee Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/employee-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.48385",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483852"
                            },
                            {
                                "id": 5,
                                "name": "All Profiles",
                                "description": "All Profiles",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/users-profile-report",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483855",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483857"
                            },
                            {
                                "id": 6,
                                "name": "Audit Report",
                                "description": "Audit",
                                "icons": "icofont-chart-bar-graph",
                                "action": "/audit",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483861",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483863"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Business Conditions",
                        "groupDescription": "Business Conditions",
                        "groupIcons": "pi pi-chart-line",
                        "creationTimestamp": "2023-07-24T10:41:11.917095",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.917097",
                        "systemFunctions": [
                            {
                                "id": 7,
                                "name": "View Conditions",
                                "description": "View Business Conditions",
                                "icons": "icofont-cogs",
                                "action": "/business-conditions",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483874",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483876"
                            },
                            {
                                "id": 8,
                                "name": "Edit Conditions",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-cogs",
                                "action": "/business-conditions",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483879",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483881"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Business Conditions",
                        "groupDescription": "Business Conditions",
                        "groupIcons": "pi pi-chart-line",
                        "creationTimestamp": "2023-07-24T10:41:11.91818",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.918183",
                        "systemFunctions": [
                            {
                                "id": 7,
                                "name": "View Conditions",
                                "description": "View Business Conditions",
                                "icons": "icofont-cogs",
                                "action": "/business-conditions",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483874",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483876"
                            },
                            {
                                "id": 8,
                                "name": "Edit Conditions",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-cogs",
                                "action": "/business-conditions",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483879",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483881"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Manage Policy",
                        "groupDescription": "Manage your policy",
                        "groupIcons": "icofont-ebook",
                        "creationTimestamp": "2023-07-24T10:41:11.919271",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.919273",
                        "systemFunctions": [
                            {
                                "id": 9,
                                "name": "Track Application",
                                "description": "Track your pending application",
                                "icons": "pi pi-compass",
                                "action": "/track-policy-application",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483893",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483895"
                            },
                            {
                                "id": 10,
                                "name": "View Policy",
                                "description": "View your policy",
                                "icons": "icofont-ebook",
                                "action": "/business-conditions",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483901",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483903"
                            },
                            {
                                "id": 11,
                                "name": "Edit Beneficiaries",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-ebook",
                                "action": "/beneficiaries",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483906",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483908"
                            },
                            {
                                "id": 12,
                                "name": "Edit Banking Detail",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-ebook",
                                "action": "/banking-details",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483911",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483913"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Manage Policy",
                        "groupDescription": "Manage your policy",
                        "groupIcons": "icofont-ebook",
                        "creationTimestamp": "2023-07-24T10:41:11.919963",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.919965",
                        "systemFunctions": [
                            {
                                "id": 9,
                                "name": "Track Application",
                                "description": "Track your pending application",
                                "icons": "pi pi-compass",
                                "action": "/track-policy-application",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483893",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483895"
                            },
                            {
                                "id": 10,
                                "name": "View Policy",
                                "description": "View your policy",
                                "icons": "icofont-ebook",
                                "action": "/business-conditions",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483901",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483903"
                            },
                            {
                                "id": 11,
                                "name": "Edit Beneficiaries",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-ebook",
                                "action": "/beneficiaries",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483906",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483908"
                            },
                            {
                                "id": 12,
                                "name": "Edit Banking Detail",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-ebook",
                                "action": "/banking-details",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483911",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483913"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Manage Policy",
                        "groupDescription": "Manage your policy",
                        "groupIcons": "icofont-ebook",
                        "creationTimestamp": "2023-07-24T10:41:11.920653",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.920654",
                        "systemFunctions": [
                            {
                                "id": 9,
                                "name": "Track Application",
                                "description": "Track your pending application",
                                "icons": "pi pi-compass",
                                "action": "/track-policy-application",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483893",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483895"
                            },
                            {
                                "id": 10,
                                "name": "View Policy",
                                "description": "View your policy",
                                "icons": "icofont-ebook",
                                "action": "/business-conditions",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483901",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483903"
                            },
                            {
                                "id": 11,
                                "name": "Edit Beneficiaries",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-ebook",
                                "action": "/beneficiaries",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483906",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483908"
                            },
                            {
                                "id": 12,
                                "name": "Edit Banking Detail",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-ebook",
                                "action": "/banking-details",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483911",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483913"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Manage Policy",
                        "groupDescription": "Manage your policy",
                        "groupIcons": "icofont-ebook",
                        "creationTimestamp": "2023-07-24T10:41:11.921182",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.921184",
                        "systemFunctions": [
                            {
                                "id": 9,
                                "name": "Track Application",
                                "description": "Track your pending application",
                                "icons": "pi pi-compass",
                                "action": "/track-policy-application",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483893",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483895"
                            },
                            {
                                "id": 10,
                                "name": "View Policy",
                                "description": "View your policy",
                                "icons": "icofont-ebook",
                                "action": "/business-conditions",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483901",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483903"
                            },
                            {
                                "id": 11,
                                "name": "Edit Beneficiaries",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-ebook",
                                "action": "/beneficiaries",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483906",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483908"
                            },
                            {
                                "id": 12,
                                "name": "Edit Banking Detail",
                                "description": "Edit Business Conditions",
                                "icons": "icofont-ebook",
                                "action": "/banking-details",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483911",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483913"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Financial Services",
                        "groupDescription": "Financial Services",
                        "groupIcons": "icofont-cash-on-delivery",
                        "creationTimestamp": "2023-07-24T10:41:11.922416",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.922418",
                        "systemFunctions": [
                            {
                                "id": 13,
                                "name": "Apply Loan",
                                "description": "Apply Loan",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483922",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483924"
                            },
                            {
                                "id": 14,
                                "name": "Track Application",
                                "description": "Track Application",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483926",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483927"
                            },
                            {
                                "id": 15,
                                "name": "View loans ",
                                "description": "View Loans",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483932",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483933"
                            },
                            {
                                "id": 16,
                                "name": "Make Payments",
                                "description": "Make Payments",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483936",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483938"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Financial Services",
                        "groupDescription": "Financial Services",
                        "groupIcons": "icofont-cash-on-delivery",
                        "creationTimestamp": "2023-07-24T10:41:11.922912",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.922913",
                        "systemFunctions": [
                            {
                                "id": 13,
                                "name": "Apply Loan",
                                "description": "Apply Loan",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483922",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483924"
                            },
                            {
                                "id": 14,
                                "name": "Track Application",
                                "description": "Track Application",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483926",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483927"
                            },
                            {
                                "id": 15,
                                "name": "View loans ",
                                "description": "View Loans",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483932",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483933"
                            },
                            {
                                "id": 16,
                                "name": "Make Payments",
                                "description": "Make Payments",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483936",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483938"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Financial Services",
                        "groupDescription": "Financial Services",
                        "groupIcons": "icofont-cash-on-delivery",
                        "creationTimestamp": "2023-07-24T10:41:11.923317",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.923318",
                        "systemFunctions": [
                            {
                                "id": 13,
                                "name": "Apply Loan",
                                "description": "Apply Loan",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483922",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483924"
                            },
                            {
                                "id": 14,
                                "name": "Track Application",
                                "description": "Track Application",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483926",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483927"
                            },
                            {
                                "id": 15,
                                "name": "View loans ",
                                "description": "View Loans",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483932",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483933"
                            },
                            {
                                "id": 16,
                                "name": "Make Payments",
                                "description": "Make Payments",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483936",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483938"
                            }
                        ]
                    },
                    {
                        "groupId": null,
                        "groupName": "Financial Services",
                        "groupDescription": "Financial Services",
                        "groupIcons": "icofont-cash-on-delivery",
                        "creationTimestamp": "2023-07-24T10:41:11.923781",
                        "lastUpdateTimestamp": "2023-07-24T10:41:11.923781",
                        "systemFunctions": [
                            {
                                "id": 13,
                                "name": "Apply Loan",
                                "description": "Apply Loan",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483922",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483924"
                            },
                            {
                                "id": 14,
                                "name": "Track Application",
                                "description": "Track Application",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483926",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483927"
                            },
                            {
                                "id": 15,
                                "name": "View loans ",
                                "description": "View Loans",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483932",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483933"
                            },
                            {
                                "id": 16,
                                "name": "Make Payments",
                                "description": "Make Payments",
                                "icons": "icofont-cash-on-delivery",
                                "action": "/loans",
                                "endpoints": [
                                    {}
                                ],
                                "creationTimestamp": "2023-07-24T09:23:09.483936",
                                "lastUpdateTimestamp": "2023-07-24T09:23:09.483938"
                            }
                        ]
                    }
                ]
            },
            "BeneficiaryID": 23232,
            "Member_ID": 656565,
            "BenTitle": "Mr",
            "BenName": "Tafadzwa",
            "BenSurname": "Nyemba",
            "BenID": "23-3434334W23",
            "BenDOB": "2023-09-34",
            "BenRelation": "MOTHER",
            "BenTelephone": "0777754443",
            "BenGender": "MALE",
            "PassportNumber": "232223T",
            "DTSTAMP": "2023-07-24T08:17:55.444Z"
          },
          {
            "BeneficiaryID": 11111,
            "Member_ID": 989898,
            "BenTitle": "Miss",
            "BenName": "Sophia",
            "BenSurname": "Johnson",
            "BenID": "43-8765432X56",
            "BenDOB": "1990-06-12",
            "BenRelation": "DAUGHTER",
            "BenTelephone": "0888123456",
            "BenGender": "FEMALE",
            "PassportNumber": "ABC123XYZ",
            "DTSTAMP": "2023-07-24T08:17:55.444Z"
          },
          {
            "BeneficiaryID": 22222,
            "Member_ID": 757575,
            "BenTitle": "Mrs",
            "BenName": "Emily",
            "BenSurname": "Smith",
            "BenID": "78-5432109Y89",
            "BenDOB": "1975-02-25",
            "BenRelation": "WIFE",
            "BenTelephone": "0777345678",
            "BenGender": "FEMALE",
            "PassportNumber": "XYZ987ABC",
            "DTSTAMP": "2023-07-24T08:17:55.444Z"
          }
          ,
          {
            "BeneficiaryID": 33333,
            "Member_ID": 123456,
            "BenTitle": "Mr",
            "BenName": "Michael",
            "BenSurname": "Anderson",
            "BenID": "91-6543210Z45",
            "BenDOB": "1988-11-02",
            "BenRelation": "FATHER",
            "BenTelephone": "0999888777",
            "BenGender": "MALE",
            "PassportNumber": "PQR456LMN",
            "DTSTAMP": "2023-07-24T08:17:55.444Z"
          }
          ,{
            "BeneficiaryID": 23232,
            "Member_ID": 656565,
            "BenTitle": "Mr",
            "BenName": "Tafadzwa",
            "BenSurname": "Nyemba",
            "BenID": "23-3434334W23",
            "BenDOB": "2023-09-34",
            "BenRelation": "MOTHER",
            "BenTelephone": "0777754443",
            "BenGender": "MALE",
            "PassportNumber": "232223T",
            "DTSTAMP": "2023-07-24T08:17:55.444Z"
          },
        {
          "BeneficiaryID": 23232,
            "Member_ID": 656565,
            "BenTitle": "Mr",
            "BenName": "Tafadzwa",
            "BenSurname": "Nyemba",
            "BenID": "23-3434334W23",
            "BenDOB": "2023-09-34",
            "BenRelation": "MOTHER",
            "BenTelephone": "0777754443",
            "BenGender": "MALE",
            "PassportNumber": "232223T",
            "DTSTAMP": "2023-07-24T08:17:55.444Z"
        },
      {
        "BeneficiaryID": 23232,
            "Member_ID": 656565,
            "BenTitle": "Mr",
            "BenName": "Tafadzwa",
            "BenSurname": "Nyemba",
            "BenID": "23-3434334W23",
            "BenDOB": "2023-09-34",
            "BenRelation": "MOTHER",
            "BenTelephone": "0777754443",
            "BenGender": "MALE",
            "PassportNumber": "232223T",
            "DTSTAMP": "2023-07-24T08:17:55.444Z"
      }])
        } else {
          console.log(response.data.status);
        }
      });

    } catch (error) {
      console.log(error)
    }
  };


  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(employees);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'doves-self-service-employees-profiles-report');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
  };


  const onGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
  };

  const globalFilterElement = (
    <input
      type="text"
      className="p-inputtext p-component"
      placeholder="Search"
      value={globalFilter}
      onChange={onGlobalFilter}
    />
  );

  const handleCreateUser = async () =>{
    setDialogVisible(false)

    await toast.current.show({ severity: 'success', summary: 'Success', detail: 'User registered successfully' });

  }


  const menu = useRef(null);
  const items = [
    {
      label: 'Download CSV',
      icon: 'pi pi-file-excel',
      command: () => { exportCSV(false) }
    },
    {
      label: 'Download PDF',
      icon: 'pi pi-file-pdf',
      command: () => { exportPdf(false) }
    }
  ];
  return (

    
    <div className="card home-chart"> 

    { activeUser === null ? (
      <Dialog header="Register new user" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() => {
        setActiveUser(null)
        setDialogVisible(false)}}>
      <CreateNewUser onCloseDialog = { handleCreateUser} style={{ marginTop: "20px;"}} />
    </Dialog>
    ) : (
      <Dialog header="Edit user" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() =>{
        setActiveUser(null)
        setDialogVisible(false)}}>
          <EditUser employeeData = {activeUser}  onCloseDialog = { handleCreateUser} style={{ marginTop: "20px;"}} />
      </Dialog>
    )
  }
      

      
      
      <Tooltip target=".export-buttons>button" position="bottom" />
      <div className="card-header">
        <h2 className="card-title home-chart">Employees Profile Report</h2>
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {globalFilterElement}
          </span>
        </div>
        <div id="report-select">
          <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
          <Button label="Create new" raised size="small" icon="pi pi-user-plus" onClick={() => setDialogVisible(!dialogVisible)} />

        </div>

      </div>

      <div className="card-body">

        <DataTable
          paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
          ref={dt}
          value={employees}
          tableStyle={{ minWidth: '50rem' }}
          globalFilter={globalFilter}
          emptyMessage="No employees found"
        >
          {cols.map((col, index) => {
            if (col.field === 'actions') {
              return (
                <Column
                  key={index}
                  body={renderActions}
                  style={{ textAlign: 'center' }}
                />
              );
            }
            return (
              <Column key={index} field={col.field} header={col.header} />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};

export default EmployeesProfileReportTable;
