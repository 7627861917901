import axios from "axios";
import React, { useState, useRef } from "react";
import { useMountEffect } from 'primereact/hooks';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Divider } from "primereact/divider";
import { Skeleton } from 'primereact/skeleton';
import { Messages } from 'primereact/messages';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Timeline } from 'primereact/timeline';
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Link, useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import AnimatedSuccessGIF from '../../../../images/check-green.gif';
const ConfirmationForm = ({ moveToNextView, moveToPreviousView, userLoanApplicationData, setUserLoanApplicationData }) => {


  const handleSubmit = (e) => {
    e.preventDefault();

  };


  useMountEffect(() => {
    console.log("userLoanApplicationData\n\n\n")
    console.log(userLoanApplicationData)
    // fetchBanks(); 
  });

  const [loading, setloading] = useState(false);
  const moveToNext = (e) => {

    if (canContinue) {
      setUserLoanApplicationData({ ...userLoanApplicationData })
      // moveToNextView();
      setVisible(true)
    }

  }

  const [canContinue, setCanContinue] = useState(false)
  const history = useHistory()
  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const msgs = useRef(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [visible, setVisible] = useState(false);

  const customizedContent = (item) => {

    return (
      <div>
        <small> {item.period ? "Payment number " + item.period : "Start"}  </small><br />
        <small> Due on Date: </small><h3><strong>{item?.dueDate[2] + "-" + item?.dueDate[1] + "-" + item?.dueDate[0]}</strong></h3>
        <ul>
          <li className='row '>
            <div className='col-md-12 text-left'>
              <p><strong>Amount due : {item.totalInstallmentAmountForPeriod ? "$" + item.totalInstallmentAmountForPeriod : "$0"}</strong></p>
            </div>
          </li>

        </ul>
      </div>
    );
  };

  const submitLoanApplication = async () => {

    console.log("userLoanApplicationData\n\n\n\n\n")
    console.log(userLoanApplicationData)
    var payload = {
      "operation": "LoanCreation",
      "accessToken": "1234",
      "requestBody": {
        "dateFormat": "dd MMMM yyyy",
        "locale": "en_GB",
        "clientId": userLoanApplicationData?.userData[0]?.entityId,
        "productId": userLoanApplicationData?.selectedProduct?.id,
        "principal": userLoanApplicationData?.loanAmount,
        "loanTermFrequency": userLoanApplicationData?.numberOfMonths,
        "loanTermFrequencyType": 2,
        "loanType": "individual",
        "numberOfRepayments": userLoanApplicationData?.numberOfMonths,
        "repaymentEvery": 1,
        "repaymentFrequencyType": userLoanApplicationData?.selectedProduct?.repaymentFrequencyType?.id,
        "interestRatePerPeriod": userLoanApplicationData?.selectedProduct?.interestRatePerPeriod,
        "amortizationType": userLoanApplicationData?.selectedProduct?.amortizationType?.id,
        "interestType": userLoanApplicationData?.selectedProduct?.interestType?.id,
        "interestCalculationPeriodType": userLoanApplicationData?.selectedProduct?.interestCalculationPeriodType?.id,
        "submittedOnDate": new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' '),
        "expectedDisbursementDate": new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' '),
        "transactionProcessingStrategyId": userLoanApplicationData?.selectedProduct?.transactionProcessingStrategyId,
        "repaymentsStartingFromDate": new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' ')


      }
    };
    console.log(payload)

    setloading(true)
    try {

      await axios.post(process.env.REACT_APP_DOVESAPI + 'musoni/loans/new',
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + (localStorage.getItem('token'))
          }
        }).then((response) => {
          setloading(false)
          if (response.data.status === "SUCCESS" && response.data.responseBody != null) {
            console.log(response.data?.responseBody)
            moveToNext();
          } else {
            console.log(response.data.message)
          }
        });

    } catch (error) {
      setloading(false)
      console.error('Error submitting loan details:', error);
    }
  };

  return (
    <div>
      <BlockUI blocked={loading}>
        <center>

          <div className="row  ">
            <Card className="m-2 col-xxl-12 col-xl-12 col-lg-12">
              <Messages ref={msgs} />
              {
                loading ?
                  <>
                    <div className="border-round border-1 surface-border p-4 surface-card">
                      <div className="flex mb-3">
                        <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem"></Skeleton>
                        </div>
                      </div>
                      <Skeleton width="100%" height="150px"></Skeleton>
                      <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                      </div>
                    </div></>
                  : <>

                    <Divider align="center" type="dashed">
                      <div className="inline-flex align-items-left">
                        <h3><i className="pi pi-check-circle mr-2"></i>Confirm your application details : </h3>
                      </div>
                    </Divider>
                    <div className="inline-flex text-left">
                      <div className="card">
                        <Accordion multiple activeIndex={0} >
                          <AccordionTab header="Your personal information" active>
                            <ul>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Full Name</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.userData[0]?.entityName}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Phone Number</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.userData[0]?.entityMobileNo}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>National Id Number</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.userData[0]?.entityExternalId}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Loan Profile Status</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.userData[0]?.entityStatus?.value}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Loan Profile Account ID</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.userData[0]?.entityAccountNo}</p>
                                </div>
                              </li>
                            </ul>
                          </AccordionTab>
                          <AccordionTab header="Loan Detail" active>
                            <>  <ul>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Total Principal Expected</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.loanCalculationData?.currency?.displayLabel}{userLoanApplicationData?.loanCalculationData?.totalPrincipalDisbursed}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Total Interest Changed</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.loanCalculationData?.currency?.displayLabel}{userLoanApplicationData?.loanCalculationData?.totalInterestCharged}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Total Repayment Amount</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.loanCalculationData?.currency?.displayLabel}{userLoanApplicationData?.loanCalculationData?.totalRepaymentExpected}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Net Salary </strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.loanCalculationData?.currency?.displayLabel}{userLoanApplicationData?.netSalary}</p>
                                </div>
                              </li>
                              <li className='row '>
                                <div className='col-md-6 text-left'>
                                  <p><strong>Loan Period</strong></p>
                                </div>
                                <div className="text-right col-md-6">
                                  <p> {userLoanApplicationData?.numberOfMonths} Month(s)</p>
                                </div>
                              </li>


                              {/* {product.description.map((element, index) => (
                                <li key={index}>{element}</li>
                            ))} */}
                            </ul>
                              <div className="mt-4 mb-2">
                                <Divider align="center" type="dashed" >
                                  <div className="inline-flex align-items-left">
                                    <h3><i className="pi pi-file mr-2"></i>Loan Schedule Detail Below : </h3>
                                  </div>
                                </Divider>
                                <div>
                                  <Divider align="center" type="dashed" >
                                    <div className="inline-flex align-items-left">
                                      <h5><i className="pi pi-file mr-2"></i>Repayment Schedule : </h5>
                                    </div>
                                  </Divider>
                                  <div>
                                    {userLoanApplicationData?.paymentSchedule && Object.keys(userLoanApplicationData?.paymentSchedule).length !== 0 && <Timeline value={userLoanApplicationData?.paymentSchedule} content={customizedContent} />}
                                  </div>
                                </div>
                              </div></>
                          </AccordionTab> 
                        </Accordion>
                      </div>
                    </div>




                    <div className="row m-2">

                      <center > <div>
                        <TriStateCheckbox
                          id="item"
                          name="item"
                          value={termsAgreed}
                          onChange={(e) => {
                            setTermsAgreed(e.value);
                            setCanContinue(e.value);
                          }}

                        />  <Button to label="* I've read and accept the terms & conditions. " severity="info" text onClick={() => setTermsAgreed(true)} />
                        <br />
                        <Link to="https://doves.co.zw" target="_blank" rel="  noreferrer" severity="info">
                          <i className="pi pi-external-link"> </i>
                          . Click here to read Terms and conditions
                        </Link>

                      </div>
                        <div className="text-center mt-3">
                          {canContinue && <div className="text-center mt-3">
                            <Button
                              onClick={submitLoanApplication}
                              style={{ backgroundColor: '#084b9b', border: '1px solid #084b9b' }}
                              className="justify-content-center text-center  "
                              size="large"
                              label={<>Continue</>}
                              icon="pi pi-arrow-right"
                              severity="info"
                              rounded
                              raised


                            />
                          </div>}

                        </div>

                      </center>
                    </div>

                  </>
              } </Card>



          </div>


        </center>
      </BlockUI>
      <Dialog header="" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
        <center>
          <div className=" text-center my-4">
            <img width="100px" src={AnimatedSuccessGIF} alt="" />
          </div>

          <h3>Loan Application Submitted Successfully</h3>
          <p>You have successfully submitted your loan application<br />You can track the loan status and progress on the <i>Manage Loans Dashboard</i></p>
          <Button
            onClick={() => {
              setVisible(false)
              history.push("/loans")
            }}

            className='justify-content-center text-center m-2'
            size='large'
            label='Ok'
            icon="pi pi-check-circle"
            severity="success"
            rounded
            raised
            style={{ color: "white" }} // Add this style to set the button text color
          />

        </center>

      </Dialog>
    </div>
  );
};

export default ConfirmationForm;