import React, { useState, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PaynowBanner from "../../../images/paynow/Paynow Badge-vector-hires LIGHT.svg"
import PaynowButton from "../../../images/paynow/button_make-payment_large.svg"
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import paymentImage from "../../../images/patterns/payments-bg.png"
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea'
import AnimatedSuccessGIF from '../../../images/check-green.gif'
import AnimatedWarningGIF from '../../../images/warning.gif'
import { Link, useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Messages } from 'primereact/messages';
import PaymentForm from "./components/PaymentForm"
import { useInterval, useMountEffect } from 'primereact/hooks';
import { DataTable } from 'primereact/datatable';



function MakePayment({ balanceFormatted, policyNumberCurrentUser, phoneNumberCurrentUser }) {

  const [isPaymentDialogVisible, setisPaymentDialogVisible] = useState(false);
  const [isPaymentLoading, setisisPaymentLoading] = useState(false);
  const [isFormValid, setisFormValid] = useState(false);
  const [phoneNumber, setphoneNumber] = useState(phoneNumberCurrentUser);
  const [policyNumber, setpolicyNumber] = useState(policyNumberCurrentUser);
  const [paymentNotes, setpaymentNotes] = useState("");
  const [amount, setAmount] = useState(balanceFormatted);
  const [initialmount, setInitialmount] = useState(balanceFormatted);
  const [paymentMethods, setpaymentMethods] = useState(["Ecocash", "Pay using Paynow"])
  const [paymentMethod, setpaymentMethod] = useState("")
  const history = useHistory()
  const toast = useRef(null);
  const msgs = useRef(null);
  const [processingPaymentDisplay, setprocessingPaymentDisplay] = useState(null);
  const [activeView, setActiveView] = useState(0);
  const toastBC = useRef(null);
  let userProfile = {};
  const [seconds_left, setseconds_left] = useState(30);
  const [serverResponse, setserverResponse] = useState({});
  const setisPaymentDialogVisibleCheck = (value) => {
    if (isPaymentLoading) {
      toast.current.show({ severity: 'warn', summary: 'Processing Payment', detail: 'You cannot close this dialog while your payment is processing', life: 3000 });
    } else {
      setisPaymentDialogVisible(value);
    }

  }
  const submitPaymentRequest = (e) => {

    // Perform your custom form validation logic
    const isFormValid =
      paymentMethod !== "" &&
      phoneNumber !== "" &&
      amount >= balanceFormatted &&
      policyNumber !== ""

      ;
    if (isFormValid) {
      setisisPaymentLoading(true)
      confirm()
    } else {

      setisFormValid(false);
      // msgs.current.show(
      //   { sticky: false, severity: 'warn', detail: 'Please fill in the required fields.'+ '\n\n'+'Make sure your payment amount is equal or greater than your current balance $'+ balanceFormatted, closable: true }
      // );
      toast.current.show({ severity: 'warn', summary: 'Make Payment' + (amount >= balanceFormatted), detail: 'Please fill in the required fields.' + '\n\n' + 'Make sure your payment amount is equal or greater than your current balance $' + balanceFormatted, life: 9000 });

    }
    e.preventDefault();



  }

  const viewList = [
    <PaymentForm
      paymentMethods={paymentMethods}
      paymentMethod={paymentMethod}
      phoneNumber={phoneNumber}
      amount={amount}
      initialmount={initialmount}
      policyNumber={policyNumber}
      paymentNotes={paymentNotes}
      handlePaymentMethodChange={(e) => setpaymentMethod(e)}
      handlePhoneNumberChange={(e) => setphoneNumber(e.target.value)}
      handleAmountChange={(e) => setAmount(e)}
      handlePolicyNumberChange={(e) => setpolicyNumber(e.target.value)}
      handlePaymentNotesChange={(e) => setpaymentNotes(e.target.value)}
      submitPaymentRequest={submitPaymentRequest}
      balanceFormatted={balanceFormatted}
    />,
    <SUBMITTED_REQUEST />,
    <VERIFY_REQUEST />,
    <RECEIVED_FAILURE />,
    <RECEIVED_SUCCESS />,
  ]
  function displayCurrentState(paymentStage, response) {

    // console.log("new disps" + paymentStage)
    switch (paymentStage) {
      case 'SUBMITTED_REQUEST': {
        setserverResponse(response);
        setActiveView(1)
        break;
      }
      case 'VERIFY_REQUEST': {
        setserverResponse(response);
        setActiveView(2)
        // setserverResponse(response);
        break;
      }
      case 'RECEIVED_FAILURE': {
        setActiveView(3)
        break;
      }
      case 'RECEIVED_SUCCESS': {
        setActiveView(4)
        break;
      }
      default: {
        setActiveView(0)
        break;
      }
    }


  }


  useMountEffect(() => {
    userProfile = (JSON.parse(localStorage.getItem('userProfile')));
    displayCurrentState("", null)
  }, []);

  function RECEIVED_FAILURE() {
    return <>
      <center>
        <div className=" text-center my-4">
          <img width="100px" src={AnimatedWarningGIF} alt="" />
        </div>

        <h3>Payment failed to process</h3>
        {serverResponse && <><p><strong>Failure Reason : </strong>{serverResponse.message}</p></>}
        <p>You payment could not be processed</p>
        <Button
          onClick={() => {
            history.push("/")
          }}
          className='justify-content-center text-center m-2'
          size='large'
          label='Ok'
          icon="pi pi-check-circle"
          severity="info"
          rounded
          raised
          style={{ color: "white" }} // Add this style to set the button text color
        />

      </center>
    </>;
  }

  function RECEIVED_SUCCESS() {
    return <>
      <center>

        <div className=" text-center my-4">
          <img width="100px" src={AnimatedSuccessGIF} alt="" />
        </div>

        <h3>Payment was processed Successfully</h3>
        {serverResponse && <><p>Your payment reference is : <strong>{serverResponse.responseBody.paymentReference}</strong></p></>}
        <p>You have successfully made a payment to your policy</p>
        <div className='row'>
          <div className='col-xxl-6 col-xl-6 col-lg-12'>
            <Button
              onClick={() => {
                setisisPaymentLoading(false)
                history.push("/")
              }}
              className='justify-content-center text-center m-2'
              size='large'
              label='Ok, Close'
              icon="pi pi-check-circle"
              severity="success"
              rounded
              raised
              style={{ color: "white" }}
            />
          </div>
          <div className='col-xxl-4 col-xl-3 col-lg-12'>
            <Button
              onClick={() => {
                setisisPaymentLoading(false)
                exportPdf();
              }}
              className='justify-content-center text-center m-2'
              size='large'
              label='Download POP'
              icon="pi pi-download"
              severity="success"
              rounded
              outlined
            /></div>
        </div>

      </center>
    </>;
  }

  function SUBMITTED_REQUEST() {

    return <>
      <center>
        <h3>You will receive a prompt to input your security pin on your phone</h3>
        <p>Kindly authorise your {paymentMethod} payment on your phone number.</p>
        {serverResponse && <><p>Your payment reference is : <strong>{serverResponse.responseBody.paymentReference}</strong></p></>}
        {serverResponse && <><p><strong>Your payment poll verification link : </strong><Link to={serverResponse.responseBody.pollUrl}>{serverResponse.responseBody.pollUrl}</Link></p></>}
        {serverResponse && <><p>{serverResponse.responseBody.paymentInstructions}</p></>}
        <div className=" text-center my-4">
          <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
          <h5>will automatically verify in ({seconds_left})second</h5>
        </div>

      </center>
    </>;
  }

  function VERIFY_REQUEST() {
    return <>
      <center>
        <h3>Confirm payment status</h3>
        <p>We are verifying your payment.</p>
        <div className=" text-center my-4">
          <h5>Your payment has not been verified</h5>
          {serverResponse && <><p>Your payment reference is : <strong>{serverResponse.responseBody.paymentReference}</strong></p></>}
          {serverResponse && <><p><strong>Your payment poll verification link : </strong><Link to={serverResponse.responseBody.pollUrl}>{serverResponse.responseBody.pollUrl}</Link></p></>}
          {serverResponse && <><p>{serverResponse.responseBody.paymentInstructions}</p></>}
        </div>
        <div className='row'>
          <div className='justify-content-center'>

            <Button
              onClick={() => {
                handleCloseDialog(serverResponse);
              }}
              className='justify-content-center text-center m-2'
              size='large'
              label='Cancel Payment'
              icon="pi pi-times"
              severity="primary"
              rounded
              outlined
              raised
            // Add this style to set the button text color
            />
            <Button
              onClick={() => {
                // setisisPaymentLoading(false)
                // history.push("/")
                // displayCurrentState("RECEIVED_SUCCESS", null)
                verifyPayment(serverResponse);
              }}
              className='justify-content-center text-center m-2'
              size='large'
              label='Retry Verification'
              icon="pi pi-refresh"
              severity="info"
              rounded
              outlined
              raised
            // Add this style to set the button text color
            />
            {/* <Button
              onClick={() => {
                setisisPaymentLoading(false)
                window.location.reload();
              }}
              className='justify-content-center text-center m-2'
              size='large'
              label='Cancel Payment'
              icon="pi pi-times"
              severity="info"
              rounded
              outlined
              raised
            Add this style to set the button text color
            /> */}
          </div>
        </div>

      </center>
    </>;
  }




  const submitPaymentRequestToServer = async () => {

    try {
      userProfile = (JSON.parse(localStorage.getItem('userProfile')));
      const payload = {
        "operation": "make-payment",
        "requestBody": {
          "policyNumber": policyNumber,
          "productName": "payment for policy number " + policyNumber,
          "productID": userProfile.userId,
          "notes": "payment for policy number " + policyNumber,
          "userId": userProfile.userId,
          "paymentMethod": paymentMethod.toLowerCase(),
          "mobileNumber": phoneNumber,
          "payerName": userProfile.firstName + " " + userProfile.lastName,
          "billingAddress": userProfile.firstName + " " + userProfile.lastName,
          "emailAddress": userProfile.emailAddress,
          "amount": amount

        }
      }

      console.log("!!!!!!!!!!!!!!!!!!!!!")
      // console.log(payload);
      // console.log(userProfile);

      await axios.post(process.env.REACT_APP_DOVESAPI + 'payment/ecocash', payload,{
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {
        if (response.data.status === "SUCCESS") {
          console.log("!!!!!!!!!!!!!!!!!!!!!")
          console.log(response);

          setisisPaymentLoading(true);
          setisStartdownActive(true); // Start the countdown
          // startCountDown(15);
          startCountDown(30, true, setseconds_left, response.data);
          displayCurrentState("SUBMITTED_REQUEST", response.data);
          // console.log("--------------------------PPP____________")
        } else {
          setisisPaymentLoading(true)
          displayCurrentState("RECEIVED_FAILURE", response.data)
          console.log(displayCurrentState)
          console.log("CHANGED THE DISPLAY")
        }
      });

    } catch (error) {
      displayCurrentState("RECEIVED_FAILURE", error)
      console.error('Error fetching balance details:', error);
    }
  };

  const handleCloseDialog = () => {

    setisPaymentDialogVisible(false);
    setActiveView(0)
  }

  const verifyPayment = async (responseData) => {

    try {
      console.log("POLLING TRANSACTION " + responseData.responseBody.paymentReference);
      await axios.get(process.env.REACT_APP_DOVESAPI + 'payment/verifyPayment/' + responseData.responseBody.paymentReference, {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {
        if (response.data.status === "SUCCESS") {
          console.log("verificatrion response")
          console.log(response.data.responseBody)
          switch (response.data.responseBody.paymentStatus) {
            case 'AWAITING DELIVERY':
            case 'PAID':
            case 'DELIVERED': {
              console.log(response);
              setisisPaymentLoading(false)
              displayCurrentState("RECEIVED_SUCCESS", response.data.responseBody)
              break;
            }
            default: {
              setisisPaymentLoading(false)
              setserverResponse(response.data);
              displayCurrentState("VERIFY_REQUEST", response.data)
              // displayCurrentState("RECEIVED_FAILURE", response.data)
              console.log(displayCurrentState)
              console.log("CHANGED THE DISPLAY")
              break;
            }
          }
        } else {
          setisisPaymentLoading(false)
          displayCurrentState("VERIFY_REQUEST", null)
          // displayCurrentState("RECEIVED_FAILURE", response.data)
          console.log(displayCurrentState)
          console.log("CHANGED THE DISPLAY")
        }
      });

    } catch (error) {
      displayCurrentState("RECEIVED_FAILURE", error)
      console.error('Error fetching balance details:', error);
    }
  };
  const [isStartdownActive, setisStartdownActive] = useState(false);


  function startCountDown(seconds, isStartdownActive, setseconds_left, responseData) {
    console.log("Starting countdown..." + responseData);

    const interval = setInterval(() => {
      // setseconds_left((prevSecond) => (prevSecond === seconds ? 0 : prevSecond - 1)); 
      if (seconds > 0 && isStartdownActive) {
        console.log("Countdown:", seconds);
        seconds--; // Decrement the seconds
        setseconds_left(seconds);
      } else if (seconds === 0) {
        verifyPayment(responseData);
        console.log("Countdown finished.");
        clearInterval(interval); // Stop the countdown
        // Perform actions when the countdown is finished.
      }
    }, 1000);
  }

  const cols = [
    { field: 'payerName ', header: 'Payer Name' },
    { field: 'policyNumber ', header: 'Policy Number' },
    { field: 'mobileNumber', header: 'Mobile Number' },
    { field: 'paymentMethod', header: 'paymentMethod' },
    { field: 'paymentReference', header: 'Payment Reference' },
    { field: 'amount', header: 'Amount' },
    { field: 'notes', header: 'Payment Notes' }
  ];

  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        let responseBody = [
          {
            payerName: serverResponse.responseBody.payerName,
            policyNumber: serverResponse.responseBody.policyNumber,
            mobileNumber: serverResponse.responseBody.mobileNumber,
            paymentMethod: serverResponse.responseBody.paymentMethod,
            paymentReference: serverResponse.responseBody.paymentReference,
            amount: serverResponse.responseBody.amount,
            notes: serverResponse.responseBody.notes
          },
        ];

        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, responseBody);
        doc.save('paymentreceipt.pdf');
      });
    });
  };



  const confirm = () => {
    toastBC.current.show({
      severity: 'info',
      sticky: true,
      className: 'border-none',
      content: (
        <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
          <div className="text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <div className="font-bold text-xl my-3">Confirm your application details?</div>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={(e) => { toastBC.current.clear(); submitPaymentRequestToServer() }}
              className='justify-content-center text-center m-2'
              size='large'
              label='Confirm'
              icon="pi pi-check-circle"
              severity="success"
              rounded
              raised
              style={{ color: "white" }} // Add this style to set the button text color
            />
            <Button
              onClick={(e) => { toastBC.current.clear(); }}
              className='justify-content-center text-center m-2'
              size='large'
              label='Cancel'
              icon="pi pi-times-circle"
              severity="warning"
              rounded
              raised
              style={{ color: "white" }} // Add this style to set the button text color
            />
          </div>
        </div>
      )
    });
  };
  return (
    <>
    <Card className='mb-5 mt-3 ' header={(<img src={paymentImage} style={{ width: '100%' }} />)}>
      <div className="invite-content">
        <h3>Make Payments</h3>
        <p>Make payments to your policy and stay update</p>
        <p>All Payments in USD</p>

        <Button text outlined className="bg-bluegray-600 hover:bg-bluegray-400 border-bluegray-700"
          onClick={() => setisPaymentDialogVisible(true)}>
          <img alt="logo" src={PaynowButton} className="h-3rem"></img>
        </Button>
        <div className='mb-2' style={{ display: 'flex', alignItems: 'flex-start' }}>
          {/* SVG image with CSS styles for size and alignment */}
          <img
            src={PaynowBanner}
            alt="PayNow Banner"
            style={{ maxWidth: '50%', height: 'auto', alignSelf: 'flex-start' }}
          />
        </div>
      </div>
    </Card>

      <Dialog header="Make Payment " visible={isPaymentDialogVisible} maximizable style={{ width: '50vw',height: '40vw' }} onHide={() => setisPaymentDialogVisibleCheck(false)}>
        <Toast ref={toast} />
        <Toast ref={toastBC} position="center" />
        <Messages ref={msgs} />
        {React.cloneElement(viewList[activeView])}
      </Dialog>
    </>
  );
}

export default MakePayment;
