import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Header from '../../layout/headers/header'
import CustomerNavMenu from "../../layout/navigation/topbar/CustomerNavMenu"
import DynamicDashboard from "../../layout/dashboard/dynamic-dashboard"
import DynamicNavigation from "../../layout/navigation/dynamic-navigation"
import ProductCarousel from "../../components/products_services/products_carousel_min";
import TrackPolicyApplication from "../../components/policy/track_policy_application_progress"
import MakePayment from '../../components/makePayment/makePayment';
import CreateNewPolicyPage from "../create_policy/createNewPolicyPage"
import { Card } from 'primereact/card';
import axios from 'axios';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import localBGImage from "../../../images/patterns/applyPolicy.png"
import { Link, useHistory } from 'react-router-dom';
import DisplayPolicyDetailComponent from "./displayPolicy/displayPolicyDetailComponent"
import { ScrollPanel } from 'primereact/scrollpanel';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import { useMountEffect } from 'primereact/hooks';
import { Dialog } from 'primereact/dialog';
import localBGImageBeneficiaries from "../../../images/patterns/beneficiaries-bg.png"
import { BlockUI } from 'primereact/blockui';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import BeneficiaryMembersJS from "./../create_policy/components/policyForm/BeneficiaryMembersJS";
import AddBeneficiaryMembers from "./../create_policy/components/policyForm/AddBeneficiaryMembers.jsx";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { SelectButton } from "primereact/selectbutton";

const ManagePolicy = () => {

    const [balanceDetails, setBalanceDetails] = useState(null);
    const [policyDisplay, setpolicyDisplay] = useState(null);
    const [selectedPolicyOBJ, setSelectedPolicyOBJ] = useState(null);
    const [selectedDisplayPolicyDetails, setselectedDisplayPolicyDetails] = useState(null);
    const [selectedDisplayPolicyComponent, setselectedDisplayPolicyComponent] = useState(null);

    const [loading, setLoading] = useState(true);
    const [userPolicyInput, setUserPolicyInput] = useState(true);


    const [loadingFullPolicy, setloadingFullPolicy] = useState(false);
    const history = useHistory()
    // const [policyList, setPolicyList] = useState([]);
    const [policyValue, setpolicyValue] = useState(null);
    const [policyDisplayKey, setpolicyDisplayKey] = useState(1);
    let userProfile = {};
    const [newBenVisible, setNewBenVisible] = useState(false);
    function parseTrackApplicationResponse(apiResponse, policyDisplayKey) {
        setLoading(false)
        // console.log(apiResponse);
        if (apiResponse && apiResponse.responseBody && !(Array.isArray(apiResponse.responseBody)) && apiResponse.responseBody.policyApplicationRequestId) {
            console.log("---- Yes");
            return <NewApplicationPolicyDisplay applicationDetail={apiResponse.responseBody} />;

        } else if (apiResponse && apiResponse.responseBody && (Array.isArray(apiResponse.responseBody)) && apiResponse.responseBody.length > 0) {
            console.log("---- NO");
            return <EasipolPolicyDisplay key={policyDisplayKey} policyList={apiResponse.responseBody} policyDisplayKey={policyDisplayKey} apiResponse={apiResponse} />;

        } else {
            console.log("---- MAYbe");

            return <NewApplicationPolicyDisplay applicationDetail={apiResponse.responseBody} />;
        }
    }



    useEffect(() => {
        // fetchActiveProducts()
        console.log("policyDisplayKey updated:", policyDisplayKey);
    }, [policyDisplayKey]);

    function updateSelectedPolicy(policyValue, policyList, apiResponse) {
        let policyDisplayKey = policyList.findIndex(item => item.MainMember.Policy_Number === policyValue.MainMember.Policy_Number);
        console.log(policyDisplayKey)
        console.log(policyList[policyDisplayKey])
        setpolicyDisplayKey(policyList.findIndex(item => item.MainMember.Policy_Number === policyValue.MainMember.Policy_Number));
        setpolicyDisplay(parseTrackApplicationResponse(apiResponse, policyDisplayKey));

        setpolicyValue(policyList[policyDisplayKey])
        // setpolicyDisplay(parseTrackApplicationResponse(response.data))
    }

    /*

        NEW BENEFICIARY 

    */

    function EasipolPolicyDisplay({ policyList, policyDisplayKey, apiResponse }) {
        return (<>
            {policyList ?
                <> <div className="row">
                    <div className="col-xxl-5 col-xl-5 col-lg-12">
                        <Card className='' header={(<img src={localBGImage} alt="" style={{ width: '100%' }} />)}>
                            <h2 className='text-left mt-1 mb-2'><strong>Your Policy Details</strong></h2>
                            <Divider type='dotted' />

                            <div className="card flex justify-content-center">
                                <Dropdown required
                                    value={policyList[policyDisplayKey]}
                                    onChange={(e) => updateSelectedPolicy(e.value, policyList, apiResponse)}
                                    options={policyList} optionLabel="MainMember.Policy_Number"
                                    className="w-full md:w-14rem" />
                            </div>
                            <Divider type='dotted' />
                            {policyList[policyDisplayKey].MainMember.Status === 0 ? (<Message severity="warn" text="Your policy status is INACTIVE, you need to make a subscription to enable it" style={{ width: '100%' }} />) : policyList[policyDisplayKey].MainMember.Status === 1 ? null : policyList[policyDisplayKey].MainMember.Status === 2 ? <Message severity="warn" text="Your policy has lapsed , you need to make a subscription to enable it" style={{ width: '100%' }} /> : <Message severity="warn" text="Your policy status is ARCHIVED, please call our customer service for guidance" style={{ width: '100%' }} />}
                            <Divider type='dotted' />
                            {loadingFullPolicy ? (
                                <div className="border-round border-1 surface-border p-4 surface-card">
                                    <div className="flex mb-3">
                                        <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                                        <div>
                                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                                            <Skeleton height=".5rem"></Skeleton>
                                        </div>
                                    </div>
                                </div>) :
                                <div className="col-xxl-12 col-xl-12 col-lg-12">
                                    {/* THE SELECTED POLICY DETAILS TO BE DISPLAYED BELOW */}
                                    {policyList ?
                                        <><ul className='mt-1'>
                                            <li className='row'>
                                                <div className='col-md-6'>
                                                    <h4>Principal Member</h4>
                                                </div>
                                                <div className="text-right col-md-6">
                                                    <h5>{policyList[policyDisplayKey].MainMember.Title} {policyList[policyDisplayKey].MainMember.First_Name} {policyList[policyDisplayKey].MainMember.Surname}</h5>
                                                </div>
                                            </li>
                                            <br></br>
                                            <li className='row'>
                                                <div className='col-md-6'>
                                                    <h4>Monthly Premium</h4>
                                                </div>
                                                <div className="text-right col-md-6">
                                                    <h5>${policyList[policyDisplayKey].MainMember.UsualPremium}</h5>
                                                </div>
                                            </li>
                                            <br></br>
                                            <li className='row'>
                                                <div className='col-md-6'>
                                                    <h4>Policy Number</h4>
                                                </div>
                                                <div className="text-right col-md-6">
                                                    <h5>{policyList[policyDisplayKey].MainMember.Policy_Number}</h5>
                                                    {/* <span>Your policy Number.</span> */}
                                                </div>
                                            </li>
                                            <br></br>
                                            <li className='row'>
                                                <div className='col-md-6'>
                                                    <h4>Policy Status</h4>
                                                </div>
                                                {/* <div className="text-right col-md-6">
                                                <h5>{policyList[policyDisplayKey].MainMember.Status === 0 ? "INACTIVE" : policyList[policyDisplayKey].MainMember.Status === 1 ? "ACTIVE" : policyList[policyDisplayKey].MainMember.Status === 2 ? "LAPSED" : "ARCHIVED"}</h5>
                                                <span>Your policy Number.</span>
                                            </div> */}
                                                <div className="text-right col-md-6">
                                                    <Button
                                                        raised
                                                        size='small'
                                                        label={policyList[policyDisplayKey].MainMember.Status === 0 ? "INACTIVE" : policyList[policyDisplayKey].MainMember.Status === 1 ? "ACTIVE" : policyList[policyDisplayKey].MainMember.Status === 2 ? "LAPSED" : "ARCHIVED"}
                                                        severity={policyList[policyDisplayKey].MainMember.Status === 0 ? "warning" : policyList[policyDisplayKey].MainMember.Status === 1 ? "success" : policyList[policyDisplayKey].MainMember.Status === 2 ? "warning" : "danger"} />
                                                </div>
                                            </li>
                                            <br></br>
                                            <li className='row'>
                                                <div className='col-md-6'>
                                                    <h4>Policy Product</h4>
                                                </div>
                                                <div className="text-right col-md-6">
                                                    <h5>{policyList[policyDisplayKey].MainMember.Product_Name}</h5>
                                                </div>
                                            </li>
                                            <br></br>
                                            <li className='row'>
                                                <div className='col-md-6'>
                                                    <h4>Address</h4>
                                                </div>
                                                <div className="text-right col-md-6">
                                                    <h5>{policyList[policyDisplayKey].MainMember.Address}</h5>
                                                    <span>{policyList[policyDisplayKey].MainMember.Region}</span>
                                                </div>
                                            </li>
                                            <br></br>
                                        </ul>
                                        </> :

                                        <><h5>Select Policy to display</h5></>
                                    }
                                    {/* <EasipolPolicyDisplayComponent selectedPolicy={selectedPolicyOBJ} /> */}
                                </div>

                            }
                        </Card>
                        <div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-12">
                        {policyList[policyDisplayKey] ?
                            <Card className='' header={(<img src={localBGImageBeneficiaries} style={{ width: '100%' }} />)}>
                                <div className="invite-content">
                                    <h2 className='text-left mt-1 mb-2'><strong>Your Beneficiaries</strong></h2>
                                    <Divider type='dotted' />
                                    <Accordion activeIndex={0}>
                                        <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Principal Member Details </h4>}>
                                            <DataTable value={[policyList[policyDisplayKey].MainMember]} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field='ID_Number' header="ID Number"></Column>
                                                <Column field='Title' header="Title"></Column>
                                                <Column field='First_Name' header="First Name"></Column>
                                                <Column field='Surname' header="Last Name"></Column>
                                                <Column field='Date_Of_Birth' header="Date Of Birth"></Column>
                                                <Column field='Gender' header="Gender"></Column>
                                                <Column field='Marital_Status' header="Marital Status"></Column>
                                                {/* <Column field='Address' header="Gender"></Column> */}
                                                <Column field='Cell_Number' header="Cell Number"></Column>
                                            </DataTable>
                                        </AccordionTab>
                                        <Divider type="dotted" />
                                        <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Beneficiary Details </h4>}>
                                            {policyList[policyDisplayKey].Spouse && policyList[policyDisplayKey].Spouse.length !== 0 ? (<>
                                                <h4 className='mt-1 mb-2 --blue text-left'>
                                                    <strong>Spouse Beneficiary Details</strong> :
                                                </h4>
                                                <Divider type="dotted" />
                                                <DataTable value={[policyList[policyDisplayKey].Spouse]} tableStyle={{ minWidth: '50rem' }}>
                                                    <Column field='Member_ID' header="Member_ID"></Column>
                                                    <Column field='ID_Num' header="ID Number"></Column>
                                                    <Column field='Name' header="First Name"></Column>
                                                    <Column field='Surname' header="Last Name"></Column>
                                                    <Column field='Date_of_Birth' header="Date Of Birth"></Column>
                                                    <Column field='Gender' header="Gender"></Column>
                                                    <Column field='Telephone' header="Phone Number"></Column>
                                                </DataTable></>) :
                                                <><small>No Spouse Beneficiary Details</small><Divider type="dotted" />
                                                </>}

                                            {policyList[policyDisplayKey].Children && policyList[policyDisplayKey].Children.length !== 0 ?
                                                <><h4 className='mt-1 mb-2 --blue text-left'>  <strong>Children Beneficiary Details</strong>  : </h4>
                                                    {policyList[policyDisplayKey].Children.map((beneficiary) => (
                                                        <><Divider type="dotted" />
                                                            <DataTable value={[beneficiary]} tableStyle={{ minWidth: '50rem' }}>
                                                                <Column field='ID_num' header="ID Number"></Column>
                                                                <Column field='Name' header="First Name"></Column>
                                                                <Column field='Surname' header="Last Name"></Column>
                                                                <Column field='Date_of_birth' header="Date Of Birth"></Column>
                                                                <Column field='Gender' header="Gender"></Column>
                                                                <Column field='AtVarsity' header="Is Tertiary Student"></Column>
                                                            </DataTable></>

                                                    ))}
                                                </>
                                                : <><small>No Child Beneficiary Details</small><Divider type="dotted" /></>}

                                            {policyList[policyDisplayKey].ExtendedFamiy && policyList[policyDisplayKey].ExtendedFamiy.length !== 0 ?
                                                <> <h4 className='mt-1 mb-2 --blue text-left'> <strong>Extended Family Beneficiary Details</strong>  : </h4>
                                                    {policyList[policyDisplayKey].ExtendedFamiy.map((beneficiary) => (
                                                        < >
                                                            <Divider type="dotted" />
                                                            <DataTable value={[beneficiary]} tableStyle={{ minWidth: '50rem' }}>
                                                                <Column field='ID_num' header="ID Number"></Column>
                                                                <Column field='Name' header="First Name"></Column>
                                                                <Column field='Surname' header="Last Name"></Column>
                                                                <Column field='Date_of_birth' header="Date Of Birth"></Column>
                                                                <Column field='Gender' header="Gender"></Column>
                                                                <Column field='Relation' header="Relationship"></Column>
                                                            </DataTable>
                                                        </>
                                                    ))}

                                                </>
                                                : <><small>No Extended Family Beneficiary Details</small><Divider type="dotted" /></>}

                                            {policyList[policyDisplayKey].memberBeneficiary && policyList[policyDisplayKey].memberBeneficiary.length !== 0 ?
                                                <> <h4 className='mt-1 mb-2 --blue text-left'> <strong>Other Beneficiary Details</strong> : </h4>
                                                    {policyList[policyDisplayKey].memberBeneficiary.map((beneficiary) => (
                                                        <>
                                                            <Divider type="dotted" />
                                                            <DataTable value={[policyList[policyDisplayKey].memberBeneficiary]} tableStyle={{ minWidth: '50rem' }}>
                                                                <Column field='IdNumber' header="ID Number"></Column>
                                                                <Column field='Title' header="Title"></Column>
                                                                <Column field='FirstName' header="First Name"></Column>
                                                                <Column field='Surname' header="Last Name"></Column>
                                                                <Column field='DateOfBirth' header="Date Of Birth"></Column>
                                                                <Column field='Gender' header="Gender"></Column>
                                                                <Column field='Relationship' header="Relationship to Principal"></Column>
                                                                <Column field='Telephone' header="Telephone"></Column>
                                                            </DataTable>
                                                        </>
                                                    ))}

                                                </>
                                                : <><small>No Other Beneficiary Details</small><Divider type="dotted" /></>}

                                            <center>
                                                <ConfirmPopup />
                                                <div
                                                    style={{
                                                        border: '2px dotted #ccc',
                                                        borderRadius: '15px',
                                                        padding: '1em',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        maxHeight: "2%",
                                                        minHeight: "1em",


                                                    }}
                                                    className="m-1 col-xxl-6 col-xl-6 col-lg-6 "
                                                >
                                                    {/* <center>
                                                        <div className='row'>
                                                            <div className="  col-xxl-12 col-xl-12 col-lg-12" >
                                                                <Button
                                                                    onClick={() => { setNewBenVisible(true) }}
                                                                    style={{ backgroundColor: '#084b9b', border: '1px solid #084b9b' }}
                                                                    className="justify-content-center text-center  "
                                                                    size="large"
                                                                    label={<>Add New Beneficiary</>}
                                                                    icon="pi pi-user-plus"
                                                                    severity="info"
                                                                    rounded
                                                                    raised


                                                                />
                                                            </div>
                                                            <div>
                                                                <Divider />
                                                                <p><small>Add your spouse, children and relative beneficiaries to your existing policy.</small></p>

                                                            </div>
                                                        </div>
                                                    </center> */}
                                                </div>

                                            </center>
                                        </AccordionTab>
                                        <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Banking Details </h4>}>
                                            {policyList[policyDisplayKey].BankDetail && policyList[policyDisplayKey].BankDetail.length !== 0 ? (
                                                <>
                                                    {policyList[policyDisplayKey].BankDetail.map((bankDetail) => (
                                                        <DataTable value={[bankDetail]} tableStyle={{ minWidth: '50rem' }}>
                                                            {/* <Column field='BankName' header="Bank Name"></Column>
                                                            <Column field='BranchName' header="Branch Name"></Column> */}
                                                            <Column field='Account_Name' header="Account Name"></Column>
                                                            <Column field='Account_Number' header="Account Number"></Column>
                                                            <Column field='Type_of_Account' header="Type of Account"></Column>
                                                        </DataTable>
                                                    ))}
                                                </>) : <></>}

                                        </AccordionTab>
                                    </Accordion>
                                    <Divider type='dotted' />


                                </div>
                            </Card>
                            : <></>}
                    </div>

                </div></> : <h5>Select policy to display</h5>}
        </>
        );
    }

    function NewApplicationPolicyDisplay(existingApplicationProgress) {
        console.log("UPDATE  APPLICATION ");
        console.log(existingApplicationProgress);
        return (
            <>
                <CreateNewPolicyPage className="mt-2" existingApplicationProgress={existingApplicationProgress} /></>
        );
    }

    const getPolicyDetails = async () => {
        setLoading(true)
        try {
            userProfile = (JSON.parse(localStorage.getItem('userProfile')));

            await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/track/' + userProfile.idnumber, {
                headers: {
                    Authorization: 'Bearer ' + (localStorage.getItem('token'))
                }
            }).then((response) => {

                if (response.data.status === "SUCCESS") {
                    // console.log(response);

                    if (response.data.responseBody === null) {
                    } else {
                        setBalanceDetails(response.data.responseBody);
                    }

                } else {
                    // console.log(response.data.status)
                }
                // console.log(userProfile.idnumber);
                // console.log(response.data);
                setLoading(false)
                setpolicyDisplay(parseTrackApplicationResponse(response.data, 0))
            });

        } catch (error) {
            setLoading(false)
            console.error('Error fetching balance details:', error);
        }
    };

    useMountEffect(() => {
        getPolicyDetails();
    }, []);

    return (
        <>
            <Header />
            <DynamicNavigation />
            <div className="content-body">
                <div className="container">
                    <div className="row">
                        {loading ? (
                            <div className="border-round border-1 surface-border p-4 surface-card">
                                <div className="flex mb-3">
                                    <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                                    <div>
                                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                                        <Skeleton height=".5rem"></Skeleton>
                                    </div>
                                </div>
                                <Skeleton width="100%" height="150px"></Skeleton>
                                <div className="flex justify-content-between mt-3">
                                    <Skeleton width="4rem" height="2rem"></Skeleton>
                                    <Skeleton width="4rem" height="2rem"></Skeleton>
                                </div>
                            </div>) :
                            <div className="col-xxl-12 col-xl-12 col-lg-12">
                                {policyDisplay && policyDisplayKey ? policyDisplay : <>{policyDisplay}</>}
                            </div>}
                    </div>
                </div>
            </div>
            <Dialog header="Add New Beneficiary" visible={newBenVisible} style={{ width: '20vw' }} onHide={() => { setNewBenVisible(false) }}>
                <AddBeneficiaryMembers policyDisplay={policyDisplay} />
            </Dialog>
        </>
    );

};

export default ManagePolicy;