import React, { useState, useRef } from 'react';
// src/jsx/layout/dynamic-sidebar.jsx
// src/jsx/components/admin/roles-management/ViewRoles.jsx
import Sidebar from '../../../layout/navigation/sidebar/dynamic-sidebar';
import Header from '../../../layout/headers/header';
import BankingDetailsTable from '../../tables/BankingDetailsTable.jsx';


const UserRoles = ()=>{


    return (
        <>
        <Header />
        <Sidebar />
        <div className="content-body">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12">
                        <BankingDetailsTable />
                    </div>
                </div>
            </div>
        </div> 
        </>
    )
}

export default UserRoles;