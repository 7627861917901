import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PolicyBalance from '../../components/paymentsHistory/getPolicyDisplay'
import PolicyPaymentHistory from '../../components/paymentsHistory/PolicyPaymentHistoryTable_'
import profile2 from '../../../images/profile/2.png'
import DonutChart from '../../components/charts/donut'
import qrImg from '../../../images/qr.svg'
import Sidebar from '../navigation/sidebar/dynamic-sidebar';
import { Divider } from 'primereact/divider';
import { useMountEffect } from 'primereact/hooks';
import { Skeleton } from 'primereact/skeleton';


const EmployeeDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState([]);
    let userProfile = {};

    useMountEffect(() => {
        userProfile = (JSON.parse(localStorage.getItem('userProfile')));
        fetch(process.env.REACT_APP_DOVESAPI + 'report/dashboard', {
            headers: {
              Authorization: 'Bearer ' + (localStorage.getItem('token'))
            }
          })
            .then(response => response.json())
            .then(data => {
                setLoading(false)
                if (data.status === 'SUCCESS') {
                    setDashboardData(data.responseBody);
                } else {
                    console.error('Failed to fetch policy history:', data.message);
                }
            })
            .catch(error => {
                setLoading(false)
                console.error('Error fetching policy history:', error);
            });
    }, []);


    return (
        <>
            {loading ? (
                <div className="border-round border-1 surface-border p-4 surface-card">
                    <div className="flex mb-3">
                        <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                        <div>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton height=".5rem"></Skeleton>
                        </div>
                    </div>
                    <Skeleton width="100%" height="150px"></Skeleton>
                    <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                    </div>
                </div>) : <>
                <div className="content-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="wallet-widget card">
                                    <h5>Total Applications</h5>
                                    <h2><span className="text-primary">{dashboardData.applicationTotalRequests}</span> <sub></sub></h2>
                                    <p>Lifetime</p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="wallet-widget card">
                                    <h5>Completed Applications</h5>
                                    <h2><span className="text-success">{dashboardData.getCompletedApplicationsTotal}</span> <sub></sub></h2>
                                    <p>Lifetime</p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="wallet-widget card">
                                    <h5>Incomplete Applications</h5>
                                    <h2><span className="text-danger">{dashboardData.getIncompleteApplicationsTotal}</span> <sub></sub></h2>
                                    <p>Lifetime</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xxl-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Policy Applications (Grouped By Product Category)</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th>Product Name</th>
                                                        <th>Applications</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dashboardData.getApplicationsByProductCategory && dashboardData.getApplicationsByProductCategory.map((data, index) => (

                                                        <tr>
                                                            <td className="coin-name">
                                                                <i className="icofont-add-users"></i>
                                                                <span>{data.product}</span>
                                                            </td>
                                                            <td className="success-arrow">
                                                                <i className="icofont-arrow-up ml-2"></i>
                                                                <strong>{data.count}</strong>

                                                            </td>
                                                        </tr>

                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div className="col-xxl-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Policy applications (Grouped By Progress / Status)</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                                <div className="balance-chart">
                                                {dashboardData.getApplicationsByStatus ? <DonutChart getApplicationsByStatus={dashboardData.getApplicationsByStatus} />: <></> }
                                                    {/* <h5>Total Applications : {dashboardData.applicationTotalRequests}</h5> */}
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">

                                                <ul className="balance-widget">
                                                    {dashboardData.getApplicationsByStatus && dashboardData.getApplicationsByStatus.map((data, index) => (

                                                        <li>
                                                            <div className="icon-title">
                                                                <span>{data.status}</span>
                                                            </div>
                                                            <div className="text-right">
                                                                <h5>{data.count}</h5>
                                                            </div>
                                                        </li>

                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></>}
        </>
    );
};

export default EmployeeDashboard;