
import React, { useState, useRef } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import logo from '../../../../images/logo.png'

import thumb from '../../../../images/profile/2.png'
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { useMountEffect } from 'primereact/hooks';

export default function CustomerMenu({ activeItem }) {
    const [activeIndex, setActiveIndex] = useState(activeItem);
    const items = [
        { label: <strong>Home</strong>, icon: 'pi pi-fw pi-home', url: "/"},
        { label:  <strong>Manage Policy</strong>, icon: 'pi pi-fw pi-inbox', url: "/manage-policy" },
        { label:  <strong>Manage Payments</strong>, icon: 'pi pi-fw pi-credit-card', url: "/payments" },
        { label:  <strong>Manage Loans</strong>, icon: 'pi pi-fw pi-wallet', url: "/loans" },
        // { label:  <strong>Terms and Conditions</strong>, icon: 'pi pi-fw pi-question-circle', url: "/termsandconditions" },
     
        // { label:  <strong>Notifications</strong> , icon: 'pi pi-fw pi-file', url: "/news" },
        // { label: 'Profile', icon: 'pi pi-fw pi-id-card' ,url:"/manage-policy"} termsandconditions
    ];

    const [userType, setuserType] = useState([]);
    const [fullName, setfullName] = useState([]);
    const [firstName, setfirstName] = useState([]);
    const [initialsName, setinitialsName] = useState([]);
    const [lastName, setlastName] = useState([]);
    const [userId, setuserId] = useState([]);
    useMountEffect(() => {
        setActiveIndex(activeItem)
        const fetchUserRole = async () => {
            try {
                setuserType((localStorage.getItem('userType')));
                setinitialsName(localStorage.getItem('firstName').charAt(1) + "" + (localStorage.getItem('lastName').charAt(1)));
                var fullName = localStorage.getItem('firstName').toString() + " " + localStorage.getItem('lastName').toString();
                setfullName(fullName);
                setfirstName((localStorage.getItem('firstName')));
                setlastName((localStorage.getItem('lastName')));
                setuserId((localStorage.getItem('userId')));

            } catch (error) {
                console.error('Error fetching user userRole:', error);
            }
        };
        fetchUserRole();
    });



    const menuRight = useRef(null);
    const toast = useRef(null);
    const itemsProfileMenu = [
        // {
        //     label:fullName ,
        // },

        {
            label: 'Manage Profile',
            items: [

                // {
                //     label: 'Edit Profile',
                //     icon: 'pi pi-user',
                //     command: () => {
                //         toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                //     },

                // },
                {
                    label: 'Change Password',
                    icon: 'pi pi-lock',
                    command: () => {
                        // toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                    }
                }
            ]
        },
        { separator: true },
        {
            label: <strong>Logout</strong>,
            icon: 'pi pi-sign-out',
            url: '/signin'
        }
    ];
    const start = <img alt="logo" src={logo} height="70" className="mr-2"></img>;
    const end = <div className="flex-auto">
        <Menu model={itemsProfileMenu} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
        <Avatar onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup label={initialsName} size="large" shape="circle" className="p-overlay-badge" style={{ backgroundColor: '#084b9b', color: '#ffffff' }}>
            {/* <Badge value="0" /> */}
        </Avatar></div>;
    return (
        <div className="header">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div  style={{   justifyContent: 'center' }}>
                            <div className='mb '>
                                <Menubar model={items} start={start} end={end} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
