import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import AddNewProducts from '../products_services/add-new-product.jsx';
import ViewProduct from '../products_services/view-product.jsx';
import EditProduct from '../products_services/edit-product.jsx';
import { useMountEffect } from 'primereact/hooks';
import axios from 'axios';


export default function ProductList() {
  const [visible, setVisible] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [productsAvailable, setActiveProducts] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [termAndConditions, setTermAndConditions] = useState('')
  const [groupImageBannerUrl, setGroupImageBannerUrl] = useState('')
  const renderActions = (rowData) => {
    return (
      <React.Fragment>
        <Button
          text raised
          size="small"
          label='View'
          icon="pi pi-eye"
          className="p-button-info"
          style={{ marginRight: '5px' }}
          onClick={() => {
            setViewVisible(true);
            setSelectedRowData(rowData);
          }}
        />
        <Button
          text raised
          size="small"
          label='Edit'
          icon="pi pi-pencil"
          className="p-button-primary"
          style={{ marginRight: '5px' }}
          onClick={() => {
            setEditVisible(true);
            setSelectedRowData(rowData);
          }}
        />
        <Button
          text raised
          size="small"
          label="Delete"
          icon="pi pi-times"
          className=" p-button-danger"
          onClick={async () => {
            handleDeleteGroup(rowData)
          }}
        />


      </React.Fragment>
    );
  };

  async function fetchAllProducts() {

    let activeProducts = []
    await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/products/active', {
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    }).then((response) => {

      let products = response.data.responseBody

      products.forEach(product => {

        activeProducts.push(product)
      });
      setTermAndConditions(response.data.responseBody.termAndConditions)
      setGroupImageBannerUrl(response.data.responseBody.groupImageBannerUrl)
      console.log(response.data.responseBody)
    });

    setActiveProducts(activeProducts);
  }

  useMountEffect(() => {
    fetchAllProducts()
  });

  const cols = [
    { field: 'index', header: '#' },
    { field: 'groupImageBannerUrl', header: 'Artwork' },
    { field: 'easipolSubGroupId', header: 'Group ID' },
    { field: 'activeStatus', header: 'Status' },
    { field: 'easipolPolicySubGroup.Description', header: 'Name' },
    { field: 'easipolPolicySubGroup.planProducts', header: 'No of Products' },
    { field: 'category', header: 'Category' },
    { field: 'activeStatus', header: 'is Active' },
    // { field: 'easipolPolicySubGroup.CommunicationContactNumber', header: 'Contact Number' },
    { field: 'actions', header: 'Actions' },

  ];
  const imageBodyTemplate = (url) => {
    return <img src={url.groupImageBannerUrl} alt="No Group Artwork" className="w-2rem shadow-2 border-round" style={{ width: '80%' }} />;
  };

  const productCounter = (url) => {
    console.log(url.planProducts)
    return <>{url?.planProducts.length} </>;
  };
  const viewProductTemplate = (rowData) => {
    return (
      <Dialog header="" visible={viewVisible} maximizable style={{ width: '80vw' }} onHide={() => setViewVisible(false)}>
        <div>
          <ViewProduct rowData={rowData} onCloseDialog={handleViewProduct} style={{ marginTop: '20px' }} />
        </div>
      </Dialog>
    )
  }

  const editProductTemplate = (rowData) => {
    return (
      <Dialog header="" visible={editVisible} maximizable style={{ width: '50vw' }} onHide={() => setEditVisible(false)}>
        <div>
          <EditProduct rowData={rowData} termAndConditions={termAndConditions} groupImageBannerUrl={groupImageBannerUrl} onCloseDialog={handleViewProduct} style={{ marginTop: '20px' }} />
        </div>
      </Dialog>
    )
  }

  const addNewProductTemplate = () => {
    return (
      <Dialog header="Add New Product Group" visible={visible} maximizable style={{ width: '30vw' }} onHide={() => setVisible(false)}>
        <div>
          <AddNewProducts onCloseDialog={handleAddProduct} style={{ marginTop: "20px;" }} />
        </div>
      </Dialog>
    )
  }
  const handleAddProduct = async () => {
    setVisible(false)
  }

  const handleViewProduct = async () => {
    console.log("clicked")
    setViewVisible(false)
  }

  const handleDeleteGroup = async (rowData) => {

    await axios.delete(process.env.REACT_APP_DOVESAPI + 'policy/products/group/' + rowData.easipolSubGroupId, {
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('token'))
      }
    }).then((response) => {
      window.location.reload()
      console.log("Done!")
    })
    console.log(rowData.easipolSubGroupId)
  }
  return (
    <div className="card ">
      <div className="card-header">
        <h2 className="card-title ">Active Products List</h2>
        <Button onClick={() => setVisible(true)} icon="pi pi-plus" size='small' className='' style={{ float: "right" }} label='Add New Product' text raised />
      </div>

      {addNewProductTemplate()}

      {viewProductTemplate(selectedRowData)}

      {editProductTemplate(selectedRowData)}

      <div className="card-body">
        <DataTable
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={productsAvailable.map((item, index) => ({ ...item, index: index + 1 }))}
          emptyMessage="No Products found"
        >
          {cols.map((col, index) => {
            if (col.field === 'actions') {
              return (
                <Column
                  key={index + 'b'}
                  body={renderActions}
                  style={{ textAlign: 'center' }}
                />
              );
            }
            if (col.field === 'groupImageBannerUrl') {
              return (
                <Column style={{ width: '3rem' }} key={index} body={imageBodyTemplate} header={col.header} />
              );
            }
            if (col.field === 'easipolPolicySubGroup.planProducts') {
              return (
                <Column key={index} body={productCounter} header={col.header} />
              );
            }
            return <Column key={index + "a"} field={col.field} header={col.header} />;
          })}
        </DataTable>
      </div>

    </div>
  );
}
