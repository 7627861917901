import React, { useState, useRef } from "react";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Avatar } from 'primereact/avatar';
import { Divider } from "primereact/divider";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useMountEffect } from 'primereact/hooks';
import { Toast } from 'primereact/toast';
import BeneficiaryMembersJS from "./BeneficiaryMembersJS";

const AddBeneficiaryMembers = ({ policyDisplay }) => {



    let [beneficiary, setBeneficiary] = useState({ name: "", surname: "", relationship: "", dateOfBirth: "", idNumber: "", gender: '', firstName: "", lastName: "", phoneNumber: "", beneficiaryAge: 0, initials: "", isTertiary: '', selectedProduct: null, active: false });
    const [errorMessage, setErrorMessage] = useState("");
    const [contextErrors, setContextErrors] = useState([]);
    const [spouseSelected, setSpouseSelected] = useState(0);
    const [visible, setVisible] = useState(false);
    const [quoteParams, setQuoteParams] = useState([])
    const [allFormsInValid, setAllFormsInValid] = useState(true)
    const beneficiaryMembersJS = new BeneficiaryMembersJS();
    const [mainMemberProduct, setMainMemberProduct] = useState(0)
    const [productExtension, setProductExtension] = useState([])
    const [productSelected, setProductSelected] = useState(null)
    const memberBeneficiary = [];
    const memberChild = [];
    const memberExtended = [];
    const toast = useRef(null);
    let memberSpouse = {};


    const handleAddBeneficiary = () => {
        let validator = true;

        if (!validator) return

        setAllFormsInValid(false)
        setErrorMessage("");
    };

    async function fetchActiveProducts() {

        console.log(policyDisplay.props.policyList[policyDisplay.key].MainMember.SubGroupID + "is subgroup while product id is " + policyDisplay.props.policyList[policyDisplay.key].MainMember.ProductID)

        await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/products/active', {
            headers: {
                Authorization: 'Bearer ' + (localStorage.getItem('token'))
            }
        }).then((response) => {

            console.log("Fetched")
            console.log(response)
            console.log(response.data.responseBody)

            response.data.responseBody.forEach(element => {
                if (element.easipolSubGroupId === policyDisplay.props.policyList[policyDisplay.key].MainMember.SubGroupID) {
                    element.planProducts.forEach((product) => {
                        if (Number(product.ProductID) === Number(policyDisplay.props.policyList[policyDisplay.key].MainMember.ProductID)) {
                            console.log("Product FOund!")
                            console.log(product)
                            setProductExtension(product.productExtensions)
                        }
                    })
                }
            });

        }).catch((error) => {
            console.log(error)
        })
    }

    useMountEffect(() => {
        fetchActiveProducts()

    });
    function alterBeneficiaryArray(arr, index, newValue) {
        if (index >= 0 && index < arr.length) {
            arr[index] = newValue;
        } else {
            console.error('Index out of range.');
        }
    }

    function clearContextErrors(index) {

        if (contextErrors.length > 0) {
            const newErrors = contextErrors.filter((element) => element.index !== index);

            setContextErrors(newErrors);
        }

    }

    function validateName(element, index) {

        try {
            clearContextErrors(index)
            if (element.name === '') {
                return { 'index': index, field: 'name', errorMessage: 'Please enter your name' }

            }
            else if (element.name && element.name.split(" ").length < 2) {
                return { 'index': index, field: 'name', errorMessage: 'Please enter your full name' }
            } else {
                return true
            }
        } catch (error) {
            console.log(error)
        }
    }

    function validateRelationship(element, index) {

        var numOfSpouse = 0;


        if (beneficiary.relationship === "Spouse") {
            numOfSpouse = numOfSpouse + 1
        }


        if (Number(mainMemberProduct.CanHaveSpouse) === 0 && element.relationship === 'Spouse') {
            return { 'index': index, field: 'relationship', errorMessage: 'The main member product does not allow spouse beneficiaries' }
        }

        if (Number(mainMemberProduct.CanHaveChildren) === 0 && element.relationship === "Child") {
            return { 'index': index, field: 'relationship', errorMessage: 'The main member product does not allow children beneficiaries.' }
        }

        if (numOfSpouse > 1) {

            return { 'index': index, field: 'relationship', errorMessage: 'You can not have more than one spouse.' }
        }
        else if (element.relationship === '') {
            return { 'index': index, field: 'relationship', errorMessage: 'Please select your relationship with the beneficiary.' }
        }

        if (element.relationship === 'Child' && element.beneficiaryAge > 23) {
            changeRelationshipFromChild(index)
        }

        return true

    }

    function changeRelationshipFromChild(index) {
        let arr = []

        beneficiary.relationship = 'Relative';


        showToastMessage("Policy Guide", "You are not allowed to have a child beneficiary above 23, we have changed the relationship to relative. Please review", "info")

        setBeneficiary(beneficiary)
    }
    function validateDateOfBirth(element, index) {

        const benAge = beneficiaryMembersJS.validateAge(element.dateOfBirth);

        // const updatedBeneficiary = { ...beneficiary, beneficiaryAge: benAge };

        // setBeneficiary(updatedBeneficiary);
        

        if (element.relationship === 'Child' && benAge > 23) {
            showToastMessage("Policy Guide", "You are not allowed to have a child beneficiary above 23, we have changed the relationship to relative. Please review", "info")
            changeRelationshipFromChild(index)
        }

        if (element.dateOfBirth === '' || element.dateOfBirth === null) {
            return { 'index': index, errorMessage: 'Please select date of birth.' }
        }
        else if (benAge > 74 || benAge < 0) {
            return { 'index': index, errorMessage: 'Invalid beneficiary age, accepted age limit is between 0 and 75.' }
        }
        else {
            return true
        }

    }

    function validateIdNumber(element, index) {

        if (element.idNumber === '') {
            return { 'index': index, errorMessage: 'Please enter the ID Number of the beneficiary.' }
        }

        return true

    }

    function validateGender(element, index) {

        if (element.gender === '') {
            return { 'index': index, errorMessage: 'Please select gender of the beneficiary.' }
        }

        return true

    }

    function validateProduct(element, index) {

        if (element.selectedProduct === null && element.relationship === 'Relative') {

            return { 'index': index, errorMessage: 'Please select product for the beneficiary.' }
        }

        return true

    }

    function validateTertiary(element, index) {
        console.log(element)

        if (element.dateOfBirth != null) {

            const age = beneficiaryMembersJS.validateAge(element.dateOfBirth);

            if (element.isTertiary === '' && age > 18 && element.relationship === 'Child') {
                return { 'index': index, errorMessage: 'Please tell us whether  the beneficiary is still in tertiary.' }
            }
        }


        return true

    }

    function splitFullNameWithInitials(fullName) {
        const names = fullName.split(" ");
        let initials = "";
        let firstName = "";
        let lastName = "";

        if (names.length > 0) {
            initials = names.map(name => name[0]).join(".");
            firstName = names[0];
            if (names.length > 1) {
                lastName = names.slice(1).join(" ");
            }
        }

        return {
            firstName,
            lastName,
            initials,
        };
    }

    // async function handleSubmitBeneficiary() {
    //     try {
    //         const token = localStorage.getItem('token');
    //         const apiUrl = process.env.REACT_APP_DOVESAPI;

    //         const names = splitFullNameWithInitials(beneficiary.name)
    //         console.log(" logging beneficiary")
    //         console.log(beneficiary)
    //         console.log("logged beneficiary")

    //         let beneficiaryData = null

    //         if(beneficiary.relationship === 'Child'){
    //             beneficiaryData = {
    //                 "child": {
    //                 "child": [
    //                     {
    //                         "policyNumber": policyDisplay.props.policyList[policyDisplay.key].MainMember.Policy_Number,
    //                         "Name": names.firstName,
    //                         "Surname": names.lastName,
    //                         "ID_Num": beneficiary.idNumber,
    //                         "PassportNumber": "NONE",
    //                         "DateofBirth": beneficiary.dateOfBirth,
    //                         "InceptionDate": new Date().toISOString().slice(0, 10),
    //                         "Cover": 0,
    //                         "Gender": beneficiary.gender,
    //                         "atVarsity": beneficiary.isTertiary === 'YES'? true : false
    //                     }
    //                 ]
    //             }}
    //         }
    //         else if(beneficiary.relationship === 'Spouse'){
    //             beneficiaryData = {
    //                 "spouse": {
    //                 "PolicyNumber": policyDisplay.props.policyList[policyDisplay.key].MainMember.Policy_Number,
    //                 "ID_Num": beneficiary.idNumber,
    //                 "PassportNumber": "NONE",
    //                 "Name": names.firstName,
    //                 "Initials": names.initials,
    //                 "Surname": names.lastName,
    //                 "DateofBirth": beneficiary.dateOfBirth,
    //                 "InceptionDate": new Date().toISOString().slice(0, 10),
    //                 "Note": "nothing missing",
    //                 "waitingPeriod": 0,
    //                 "Telephone": beneficiary.phoneNumber,
    //                 "Gender": beneficiary.gender
    //             }}
    //         }

    //         else{

    //             beneficiaryData = {
    //                 "extended": {
    //                 "extendedMember": [
    //                     {
    //                         "policyNumber": policyDisplay.props.policyList[policyDisplay.key].MainMember.Policy_Number,
    //                         "ProductID": beneficiary.selectedProduct.ProductID,
    //                         "Name": names.firstName,
    //                         "Surname": names.lastName,
    //                         "ID_Num": beneficiary.idNumber,
    //                         "Passport_Num": "NONE",
    //                         "DateofBirth": beneficiary.dateOfBirth,
    //                         "InceptionDate": new Date().toISOString().slice(0, 10),
    //                         "Relation": beneficiary.relationship,
    //                         "Gender": beneficiary.gender,
    //                     }
    //                 ]
    //             }}
    //         }
    //         const payload = {
    //             "accessToken": "",
    //             "operation": "addNewBeneficiary",
    //             "requestBody": beneficiaryData
    //         };

    //         const response = await axios.post(`${apiUrl}policy/beneficiary/new`, payload, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             }
    //         });

    //         console.log('Response:', response.data);

    //         if(response.data.status === 'SUCCESS'){
    //             showToastMessage("SUCCESS", "You have successfully added another beneficiary", "success")
    //             console.log("Bemeficiarity saved suxxessfully")
    //         }

    //     } catch (error) {
    //         console.error("Error in handleSubmitBeneficiary:", error);

    //     }
    // }


    async function handleSubmitBeneficiary() {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_DOVESAPI;

            const beneficiaryData = createBeneficiaryData(beneficiary);
            const payload = {
                accessToken: "",
                operation: "addNewBeneficiary",
                requestBody: beneficiaryData,
            };

            const response = await axios.post(`${apiUrl}policy/beneficiary/new`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log('Response:', response.data);

            if (response.data.status === 'SUCCESS') {
                showToastMessage("SUCCESS", "You have successfully added another beneficiary", "success");
                console.log("Beneficiary saved successfully");
            }
        } catch (error) {
            console.error("Error in handleSubmitBeneficiary:", error);
        }
    }

    function createBeneficiaryData(beneficiary) {
        const names = splitFullNameWithInitials(beneficiary.name);

        if (beneficiary.relationship === 'Child') {
            return {
                child: [
                    {
                        policyNumber: policyDisplay.props.policyList[policyDisplay.key].MainMember.Policy_Number,
                        Name: names.firstName,
                        Surname: names.lastName,
                        ID_Num: beneficiary.idNumber,
                        PassportNumber: "NONE",
                        DateofBirth: beneficiary.dateOfBirth,
                        InceptionDate: new Date().toISOString().slice(0, 10),
                        Cover: 0,
                        Gender: beneficiary.gender,
                        atVarsity: beneficiary.isTertiary === 'YES',
                    },
                ],
            };
        } else if (beneficiary.relationship === 'Spouse') {
            return {
                spouse: {
                    policyNumber: policyDisplay.props.policyList[policyDisplay.key].MainMember.Policy_Number,
                    ID_Num: beneficiary.idNumber,
                    PassportNumber: "NONE",
                    Name: names.firstName,
                    Initials: names.initials,
                    Surname: names.lastName,
                    DateofBirth: beneficiary.dateOfBirth,
                    InceptionDate: new Date().toISOString().slice(0, 10),
                    Note: "nothing missing",
                    waitingPeriod: 0,
                    Telephone: beneficiary.phoneNumber,
                    Gender: beneficiary.gender,
                },
            };
        } else {
            return {
                extendedMember: [
                    {
                        policyNumber: policyDisplay.props.policyList[policyDisplay.key].MainMember.Policy_Number,
                        ProductID: beneficiary.selectedProduct.ProductID,
                        Name: names.firstName,
                        Surname: names.lastName,
                        ID_Num: beneficiary.idNumber,
                        Passport_Num: "NONE",
                        DateofBirth: beneficiary.dateOfBirth,
                        InceptionDate: new Date().toISOString().slice(0, 10),
                        Relation: beneficiary.relationship,
                        Gender: beneficiary.gender,
                    },
                ],
            };
        }
    }

    function validateAllFields(element, index) {

        const validationFunctions = [
            validateName,
            validateRelationship,
            validateDateOfBirth,
            validateIdNumber,
            validateProduct,
            validateGender,
            validateTertiary
        ];

        const concatenatedObj = [];
        let isValid = true;

        for (const validateFn of validationFunctions) {
            const result = validateFn(element, index);

            if (typeof result === 'object') {
                concatenatedObj.push(result);
            }

            isValid = isValid && result === true;
        }

        if (isValid) setAllFormsInValid(false)

        setContextErrors(concatenatedObj);
        return isValid;
    }


    const confirm1 = (event, index) => {
        confirmPopup({
            target: event.originalEvent.target,
            message: event.value === "YES" ? 'Are you sure this beneficiary is a tertiary student?' : 'Kindly confirm this beneficiary is not a tertiary student?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptTertiary(event, index),
            reject: () => rejectTertiary(event, index)
        });
    };


    const acceptTertiary = (e, index) => {
        // Create a shallow copy of the beneficiaries object
        const updatedBeneficiaries = { ...beneficiary };
      
        if (e.value === 'YES') {
          updatedBeneficiaries.isTertiary = e.value;
        } else {
          updatedBeneficiaries.relationship = "Relative";
          showToastMessage("Policy Guide", "Your child beneficiary is above 18 and not in tertiary education, we have changed the relationship to relative. Please review", "info");
        }
      
        // Update the entire beneficiaries object
        setBeneficiary(updatedBeneficiaries);
      };

    const rejectTertiary = (e, index) => {

    };

    function ProductDropdown({ index, selectedProduct, planProducts }) {

        return (
            <Dropdown
                id={`product${index}`}
                name="selectedProduct"
                value={productSelected}
                onChange={(e) => handleProductChange(e, index)}
                options={planProducts}

                optionLabel="ProductName1"
                placeholder={planProducts.length === 0 ? "No products for additional beneficiaries in this package" : "Select a product"}
                className="w-full md:w-6rem"
            />
        );
    }

    const handleRemoveBeneficiary = (index) => {

    };



    const handleBeneficiaryChange = (e, index) => {
        // Destructure the 'name' and 'value' properties from the event target
const { name, value } = e.target;

// Create a copy of the 'beneficiary' object with the updated property
let updatedBeneficiary = { ...beneficiary, [name]: value };

// Check if the updated property is 'dateOfBirth'
if (name === 'dateOfBirth') {
    // Assuming beneficiaryMembersJS.validateAge is a function to calculate age
    const benAge = beneficiaryMembersJS.validateAge(value);

    // Update the 'beneficiaryAge' property with the calculated age
    updatedBeneficiary = { ...updatedBeneficiary, beneficiaryAge: benAge };
}

// Update the 'beneficiary' state with the updatedBeneficiary object
setBeneficiary(updatedBeneficiary);


        const validationFunctions = {
            name: validateName,
            relationship: validateRelationship,
            dateOfBirth: validateDateOfBirth,
            idNumber: validateIdNumber,
            selectedProduct: validateProduct,
            gender: validateGender,
            isTertiary: validateTertiary,
        };

        if (name in validationFunctions) {
            const validationFunction = validationFunctions[name];
            const fieldError = validationFunction(updatedBeneficiary, 0);

            if (typeof fieldError === 'object') {
                const updatedErrors = contextErrors.map((element) => {
                    if (element.field === name) {
                        return fieldError;
                    }
                    return element;
                });

                if (!updatedErrors.some((element) => element.field === name)) {
                    updatedErrors.push(fieldError);
                }

                setContextErrors(updatedErrors);
            }
        }
    };


    const handleProductChange = (e, index) => {


        const { name, value } = e.target;
        setProductSelected(value);
        beneficiary[name] = value;
        console.log(beneficiary)
        setBeneficiary(beneficiary);
    }

    const showToastMessage = (title, message, messageType) => {
        toast.current.show({ severity: messageType, summary: title, detail: message, life: 15000 });
    }

    const relationshipOptions = [
        "Spouse",
        "Child",
        "Relative",
    ];

    return (
        <div>
            <Toast ref={toast} />
            <div className="col-xxl-12 col-xl-12 col-lg-12" style={{ marginBottom: '12px' }}>
                <input
                    type="text"
                    className="form-control"
                    id={`name${0}`}
                    name="name"
                    placeholder=" Full Name"
                    value={beneficiary.name}
                    onChange={(e) => {
                        handleBeneficiaryChange(e, 0)
                    }
                    }
                    required
                />
            </div>

            <div className="col-xxl-12 col-xl-12 col-lg-12" style={{ marginBottom: '12px' }}>
                <select
                    className="form-select"
                    id={`relationship${0}`}
                    name="relationship"
                    value={beneficiary.relationship}
                    onChange={(e) => {
                        handleBeneficiaryChange(e, 0)
                    }
                    }
                    required
                >
                    <option value="">Select Relationship</option>
                    {relationshipOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </div>

            <div className="col-xxl-12 col-xl-12 col-lg-12" style={{ marginBottom: '12px' }}>
                <select
                    className="form-select"
                    id={`gender${0}`}
                    name="gender"
                    value={beneficiary.gender}
                    onChange={(e) => {
                        handleBeneficiaryChange(e, 0)
                    }
                    }
                    required
                >
                    <option value="">Select Gender</option>
                    <option value="MALE"> MALE </option>
                    <option value="FEMALE"> FEMALE </option>

                </select>
            </div>

            <div className="col-xxl-12 col-xl-12 col-lg-12" style={{ marginBottom: '12px' }}>
                <input
                    type="date"
                    className="form-control"
                    id={`dateOfBirth${0}`}
                    name="dateOfBirth"
                    placeholder="Date of Birth"
                    value={beneficiary.dateOfBirth}
                    onChange={(e) => {
                        handleBeneficiaryChange(e, 0)
                    }
                    }
                    required
                />
            </div>

            <div className="col-xxl-12 col-xl-12 col-lg-12" style={{ marginBottom: '12px' }}>
                <input
                    type="text"
                    className="form-control"
                    id={`idNumber${0}`}
                    name="idNumber"
                    placeholder="ID Number"
                    value={beneficiary.idNumber}
                    onChange={(e) => {
                        handleBeneficiaryChange(e, 0)
                    }
                    }
                    required
                />
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12" style={{ marginBottom: '12px' }} >
                <input
                    type="text"
                    className="form-control"
                    id={`phoneNumber${0}`}
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={beneficiary.phoneNumber}
                    onChange={(e) => {
                        handleBeneficiaryChange(e, 0)
                    }
                    }
                    required
                />
            </div>

            {beneficiary.relationship === "Relative" ? (
                <div className="card flex justify-content-center">

                    {console.log(policyDisplay.props.policyList[policyDisplay.key].MainMember)}
                    {productExtension && (
                        <ProductDropdown
                            index={0}
                            selectedProduct={beneficiary.selectedProduct}
                            planProducts={productExtension}
                        />
                    )}
                </div>
            ) : null}

            {beneficiary.relationship === "Child" && beneficiary.beneficiaryAge > 18 ? (
                <>
                    <div className="col-xxl-12 col-xl-12 col-lg-12 ">
                        <span>
                            <label className="form-label"><small>Is beneficiary a tertiary student? </small></label>
                            <div className="card flex flex-wrap gap-2 justify-content-center">
                                <SelectButton disabled={beneficiary.active} name="selectButton" id="selectButton" value={beneficiary.isTertiary} label="Confirm" onChange={(e) => {

                                    if (e.value === null && beneficiary.isTertiary === "YES") e.value = "YES"
                                    else if (e.value === null && beneficiary.isTertiary === "NO") e.value = "NO"

                                    if (e.value === beneficiary.isTertiary) return true

                                    confirm1(e, 0)
                                }}
                                    options={['YES', 'NO']} />
                            </div>
                        </span>
                    </div>
                </>

            ) : (
                <></>
            )}

            <div>

                <div>
                    {contextErrors.map((element, index1) => (
                        0 === element.index && (
                            <div key={index1}>
                                <br />
                                <div className="alert alert-danger">{element.errorMessage}</div>
                            </div>
                        )
                    ))}
                </div>

            </div>
            <center >
                <Button onClick={() => {
                    let validator = true;
                    validator = validateAllFields(beneficiary, 0)
                    if (!validator) return
                    else handleSubmitBeneficiary()
                }} label="Submit"></Button>
            </center>

        </div>
    );
}

export default AddBeneficiaryMembers;