import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../layout/headers/header'
import Sidebar from '../../../layout/navigation/sidebar/dynamic-sidebar'; 
import AllUsersProfileReportTable from './AllUsersProfileReportTable'

const AllUsersProfileReport = () => {

    
    return (
        <>
            <Header />
            <Sidebar />
            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <AllUsersProfileReportTable  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllUsersProfileReport;