import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Header from '../../layout/headers/header';
import CustomerNavMenu from "../../layout/navigation/topbar/CustomerNavMenu";
import DynamicDashboard from "../../layout/dashboard/dynamic-dashboard";
import DynamicNavigation from "../../layout/navigation/dynamic-navigation";
import TrackLoanApplication from "../../components/loans/track_loans_application_progress";
import MakePayment from '../../components/makePayment/makePayment';
import ClientLoans from '../../components/loans/LoansApplied.jsx';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import paymentImage from "../../../images/patterns/loans-bg.png"
import { Link, useHistory } from 'react-router-dom';

const Loans = () => {
    const [loansAvailable, setLoansAvailable] = useState(false);
    const history = useHistory()
    return (
        <>
            <Header />
            <DynamicNavigation />
            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-12">
                            <Card className='  '
                                header={(<img src={paymentImage} style={{ width: '100%' }} />)}
                            >
                                <div className='m-2 flex justify-content-center'>
                                    <h3 className='text-center p-3'>Apply for a new loan</h3>
                                    <center>
                                        <div className='row'>
                                            <div className="  col-xxl-12 col-xl-12 col-lg-12" >
                                                <Button
                                                    onClick={() => { history.push("/loans/new") }}
                                                    style={{ backgroundColor: '#084b9b', border: '1px solid #084b9b' }}
                                                    className="justify-content-center text-center  "
                                                    size="large"
                                                    label={<>Apply for loan</>}
                                                    icon="pi pi-plus"
                                                    severity="info"
                                                    rounded
                                                    raised


                                                />
                                            </div>
                                            <div>
                                                <Divider />
                                                <p><small>We offer the following services: short term loans; foreign exchange services; investments; financial intelligence; financial planning. </small></p>

                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </Card>

                        </div>
                        <TrackLoanApplication />


                    </div>
                </div>
            </div>
        </>
    );
};

export default Loans;
