import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useMountEffect } from 'primereact/hooks';
import { Divider } from 'primereact/divider';
function PaymentForm({
  paymentMethods,
  paymentMethod,
  phoneNumber,
  amount,
  initialmount,
  policyNumber,
  paymentNotes,
  handlePaymentMethodChange,
  handlePhoneNumberChange,
  handleAmountChange,
  handlePolicyNumberChange,
  handlePaymentNotesChange,
  submitPaymentRequest,
  balanceFormatted
}) {

  const oneMonth = Number(initialmount);
  const twoMonths = Number(initialmount) * 2;
  const threeMonths = Number(initialmount) * 3;

  const paymentOptions = [
    { label: '1 Month $' + oneMonth, amount: oneMonth },
    { label: '2 Months $' + twoMonths, amount: twoMonths },
    { label: '3 Months $' + threeMonths, amount: threeMonths }
  ];

  const [defaultAmount, setAmount] = useState({ label: '1 Month', amount: Number(initialmount) });
  const [defaultMethod, setMethod] = useState(paymentMethod);

  useMountEffect(() => {
    paymentOptions.forEach((element) => {
      if (element.amount === initialmount) {
        setAmount(element)
      }
    })
  })

  return (
    <form name="principalMemberForm">
      <div className="row g-4">
        <div className="col-xxl-6 col-xl-6 col-lg-6">
          <label className="form-label">Select payment method</label>
          {/* <select
            className="form-select"
            id="paymentMethod"
            name="paymentMethod"
            required
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <option value="">Select payment method</option>
            {paymentMethods.map((paymentType) => (
              <option key={paymentType} value={paymentType}>
                {paymentType}
              </option>
            ))}
          </select> */}

          <Dropdown id="paymentMethod" name="paymentMethod" value={defaultMethod}
            onChange={(e) => {
              handlePaymentMethodChange(e.value)
              setMethod(e.value)
            }
            }
            options={paymentMethods}
            placeholder="Payment method" className="form-control" />
        </div>
        {defaultMethod && defaultMethod === "Ecocash" && <div className="col-xxl-6 col-xl-6 col-lg-6">
          <label className="form-label">Phone number</label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            placeholder="Enter phone number"
            name="phoneNumber"
            required
            value={phoneNumber}
            onChange={handlePhoneNumberChange}

          />
        </div>}

        {defaultMethod && defaultMethod === "Ecocash" && <div className="col-xxl-6 col-xl-6 col-lg-6">
          <label className="form-label">Balance Due</label>

          <Dropdown value={defaultAmount}
            onChange={(e) => {
              handleAmountChange(e.value.amount)
              setAmount(e.value)
            }
            }
            options={paymentOptions} optionLabel="label"
            placeholder="Choose period" className="form-control" />

        </div>}
        {defaultMethod && defaultMethod === "Ecocash" && <div className="col-xxl-6 col-xl-6 col-lg-6">
          <label className="form-label">Policy Number</label>
          <input
            type="text"
            className="form-control"
            id="policyNumber"
            placeholder="Enter policy number"
            name="policyNumber"
            required
            value={policyNumber}
            onChange={handlePolicyNumberChange}

          />
        </div>}
        {defaultMethod && defaultMethod === "Ecocash" && <div className="text-center">
          <Button
            onClick={submitPaymentRequest}
            className="justify-content-center text-center mt-4"
            size="large"
            outlined
            label={<><strong>Continue </strong></>}
            icon="pi pi-arrow-right"
            severity="info"
            rounded
            raised
          />
        </div>}
        {
          defaultMethod && defaultMethod === "Pay using Paynow" && <>
            <Divider type='dashed' />
            <iframe src="https://www.topup.co.zw/billpay/widget/doves-funeral-services?iframe=true"
              title='Doves Visa Payment'
              style={{
                width:"100%",height: '80vw'
              }}
              // style={"width: 100%;padding: 15px;border: 3px solid lightgrey;border-radius: 6px;height: 500px;"}
            ></iframe>

          </>
        }
      </div>
    </form>
  );
}

export default PaymentForm;
