import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { TieredMenu } from 'primereact/tieredmenu';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useMountEffect } from 'primereact/hooks';
import AddNewAd from "./AddNewAd"
import EditAd from "./EditAd"

const AdsTable = () => {
  const [employees, setemployees] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [activeAds, setActiveAds] = useState(null)

  const dt = useRef(null);
  const toast = useRef(null);
  const [reloadData, setReloadData] = useState(false);


  const handleLock = async (data) => {
    let userPayload = {
      "operation": "create-user-profile",
      "requestBody": data
    };

    const token = localStorage.getItem('token')

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    const response = await axios.put(process.env.REACT_APP_DOVESAPI + 'user/edit', userPayload, config).then((response) => {
      if (response.data.status === "SUCCESS") {
        toast.current.show({ severity: 'success', summary: 'Updated', detail: data.isLocked ? 'You have successfully locked this profile' : 'You have successfully unlocked this profile', life: 3000 });
      }
      else {
        toast.current.show({ severity: 'warn', summary: 'Failed', detail: response.data.message, life: 3000 });
      }
    });
  };

  const handleActive = async () => {

    let userPayload = {
      "operation": "create-user-profile",
      "requestBody": data
    };

    const token = localStorage.getItem('token')

    const config = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };

    const response = await axios.put(process.env.REACT_APP_DOVESAPI + 'user/edit', userPayload, config).then((response) => {
      if (response.data.status === "SUCCESS") {
        toast.current.show({ severity: 'success', summary: 'Updated', detail: data.isActive ? 'You successfully activated this profile' : 'You successfully disabled the profile', life: 3000 });
      }
      else {
        toast.current.show({ severity: 'warn', summary: 'Failed', detail: response.data.message, life: 3000 });
      }
    });


  }

  const rejectFunction = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };


  const cols = [
    { field: 'title', header: 'Title' },
    { field: 'image', header: 'Image Url' },
    { field: 'description', header: 'Description' },
    { field: 'callToActionURL', header: 'Call To Action URL' },
    { field: 'actions', header: 'Actions' },
  ];

  useMountEffect(() => {
    fetchUsers();
  }, [reloadData]);


  function renderActions(rowData) {
    return (
      <React.Fragment>
        <Button
          text raised
          size="small"
          label='Edit'
          icon="pi pi-pencil"
          className="p-button-info"
          style={{ marginRight: '5px' }}
          onClick={() => {
            setReloadData(!reloadData);
            setActiveAds(rowData)
            setDialogVisible(!dialogVisible)

          }}
        />
      </React.Fragment>
    );
  }

  const [dialogVisible, setDialogVisible] = useState(false);

  const data = []



  const fetchUsers = async () => {
    try {
      await axios.get(process.env.REACT_APP_DOVESAPI + 'advertisement/all', {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {
        // console.log(response);
        if (response.data.status === "SUCCESS") {
          setemployees((response.data.responseBody));
        } else {
          console.log(response.data.status);
        }
      });

    } catch (error) {
      console.log(error)
    }
  };


  const onGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
  };

  const globalFilterElement = (
    <input
      type="text"
      className="p-inputtext p-component"
      placeholder="Search"
      value={globalFilter}
      onChange={onGlobalFilter}
    />
  );

  const handleCreateUser = async () => {
    setDialogVisible(false)

    await toast.current.show({ severity: 'success', summary: 'Success', detail: 'User registered successfully' });

  }

  const imageBodyTemplate = (advertisement) => {
    return <img src={advertisement.image} alt={advertisement.image} className="w-2rem shadow-2 border-round" style={{ width: '80%' }} />;
  };

  return (


    <div className="card home-chart">
      <Toast ref={toast} />


      {activeAds === null ? (
        <Dialog header="Add new advertisement" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() => {
          setActiveAds(null)
          setDialogVisible(false)
        }}>
          <AddNewAd onCloseDialog={handleCreateUser} style={{ marginTop: "20px;" }} />
        </Dialog>
      ) : (
        <Dialog header="Edit user" visible={dialogVisible} maximizable style={{ width: '30vw' }} onHide={() => {
          setActiveAds(null)
          setDialogVisible(false)
        }}>
          <EditAd activeAds={activeAds} onCloseDialog={handleCreateUser} style={{ marginTop: "20px;" }} />
        </Dialog>
      )
      }




      <Tooltip target=".export-buttons>button" position="bottom" />
      <ConfirmPopup />

      <div className="card-header">
        <h2 className="card-title home-chart">Manage Advertisements</h2>
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {globalFilterElement}
          </span>
        </div>
        <div id="report-select">

          <Button label="Create new" raised size="small" icon="pi pi-news-plus" onClick={() => setDialogVisible(!dialogVisible)} />

        </div>

      </div>

      <div className="card-body">

        <DataTable
          paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
          ref={dt}
          value={employees}
          tableStyle={{ minWidth: '50rem' }}
          globalFilter={globalFilter}
          emptyMessage="No advertisements found"
        >
          {cols.map((col, index) => {
            if (col.field === 'actions') {
              return (
                <Column
                  key={index}
                  body={renderActions}
                  style={{ textAlign: 'center' }}
                />
              );
            }
            if (col.field === 'image') {
              return (
                <Column key={index} body={imageBodyTemplate} header={col.header} />

              );
            }
            return (

              <Column key={index} field={col.field} header={col.header} />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};

export default AdsTable;
