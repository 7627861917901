import React, { useState, useEffect } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import UserProfile from '../../profile/userProfile';
import { useMountEffect } from 'primereact/hooks';
import { Button } from 'primereact/button';

const Sidebar1 = () => {

    localStorage.setItem('sideBarHidden', 'false');

    const [isSidebarVisible, setSidebarVisible] = useState(
        localStorage.getItem('sideBarHidden') !== 'true'
    );
    const [items, setItems] = useState([]);

    useMountEffect(() => {
        const fetchUserPermissions = async () => {
            try {
                const userPermissions = JSON.parse(localStorage.getItem('userRole')).permissionsGroups;

                const updatedItems = userPermissions.map((permissionsGroup) => {
                    const systemFunctionItems = permissionsGroup.systemFunctions
                        ? permissionsGroup.systemFunctions.map((functionItem) => ({
                            label: functionItem.name,
                            icon: 'pi pi-angle-right',
                            url: functionItem.action,
                        }))
                        : [];

                    return {
                        label: permissionsGroup.groupName,
                        headerIcon: permissionsGroup.groupIcons,
                        items: systemFunctionItems,
                    };
                });

                setItems(updatedItems); // Update the state with the new items
            } catch (error) {
                console.error('Error fetching user permissions:', error);
            }
        };
        fetchUserPermissions();
    });

    useEffect(() => {
        // Update localStorage whenever isSidebarVisible changes
        localStorage.setItem('sideBarHidden', !isSidebarVisible);
    }, [isSidebarVisible]);

    useEffect(() => {
        // Handler for storage event
        const handleStorageChange = (event) => {
            if (event.key === 'sideBarHidden') {
                setSidebarVisible(event.newValue !== 'true');
            }
        };

        // Add event listener
        window.addEventListener('storage', handleStorageChange);

        // Clean up
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const sidebarStyle = {
        display: isSidebarVisible ? 'block' : 'none',
        background: isSidebarVisible ? 'transparent' : '#fff',
        height: '80vh',
    };

    const buttonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 100, // Ensure the button is on top
    };

    return (
        <>
            {isSidebarVisible && (
                <div className="card flex justify-content-between sidebar">

                    <div style={{ height: '80vh', background: 'transparent' }}>
                        <PanelMenu model={items} multiple={false} className="w-full md:w-20rem" />
                        <div className="mt-3">
                            <UserProfile />
                        </div>
                    </div>
                    <div>
                        <div style={{ marginLeft: "1em", marginTop: "2em", marginBottom: "1em" }} className="sidebar-icon" onClick={() => setSidebarVisible(!isSidebarVisible)}>
                            <i className="pi pi-bars"></i><strong> {isSidebarVisible ? " Hide Sidebar" : " Show SideBar"}</strong>
                        </div>
                    </div>
                    {/* <div className="flex flex-wrap justify-content-center gap-3 mb-4">
                        <Button 
                            type="button" 
                            icon={isSidebarVisible ? 'pi pi-eye-slash' : "pi pi-eye"} 
                            outlined 
                            onClick={() => setSidebarVisible(!isSidebarVisible)} 
                        />
                    </div> */}
                </div>
            )}

            {!isSidebarVisible && (
                <div className=' '>
                    <div className="sidebar-icon m-3" onClick={() => setSidebarVisible(!isSidebarVisible)}>
                        <i className="pi pi-bars m-1"></i><strong> {isSidebarVisible ? " Hide Sidebar" : " Show SideBar"}</strong>
                    </div>
                </div>
            )}
        </>
    );
};

export default Sidebar1;
