import React, { useState, useRef } from "react";
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import axios from 'axios';
import { Divider } from 'primereact/divider';
import { MultiSelect } from 'primereact/multiselect';
import { useMountEffect } from 'primereact/hooks';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const CreateNewUser = ({ roleData }) => {
    const toast = useRef(null);
    const [groupedRoles, setGroupedRoles] = useState([]);
    const [groupedPermissions, setGroupedPermissions] = useState([]);
    let [selectedPermissions, setSelectedPermissions] = useState();
    const label = "name";
    const [isMultiSelectActive, setIsMultiSelectActive] = useState(false);
    const [openPanel, setOpenPanel] = useState(null);
    const [panelStatus, setPanelStatus] = useState('closed');
    const [confirmedPermissions, setConfirmedPermissions] = useState([])
    const [roleName, setRoleName] = useState(roleData.name)
    const [roleid, setRoleId] = useState(roleData.id)
    const [roleDescription, setRoleDescription] = useState(roleData.description)

    const fetchedData = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_DOVESAPI + 'system/functions/groups', {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }
            });
            //console.log(response.data.responseBody);
            setGroupedRoles(response.data.responseBody);
        } catch (e) {
            //console.log(e);
        }
    };



    const fetchPermissions = async () => {
        await axios.get(process.env.REACT_APP_DOVESAPI + 'system/functions', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            setGroupedPermissions(response.data.responseBody)
            groupedPermissions.map((data) => ({
                name: data.name,
                code: data.id,
                description: data.description

            }))
        }).catch((e) => {

        })
    }


    useMountEffect(() => {

    }, []);

    useMountEffect(() => {
        const fetchData = async () => {
            await fetchSetRole();
            await fetchedData();
            await fetchPermissions();
        };

        fetchData();
    });


    async function fetchSetRole() {
        // console.log(JSON.stringify(roleData))
    }
    const handleRoleChange = (role, value) => {
        setSelectedPermissions(value);

        console.log(value);
        let data = {
            functionName: role,
            permissions: value,
        };

        handleConfirmedPermissions(data);
    };


    const handleConfirmedPermissions = (data) => {

        const existingElement = confirmedPermissions.find(
            (element) => element.functionName === data.functionName
        );

        if (existingElement) {

            setConfirmedPermissions((prevConfirmedPermissions) =>
                prevConfirmedPermissions.map((element) =>
                    element.functionName === data.functionName
                        ? { ...element, permissions: [...element.permissions, ...data.permissions] }
                        : element
                )
            );
        } else {

            setConfirmedPermissions((prevConfirmedPermissions) => [
                ...prevConfirmedPermissions,
                data,
            ]);
        }
    };



    const handleMultiSelectFocus = () => {
        setIsMultiSelectActive(true);
    };

    const handleMultiSelectBlur = () => {
        setIsMultiSelectActive(false);
    };

    const handlePanelToggle = (e, groupName) => {


        console.log(e)
        let finalStatus = true
        selectedPermissions = []

        confirmedPermissions.forEach((element, index) => {

            if (element.functionName === groupName) {

                confirmedPermissions.slice(index)
                setSelectedPermissions(element.permissions)
                finalStatus = false
                return false;
            }
            else {

            }




        });

        if (finalStatus === true) {
            setSelectedPermissions(null)
        }
        setOpenPanel((prevOpenPanel) => {
            const isOpen = prevOpenPanel === groupName;
            setPanelStatus(isOpen ? 'closed' : 'open');
            return isOpen ? null : groupName;
        });

    };

    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const Payload = {
            id: roleid,
            roleName: roleName,
            roleDescription: roleDescription,
            systemFunctions: confirmedPermissions.flatMap(item => item.permissions.map(permission => permission.id))
        }
        console.log(Payload)
        try {



            await axios.post(process.env.REACT_APP_DOVESAPI + 'system/roles/add',
                {
                    "accessToken": "",
                    "operation": "set-new-password",
                    "requestBody": Payload
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + (localStorage.getItem('token'))
                    }
                }).then((response) => {
                    setLoading(false)
                    if (response.data.status === "SUCCESS" && response.data.responseBody != null) {
                        console.log(response.data?.responseBody)
                        window.location.reload();
                    } else {
                        toast.current.show({
                            severity: 'error',
                            summary: 'Failed to create a new role',
                            detail: response.data.message,
                            life: 4000
                        })
                        console.log(response.data.message)
                    }
                });

        } catch (error) {
            setLoading(false)
            console.error('Error creating new role:', error);
        }
        setLoading(false);
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <form onSubmit={handleSubmit}>

                                <div className="card flex justify-content-center" style={{ marginTop: '40px' }}>
                                    <span className="p-float-label">
                                        <InputText id="roleName" value={roleName} className='create-role-input' onChange={(e) => {
                                            setRoleName(e.target.value)
                                        }} />
                                        <label htmlFor="roleName"> Role Name </label>
                                    </span>
                                </div>


                                <div className="card flex justify-content-center" style={{ marginTop: '40px' }}>
                                    <span className="p-float-label">
                                        <InputText id="roleDescription" value={roleDescription} className='create-role-input' onChange={(e) => {
                                            setRoleDescription(e.target.value)
                                        }} />
                                        <label htmlFor="roleDescription"> Role Description </label>
                                    </span>
                                </div>

                                <Divider className="p-divider-center"> <small>Assign Functions that applies</small></Divider>

                                {/* { JSON.stringify(selectedPermissions)} */}
                                {groupedRoles.map((role) => (
                                    <div className="card" key={role.groupName}>
                                        <Panel
                                            header={role.groupName}
                                            toggleable
                                            collapsed={openPanel !== role.groupName}
                                            className="panel-header"
                                            onToggle={(e) => handlePanelToggle(e, role.groupName)}
                                        >
                                            <div className="card-body flex justify-content-center">
                                                <span className="p-float-label">
                                                    <MultiSelect
                                                        size="small"
                                                        value={selectedPermissions}
                                                        filter
                                                        onChange={(e) => handleRoleChange(role.groupName, e.value)}
                                                        options={role.systemFunctions}
                                                        optionLabel={label}
                                                        display="chip"
                                                        placeholder="Select permissions"
                                                        maxSelectedLabels={3}
                                                        className="w-full md:w-20rem create-role-input"
                                                        onFocus={handleMultiSelectFocus}
                                                        onBlur={handleMultiSelectBlur}
                                                    />
                                                    <label style={{ paddingLeft: "5px", paddingRight: "5px" }} htmlFor={`permissionName${role.groupName}`}>Permissions</label>
                                                </span>
                                            </div>
                                        </Panel>
                                    </div>
                                ))}
                                <Button onClick={handleSubmit} size="small">Save Changes</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateNewUser;
