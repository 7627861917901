

import React, { useState, useRef } from "react";
import Sidebar from '../../layout/navigation/sidebar/dynamic-sidebar';
import Header from '../../layout/headers/header'
import AdsTable from './adsTable'

const ManageAds = () => {
    

    return (
        <>
        <Header />
        <Sidebar />
        <div className="content-body">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12">
                        <AdsTable />
                    </div>
                </div>
            </div>
        </div> 
        </>
    );
};

export default ManageAds;
