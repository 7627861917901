import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Avatar } from 'primereact/avatar';
import { Divider } from "primereact/divider";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useMountEffect } from 'primereact/hooks';
import BeneficiaryMembersJS from "./BeneficiaryMembersJS";




const BeneficiaryMembersForm = ({ moveToNextView, moveToPreviousView, userPolicyInput, setuserPolicyInput, titles, toast }) => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [contextErrors, setContextErrors] = useState([]);
  const [loading, setloading] = useState(false);
  const [spouseSelected, setSpouseSelected] = useState(0);
  const [visible, setVisible] = useState(false);
  const [quoteParams, setQuoteParams] = useState([])
  const [allFormsInValid, setAllFormsInValid] = useState(true)
  const beneficiaryMembersJS = new BeneficiaryMembersJS();
  const [mainMemberProduct, setMainMemberProduct] = useState(0)
  const memberBeneficiary = [];
  const memberChild = [];
  const memberExtended = [];
  let memberSpouse = {};
  // const toast1 = useRef(null);

  const handleAddBeneficiary = () => { 
    let validator = true;
    beneficiaries.forEach((element, index) => {

      validator = validateAllFields(element, index)

    })

    if (userPolicyInput.selectedProduct && userPolicyInput.selectedProduct.includeParentProductInExtensions && (userPolicyInput.selectedProduct.includeParentProductInExtensions === true || userPolicyInput.selectedProduct.includeParentProductInExtensions + "" === "null")) {
      console.log('Success | Include Parent is options  == ' + userPolicyInput.selectedProduct.includeParentProductInExtensions)
      userPolicyInput?.selectedProduct?.productExtensions?.push(userPolicyInput?.selectedProduct)
      setuserPolicyInput(userPolicyInput);
    } else {
      console.log('Failure | Include Parent is options  == ' + userPolicyInput.selectedProduct.includeParentProductInExtensions)
    }

    if (!validator) return

    setBeneficiaries([...beneficiaries, { name: "", surname: "", relationship: "", dateOfBirth: "", idNumber: "", gender: '', firstName: "", lastName: "", phoneNumber: "", selectedProduct: userPolicyInput.selectedProduct, beneficiaryAge: 0, initials: "", isTertiary: '', active: false }]);
    setAllFormsInValid(false)
    setErrorMessage("");
  };

  function alterBeneficiaryArray(arr, index, newValue) {
    if (index >= 0 && index < arr.length) {
      arr[index] = newValue;
    } else {
      // console.error('Index out of range.');
    }
  }

  function clearContextErrors(index) {

    if (contextErrors.length > 0) {
      const newErrors = contextErrors.filter((element) => element.index !== index);

      setContextErrors(newErrors);
    }

  }

  function validateName(element, index) {
    try {
      clearContextErrors(index)
      if (element.name === '') {
        return { 'index': index, field: 'name', errorMessage: 'Please enter your name' }

      }
      if (element.name && element.name.split(" ").length < 2) {
        return { 'index': index, field: 'name', errorMessage: 'Please enter your full name' }
      } else {
        return true
      }
    } catch (error) {
      // console.log(error)
    }
  }

  // function validateRelationship(element, index) {

  //   clearContextErrors(index)
  //   var numOfSpouse = 0;

  //   beneficiaries.forEach((element1) => {
  //     if (element1.relationship === "Spouse") {
  //       numOfSpouse = numOfSpouse + 1
  //     }
  //   });

  //   if(mainMemberProduct.CanHaveSpouse === 0 && element.relationship ==='Spouse'){
  //     return { 'index': index, field: 'relationship', errorMessage: 'The main member product does not allow spouse beneficiaries' }
  //   }

  //   if(mainMemberProduct.CanHaveChildren === 0 && element.relationship ==="Child"){
  //     return { 'index': index, field: 'relationship', errorMessage: 'The main member product does not allow children beneficiaries.' }
  //   }

  //   if (numOfSpouse > 1) {

  //     return { 'index': index, field: 'relationship', errorMessage: 'You can not have more than one spouse.' }
  //   }
  //   else if (element.relationship === '') {
  //     return { 'index': index, field: 'relationship', errorMessage: 'Please select your relationship with the beneficiary.' }
  //   }

  //   if (element.relationship === 'Child' && element.beneficiaryAge > 23) {
  //     changeRelationshipFromChild(index)
  //   }
  //   else {
  //     return true
  //   }

  // }
  function validateRelationship(element, index) {
    // console.log("mainMemberProduct")
    // console.log(userPolicyInput.selectedProduct.productExtensions)
    // console.log("userPolicyInput.selectedProduct")
    clearContextErrors(index)
    var numOfSpouse = 0;

    beneficiaries.forEach((element1) => {
      if (element1.relationship === "Spouse") {

        if (mainMemberProduct.CanHaveChildren === 0) {
          return { 'index': index, field: 'relationship', errorMessage: 'Sorry, the product you selected can not have spouse as a beneficiary' }
        }
        numOfSpouse = numOfSpouse + 1
      }
    });

    if (Number(mainMemberProduct.CanHaveSpouse) === 0 && element.relationship === 'Spouse') {
      return { 'index': index, field: 'relationship', errorMessage: 'The main member product does not allow spouse beneficiaries' }
    }

    if (userPolicyInput.selectedProduct.productExtensions && userPolicyInput.selectedProduct.productExtensions.length === 0 && element.relationship === 'Relative') {
      return { 'index': index, field: 'relationship', errorMessage: 'This product does not allow relative beneficiaries' }
    }

    if (Number(mainMemberProduct.CanHaveChildren) === 0 && element.relationship === "Child") {
      return { 'index': index, field: 'relationship', errorMessage: 'The main member product does not allow children beneficiaries.' }
    }

    if (numOfSpouse > 1) {

      return { 'index': index, field: 'relationship', errorMessage: 'You can not have more than one spouse.' }
    }
    else if (element.relationship === '') {
      return { 'index': index, field: 'relationship', errorMessage: 'Please select your relationship with the beneficiary.' }
    }

    if (element.selectedProduct && (element.relationship === 'Child' && element.beneficiaryAge > Number(element.selectedProduct.ageTo))) {
      changeRelationshipFromChild(index)
    }

    return true

  }

  function changeRelationshipFromChild(index) {
    let arr = []
    beneficiaries.forEach((element, index1) => {
      if (index === index1) element.relationship = 'Relative'
      arr.push(element)
    })

    showToastMessage("Policy Guide", "You are not allowed to have a child beneficiary above 23, we have changed the relationship to relative. Please review", "info")

    setBeneficiaries(arr)
  }
  function validateDateOfBirth(element, index) {
    // console.log("validate age element")
    // console.log(element)
    // console.log("validate age element")
    clearContextErrors(index)
    const benAge = beneficiaryMembersJS.validateAge(element.dateOfBirth);

    if (element.selectedProduct && (element.relationship === 'Child' && benAge > Number(element.selectedProduct.ageTo))) {
      // showToastMessage ("Relationship changed", 'Beneficiary not qualified to be a child beneficiary', 'info')
      // toast.current.show({ severity: 'info', summary: 'Sticky', detail: '', sticky: true });
      showToastMessage("Policy Guide", "You are not allowed to have a child beneficiary above " + Number(element.selectedProduct.ageTo) + ", we have changed the relationship to relative. Please review", "info")
      changeRelationshipFromChild(index)
    }

    if (element.dateOfBirth === '' || element.dateOfBirth === null) {
      return { 'index': index, errorMessage: 'Please select date of birth.' }
    }
    else if (element.selectedProduct && (benAge > Number(element.selectedProduct.ageTo) || benAge < Number(element.selectedProduct.ageFrom))) {
      return { 'index': index, errorMessage: 'Invalid beneficiary age, accepted age limit is between ' + Number(element.selectedProduct.ageFrom) + ' and ' + Number(element.selectedProduct.ageTo) + '.' }
    }
    else {
      return true
    }

  }

  function validateIdNumber(element, index) {
    clearContextErrors(index)

    if (element.idNumber === '') {
      return { 'index': index, errorMessage: 'Please enter the ID Number of the beneficiary.' }
    }

    return true

  }

  function validateGender(element, index) {
    clearContextErrors(index)

    if (element.gender === '') {
      return { 'index': index, errorMessage: 'Please select gender of the beneficiary.' }
    }

    return true

  }

  function validateTertiary(element, index) {
    clearContextErrors(index)
    // console.log("Log")
    // console.log(element)

    if (element.dateOfBirth != null) {

      const age = beneficiaryMembersJS.validateAge(element.dateOfBirth);

      if (element.isTertiary === '' && age > 18 && element.relationship === 'Child') {
        return { 'index': index, errorMessage: 'Please tell us whether  the beneficiary is still in tertiary.' }
      }
    }


    return true

  }

  function validateProduct(element, index) {
    clearContextErrors(index)

    // console.log(element)

    if (element.relationship === "Relative") {

      // console.log("START")
      // console.log(element.selectedProduct)
      // console.log(userPolicyInput.selectedProduct.productExtensions)
      // console.log("END")

      if (!element.selectedProduct || !element.selectedProduct.ProductID) {
        // If selectedProduct is not defined or ProductID is missing, exit early.
        return { 'index': index, errorMessage: 'Please select a product for the member added.' };
      }

      const doesExist = userPolicyInput.selectedProduct.productExtensions.some(extension =>
        extension.ProductID === element.selectedProduct.ProductID
      );

      if (!doesExist) {
        return { 'index': index, errorMessage: 'Please select a product for the member added.' };
      }




    }


    return true
  }

  function validateAllFields(element, index) {
    const validationFunctions = [
      validateName,
      validateRelationship,
      validateDateOfBirth,
      validateIdNumber,
      validateGender,
      validateTertiary,
      validateProduct
    ];

    const concatenatedObj = [];
    let isValid = true;

    for (const validateFn of validationFunctions) {
      const result = validateFn(element, index);

      if (typeof result === 'object') {
        concatenatedObj.push(result);
      }

      isValid = isValid && result === true;
    }

    if (isValid) setAllFormsInValid(false)

    setContextErrors(concatenatedObj);
    return isValid;
  }

  function getMainMemberPolicy() {
    userPolicyInput.selectedSubGroupPlan.planProducts.forEach((element) => {

      if (Number(element.ProductID) === Number(userPolicyInput.selectedProduct.ProductID)) {
        setMainMemberProduct(element)
      }


    })

  }


  function populateForExisting() {

    let existingBeneficiaries = []
    if (userPolicyInput.memberSpouse) {
      existingBeneficiaries.push({ name: userPolicyInput.memberSpouse.Name, surname: userPolicyInput.memberSpouse.Surname, relationship: "Spouse", dateOfBirth: userPolicyInput.memberSpouse.Date_of_Birth, idNumber: userPolicyInput.memberSpouse.ID_Num, gender: userPolicyInput.memberSpouse.Gender, firstName: "", lastName: "", phoneNumber: userPolicyInput.memberSpouse.Telephone, selectedProduct: userPolicyInput.selectedProduct, beneficiaryAge: userPolicyInput.memberSpouse.Age, initials: userPolicyInput.memberSpouse.Initials, isTertiary: '', active: false });
    }

    if (userPolicyInput.memberChild && userPolicyInput.memberChild.length > 0) {
      userPolicyInput.memberChild.forEach(element => {

        // console.log(element)
        existingBeneficiaries.push({ name: element.Name, surname: element.Surname, relationship: "Child", dateOfBirth: element.Date_of_birth, idNumber: element.ID_num, gender: element.Gender, firstName: "", lastName: "", phoneNumber: element.Telephone, selectedProduct: userPolicyInput.selectedProduct, beneficiaryAge: element.Age, initials: element.Initials, isTertiary: element.AtVarsity ? 'YES' : 'NO', active: false });
      });
    }

    setBeneficiaries(existingBeneficiaries)
  }

  useMountEffect(() => {
    getMainMemberPolicy()
    populateForExisting()

  });



  const moveToNext = (e) => {

    // let validator = true;
    // beneficiaries.forEach((element, index) => {
    //   validator = validateAllFields(element, index)
    //   if (!validator) return
    // })

    let validator = true;
    beneficiaries.forEach((element, index) => {

      validator = validateAllFields(element, index)

    })

    if (!validator) return


    var errorsExist = false

    const errors = [];

    for (const [index, beneficiary] of beneficiaries.entries()) {

      if (!beneficiaryMembersJS.validateFields(beneficiary)) {

        errors.push({ index: index, field: 'allerrors', errorMessage: `Please fill in all fields for beneficiary ${index + 1}` });
      }
    }

    if (errors.length > 0) {
      setContextErrors(errors);
      return;
    }

    const calculateAge = (dateOfBirth) => {
      const birthDate = new Date(dateOfBirth);
      const currentDate = new Date();

      const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
      const monthsDiff = currentDate.getMonth() - birthDate.getMonth();

      if (monthsDiff < 0 || (monthsDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
        return yearsDiff - 1;
      } else {
        return yearsDiff;
      }
    };

    beneficiaries.forEach((beneficiary) => {

      const beneficiaryDateOfBirth = beneficiary.dateOfBirth
      const beneficiaryAge = calculateAge(beneficiaryDateOfBirth);
      if (beneficiary.selectedProduct && (beneficiaryAge < Number(beneficiary.selectedProduct.ageFrom) && beneficiaryAge > Number(beneficiary.selectedProduct.ageTo))) {

      }

      if (beneficiary.relationship === "Spouse") {

        memberSpouse = ({
          Name: beneficiary.name,
          Initials: beneficiary.initials,
          Surname: beneficiary.lastName,
          ID_Num: beneficiary.idNumber,
          DateOfBirth: beneficiaryMembersJS.formatDateToDDMMYYYY(beneficiaryDateOfBirth),
          Gender: beneficiary.gender,
          Incep_Date: beneficiaryMembersJS.formatDateToDDMMYYYY("2023-08-03"),
          Telephone: beneficiary.phoneNumber,
          Cover: 0,
        });
      } else if (beneficiary.relationship === "Child") {
        memberChild.push({
          FirstName: beneficiary.firstName,
          LastName: beneficiary.lastName,
          IdNumber: beneficiary.idNumber,
          DateOfBirth: beneficiaryMembersJS.formatDateToDDMMYYYY(beneficiaryDateOfBirth),
          Gender: beneficiary.gender,
          InceptionDate: beneficiaryMembersJS.formatDateToDDMMYYYY("2023-08-03"),
          AtVarsity: false,
          Cover: 0,
          Telephone: beneficiary.phoneNumber,
          waitingPeriod: 0,
        });
      } else if (beneficiary.relationship === "Grandfather" || beneficiary.relationship === "Grandmother") {
        memberExtended.push({
          ProductId: beneficiary.selectedProduct,
          FirstName: beneficiary.firstName,
          LastName: beneficiary.lastName,
          IdNumber: beneficiary.idNumber,
          DateOfBirth: beneficiaryMembersJS.formatDateToDDMMYYYY(beneficiaryDateOfBirth),
          Gender: beneficiary.gender,
          InceptionDate: beneficiaryMembersJS.formatDateToDDMMYYYY("2023-08-03"),
          Relationship: beneficiary.relationship.toUpperCase(),
          Telephone: beneficiary.phoneNumber,
          Cover: 0,
        });
      } else {
        memberBeneficiary.push({
          ProductId: beneficiary.selectedProduct.ProductID,
          FirstName: beneficiary.firstName,
          LastName: beneficiary.lastName,
          IdNumber: beneficiary.idNumber,
          DateOfBirth: beneficiaryMembersJS.formatDateToDDMMYYYY(beneficiaryDateOfBirth),
          Gender: beneficiary.gender,
          InceptionDate: beneficiaryMembersJS.formatDateToDDMMYYYY("2023-08-03"),
          Relationship: beneficiary.relationship.toUpperCase(),
          Telephone: beneficiary.phoneNumber,
          Cover: 0,
        });
      }
    });
    const payload = {
      "operation": "createPolicyApplicationStage2",
      "requestBody": {
        "planId": userPolicyInput.selectedPlanName,
        "planName": userPolicyInput.selectedPlanID,
        "productId": userPolicyInput.selectedProductId,
        "productName": userPolicyInput.selectedProductName,
        "applicationTotalStages": 5,
        "currentStages": 3,
        "applicationType": userPolicyInput.selectedPlanType,
        "mainMember": userPolicyInput.mainMember,
        "memberSpouse": memberSpouse,
        "memberChild": memberChild,
        "memberExtended": memberExtended,
        "memberBeneficiary": memberBeneficiary

      }
    }
    setuserPolicyInput({ ...userPolicyInput, memberSpouse: memberSpouse, memberChild: memberChild, memberExtended: memberExtended, memberBeneficiary: memberBeneficiary });
    submitStage2toServer(payload);

  }


  const confirm1 = (event, index) => {
    confirmPopup({
      target: event.originalEvent.target,
      message: event.value === "YES" ? 'Are you sure this beneficiary is a tertiary student?' : 'Kindly confirm this beneficiary is not a tertiary student?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => acceptTertiary(event, index),
      reject: () => rejectTertiary(event, index)
    });
  };


  const acceptTertiary = (e, index) => {

    const updatedBeneficiaries = [...beneficiaries];
    if (e.value === 'YES') {
      updatedBeneficiaries[index].isTertiary = e.value;
      setBeneficiaries(updatedBeneficiaries);
    }
    else {
      updatedBeneficiaries[index].relationship = "Relative";
      setBeneficiaries(updatedBeneficiaries);
      showToastMessage("Policy Guide", "Your child beneficiary is above 18 and not in tertiary education, we have changed the relationship to relative. Please review", "info")
    }

  };

  const rejectTertiary = (e, index) => {

  };

  function ProductDropdown({ index, selectedProduct, handleProductChange, planProducts }) {
    // console.log("/n/n/n/n/n/n/n -------")
    // console.log(userPolicyInput)
    // console.log(mainMemberProduct)

    // const arr = planProducts.filter((product, index) => {
    //   const a = product.CanHaveChildren === "0" && product.Total <= mainMemberProduct.Total
    //   // console.log(index + "First Part requests "+(product.CanHaveChildren === "0"))
    //   // console.log(index + "Second part "+(product.Total <= mainMemberProduct.Total)+ "=== values ===product = "+product.Total +" main member ="+mainMemberProduct.Total)
    //   // console.log(index + "All requests "+a)
    //   return a
    // });

    //  if (selectedProduct && selectedProduct.includeParentProductInExtensions  && selectedProduct.includeParentProductInExtensions=== true){
    //   console.log('SOLVING ISSUES')
    //   console.log(selectedProduct)
    //   console.log(selectedProduct.includeParentProductInExtensions)
    //   console.log(planProducts.productExtensions)
    //  }else{
    //   console.log('SOLVING ISSUES')
    //   console.log(selectedProduct)
    //   console.log(selectedProduct.includeParentProductInExtensions)
    //  }
    return (
      <Dropdown
        id={`product${index}`}
        name="selectedProduct"
        value={selectedProduct}
        onChange={(e) => handleProductChange(e, index)}
        options={planProducts.productExtensions}
        required
        optionLabel="ProductName1"
        placeholder={planProducts.productExtensions.length === 0 ? "No products for additional beneficiaries in this package" : "Select a product"}
        className="w-full md:w-6rem"
      />
    );
  }

  const handleRemoveBeneficiary = (index) => {
    const updatedBeneficiaries = [...beneficiaries];
    updatedBeneficiaries.splice(index, 1);
    setBeneficiaries(updatedBeneficiaries);
  };



  const handleBeneficiaryChange = (e, index) => {

    const { name, value } = e.target;

    const updatedBeneficiaries = [...beneficiaries];
    if (name === "dateOfBirth") {
      const benAge = beneficiaryMembersJS.validateAge(value);
      updatedBeneficiaries[index]["beneficiaryAge"] = benAge;
    }

    if (name === "name") {
      const nameDetails = beneficiaryMembersJS.generateNameDetails(value);
      updatedBeneficiaries[index].firstName = nameDetails.firstName;
      updatedBeneficiaries[index].lastName = nameDetails.lastName;
      updatedBeneficiaries[index].initials = nameDetails.initials;
    }

    updatedBeneficiaries[index][name] = value;
    setBeneficiaries(updatedBeneficiaries);
  };


  const handleGenderChange = (e, index) => {
    const { name, value } = e.target;
    const updatedGender = [...beneficiaries];
    updatedGender[index][name] = value;
    setBeneficiaries(updatedGender);
  }

  const handleProductChange = (e, index) => {


    const { name, value } = e.target;
    const updatedProduct = [...beneficiaries];
    updatedProduct[index][name] = value;
    setBeneficiaries(updatedProduct);
  }

  const showToastMessage = (title, message, messageType) => {
    toast.current.show({ severity: messageType, summary: title, detail: message, life: 15000 });
  }

  const relationshipOptions = [
    "Spouse",
    "Child",
    "Relative",
  ];

  const submitStage2toServer = async (payload) => {
    showToastMessage("Progress Saved", userPolicyInput.mainMemberName + " your progress will be updated after confirmation", "success");
    moveToNextView();
  }

  return (
    <div>
      <ConfirmPopup />
      <BlockUI blocked={loading}>
        <center>
          <div className="row g-2 flex-nowrap" style={{ overflowX: 'auto' }}>
            {beneficiaries.map((beneficiary, index) => (
              <Card className="m-2 col-xxl-3 col-xl-3 col-lg-3" key={index}>
                <div className="row g-2">

                  <>
                    <h4><small>Add Beneficiary ({index + 1})</small></h4>
                    <Divider className="m-1 p-0" />
                    <Button
                      onClick={() => handleRemoveBeneficiary(index)}
                      className="justify-content-center text-center mt-4"
                      size="small"
                      label={<>Remove Beneficiary</>}
                      icon="pi pi-user-minus"
                      severity="warn"
                      key={index}
                      rounded
                      raised
                      outlined
                    />

                  </>
                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <input
                      type="text"
                      className="form-control"
                      id={`name${index}`}
                      name="name"
                      placeholder=" Full Name"
                      value={beneficiary.name}
                      onChange={(e) => {
                        validateAllFields({ "name": e.target.value }, index)
                        handleBeneficiaryChange(e, index)
                      }
                      }
                      required
                    />
                  </div>

                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <select
                      className="form-select"
                      id={`relationship${index}`}
                      name="relationship"
                      value={beneficiary.relationship}
                      onChange={(e) => {
                        validateAllFields({ "relationship": e.target.value }, index)
                        handleBeneficiaryChange(e, index)
                      }
                      }
                      required
                    >
                      <option value="">Select Relationship</option>
                      {relationshipOptions.map((option) => (
                        <>
                          {
                            (
                              userPolicyInput.selectedProduct.productExtensions
                              && userPolicyInput.selectedProduct.productExtensions.length === 0
                              && option === "Relative")
                              //THE LOGIC BELOW IS MEANT TO REMOVE THE OPTION FOR CHILDREN AND SPOUSE BENS FOR CASH PLAN ONLY
                              || (userPolicyInput.selectedSubGroupPlan.category === "Cash plan" && (option === "Child" || option === "Spouse"))

                              ? <></>
                              :
                              <option key={option} value={option}>{option}</option>}</>

                      ))}
                    </select>
                  </div>

                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <select
                      className="form-select"
                      id={`gender${index}`}
                      name="gender"
                      value={beneficiary.gender}
                      onChange={(e) => {
                        validateAllFields(beneficiary, index)
                        handleGenderChange(e, index)
                      }
                      }
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="MALE"> MALE </option>
                      <option value="FEMALE"> FEMALE </option>

                    </select>
                  </div>

                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <input
                      type="date"
                      className="form-control"
                      id={`dateOfBirth${index}`}
                      name="dateOfBirth"
                      placeholder="Date of Birth"
                      value={beneficiary.dateOfBirth}
                      onChange={(e) => {
                        validateAllFields(beneficiary, index)
                        handleBeneficiaryChange(e, index)
                      }
                      }
                      required
                    />
                  </div>

                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <input
                      type="text"
                      className="form-control"
                      id={`idNumber${index}`}
                      name="idNumber"
                      placeholder="ID Number"
                      value={beneficiary.idNumber}
                      onChange={(e) => {
                        validateAllFields(beneficiary, index)
                        handleBeneficiaryChange(e, index)
                      }
                      }
                      required
                    />
                  </div>
                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <input
                      type="text"
                      className="form-control"
                      id={`phoneNumber${index}`}
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={beneficiary.phoneNumber}
                      onChange={(e) => {
                        validateAllFields(beneficiary, index)
                        handleBeneficiaryChange(e, index)
                      }
                      }
                      required
                    />
                  </div>

                  {beneficiary.relationship === "Relative" ? (
                    <div className="card flex justify-content-center">

                      {userPolicyInput.selectedSubGroupPlan && (
                        <ProductDropdown
                          index={index}
                          selectedProduct={beneficiary.selectedProduct}
                          handleProductChange={handleProductChange}
                          planProducts={userPolicyInput.selectedProduct}
                        />
                      )}
                    </div>
                  ) : null}

                  {beneficiary.relationship === "Child" && beneficiary.beneficiaryAge > 18 ? (
                    <>
                      <div className="col-xxl-12 col-xl-12 col-lg-12 ">
                        <span>
                          <label className="form-label"><small>Is beneficiary a tertiary student? </small></label>
                          <div className="card flex flex-wrap gap-2 justify-content-center">
                            <SelectButton disabled={beneficiary.active} name="selectButton" id="selectButton" value={beneficiary.isTertiary} label="Confirm" onChange={(e) => {

                              if (e.value === null && beneficiary.isTertiary === "YES") e.value = "YES"
                              else if (e.value === null && beneficiary.isTertiary === "NO") e.value = "NO"

                              if (e.value === beneficiary.isTertiary) return true

                              confirm1(e, index)
                            }}
                              options={['YES', 'NO']} />
                          </div>
                        </span>
                      </div>
                    </>

                  ) : (
                    <></>
                  )}

                  <div>

                    <div>
                      {contextErrors.map((element, index1) => (
                        index === element.index && (
                          <div key={index1}>
                            <br />
                            <div className="alert alert-danger">{element.errorMessage}</div>
                          </div>
                        )
                      ))}
                    </div>

                  </div>


                </div>

                <Dialog header="Quotation" visible={visible} style={{ width: '60vw' }} onHide={() => setVisible(false)}>

                  <DataTable value={quoteParams} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="name" header="Name"></Column>
                    <Column field="relationship" header="Relationship"></Column>
                    <Column field="totalContribution" header="Premium Contribution"></Column>
                  </DataTable>
                </Dialog>
                {index + 1 === beneficiaries.length && (
                  <Button
                    className="justify-content-center text-center mt-4"
                    size="small"
                    onClick={() => {
                      setQuoteParams(beneficiaryMembersJS.generateQuote(userPolicyInput.mainMember, mainMemberProduct, beneficiaries))
                      setVisible(true)
                    }}
                    label={<>Info </>}
                    severity="info"
                    raised
                  />
                )}
              </Card>
            ))}
            <div
              style={{
                border: '2px dotted #ccc',
                borderRadius: '15px',
                padding: '10px',
                margin: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: "50%",
                minHeight: "35em"

              }}
              className="m-4 col-xxl-3 col-xl-3 col-lg-3"
            >
              <Avatar icon="pi pi-user-plus" className="mr-2" size="xlarge" />
              <p><small>Click the button below to add beneficiaries to your policy</small></p>
              <Divider />
              <Button
                onClick={handleAddBeneficiary}
                className="justify-content-center text-center mt-4"
                size="small"
                label={<>Add Beneficiary</>}
                icon="pi pi-user-plus"
                severity="info"
                rounded
                raised
              />
            </div>

          </div>
          <div className="text-center">
            <Button onClick={moveToNext} className='justify-content-center text-center mt-4' size='large' outlined label={<><strong >Continue </strong></>} icon="pi pi-arrow-right" severity="info" rounded raised />
          </div>
        </center>
      </BlockUI>
      <center>
      </center>
    </div>
  );
};

export default BeneficiaryMembersForm;
