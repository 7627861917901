import React, { useState, useRef } from "react";
import Header from '../../layout/headers/header'
import DynamicNavigation from "../../layout/navigation/dynamic-navigation"
import SelectPlanTypeComponent from "./components/policyType/selectPlanTypeComponent"
import TitleComponent from "./components/title/titleComponent";
import PolicyPlansComponent from "./components/plans/policyPlansComponent"
import SelectProductBenefitForm from "./components/policyForm/SelectProductBenefitForm";
import PrincipalMemberForm from './components/policyForm/PrincipalMemberForm';
import BeneficiaryMembersForm from './components/policyForm/BeneficiaryMembersForm';
import BankingDetailsForm from './components/policyForm/BankingDetailsForm';
import ConfirmationForm from './components/policyForm/ConfirmationForm';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import axios from 'axios'
import { Dialog } from 'primereact/dialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { useMountEffect } from "primereact/hooks";
const CreateNewPolicyPage = ({ existingApplicationProgress, isLifePolicy },) => {

  const viewList = [

    // <SelectPlanTypeComponent />,
    // <BeneficiaryMembersForm />,


    <PolicyPlansComponent />,
    <SelectProductBenefitForm />,
    <PrincipalMemberForm />,
    <BankingDetailsForm />,
    <BeneficiaryMembersForm />,
    <ConfirmationForm />

  ]
  const viewListTitles = [
    // { "topHeading": "Create a new Doves Life Assurance Policy", "middleHeading":"Select an option below to get started!" },
    // { "topHeading": "Create a new Doves Life Assurance Policy", "middleHeading":"Select your policy type" },
    { "topHeading": "Select a plan right for you", "middleHeading": "Create a new Doves Life Assurance Policy" },
    { "topHeading": "Policy Benefits", "middleHeading": "Create a new Doves Life Assurance Policy" },
    { "topHeading": "Fill in Principal Member details", "middleHeading": "Create a new Doves Life Assurance Policy" },
    { "topHeading": "Fill in Banking details", "middleHeading": "Create a new Doves Life Assurance Policy" },
    { "topHeading": "Fill in Beneficiaries Members details", "middleHeading": "Create a new Doves Life Assurance Policy" },
    { "topHeading": "Confirm your policy details", "middleHeading": "Create a new Doves Life Assurance Policy" },
  ];
  const [activeView, setactiveView] = useState(0);
  const [userPolicyInput, setuserPolicyInput] = useState({});

  const toast = useRef(null);
  const moveToNextView = () => {
    setactiveView(activeView + 1);
    if (activeView < viewList.length) setactiveView(activeView + 1);
  }
  const moveToPreviousView = () => {
    if (activeView != 0) setactiveView(activeView - 1);
  }
  const [initialRender, setInitialRender] = useState(true); // Add this state

  useMountEffect(() => {
    console.log("-----")

    if (existingApplicationProgress && existingApplicationProgress.applicationDetail && (Array.isArray(existingApplicationProgress.applicationDetail) &&
      existingApplicationProgress.applicationDetail.length > 0) ||
      (typeof existingApplicationProgress.applicationDetail === "object" &&
        existingApplicationProgress.applicationDetail !== null &&
        Object.keys(existingApplicationProgress.applicationDetail).length > 0) && initialRender) {
      reOpenCurrentState();
      setVisible(true)
      // confirm();
      setInitialRender(false);
    }
  }, [existingApplicationProgress, initialRender]);


  async function reOpenCurrentState() {
    if (existingApplicationProgress && existingApplicationProgress.applicationDetail && (Array.isArray(existingApplicationProgress.applicationDetail) &&
      existingApplicationProgress.applicationDetail.length > 0) ||
      (typeof existingApplicationProgress.applicationDetail === "object" &&
        existingApplicationProgress.applicationDetail !== null &&
        Object.keys(existingApplicationProgress.applicationDetail).length > 0)) {
     
      console.log(existingApplicationProgress.applicationDetail);
      setuserPolicyInput({
        ...userPolicyInput,
        selectedPlanID: existingApplicationProgress.applicationDetail.productId,
        selectedPlanName: existingApplicationProgress.applicationDetail.productName,
        selectedProduct: existingApplicationProgress.applicationDetail.selectedProduct,
        selectedSubGroupPlan: existingApplicationProgress.applicationDetail.selectedPlan,
        mainMember: existingApplicationProgress.applicationDetail.mainMember,
        mainMemberName: existingApplicationProgress.applicationDetail.mainMember.First_Name,
        memberSpouse: existingApplicationProgress.applicationDetail.memberSpouse,
        memberChild: existingApplicationProgress.applicationDetail.memberChild,
        memberExtended: existingApplicationProgress.applicationDetail.memberExtended,
        memberBeneficiary: existingApplicationProgress.applicationDetail.memberBeneficiary,
        bankDetail: existingApplicationProgress.applicationDetail.bankAccount
      });

      console.log("\n\n\n\n\n\n\n\n ------ ")
      console.log({
        ...userPolicyInput,
        selectedPlanID: existingApplicationProgress.applicationDetail.productId,
        selectedPlanName: existingApplicationProgress.applicationDetail.productName,
        selectedSubGroupPlan: existingApplicationProgress.applicationDetail.selectedPlan,
        mainMember: existingApplicationProgress.applicationDetail.mainMember,
        mainMemberName: existingApplicationProgress.applicationDetail.mainMember.First_Name,
        memberSpouse: existingApplicationProgress.applicationDetail.memberSpouse,
        memberChild: existingApplicationProgress.applicationDetail.memberChild,
        memberExtended: existingApplicationProgress.applicationDetail.memberExtended,
        memberBeneficiary: existingApplicationProgress.applicationDetail.memberBeneficiary,
        bankDetail: existingApplicationProgress.applicationDetail.bankAccount
      });

      switch (existingApplicationProgress.applicationDetail.currentStages) {
        case 1:
          setactiveView(3)
          break;
        case 2:
          setactiveView(4)
          break;
        case 3:
          setactiveView(5)
          break;
        default:
          break;
      }
    }
  }

  const toastRestore = useRef(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const clear = (submit) => {
    toastRestore.current.clear();
    submit && reOpenCurrentState();
    setIsConfirmationOpen(false); // Close the confirmation
  };

  // const confirm = () => {
  //     setIsConfirmationOpen(true); // Open the confirmation
  // };

  const confirm = () => {
    setIsConfirmationOpen(true);
    console.log("CONFIRM ________-___")
    console.log(userPolicyInput)
    toastRestore.current.show({
      severity: 'info',
      sticky: true,
      className: 'border-none',
      content: (
        <> {isConfirmationOpen && (
          <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
            <div className="text-center">
              <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
              <div className="font-bold text-xl my-3">Continue your pending application details?</div>
            </div>
            <div className="flex gap-2">

            </div>
          </div>)}</>
      )
    });
  };

  const deleteApplication = async () => { 
    try {
     

      await axios.get(process.env.REACT_APP_DOVESAPI + 'policy/track/' + userPolicyInput.mainMember.ID_Number+"/delete", {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      }).then((response) => {

        if (response.data.status === "SUCCESS") {
          console.log(response);
          window.location.reload();
        } else {
          console.log(response.data.status)
        }
        // console.log(userProfile.idnumber);
        // // console.log(response.data);
        // setLoading(false)
        // setpolicyDisplay(parseTrackApplicationResponse(response.data, 0))
      });

    } catch (error) {
      // setLoading(false)
      console.error('Error clearing balance details:', error);
    }
  };

  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div>
      <Button
        onClick={(e) =>  deleteApplication()}
        className='justify-content-center text-center m-2'
        size='large'
        label='Delete Application'
        icon="pi pi-times-circle"
        severity="danger"
        rounded
        raised
        style={{ color: "white" }} // Add this style to set the button text color
      />
       <Button
        onClick={(e) => {setVisible(false)}}
        className='justify-content-center text-center m-2'
        size='large'
        label='Continue'
        icon="pi pi-check-circle"
        severity="success"
        rounded
        raised
        style={{ color: "white" }} // Add this style to set the button text color
      />
      <Button
        onClick={(e) => { setVisible(false) }}
        className='justify-content-center text-center m-2'
        size='large'
        label='Start New Application'
        icon="pi pi-tags"
        severity="info"
        rounded
        raised
        style={{ color: "white" }} // Add this style to set the button text color
      />
    </div>
  );

  return (
    <>
      <DynamicNavigation activeItem="2" />
      <Toast ref={toast} />
      <Toast ref={toastRestore} position="bottom-center" />
      <Dialog visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
        <h2>Continue with your pending application?</h2>
        <Accordion activeIndex={0}>
          {/* multiple  */}
          <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Plan & Policy Benefits </h4>}>
            <div className=' justify-content-start'>
              <h4 className='mt-1 mb-2 --blue text-left'>
                <strong>Selected Plan/Product</strong> : {userPolicyInput.selectedPlanID && userPolicyInput.selectedPlanName}
              </h4> 
            </div>
          </AccordionTab>
          <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Principal Member Details </h4>}>
            <DataTable value={[userPolicyInput.mainMember]} tableStyle={{ minWidth: '50rem' }}>
              <Column field='ID_Number' header="ID Number"></Column>
              <Column field='Title' header="Title"></Column>
              <Column field='First_Name' header="First Name"></Column>
              <Column field='Surname' header="Last Name"></Column>
              <Column field='Date_Of_Birth' header="Date Of Birth"></Column>
              <Column field='Gender' header="Gender"></Column>
              <Column field='Marital_Status' header="Marital Status"></Column>
              {/* <Column field='Address' header="Gender"></Column> */}
              <Column field='Cell_Number' header="Cell Number"></Column>
            </DataTable>
          </AccordionTab>
          <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Beneficiary Details </h4>}>
            {userPolicyInput.memberSpouse != {} ? <><h4 className='mt-1 mb-2 --blue text-left'>
              <strong>Spouse Beneficiary Details</strong> :
            </h4>
              <Divider type="dotted" />
              <DataTable value={[userPolicyInput.memberSpouse]} tableStyle={{ minWidth: '50rem' }}>
                <Column field='ID_Num' header="ID Number"></Column>
                <Column field='Name' header="First Name"></Column>
                <Column field='Surname' header="Last Name"></Column>
                <Column field='DateOfBirth' header="Date Of Birth"></Column>
                <Column field='Gender' header="Gender"></Column>
                <Column field='Telephone' header="Phone Number"></Column>
              </DataTable></> : <><small>No Spouse Beneficiary Details</small><Divider type="dotted" /></>}

            {userPolicyInput.memberChild && userPolicyInput.memberChild != [] ?
              <><h4 className='mt-1 mb-2 --blue text-left'>  <strong>Children Beneficiary Details</strong>  : </h4>
                {userPolicyInput.memberChild && userPolicyInput.memberChild.length > 0 && userPolicyInput.memberChild.map((beneficiary) => (
                  <><Divider type="dotted" />
                    <DataTable value={[beneficiary]} tableStyle={{ minWidth: '50rem' }}>
                      <Column field='IdNumber' header="ID Number"></Column>
                      <Column field='FirstName' header="First Name"></Column>
                      <Column field='LastName' header="Last Name"></Column>
                      <Column field='DateOfBirth' header="Date Of Birth"></Column>
                      <Column field='Gender' header="Gender"></Column>
                      <Column field='Telephone' header="Phone Number"></Column>
                    </DataTable></>

                ))}
              </>
              : <><small>No Spouse Beneficiary Details</small><Divider type="dotted" /></>}

            {userPolicyInput.memberExtended && userPolicyInput.memberExtended != [] ?
              <> <h4 className='mt-1 mb-2 --blue text-left'> <strong>Extended Family Beneficiary Details</strong>  : </h4>
                {userPolicyInput.memberExtended.map((beneficiary) => (
                  <>
                    <Divider type="dotted" />
                    <DataTable value={[beneficiary]} tableStyle={{ minWidth: '50rem' }}>
                      <Column field='IdNumber' header="ID Number"></Column>
                      <Column field='FirstName' header="First Name"></Column>
                      <Column field='Surname' header="Last Name"></Column>
                      <Column field='DateOfBirth' header="Date Of Birth"></Column>
                      <Column field='Gender' header="Gender"></Column>
                      <Column field='Relationship' header="Relationship to Principal"></Column>
                    </DataTable>
                  </>
                ))}

              </>
              : <><small>No Extended Family Beneficiary Details</small><Divider type="dotted" /></>}

            {userPolicyInput.memberBeneficiary != [] ?
              <> <h4 className='mt-1 mb-2 --blue text-left'> <strong>Other Beneficiary Details</strong> : </h4>
                {userPolicyInput.memberBeneficiary && userPolicyInput.memberBeneficiary
                  && userPolicyInput.memberBeneficiary.map((beneficiary) => (
                    <>
                      <Divider type="dotted" />
                      <DataTable value={[beneficiary]} tableStyle={{ minWidth: '50rem' }}>
                        <Column field='IdNumber' header="ID Number"></Column>
                        <Column field='Title' header="Title"></Column>
                        <Column field='FirstName' header="First Name"></Column>
                        <Column field='Surname' header="Last Name"></Column>
                        <Column field='DateOfBirth' header="Date Of Birth"></Column>
                        <Column field='Gender' header="Gender"></Column>
                        <Column field='Relationship' header="Relationship to Principal"></Column>
                        <Column field='Telephone' header="Telephone"></Column>
                      </DataTable>
                    </>
                  ))}

              </>
              : <><small>No Other Beneficiary Details</small><Divider type="dotted" /></>}

          </AccordionTab>
          {userPolicyInput.bankDetail && userPolicyInput.bankDetail.length > 0 ? (
            <AccordionTab header={<h4 className='mt-1 mb-2 --blue'>Banking Details </h4>}>
              <DataTable value={[userPolicyInput.bankDetail[0]]} tableStyle={{ minWidth: '50rem' }}>
                <Column field='BankName' header="Bank Name"></Column>
                <Column field='BranchName' header="Branch Name"></Column>
                <Column field='Account_Name' header="Account Name"></Column>
                <Column field='Account_Number' header="Account Number"></Column>
                <Column field='Type_of_Account' header="Type of Account"></Column>
              </DataTable>
            </AccordionTab>
          ) :
            (
              <></>
            )}


        </Accordion>
      </Dialog>
      <div className="">
        <div className="container ">
          {React.cloneElement(<TitleComponent />, {
            titles: viewListTitles[activeView],
            moveToPreviousView: moveToPreviousView,
            activeView: activeView,
            userPolicyInput: userPolicyInput
          })}
          {React.cloneElement(viewList[activeView], {
            moveToNextView: moveToNextView,
            setuserPolicyInput: setuserPolicyInput,
            userPolicyInput: userPolicyInput,
            titles: viewListTitles[activeView],
            toast: toast
          })}
        </div>
      </div>
    </>
  );
};

export default CreateNewPolicyPage;