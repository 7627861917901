import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { Card } from 'primereact/card';


import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const ViewSpouseMemberTable = ({ activeUserData }) => {
  const Users = activeUserData;

  const colsSpouseMember1 = [
    // { field: 'Title', header: 'Title' },
    { field: 'Name', header: 'Name' },
    { field: 'ID_Num', header: 'ID number' },
    { field: 'Date_of_Birth', header: 'Date Of Birth' },
    { field: 'Age', header: 'Age' },
    { field: 'Telephone', header: 'Phone' },
  ];

  const colsSpouseMember2 = [
    { field: 'Incep_Date', header: 'Date Joined' },
    { field: 'waitingPeriod', header: 'Waiting Period' },
    { field: 'PolicyMaturityDate', header: 'Maturity Date' },
  ];

  return (
    <div className="card home-chart">
      <Card>
      <center><h2 className="card-title home-chart">SPOUSE MEMBER DETAILS</h2></center>


      <div className="card-body">
        <DataTable
          value={[Users.memberSpouse]}
          tableStyle={{ minWidth: '50rem' }}
          emptyMessage="No Users found"
        >
          {colsSpouseMember1.map((col, index) => (
            <Column key={index} field={col.field} header={col.header} />
          ))}
        </DataTable>

        <br />

        <DataTable
          value={[Users.memberSpouse]}
          tableStyle={{ minWidth: '50rem' }}
          emptyMessage="No Users found"
        >
          {colsSpouseMember2.map((col, index) => (
            <Column key={index} field={col.field} header={col.header} />
          ))}
        </DataTable>
      </div>
      </Card>
    </div>
  );
};

export default ViewSpouseMemberTable;
