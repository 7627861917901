import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Header from '../layout/headers/header'
import CustomerNavMenu from "../layout/navigation/topbar/CustomerNavMenu"
import DynamicDashboard from "../layout/dashboard/dynamic-dashboard"
import DynamicNavigation from "../layout/navigation/dynamic-navigation"

const Dashboard = () => {
    return (
        <>
            <Header />
            <DynamicNavigation/>
            <DynamicDashboard/>
        </>
    );
};

export default Dashboard;