
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import { Link, useHistory } from 'react-router-dom';
import image1 from '../../../../../images/products/1.jpg'
import image2 from '../../../../../images/products/2.jpg'
import image3 from '../../../../../images/products/3.jpg'
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import { BlockUI } from 'primereact/blockui';
import { useMountEffect } from 'primereact/hooks';
        

export default function ProductCarousel({ moveToNextView, moveToPreviousView, userPolicyInput, setuserPolicyInput }) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allGroups, setallGroups] = useState([]);
    const history = useHistory()
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const moveNext = (selectedPlanID, selectedPlanName, selectedPlan,) => {
        setuserPolicyInput({ ...userPolicyInput, selectedSubGroupPlan: selectedPlan, selectedPlanID: selectedPlanID, selectedPlanName: selectedPlanName, allGroups: allGroups });
        moveToNextView();
    };

    

function getCategoryFromCurrentUrl() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const category = urlSearchParams.get('category');
  return category;
}

async function getActiveProducts() {
  try {
    const response = await fetch(process.env.REACT_APP_DOVESAPI + 'policy/products/active', {
        headers: {
          Authorization: 'Bearer ' + (localStorage.getItem('token'))
        }
      });
    const data = await response.json();

    if (data.status === 'SUCCESS') {
      const categoryValue = getCategoryFromCurrentUrl();
        let filteredProducts = []
      if( categoryValue !== null){
      filteredProducts = data.responseBody.filter(group => group.category.toUpperCase() === categoryValue.toUpperCase());

      }
      else{
        const productsList = data.responseBody.map(group => group);
        filteredProducts = productsList;
      }

      setProducts(filteredProducts);
      setLoading(false);
    } else {
      setLoading(false);
      console.error('Failed to fetch policy history:', data.message);
    }
  } catch (error) {
    setLoading(false);
    console.error('Error fetching policy history:', error);
  }
}

    useMountEffect(() => {
        setLoading(true)
        getActiveProducts()
    }, []);

    const handleLearnMore = (id) => {

        // history.push(`/onboarding?id=${id}`);
        // route this to "/onboarding/id=id""
    }


    const productTemplate = (group) => {
        return (
            <div style={{ height: "30em" }} className="border-1 surface-border border-round m-2 text-center py-1 px-1">
                <div>
                    <div className="mb-3">
                        <img src={group.groupImageBannerUrl} alt={group.Description} className="w-1 shadow-2 rounded" style={{ width: '100%', height: 'auto' }} />
                    </div>
                    <h4>{group.easipolPolicySubGroup.Description}</h4>
                    <div>
                        <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>{"APPLY"}</strong></>} severity="info" rounded outlined raised
                            onClick={() => { moveNext(group.easipolPolicySubGroup.SubGroupID, group.easipolPolicySubGroup.Description, group) }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
          <BlockUI blocked={loading}>
            {loading ? 
            <div className="card">
                <div className="border-round border-1 surface-border p-4 surface-card">
                    <div className="flex mb-3">
                        <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                        <div>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton height=".5rem"></Skeleton>
                        </div>
                    </div>
                    <Skeleton width="100%" height="150px"></Skeleton>
                    <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                    </div>
                </div>
            </div> : <center>
                {/* { console.log(userPolicyInput)} */}
                <div className="container">
                        <div className="row" style={{ overflowY: 'auto' }}>
                            {products.map((group, index) => (
                                <div key={index} className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
                                    <div style={{ height: "auto"}} className="card border-1 surface-border border-round m-2 text-center py-1 px-1">
                                        <div style={{ marginBottom: '15px' }}>
                                            <div className="mb-3">
                                                <img src={group.groupImageBannerUrl} alt={group.Description} className="w-100 shadow-2 rounded" style={{ height: 'auto' }} />
                                            </div>
                                            <h4>{group.easipolPolicySubGroup.Description}</h4>
                                            <div>
                                                <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>{"APPLY"}</strong></>} severity="info" rounded outlined raised
                                                    onClick={() => { moveNext(group.easipolPolicySubGroup.SubGroupID, group.easipolPolicySubGroup.Description, group) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                        </div>
                    </div>
                {/* <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                <div className="row g-2 flex-nowrap" style={{ overflowX: 'auto' }}>
                    {products.map((group, index) => (
                        <div style={{ height: "30em" }} key={index} className="card col-xxl-3 col-xl-3 col-md-3 col-lg-3 border-1 surface-border border-round m-2 text-center py-1 px-1">
                            <div>
                                <div className="mb-3">
                                    <img src={group.groupImageBannerUrl} alt={group.Description} className="w-1 shadow-2 rounded" style={{ width: '100%', height: 'auto' }} />
                                    <img src={group.groupImageBannerUrl} alt={group.Description} className="w-1 shadow-2 rounded" style={{ width: '100%', height: '260px' }} />
                                </div>
                                <h4>{group.easipolPolicySubGroup.Description}</h4>
                                <div>
                                    <Button className='justify-content-center text-center mt-4' size='large' label={<><strong color='blue'>{"APPLY"}</strong></>} severity="info" rounded outlined raised
                                        onClick={() => { moveNext(group.easipolPolicySubGroup.SubGroupID, group.easipolPolicySubGroup.Description, group) }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <Carousel className='mt-8' value={products} numScroll={1} numVisible={products.size} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
                </div> */}
            </center>}

            </BlockUI>
        </>)
}
