
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import AreaChart from '../../components/charts/area'
import DonutChart from '../../components/charts/donut'
import PriceArea from '../../components/charts/price-area'
import PolicyBalance from '../../components/paymentsHistory/getPolicyDisplay'
import PolicyPaymentHistory from '../../components/paymentsHistory/PolicyPaymentHistoryTable_'
import profile2 from '../../../images/profile/2.png'
import TopNavBar from "../old/menuBar"
import PaynowBanner from "../../../images/paynow/Paynow Badge-vector-hires LIGHT.svg"
import PaynowButton from "../../../images/paynow/button_make-payment_large.svg"
import { Button } from 'primereact/button';
import ProductCarousel from '../../components/products_services/products_carousel_min'
import MakePayment from '../../components/makePayment/makePayment';
import { Card } from 'primereact/card';
import News from "../../components/news/news_carousel"
import { Divider } from 'primereact/divider';
const CustomerDashboard = () => {
    return (
        <>
            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12">
                            <PolicyBalance />
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerDashboard;